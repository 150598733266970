import React from "react";
import { Tickets } from "../../EventDetail/components";

const DraftTicket = ({ id }) => {
  return (
    <div className="py-6 px-6 w-full">
      <Tickets id={id} />
    </div>
  );
};

export default DraftTicket;
