import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UploadImage } from "../../../components";

function Attachments({ setValue, getValues, control, errors, saveAsDraft }) {
  const { t } = useTranslation();

  useEffect(() => {
    console.log(getValues());
  }, []);

  return (
    <section aria-labelledby="section-1-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="p-6">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
            <label
              htmlFor="cover-photo"
              className={`text-sm flex flex-col font-medium  sm:mt-px sm:pt-2  ${
                errors?.banner_image ? "text-red-400" : "text-gray-700"
              }`}
            >
              {t("attachments:bannerImageRectangle")}

              <label className="text-xs text-gray-500">
                {t("utils:bannerImageDescription")}
              </label>
            </label>

            <UploadImage
              getValues={getValues}
              setValue={setValue}
              control={control}
              name="banner_image"
              width={1920}
              height={710}
            />
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
            <label
              htmlFor="cover-photo"
              className={`text-sm flex flex-col font-medium  sm:mt-px sm:pt-2  ${
                errors?.image ? "text-red-400" : "text-gray-700"
              }`}
            >
              {t("attachments:bannerImageSquare")}
              <label className="text-xs text-gray-500">
                {t("utils:thumbnailImageDescription")}
              </label>
            </label>
            <UploadImage
              getValues={getValues}
              setValue={setValue}
              control={control}
              width={1080}
              height={1080}
              name="image"
            />
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
            <label
              htmlFor="cover-photo"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t("attachments:seatMap")}
            </label>
            <UploadImage
              getValues={getValues}
              setValue={setValue}
              control={control}
              name="seat_map"
            />
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end gap-4">
          {(getValues("type") == "draft" ||
            !window.location.pathname.includes("update-event")) && (
            <button
              onClick={() => {
                saveAsDraft();
              }}
              className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {t("eventForm:saveAsDraft")}
            </button>
          )}
          <button
            type="submit"
            className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {t("eventForm:finish")}
          </button>
        </div>
      </div>
    </section>
  );
}

export default Attachments;
