import React, { useState } from "react";
import { AiOutlineDown } from "react-icons/ai";
import { amountConverter } from "../../../../../utils/stringUtils";

const UsedBlock = ({ coupon }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="flex pt-1 pb-1 w-full flex-col">
      <div
        onClick={() => setIsActive(!isActive)}
        className="flex items-center px-2 py-2 sm:px-6 border w-full rounded rounded-12 hover:bg-gray-100 cursor-pointer"
      >
        <div className="min-w-0 flex items-center w-full">
          <div className="text-sm font-medium text-black truncate w-3/12">
            {coupon.name}
          </div>

          <p className="text-sm font-medium w-3/12 text-primary-500">
            {coupon.totalCount}
          </p>
          <p className="text-sm font-medium pl-5 w-3/12 md:flex xs:hidden text-primary-500">
            {amountConverter(coupon.totalPrice)}
          </p>
          <p className="text-sm font-medium pl-5 w-4/12 text-primary-500">
            {amountConverter(coupon.discountAmount)}
          </p>
        </div>

        <p className="flex items-center text-sm text-gray-800 ">
          <AiOutlineDown />
        </p>
      </div>
      {isActive && (
        <div className="shadow rounded rounded-12">
          {coupon?.types.map((type, i) => {
            return (
              <div
                key={i}
                className="flex items-center px-2 py-2 sm:px-6 border w-full bg-gray-100 "
              >
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="min-w-600 flex-1">
                    <div>
                      <p className="text-sm font-medium text-black truncate">
                        {type.name}
                      </p>
                    </div>
                  </div>

                  <div className="min-w-0 flex px-4 md:grid md:grid-cols-1 md:gap-4 md:flex-1">
                    <p className="text-sm font-medium text-primary-500"></p>
                  </div>
                  <p className="flex items-center text-sm text-gray-800 ">
                    {type.count}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default UsedBlock;
