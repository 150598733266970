import authStore from "../stores/authStore";

const isHaveAccess = (accessRole) => {
  if (
    authStore.roles?.includes(accessRole) ||
    authStore.roles?.includes("all:access")
  )
    return true;
  else if (!accessRole) {
    return true;
  } else return false;
};

export { isHaveAccess };
