import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import {
  CouponForm,
  EventDetails,
  GenericButton,
  Loading,
} from "../../../../components";
import DetailSide from "../../../../components/DetailSide/DetailSide";
import instance from "../../../../lib/client";
import { amountConverter } from "../../../../utils/stringUtils";
import ActiveCoupons from "./components/ActiveCoupons";
import NewCoupon from "./components/NewCoupon";
import UsedCoupons from "./components/UsedCoupons";

function Coupons() {
  const [loading, setLoading] = useState(true);
  const [coupons, setCoupons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [activeCoupons, setActiveCoupons] = useState([]);
  const [event, setEvent] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const handleData = () => {
    Promise.all([
      instance.get(`report/coupon-sales/${id}`),
      instance.get(`coupon/event/${id}`),
      instance.get(`event/${query.get("slug")}`),
    ]).then(([sales, coupon, event]) => {
      setCoupons(sales.data.data.coupons);
      setActiveCoupons(coupon.data.data);
      setEvent(event.data.data);
      setLoading(false);
    });
  };

  const totalTransactionCount = () => {
    var a = 0;
    coupons.forEach((coupon) => (a += coupon.normalAmount));
    return a;
  };

  const totalDiscountAmount = () => {
    var a = 0;
    coupons.forEach((coupon) => (a += coupon.totalPrice));
    return a;
  };

  const onOpenEdit = (couponCode) => {
    setSelectedCoupon(couponCode);
    setIsOpen(true);
  };

  useEffect(() => {
    handleData();
  }, [id]);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="md:grid md:grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 w-full xs:flex xs:flex-wrap-reverse ">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2 w-full">
          <section aria-labelledby="section-1-title w-full">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6 flex flex-row flex-wrap w-full">
                  {activeCoupons.length > 0 && (
                    <div
                      className={`${
                        coupons.length <= 0 ? "md:w-full" : "md:w-2/5"
                      } xs:w-full md:pr-4 xs:pr-0`}
                    >
                      <h2 className="text-lg">{t("coupon:activeCoupons")}</h2>

                      <ActiveCoupons
                        activeCoupons={activeCoupons}
                        onEdit={onOpenEdit}
                      />
                    </div>
                  )}

                  {coupons.length > 0 && (
                    <div
                      className={` ${
                        activeCoupons.length <= 0 ? "md:w-full" : "md:w-3/5"
                      }  xs:w-full  md:pl-4 xs:pl-0 md:mt-0 xs:mt-10`}
                    >
                      <h2 className="text-lg">
                        {t("coupon:couponsUsageRates")}
                      </h2>
                      <UsedCoupons coupons={coupons} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        </div>

        <div className="grid grid-cols-1 w-full gap-4">
          <DetailSide id={id} slug={query.get("slug")} defaultSelectedTab={3} />
        </div>
      </div>
    </div>
  );
}

export default Coupons;
