import React from "react";
import CouponBlock from "./CouponBlock";

const ActiveCoupons = ({ activeCoupons, onEdit }) => {
  return (
    <div
      className="w-full flex flex-col max-h-[700px] overflow-y-auto"
      style={{ width: "100%" }}
    >
      <div className="flex items-center py-2 px-3 border w-full rounded rounded-12">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="w-4/12">
            <div>
              <p className="text-sm font-medium text-black truncate">Kupon</p>
            </div>
          </div>

          <div className="min-w-0 flex px-4 md:grid md:grid-cols-1 md:gap-4 w-5/12">
            <p className="text-sm font-medium text-primary-500">İndirim</p>
          </div>
          <p className="flex items-center text-sm text-gray-500 ">
            Kullanım Oranı
          </p>
        </div>
      </div>
      {activeCoupons.map((coupon, i) => {
        return (
          <CouponBlock key={i} coupon={coupon} onClick={() => onEdit(coupon)} />
        );
      })}
    </div>
  );
};

export default ActiveCoupons;
