import React, { useEffect, useState } from "react";
import instance from "../../../lib/client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { ImPriceTag } from "react-icons/im";
import { isHaveAccess } from "../../../utils/roleUtils";

const TicketItem = ({ provided, ticket, index, onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => onClick(ticket)}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="w-full bg-white border border-gray-300 rounded-md p-2 flex flex-col"
    >
      <div className="w-full flex flex-row justify-between border-b pb-2 border-gray-300">
        <label className="text-xs font-bold p-1">{ticket.name}</label>
        <label
          className={`text-xs p-1 px-2 rounded font-bold ${
            ticket.quantity <= 0
              ? "bg-fourth-500 text-white"
              : ticket.isActive
              ? "bg-primary-500 text-black"
              : "bg-fourth-500 text-white"
          }`}
        >
          {ticket.quantity <= 0
            ? "SOLD OUT"
            : ticket.isActive
            ? t("eventTickets:active")
            : t("eventTickets:deactive")}
        </label>
      </div>
      <div
        className="w-full flex flex-row justify-between pt-1 text-xs items-center font-semibold"
        style={{
          fontSize: "0.7rem",
        }}
      >
        <label className="text-xs font-bold p-1 flex flex-row items-center text-gray-400">
          <ImPriceTag className="mr-1" /> {ticket.value} TL
        </label>
        <label>
          {t("eventTickets:sold") + ": "}{" "}
          <label className="text-primary-500">{ticket.sold}</label>
        </label>
        <label>
          {t("eventTickets:remainingStock") + ": "}{" "}
          <label className="text-primary-500">{ticket.quantity}</label>
        </label>
        <label>
          {t("eventTickets:totalStock") + ": "}
          <label className="text-primary-500">
            {ticket.total_stock
              ? ticket.total_stock
              : ticket.quantity + ticket.sold}
          </label>
        </label>
      </div>
    </div>
  );
};

const TicketTypeList = ({
  eventId,
  setSelectedTicket,
  tickets,
  setTickets,
}) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      tickets,
      result.source.index,
      result.destination.index
    );

    instance.post(`/event/sort-tickets/${eventId}`, items);

    setTickets(items);
  };

  return (
    <div className="mt-4 max-h-[58.4vh] overflow-y-auto">
      {isHaveAccess("eventTicketList") && (
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                    {tickets.map((item, index) => (
                      <Draggable
                        key={item.realId}
                        draggableId={item.realId}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <TicketItem
                            provided={provided}
                            ticket={item}
                            onClick={setSelectedTicket}
                          />
                        )}
                      </Draggable>
                    ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      )}
    </div>
  );
};

export default TicketTypeList;
