import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../lib/client";
import Select from "react-select";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormInput } from "../../../components";
import { Controller, useWatch } from "react-hook-form";
import ArtistBlock from "./ArtistBlock";
import QuestionModal from "../../../components/QuestionModal";
import AsyncSelect from "react-select/async";

function Contents({
  selectedSection,
  setSelectedSection,
  register,
  setValue,
  getValues,
  clearErrors,
  control,
  checkIsValid,
  saveAsDraft,
  errors,
  id,
}) {
  const { t } = useTranslation();
  const [artists, setArtists] = useState([]);
  const [genres, setGenres] = useState([]);
  const [category, setCategory] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [targetUrl, setTargetUrl] = useState("");

  const djs_detail = useWatch({
    control: control,
    name: "djs_detail",
  });

  useEffect(() => {
    Promise.all([
      instance.get("artist"),
      instance.get("category/genre"),
      instance.get("category/event"),
    ]).then(([artists, genres, category]) => {
      setArtists(artists.data.data);
      setGenres(genres.data.data);
      setCategory(category.data.data);
    });
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      getValues("djs_detail"),
      result.source.index,
      result.destination.index
    );

    setValue("djs_detail", items);
  };

  const returnSelectData = (datas) => {
    var a = [];

    datas?.forEach((data) => {
      a.push({
        id: data._id,
        label: data.name
          ? data.name
          : data.currency_symbol + " - " + data.currency_name,
        value: data.slug ? data.slug : data.currency_symbol,
        image: data.image,
      });
    });

    return a;
  };

  const handleArtistSearch = async (inputValue, resolve) => {
    if (inputValue.length < 2) {
      return [];
    }
    const data = await instance.get(`artist/search?keyword=${inputValue}`);
    resolve(returnSelectData(data?.data.data));
  };

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log("trans", errors);
  };

  useEffect(() => {
    djs_detail?.length > 0 && clearErrors("values.dj_detail");
  }, [djs_detail]);

  // match the value with the options genres and category with set value

  return (
    <section aria-labelledby="section-1-title ">
      <div className="rounded-lg bg-white shadow ">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md ">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.djs_detail ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      Artist
                    </label>
                    <Controller
                      {...register("djs_detail", { required: true })}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.djs_detail
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <AsyncSelect
                            noOptionsMessage={() =>
                              "No results found, type to search"
                            }
                            placeholder={"Type to search artist"}
                            isMulti
                            onChange={onChange}
                            value={value}
                            onBlur={onBlur}
                            menuPlacement="bottom"
                            loadOptions={handleArtistSearch}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="djs_detail"
                      defaultValue=""
                    />
                    <div className="flex justify-between items-center">
                      <span className="text-red-400 text-xs  ml-1">
                        {errors?.djs_detail
                          ? "Bu Alan Boş Bırakılmamalıdır"
                          : ""}
                      </span>
                      {(!id || getValues("type") == "draft") && (
                        <div className="text-primary-600 text-xs mt-1 ml-1 mr-1 w-36 w-fit text-right">
                          <div
                            onClick={() => {
                              setTargetUrl("/new-artist");
                              setIsOpenModal(true);
                            }}
                            className="cursor-pointer "
                          >
                            {t("eventForm:addNewArtist")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                          {djs_detail?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <ArtistBlock
                                  key={index}
                                  item={item}
                                  provided={provided}
                                  index={index}
                                  t={t}
                                  register={register}
                                  getValues={getValues}
                                  setValue={setValue}
                                />
                              )}
                            </Draggable>
                          ))}
                        </div>
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <div className="col-span-6 sm:col-span-6 sm:border-t sm:border-gray-200 " />

                <div className="col-span-6 sm:col-span-6">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.genres ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("ticket:addGenres")}
                  </label>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`h-fit w-fit ${
                          errors?.genres
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          menuPlacement="top"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          isMulti
                          options={genres}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    name="genres"
                    defaultValue={[]}
                  />
                  <div className="text-red-400 text-xs mt-2 ml-1">
                    {errors?.genres ? "Bu Alan Boş Bırakılmamalıdır" : ""}
                  </div>
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.category ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    Category
                  </label>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`h-fit w-fit ${
                          errors?.category
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          menuPlacement="top"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          isMulti
                          options={category}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    name="category"
                    defaultValue={[]}
                  />
                  <div className="text-red-400 text-xs mt-2 ml-1">
                    {errors?.category ? "Bu Alan Boş Bırakılmamalıdır" : ""}
                  </div>
                </div>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5 sm:pb-5">
                  <div className="col-span-1">
                    <label
                      htmlFor="cover-photo"
                      className={`block text-sm font-medium mt-3 ${
                        errors?.podcast ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("attachments:podcast")}
                    </label>
                  </div>
                  <div className="col-span-2">
                    <FormInput
                      placeholder={`<iframe width="100%" height="300" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/211655884&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>`}
                      onChange={(e) => setValue("podcast", e.target.value)}
                      value={getValues("podcast")}
                      className={
                        errors?.podcast &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300 placeholder-gray-300"
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end gap-4">
                {(getValues("type") == "draft" ||
                  !window.location.pathname.includes("update-event")) && (
                  <button
                    onClick={() => {
                      saveAsDraft();
                    }}
                    className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {t("eventForm:saveAsDraft")}
                  </button>
                )}
                <div
                  onClick={() => {
                    onNextPage();
                  }}
                  className="bg-primary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("eventForm:next")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        header={t("utils:warning")}
        question={t("eventForm:newVenueArtistWarning")}
        noText={t("utils:cancel")}
        yesText={t("eventForm:saveAsDraft")}
        onClickYes={() => {
          saveAsDraft(targetUrl);
        }}
      />
    </section>
  );
}

export default Contents;
