import { useEffect, useState } from "react";
import { GenericButton, Loading } from "../../components";
import axios from "axios";
import { benufoUrl } from "../../lib/client";
import useStore from "../../hooks/useStores";
import numberToMoney from "../../utils/numberToMoney";
import downloadExcelFile from "../../utils/downloadExcelFile";
import { isHaveAccess } from "../../utils/roleUtils";
import ReactLoading from 'react-loading';
import { AiFillFileExcel } from "react-icons/ai";
import DetailModal from "./DetailModal";
import { useTranslation } from "react-i18next";
import { RiRefreshFill } from "react-icons/ri";
import { IoRefresh } from "react-icons/io5";
import moment from "moment";

const transactions = [
    {
      id: 'AAPS0L',
      company: 'Chase & Co.',
      share: 'CAC',
      commission: '+$4.37',
      price: '$3,509.00',
      quantity: '12.00',
      netAmount: '$4,397.00',
    },
  ]
  

const GeneralEventReport = () => {
    const [loading, setLoading] = useState(true);
    const [data,setData] = useState([])
    const [excelLoading,setExcelLoading] = useState(false);
    const {auth} = useStore()
    const [refresh,setRefresh] = useState(false)
    const [open, setOpen] = useState(false)
    const [refreshedAt, setRefreshedAt] = useState(new Date())
    const [selectedEvent, setSelectedEvent] = useState({})
    const {t} = useTranslation()

    const handleData = async () => {
        setRefresh(true)
        const {data} = await axios.get(benufoUrl+'pro/promoter-report',
        {
            headers: {
                Authorization: `Bearer ${auth.accessToken}`,
            },
        }
        )

        setData(data.data)
        setLoading(false)
        setRefreshedAt(new Date())
        setRefresh(false)
    }

    useEffect(() => {
        handleData()
    },[])

        const downloadEventsWithExcel = async () => {
            setExcelLoading(true);


            await downloadExcelFile(benufoUrl+'pro/promoter-excel-report','events.xlsx',auth.accessToken, setExcelLoading)
        }
    
    return (
        <div>
             <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                    <div className="flex flex-row gap-4">
                    {isHaveAccess('all:access') && <GenericButton className="shadow h-8 flex w-14 items-center justify-center py-1 px-4 h-full mb-4" onClick={() => {
                        if(!excelLoading) downloadEventsWithExcel()
                      }}>
                        {excelLoading ? <ReactLoading width={32} height={22} color={'white'}/> : <AiFillFileExcel/>}
                      </GenericButton>}
                      {isHaveAccess('all:access') && <GenericButton className="shadow h-8 flex w-14 items-center justify-center py-1 px-4 h-full mb-4" onClick={() => {
                        setRefresh(true)
                        handleData()
                      }}>
                        {refresh ? <ReactLoading width={32} height={22} color={'white'}/> : <IoRefresh/>}
                      </GenericButton>}
                      <span className="items-center text-gray-600 text-xs justify-center h-8 text-center flex">
                        {t('generalEventReport:refreshAt', {
                            date:moment(refreshedAt).format('DD.MM.YYYY HH:mm')
                        })}
                      </span>
                      </div>
                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <div
                      className="divide-y divide-gray-200 overflow-y-auto"
                      style={{ maxHeight: 800 }}
                    >
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    {t("generalEventReport:eventDate")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:eventName")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:venue")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:soldTicketCount")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:refundTicketCount")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:revenue")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:refundAmount")}
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    {t("generalEventReport:couponAmount")}
                  </th>
                  
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100 bg-white">
                {data?.map((transaction,i) => (
                  <tr key={i} onClick={() => {
                    setSelectedEvent(transaction)
                    setOpen(true)
                  }} className="hover:bg-gray-200 hover:cursor-pointer">
                    <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{transaction.date}</td>
                    <td className="whitespace-nowrap max-w-[350px] overflow-hidden px-2 py-2 text-sm font-medium text-gray-900">
                      {transaction.eventName}
                    </td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{transaction.venueName}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.soldedTicketCount}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{transaction.refundedTicketCount}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{numberToMoney(transaction.totalPrice) + ' TL'}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{numberToMoney(transaction.totalRefundPrice) + ' TL'}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{numberToMoney(transaction.couponPrice) + ' TL'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
    <DetailModal open={open} setOpen={setOpen}  data={selectedEvent}/>
        </div>
    );
}

export default GeneralEventReport;