import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { FormInput, GenericButton } from "../../../../../components";
import instance from "../../../../../lib/client";
import toastPush from "../../../../../utils/toastPush";
import i18n from "../../../../../services/i18n";

const defaultValues = {
  id: "",
  name: "",
  email: "",
  phone: "",
  permission: [],
  user: "",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CrewForm = ({
  isOpen,
  setIsOpen,
  handleData,
  member,
  eventId,
  permissions,
}) => {
  const [newMember, setNewMember] = useState(defaultValues);
  const [isRealUser, setIsRealUser] = useState(false);
  const { t } = useTranslation();

  const onSubmit = () => {
    if (!member?.id) {
      addMember();
    } else {
      updateMember();
    }
  };

  useEffect(async () => {
    if (member?.id) {
      const { data } = await instance.get(`/user/${member.id}`);

      setNewMember({
        ...member,
        email: data?.data?.emails[0]?.address,
        phone: data?.data?.phone_number,
      });
    }
  }, [member]);

  const findIsUser = () => {
    if (newMember.email) {
      instance
        .post("/user/email-query", {
          email: newMember.email,
        })
        .then(({ data }) => {
          if (data.data.length > 0) {
            setNewMember({
              ...newMember,
              name: data.data[0].full_name,
              phone: data.data[0].phone_number,
              user: data.data[0]._id,
            });
          }

          setIsRealUser(true);
        });
    }
  };

  const updateMember = async () => {
    if (newMember.permission.length === 0) {
      toastPush("En az 1 yetki secmelisiniz", "error");
    } else {
      const { data } = await instance.put("/crew/", {
        id: newMember.id,
        full_name: newMember.name,
        email: newMember.email,
        phone: newMember.phone,
        roles: newMember.permission,
        password: "",
        gender: "",
        user: newMember.user,
      });

      handleData();
      setIsOpen(false);
    }
  };

  const deleteMember = async () => {
    const { data } = await instance.get("/crew/delete-from-crew/" + member?.id);
    console.log(data);
    if (data?.data?.success) {
      handleData();
      setIsOpen(false);
      toastPush(data?.data?.message, "success");
    } else {
      toastPush(data?.data?.message, "error");
    }
  };

  const addMember = () => {
    if (newMember.permission.length === 0) {
      toastPush("En az 1 yetki secmelisiniz", "error");
    } else if (newMember.name && newMember.email && newMember.phone) {
      instance
        .post("/crew", {
          full_name: newMember.name,
          email: newMember.email,
          phone: newMember.phone,
          roles: newMember.permission,
          password: "",
          gender: "",
          user: newMember.user,
        })
        .then((data) => {
          handleData();
          setIsOpen(false);
        })
        .catch((err) => {
          handleData();
          setIsOpen(false);
        });
    } else {
      toastPush("Tüm alanlar doldurulmalı!");
    }
  };

  const removeRole = (perm) => {
    let a = [];

    if (perm.role !== "all:access") {
      newMember.permission.forEach((role) => {
        if (role !== perm.role) {
          a.push(role);
        }
      });
    }

    setNewMember({ ...newMember, permission: a });
  };

  const addPerm = (perm) => {
    var a = newMember.permission;

    if (perm.role === "all:access") {
      permissions.forEach((item) => {
        a.push(item.role);
      });
    } else {
      a.push(perm.role);
    }

    setNewMember({ ...newMember, permission: a });
  };

  const onClose = () => {
    setNewMember(defaultValues);
    setIsOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-[350px]">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {member?.id
                          ? t("crew:updateMember")
                          : t("crew:addToCrew")}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => onClose(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <HiOutlineX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div class="space-y-4">
                        <div className="flex-1 flex flex-col justify-between">
                          <div className="space-y-3 pb-5 flex flex-col w-full">
                            <div>
                              <div className="mt-1">
                                <FormInput
                                  type="text"
                                  id="name"
                                  value={newMember?.name}
                                  onChange={(e) =>
                                    setNewMember({
                                      ...newMember,
                                      name: e.target.value,
                                    })
                                  }
                                  placeholder={t("crew:name_surname")}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="mt-1">
                                <FormInput
                                  type="text"
                                  name="count"
                                  id="count"
                                  placeholder={t("crew:email")}
                                  value={newMember.email}
                                  onChange={(e) =>
                                    setNewMember({
                                      ...newMember,
                                      email: e.target.value,
                                    })
                                  }
                                  onBlur={() => findIsUser()}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="mt-1">
                                <FormInput
                                  type="text"
                                  name="quantity"
                                  id="quantity"
                                  placeholder={t("crew:phoneNo")}
                                  value={newMember.phone}
                                  onChange={(e) =>
                                    setNewMember({
                                      ...newMember,
                                      phone: e.target.value,
                                    })
                                  }
                                />
                              </div>
                            </div>
                            <div className="max-h-[65vh] overflow-y-auto">
                              {permissions.map((perm, i) => {
                                return (
                                  <label
                                    key={i}
                                    className="text-md font-semibold flex flex-row justify-between w-full py-2 text-sm text-gray-500 items-center pr-2"
                                  >
                                    {perm?.name[i18n.language]}

                                    <Switch
                                      key={i}
                                      checked={
                                        newMember?.permission?.find(
                                          (role) => perm.role === role
                                        )
                                          ? true
                                          : false
                                      }
                                      onChange={() => {
                                        if (
                                          newMember?.permission?.find(
                                            (role) => perm.role === role
                                          )
                                        ) {
                                          if (
                                            perm.role === "all:access" ||
                                            !newMember?.permission?.find(
                                              (role) => role === "all:access"
                                            )
                                          ) {
                                            removeRole(perm);
                                          }
                                        } else {
                                          addPerm(perm);
                                        }
                                      }}
                                      className={classNames(
                                        newMember?.permission?.find(
                                          (role) => perm.role === role
                                        )
                                          ? "bg-primary-500"
                                          : "bg-gray-200",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent  rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          newMember?.permission?.find(
                                            (role) => perm.role === role
                                          )
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                      />
                                    </Switch>
                                  </label>
                                );
                              })}
                            </div>
                            {member?.id && (
                              <GenericButton
                                onClick={() => deleteMember()}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
                              >
                                {t("utils:delete")}
                              </GenericButton>
                            )}
                            <GenericButton
                              onClick={() => onSubmit()}
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                              {member?.id
                                ? isRealUser
                                  ? t("crew:addToCrew")
                                  : t("utils:save")
                                : t("utils:add")}
                            </GenericButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CrewForm;
