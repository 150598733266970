import React, { useEffect, useState, useRef } from "react";
import { HiCheck } from "react-icons/hi";
import useStore from "../../../../../hooks/useStores";
import { HiOutlineChevronDown } from "react-icons/hi";
import { GuestBlock } from "../../../../../components";
import autoAnimate from "@formkit/auto-animate";
import {useTranslation} from "react-i18next";
import {handleDownloadClick} from "../../../../../components/GuestBlock/GuestBlock";
import {AiOutlineClose} from "react-icons/ai";


const handleZipDownloadClick = (url, name) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `${name}.zip`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Zip indirme hatası:", error);
      });
}

const GuestBlockGroup = ({
  group,
  cannotEdit = false,
  checkedGuests,
  setCheckedGuests,
    deleteGuest,
  seeMore,
  setSelectedGuest,
  setIsOpen,
}) => {
  const [showMore, setShowMore] = React.useState(false);
  const { modal } = useStore();
  const parent = useRef(null);
  const {t} = useTranslation();
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div key={group._id} className={`${showMore ? " z-50" : " z-50"}`}>
      <div
        className={`${showMore ? `shadow-lg shadow-[#6bffc7] z-50 ` : " z-50"}`}
      >
        <div
          style={
            group?.arrivedCount === group?.count
              ? { backgroundColor: "rgb(181,248,167)" }
              : null
          }
        >
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div
              className={`min-w-0 flex-1 flex items-center ${
                !cannotEdit && "cursor-pointer"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                if (!cannotEdit) {
                  console.log(group);
                }
              }}
            >
              <div className="min-w-600 flex-1">
                <div className={`flex flex-row relative `}>
                  <p className="text-sm font-medium text-black truncate">
                    {group.alias}
                  </p>
                </div>
              </div>

              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 hidden md:flex-1">
                <div>
                  <p className="text-sm font-medium text-indigo-600"></p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <span className="truncate"></span>
                  </p>
                </div>
              </div>
              <button className={`text-sm truncate ml-4 text-white mr-1  px-2 py-0.5 rounded-md font-semibold ${group.zipUrl?.length > 0 ? 'bg-green-500' :'bg-gray-400 text-black'}`} onClick={e => {
                e.stopPropagation();
                if(group.zipUrl?.length > 0) {
                  handleZipDownloadClick(group.zipUrl, group.alias)
                }
              }}>
                {`${group.zipUrl?.length > 0 ? t('utils:download') : t('utils:preparing')}`}
              </button>
              <p className="text-sm truncate ml-1 text-white mr-1 bg-[#1F2937] px-2 py-0.5 rounded-md font-bold">
                {`${group.arrivedCount}/${group.count}`}
              </p>
              {group.ticket_type && (
                <p className="text-sm truncate ml-1 text-white mr-4 bg-primary-500 px-2 py-0.5 rounded-md font-bold">
                  {group.ticket_type}
                </p>
              )}

              <p className="flex items-center text-sm text-gray-500 ">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowMore((s) => !s);
                  }}
                  className={`bg-white rounded-full h-7 w-7 flex items-center mr-2 justify-center`}
                >
                  <HiOutlineChevronDown
                    className="h-6 w-6 text-primary-500"
                    aria-hidden="true"
                  />
                </div>
              </p>
            </div>
              <p onClick={() => deleteGuest('group',group._id, group)} className="text-sm z-60 truncate cursor-pointer text-white mr-2 bg-red-500 px-1.5 py-1.5 rounded-full font-bold">
                  <AiOutlineClose/>
              </p>
          </div>
        </div>
      </div>
      <div ref={parent} className=" -z-10">
        {showMore && (
          <div className="flex flex-col -z-10">
            {group.guests
              .sort((a, b) => a.group_order - b.group_order)
              .map((guest) => (
                <GuestBlock
                  key={guest._id}
                  guest={guest}
                  deleteGuest={deleteGuest}
                  checkedGuests={checkedGuests}
                  setCheckedGuests={setCheckedGuests}
                  seeMore={(data) => {
                    setSelectedGuest(data);
                    setIsOpen(true);
                  }}
                  isGroup={true}
                  isLast={
                    group.guests[group.guests.length - 1]._id === guest._id
                  }
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default GuestBlockGroup;
