import React from "react";
import classNames from "../../utils/classNames";
import { isHaveAccess } from "../../utils/roleUtils";

const TabNav = ({ tabs, onSelect, selected }) => {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <nav
          className="relative  z-0 rounded-lg shadow flex divide-x flex-row divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => {
            if (tab.visible && isHaveAccess(tab.tag))
              return (
                <div
                  key={tab.name}
                  className={classNames(
                    selected === tabIdx + 1
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx + 1 === 0 ? "rounded-l-lg" : "",
                    tabIdx + 1 === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
                  )}
                  onClick={() => onSelect(tabIdx + 1)}
                  aria-current={selected === tabIdx + 1 ? "page" : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      selected === tabIdx + 1
                        ? "bg-primary-500"
                        : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </div>
              );
            else return null;
          })}
        </nav>
      </div>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 rounded-lg shadow flex divide-x flex-row divide-gray-200"
          aria-label="Tabs"
        >
          {tabs.map((tab, tabIdx) => {
            if (tab.visible && isHaveAccess(tab.tag))
              return (
                <div
                  key={tab.name}
                  className={classNames(
                    selected === tabIdx + 1
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx + 1 === 0 ? "rounded-l-lg" : "",
                    tabIdx + 1 === tabs.length - 1 ? "rounded-r-lg" : "",
                    "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
                  )}
                  onClick={() => onSelect(tabIdx + 1)}
                  aria-current={selected === tabIdx + 1 ? "page" : undefined}
                >
                  <span>{tab.name}</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      selected === tabIdx + 1
                        ? "bg-primary-500"
                        : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </div>
              );
            else return null;
          })}
        </nav>
      </div>
    </div>
  );
};
export default TabNav;
