import React from "react";
import ReactApexChart from "react-apexcharts";

const DonutChart = ({ name, labels, series, colors }) => {
  var options = {
    series: series,
    chart: {
      type: "donut",
    },
    labels: labels,
    colors: colors,
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div
      style={{
        height: 250,
        width: 250,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 10,
      }}
    >
      <span style={{ width: "100%" }}>{name}</span>
      <ReactApexChart
        series={options.series}
        options={options}
        type={"donut"}
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
};

export default DonutChart;
