import React, { useEffect, useState, useRef } from "react";
import { CgDetailsMore } from "react-icons/cg";
import { ImAttachment } from "react-icons/im";
import classNames from "../../utils/classNames";
import { useTranslation } from "react-i18next";
import Attachments from "./components/Attachments";
import Details from "./components/Details";
import Basics from "./components/Basics";
import Description from "./components/Descriptions";
import Social from "./components/Social";
import { useForm } from "react-hook-form";
import instance from "../../lib/client";
import toastPush from "../../utils/toastPush";
import { useHistory, useParams } from "react-router-dom";
import i18n from "../../services/i18n";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  IoClipboardOutline,
  IoLayersOutline,
  IoNewspaperOutline,
} from "react-icons/io5";
import autoAnimate from "@formkit/auto-animate";

const VenueSchema = yup.object().shape({
  name: yup.string().required(),
  translations: yup
    .array()
    .of(
      yup.object().shape({
        desc: yup.string().required(),
        rules: yup.string().required(),
        langcode: yup.string().optional(),
      })
    )
    .min(1),
  address: yup.string().required(),
  resident_artists: yup.array().optional(),
  country: yup.object().required(),
  city: yup.object().required(),
  map: yup.string().required(),
  phone: yup.string().optional(),
  opening_dates: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string().optional(),
        label: yup.string().optional(),
        open: yup.string().required(),
        close: yup.string().required(),
      })
    )
    .optional(),
  category: yup.array().optional(),
  extra_fields: yup.array().optional(),
  genre: yup.array().optional(),
  banner_image: yup.string().optional(),
  image: yup.string().required(),
});

function NewVenue() {
  const [selectedSection, setSelectedSection] = React.useState(0);
  const history = useHistory();
  const { id } = useParams();
  const [genres, setGenres] = useState([]);
  const [countrys, setCountrys] = useState([]);
  const [cities, setCities] = useState([]);
  const [extraFields, setExtraFields] = useState([]);
  const [categories, setCategories] = useState([]);
  const [artists, setArtists] = useState([]);
  const parent = useRef(null);
  const facebookUrl = "https://www.facebook.com/";
  const facebookUrl2 = "https://facebook.com/";
  const instagramUrl = "https://www.instagram.com/";
  const instagramUrl2 = "https://instagram.com/";
  const twitterUrl = "https://www.twitter.com/";
  const twitterUrl2 = "https://twitter.com/";
  const youtubeUrl = "https://www.youtube.com/";
  const spotifyUrl = "https://open.spotify.com/";

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    trigger,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(VenueSchema),
    defaultValues: {
      name: "",
      translations: [
        {
          desc: "",
          rules: "",
          langcode: "tr",
        },
        {
          desc: "",
          rules: "",
          langcode: "en",
        },
      ],
      address: "",
      resident_artists: [],
      country: {
        label: "Türkiye",
        value: 223,
      },
      city: "",
      map: "",
      phone: "",
      website: "",
      spotify: "",
      social_media: "",
      opening_dates: [],
      category: [],
      genre: [],
      extra_fields: [],
      image: "",
      banner_image: "",
      indoor_images: [],
      menu: [],
      seatMaps: [],
    },
  });
  const { t } = useTranslation();

  const onSubmit = (data) => {
    const opening_dates = data.opening_dates.map((item) => {
      return {
        ...item,
        close: moment(item.close, "HH:mm").toDate(),
        open: moment(item.open, "HH:mm").toDate(),
        // add url to link
      };
    });

    data.opening_dates = opening_dates;
    data.facebook = facebookUrl + data.facebook;
    data.twitter = twitterUrl + data.twitter;
    data.instagram = instagramUrl + data.instagram;
    data.youtube = youtubeUrl + data.youtube;
    data.spotify = spotifyUrl + data.spotify;
    data.item_category_genre = data.genre;
    data.item_category = data.category;
    data.phone = "+90" + data.phone.replace(/\s/g, "").replace("+90", "");
    console.log(data.phone);
    console.log(data);

    if (!window.location.pathname.includes("update-venue")) {
      instance
        .post("/venue", data)
        .then((data) => {
          toastPush(t("venueForm:created"));
          history.push("/dashboard/home");
        })
        .catch((err) => toastPush(t("utils:error"), "error"));
    } else {
      instance
        .put("/venue", data)
        .then((data) => {
          toastPush(t("venueForm:updated"));
          history.push("/dashboard/home");
        })
        .catch((err) => toastPush(t("utils:error"), "error"));
    }
  };

  const days = [
    {
      label: t("days:monday"),
      value: 0,
    },
    {
      label: t("days:tuesday"),
      value: 1,
    },
    {
      label: t("days:wednesday"),
      value: 2,
    },
    {
      label: t("days:thursday"),
      value: 3,
    },
    {
      label: t("days:friday"),
      value: 4,
    },
    {
      label: t("days:saturday"),
      value: 5,
    },
    {
      label: t("days:sunday"),
      value: 6,
    },
  ];

  useEffect(async () => {
    if (window.location.pathname.includes("update-venue")) {
      const { data } = await instance.get(`/venue/detail/${id}`);

      for (const [key, value] of Object.entries(data.data)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      if (data.data?.priceRange) {
        setValue("priceRange", {
          label: data.data.priceRange,
          value: data.data.priceRange,
        });
      }
      setValue("phone", data.data.phone.replace("+90", ""));

      setValue("genre", data.data.item_category_genre);
      setValue("category", data.data.item_category);

      setValue(
        "facebook",
        data?.data?.facebook.replace(facebookUrl, "").replace(facebookUrl2, "")
      );
      setValue(
        "twitter",
        data?.data?.twitter.replace(twitterUrl, "").replace(twitterUrl2, "")
      );
      setValue("youtube", data.data.youtube.replace(youtubeUrl, ""));
      setValue(
        "instagram",
        data?.data?.instagram
          .replace(instagramUrl, "")
          .replace(instagramUrl2, "")
      );
      setValue("spotify", data?.data?.spotify.replace(spotifyUrl, ""));

      const newDates = [];
      data.data.opening_dates.map((item) => {
        newDates.push({
          ...days.find((day) => (day.value = item.day)),
          open: moment(item.open).format("HH:mm"),
          close: moment(item.close).format("HH:mm"),
        });
      });

      setValue("opening_dates", newDates);

      getDatas(true);
    } else {
      getDatas(false);
    }
  }, [id]);

  const getDatas = async (isUpdate) => {
    Promise.all([
      instance.get("category/genre"),
      instance.get("venue/country"),
      instance.get("venue/extra-fields"),
      instance.get("category/venue"),
      instance.get("artist/"),
    ]).then(async ([genres, country, extraFields, categories, artist]) => {
      setArtists(
        artist.data.data.map((d) => {
          if (isUpdate && getValues("resident_artists").includes(d._id)) {
            setValue("resident_artists", [
              ...getValues("resident_artists").filter((id) => id !== d._id),
              { value: d._id, label: d.name },
            ]);
          }
          return {
            label: d.name,
            value: d._id,
          };
        })
      );

      setCategories(
        categories.data.data.map((g) => {
          if (isUpdate && getValues("category").includes(g.value)) {
            setValue("category", [
              ...getValues("category").filter((id) => id !== g.value),
              {
                value: g.value,
                label: g.label,
              },
            ]);
          }
          return {
            label: g.label,
            value: g.value,
          };
        })
      );
      setExtraFields(
        extraFields.data.data.map((g) => {
          if (isUpdate && getValues("extra_fields").includes(g._id)) {
            setValue("extra_fields", [
              ...getValues("extra_fields").filter((id) => id !== g._id),
              {
                value: g._id,
                label: g.translations.find((k) => k.langcode === i18n.language)
                  .name,
              },
            ]);
          }
          return {
            label: g.translations.find((k) => k.langcode === i18n.language)
              .name,
            value: g._id,
          };
        })
      );

      setGenres(
        genres.data.data.map((g) => {
          if (isUpdate && getValues("genre").includes(g.value)) {
            let genreList = getValues("genre").filter((id) => id !== g.value);
            setValue("genre", [
              ...genreList,
              {
                value: g.value,
                label: g.label,
              },
            ]);
          }
          return {
            label: g.label,
            value: g.value,
          };
        })
      );

      setCountrys(
        country.data.data.map((c) => {
          if (isUpdate && getValues("country")._id === c._id) {
            setValue("country", {
              label: c.country,
              value: parseInt(c.country_id),
            });
          }
          return { label: c.country, value: parseInt(c.country_id) };
        })
      );
      setValue("country", {
        label: "Türkiye",
        value: "223",
      });
      if (getValues("country")?.country_id || getValues("country")?.value) {
        const { data } = await instance.get(`venue/country/223`);
        console.log(data);
        setCities(
          data.data.map((city) => {
            if (getValues("city_id") === city.city_id) {
              setValue("city", {
                label: city.name,
                value: city.city_id,
              });
            }

            return {
              label: city.name,
              value: city.city_id,
            };
          })
        );
      }
    });
  };

  const checkIsValid = async () => {
    let isValid = true;
    switch (selectedSection) {
      case 0:
        isValid = await trigger([
          "name",
          "address",
          "country",
          "city",
          "map",
          "phone",
        ]);
        break;
      case 1:
        isValid = await trigger(["translations"]);
        break;
      case 2:
        isValid = true;
        break;
      case 3:
        isValid = await trigger([
          "resident_artists",
          "category",
          "extra_fields",
          "genre",
          "priceRange",
          "opening_dates",
        ]);
        break;
      case 4:
        isValid = true;
        break;
      default:
        isValid = true;
    }
    return isValid;
  };

  const navigation = [
    { name: t("venueForm:basics"), href: "#", icon: IoClipboardOutline },
    { name: t("venueForm:description"), href: "#", icon: IoNewspaperOutline },
    { name: t("venueForm:socialMedia"), href: "#", icon: CgDetailsMore },
    { name: t("venueForm:details"), href: "#", icon: IoLayersOutline },
    { name: t("venueForm:attachments"), href: "#", icon: ImAttachment },
  ];

  const returnCurrentSection = () => {
    switch (selectedSection) {
      case 0:
        return (
          <Basics
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            cities={cities}
            countrys={countrys}
            setCities={setCities}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );
      case 1:
        return (
          <Description
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );
      case 2:
        return (
          <Social
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );
      case 3:
        return (
          <Details
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            genres={genres}
            categories={categories}
            artists={artists}
            extraFields={extraFields}
            cities={cities}
            countrys={countrys}
            setCities={setCities}
            register={register}
            control={control}
            days={days}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );

      case 4:
        return (
          <Attachments
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                <aside className="lg:py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                  <nav className="space-y-1">
                    {navigation.map((item, i) => (
                      <span
                        key={i}
                        onClick={async () =>
                          selectedSection < i
                            ? (await checkIsValid()) && setSelectedSection(i)
                            : setSelectedSection(i)
                        }
                        className={classNames(
                          selectedSection === i
                            ? "bg-gray-800 text-white hover:text-white hover:bg-gray-900"
                            : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                          "group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer "
                        )}
                        aria-current={
                          selectedSection === i ? "page" : undefined
                        }
                      >
                        <item.icon
                          className={classNames(
                            selectedSection === i
                              ? "text-white group-hover:text-white"
                              : "text-gray-400 group-hover:text-gray-900",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate ml-2">{item.name}</span>
                      </span>
                    ))}
                  </nav>
                </aside>
              </div>
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <form ref={parent} onSubmit={handleSubmit(onSubmit)}>
            {returnCurrentSection()}
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewVenue;
