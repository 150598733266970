const en = {
  auth: {
    signInText: "Log In",
    email: "Email address",
    password: "Password",
    signIn: "Log In",
    forgotPassword: "Forgot your password?",
    signUp: "Sign Up",
    continue: "Continue",
  },
  generalEventReport:{
    tabTitle: "General Event Report",
    eventDate: "Event Date",
    eventName: "Event Name",
    venue: "Venue",
    soldTicketCount: "Sold Ticket Count",
    refundTicketCount: "Refund Ticket Count",
    revenue: "Revenue",
    refundAmount: "Refund Amount",
    couponAmount: "Coupon Amount",
    ticketName: "Ticket Name",
    count: "Count",
    price: "Price",
    total: "Total Price",
    refreshAt: "Refreshed At : {{date}}",
  },
  home: {
    tabTitle: "Home",
    title: "Dashboard",
    upcomingEvents: "Upcoming Events",
    activeEvents: "All Active Events",
    noSearchResult: "No Results Found",
    ticketSales: "Ticket Sales",
    lastTickets: "Last Tickets",
  },
  sideTab: {
    event: "Event",
    overview: "Overview",
    tickets: "Tickets",
    coupons: "Coupons",
    guests: "Guests",
  },
  events: {
    tabTitle: "Events",
    title: "Events",
    scan: "Scan Ticket",
    ticketList: "Ticket List",
    activeEventCount: "Active Event Count",
    activated: "Event Activated",
    deactivated: "Event Deactivated",
    deactiveEvents: "Deactive Events",
    waitingEvents: "Events Pending Approval",
    ticketTypes: "Ticket Types",
    active: "Active Events",
    coupons: "Coupons",
    stopSale: "Stop Sale",
    pastEvent: "Past Event",
    past: "Past Events",
    draft: "Draft",
    guests: "Guests",
    reports: "Reports",
    waiting: "Waiting For Approval",
    scanTicket: "Scan Ticket",
    sale: "Sale",
    revenue: "Revenue",
    refund: "Refund",
  },

  scanner: {
    selectTicketType: "Scan by ticket name",
    activeTicketType: "Active Tickets",
    scannerOptions: "Scanner Actions",
    selectTheTypeOfTicketToScan: "SELECT THE TYPE OF TICKET TO SCAN",
    ticketAlreadyScanned: "Ticket Already Scanned",
    invalidScanned: "Invalid Scanned",
    successScan: "Success Scan",
  },
  sales: {
    scannedBar: "Scanned Tickets",
    allBar: "Sold Tickets",
  },
  venues: {
    tabTitle: "Venues",
  },
  artists: {
    tabTitle: "Artists",
  },
  crew: {
    tabTitle: "Crew",
    title: "Edit Crew",
    addNewMember: "Add New Crew Member",
    addNewPermissionType: "Add New Permission",

    crewList: "Crew List",
    name_surname: "Name Surname",
    email: "E-Mail Address",
    phoneNo: "Phone Number",
    permList: "Permission List",
    permissions: "Permissions",
    addToCrew: "Add To Crew",
    updateMember: "Update Member",
    inviteToTeam: "Invite To Team",
  },
  finance: {
    tabTitle: "Finance",
  },
  guests: {
    deleteCancelled: "Delete Cancelled",
    deletedGroup: "Guests Deleted",
    deleteError: "Guests Could Not Be Deleted",
    listTitle: "Guest List",
    addTitle: "Add Guest",
    updateTitle: "Update Guest",
    scannedBar: "Entrant",
    guestPropertyAdded: "Guest Property Added",
    allBar: "Invited",
    userArrived: "Entrant Arrived",
    entrantType: "Guest Type",
    entrantCount: "Guest Count",
    updated: "Guest Updated",
    created: "Guest Added",
    guestTypeDeleted: "Guest Type Deleted",
    deleted: "Guest Deleted",
    propertyName: "Property Name",
    types: "Guest Types",
    addProperty: "Add Guest Type",
    editProperty: "Edit Property",
    detailTitle: "Guest Detail",
    arrivedAt: "Arrived At",
    arrivedBy: "Arrived By",
    createdBy: "Added By",
    createdAt: "Added At",
    singleGuest: "Single",
    multipleGuest: "Multiple",
    excelGuestDrag:
      "Drag and drop the Excel file here or click to select a file",
    excelGuestDrop: "Drop the Excel file here...",
    multipleInfo:
      "QR code is not available in Multiple Added Invitations. Names Invited Will be added to the list",
  },
  tasks: {
    tabTitle: "Tasks",
    title: "Tasks",
  },
  messages: {
    tabTitle: "Messages",
    title: "Messages",
  },
  coupon: {
    name: "Name",
    used: "Used",
    discount: "Discount",
    count: "Count",
    discountAmount: "Discount Amount",
    activeCoupons: "Active Coupons",
    couponsUsageRates: "Coupon Usage Rates",
    totalTransactionAmount: "Total Transaction Amount",
    totalDiscountAmount: "Total Discount Amount",
    totalDiscount: "Total Discount",
    noItem: "No Coupon Code Created Yet",
    add: "Create Coupon",
    warningText:
      "Your current active coupon will be deactivated and a new coupon will be created with the same name!",
    addNewCoupon: "Add New Coupon",
    updateCoupon: "Update Coupon",
    deactivated: "Coupon Deactivated",
    deleted: "Coupon Deleted",
    updated: "Coupon Updated",
    created: "Coupon Created",
    percentage: "Percentage Discount",
    amount: "Total Discount",
    countMax: "Usage Count",
    title: "Coupon",
  },
  refund: {
    refundReasonTitle: "Refund Reasons",
    refundedTickets: "Refunded Tickets",
    refundPrice: "Refund Price",
    refundCount: "Refund Count",
    refundReason: "Refund Reason",
    refundReasonAdded: "Refund Reason Added",
    refundReasonDeleted: "Refund Reason Deleted",
    refundSuccess: "Successful Refund",

    name: "Name",
    price: "Price",
    count: "Count",
  },
  reports: {
    tabTitle: "Reports",
    date: "Date",
    refundReport: "Refund Report",
    venue: "Venue",
    promoter: "Promoter",
    contract: "Contract Code",
    ticketSales: "Ticket Sales Report",
    gender: "Gender Report",
    ticketScan: "Ticket Scan Report",
    ticketScanCount: "Ticket Report By Type",
    ticketCountByType: "Ticket Count By Type",
    guest: "Guest Entrant Report",
    // Report Tabs
    ticketTab: "Ticket Report",
    venueTab: "Venue Report",
    detailTab: "Detailed Report",
    scanned: "Scanned",
    not_scanned: "Not Scanned",
    not_entrant: "Not Entrant",
    entrance_count: "Entrance Count",
    revenue: "Revenue",
    transactions: "Transactions",
    date_by: "Date By",
    date_range: "Date Range",
    hourly: "Hourly",
    daily: "Daily",
    saleByTicketType: "Sale By Ticket Type",
    weekly: "Weekly",
    monthly: "Monthly",
  },
  ticket: {
    customerInfo: "Customer Information",
    purchaseDate: "Purchase Date",
    ticketType: "Ticket Type",
    addTicketType: "Add new ticket type...",
    addGenres: "Add Genre",
    ticketPrice: "Ticket Price",
    ticketStatus: "Ticket Status",
    totalStock: "Total Stock",
    scannedBy: "Scanned By",
    scannedAt: "Scanned At",
    eventName: "Event Name",
    dailyTicketReport: "Daily Ticket Report",
    resendTicket: "Resend Ticket",
    returnTicket: "Return Ticket",
    usedCouponType: "Used Coupon Type",
    discountAmount: "Discount Amount",
    discountedTicketPrice: "Discounted Ticket Price",
    noSaleTicket: "No Sale Yet.",
    refundReasons: "Refund Reasons",
    removeRefundList: "Remove From Refund List",
    removedFromRefundList: "Removed from Refund List",
    whoAddedToList: "Who Added To Refund List",
    addedAtRefundList: "Added Refund List At",
    whyAddToRefundList: "Why Add To Refund List",
  },
  attachments: {
    artistImage: "Artist Image",
    bannerImageRectangle: "Banner image (Rectangle)",
    bannerImageSquare: "Banner image (Square)",
    seatMap: "Seat Map",
    indoorImages: "Indoor Images",
    menu: "Menü",
    video: "Video",
    seatMap: "Seatmap",
    podcast: "Podcast (Embed Code)",
  },
  eventAddTicket: {
    name: "Name",
    price: "Price",
    count: "Count",
    quantity: "Quantity",
    maxSale: "Max Sale",
    stopSale: "Stop Selling",
    startSale: "Start Selling",
  },
  settings: {
    tabTitle: "Settings",
    title: "Settings",
    guestsProperty: "Guest Property Settings",
    refundReasonSetting: "Refund Reason Settings",
    chooseAccount: "Choose Account",
    changeAccount: "Change Account",
    personalInformation: "Personal Information",
    billingInformation: "Billing Information",
    promoterInfo: "Promoter Information",
    editCrew: "Edit Crew",
    privacy: "Privacy Policy",
    sessions: "Active Sessions",
    session: "Active Session",
    logout: "Logout",
  },
  artistForm: {
    details: "Artist Details",
    attachments: "Attachments",
    desc: "Description",
    rules: "Rules",
    name: "Name",
    resident: "Resident",
    label: "Label",
    labelLink: "Label link",
    website: "Website",
    socialMedia: "Social Media",
    soundCloud: "Soundcloud",
    genres: "Genres",
    next: "Next",
    finish: "Finish",
    update: "Artist Updated!",
    created: "Artist Created!",
  },
  guestForm: {
    name: "Name Surname",
    email: "E-Mail",
    guestType: "Guest Type",
    ticketType: "Ticket Type",
    count: "Count",
    addNew: "Add New Guest",
  },
  eventForm: {
    eventBasics: "Basics",
    eventDetails: "Event Details",
    eventContents: "Event Contents",
    eventCategory: "Category",
    tickets: "Tickets",
    seo: "Seo",
    attachments: "Attachments",
    coupons: "Coupons",
    currencyUnit: "Currency unit",
    eventName: "Name",
    eventDesc: "Explanation",
    eventRules: "Rules",
    eventDates: "Date",
    eventGroup: "Event Group",
    addEventToGroup: "Add event to group",
    addTicket: "Add ticket",
    addDj: "add DJ",
    next: "Next",
    finish: "Finish",
    nameError: "Event Name Cannot Be Empty",
    saveAsDraft: "Save as Draft",
    addNewVenue: "Add New Venue",
    addNewArtist: "Add New Arist",
    newVenueArtistWarning:
      "You will be directed to the new Artist / Venue adding page. Newly added venues and artists will go through the approval process by the BUGECE team. You can save the changes you have made as a draft and then complete the event registration",
  },
  days: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
  },
  venueForm: {
    basics: "Basics",
    description: "Description",
    socialMedia: "Social Media",
    details: "Venue Details",
    tickets: "Tickets",
    seo: "Seo",
    attachments: "Attachments",
    residentArtists: "Resident Artists",
    coupons: "Coupons",
    name: "Name",
    desc: "Explanation",
    priceRange: "Price Range",
    categories: "Categories",
    days: "Opening Dates",
    rules: "Rules",
    phone: "Phone",
    address: "Address",
    extraFields: "Extra Fields",
    genres: "Genres",
    map: "Map",
    website: "Website",
    socialMedia: "Social Media",
    next: "Next",
    finish: "Finish",
    created: "Venue created.",
    updated: "Venue updated.",
  },
  notifyMessages: {
    successEvent: "Event created.",
    successEventUpdate: "Event updated.",
    successEventDraft: "Event saved as Draft",
    successVenue: "Venue created.",
    successArtist: "Artist created.",
    updatingEvent: "Updating event...",
    creatingEvent: "Creating event...",
    logoutedAll: "Logoutted from all devices..",
  },
  notFound: {
    venue: "Not found any venue.",
    venueExp: "You can create venue by clicking on the button below.",
    artist: "Not found any artist.",
    artistExp: "You can create artist by clicking on the button below.",
    guest: "Not found any guest!",
    guestExp: "You can create guest by clicking on the button below.",
  },
  filter: {
    sortBy: "Sort By",
    filter: "Filter",
    permission: "Permission",
    byScanned: "By Scanned",
    day: "Day",
    week: "Week",
    month: "Month",
    year: "Year",
  },
  notifications: {
    title: "Notifications",
    explanation:
      "We are renewed for a brand new BUGECE experience 💛 Your suggestions and ideas are important to us, you can reach us here 💛",
  },
  user: {
    full_name: "Name Surname",
    username: "Username",
    email: "E-Mail",
    phone: "Phone",
  },
  promoter: {
    name: "Name",
    city: "City",
    address: "Address",
    email: "E-Mail",
    phone: "Phone",
    taxNo: "Tax No",
    taxOffice: "Tax Office",
    website: "Website",
    socialMedia: "Social Media",
  },
  eventTickets: {
    tickets: "Tickets",
    active: "Active",
    deactive: "Deactive",
    addNewTicket: "Add New Ticket",
    selectPromotion: "Select Promotion",
    updateTicket: "Update Ticket",
    notAnyTicket: "No ticket has yet been created.",
    createNew: "Create New",
    sold: "Sold",
    remainingStock: "Remain. Stock",
    totalStock: "Total Stock",
  },
  status: {
    refundListDeclined: "Refund list declined for",
    refundListConfirmed: "Refund list confirmed for",
    login: "Login",
    eventPage: "Event Page",
  },
  signupModal: {
    title: "Create your account",
    subtitle: "Create your account to take advantage of BUGECE Pro features.",
    name: "Name",
    surname: "Surname",
    birthdate: "Birthdate",
    mail: "E-Posta",
    day: "Day",
    month: "Month",
    year: "Year",
    gender: "Gender",
    female: "Female",
    male: "Male",
    password: "Password",
    passwordAgain: "Password Again",
    countryCode: "Country Code",
    phoneNumber: "Phone Number",
    register: "Register",
    term: "Terms of Use",
    privacy: "Privacy Policy",
    and: "and",
    accept: "I have read and accept the",
    activeLang: "en",
    confirm: "Confirm",
  },
  utils: {
    editEvent: "Edit Event",
    unauthorized: "Your access unauthorized. Please login back.",
    search: "Search...",
    sort: "Sort",
    name: "Name & Surname",
    email: "E-mail Address",
    profile: "Your Profile",
    signOut: "Sign out",
    select: "Please Select...",
    detail: "Detail",
    add: "Add",
    edit: "Edit",
    inbox: "Inbox",
    tasks: "Tasks",
    subscription: "Subscriptions",
    all: "All",
    promotions: "Promotions",
    or: "or",
    promoters: "Promoters",
    guests: "Guests",
    delete: "Delete",
    done: "Done",
    update: "Update",
    viewAll: "View all",
    isBugeceUser: "Check Entrant BUGECE Account",
    save: "Save",
    cancel: "Cancel",
    male: "Male",
    start_date: "Start Date",
    end_date: "End Date",
    start_time: "Start Time",
    fbPixelCode: "Facebook pixel code",
    end_time: "End Time",
    from: "From",
    download: "Download",
    preparing: "Preparing",
    to: "To",
    female: "Female",
    other: "Other",
    device: "Device",
    operatingSystem: "Operating System",
    created: "Created At",
    expiration: "Expiration Date",
    logoutAll: "Logout All Devices",
    areYouSure: "Are You Sure?",
    logoutAllText: "Are you sure logout from all devices?",
    yes: "Yes",
    warning: "Warning",
    continue: "Continue",
    no: "No",
    apply: "Apply",
    activeDevices: "Active devices",
    changeAccount: "Change account",
    promoterUpdated: "Promoter updated",
    userUpdated: "User updated",
    newEvent: "New Event",
    newVenue: "New Venue",
    newArtist: "New Artist",
    newPromoter: "New Promoter",
    newGuest: "New Guest",
    newPromotion: "New Promotion",
    startNow: "Start Now",
    newCrew: "New Crew",
    backHome: "Back Home",
    startDate: "Start Date",
    endDate: "End Date",
    viewMore: "View more...",
    viewLess: "View less",
    activate: "Activate",
    deactivate: "Deactivate",
    goEvent: "Go To Event Page",
    imageSizeError: "Images dimensions is wrong!",
    loading: "Loading...",
    error: "Something went wrong!",
    sortByDateFirst: "By Date (Closest one first)",
    sortByDateSecond: "By Date (Latest one first)",
    country: "Country",
    city: "City",
    open: "Open",
    close: "Close",
    bannerImageDescription: "(Maximum size is 1920x710 pixels)",
    thumbnailImageDescription: "(Maximum size is 1080x1080 pixels)",
    desc: "Description",
    refundReasonError: "Refund reason is required!",
  },
};

export default en;
