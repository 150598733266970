import moment from "moment";
import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const FilterTabs = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(3);
  const [isStartDateOpen, setIsStartDateOpen] = useState(false);
  const [isEndDateOpen, setIsEndDateOpen] = useState(false);

  const onDateSelect = (value, i) => {
    setSelected(i);
    if (value) {
      switch (value) {
        case "day":
          setStartDate(moment());
          setEndDate(moment());
          break;
        case "week":
          setStartDate(moment().startOf("week").add(1, "day"));
          setEndDate(moment());
          break;
        case "month":
          setStartDate(moment().startOf("month"));
          setEndDate(moment());
          break;
        case "year":
          setStartDate(moment().startOf("year"));
          setEndDate(moment());
          break;
        default:
          setStartDate(moment());
          setEndDate(moment());
          break;
      }
    }
  };

  const FilterButton = ({ name, value, index, className }) => {
    return (
      <button
        onClick={() => {
          onDateSelect(value, index);
        }}
        className={`text-sm p-1 px-5 rounded md:w-20 xs:w-1/4 rounded-12 hover:bg-primary-500 hover:text-white hover:font-bold transition-all ${
          selected === index
            ? "bg-primary-500 text-white font-bold"
            : "bg-gray-200"
        } ${className}`}
      >
        {name}
      </button>
    );
  };

  const datePicks = [
    {
      name: t("filter:day"),
      value: "day",
    },
    {
      name: t("filter:week"),
      value: "week",
    },
    {
      name: t("filter:month"),
      value: "month",
    },
    {
      name: t("filter:year"),
      value: "year",
    },
  ];

  return (
    <div className="pb-4 flex md:flex-row xs:flex-col-reverse gap-2 text-gray-800 relative w-full">
      <div className="flex flex-row w-full gap-2  md:hidden xs:flex">
        {datePicks.map((date, i) => {
          return (
            <FilterButton
              key={i}
              name={date.name}
              value={date.value}
              index={i}
              className={i === 0 && "ml-auto"}
            />
          );
        })}
      </div>
      <div className="flex md:flex-row md:mb-0 xs:mb-2 xs:flex-row gap-2">
        <button
          onClick={() => {
            setIsEndDateOpen(false);
            setIsStartDateOpen(!isStartDateOpen);
          }}
          className="text-sm p-1 px-5 rounded md:w-[130px] xs:w-1/2 h-8 rounded-12 bg-gray-200  hover:bg-primary-500 hover:text-white transition-all "
        >
          {moment(startDate).isValid()
            ? moment(startDate).format("DD-MM-YYYY")
            : t("utils:startDate")}
        </button>
        {isStartDateOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: 40,
            }}
          >
            <ReactDatePicker
              selected={
                moment(startDate).isValid()
                  ? moment(startDate).toDate()
                  : moment().toDate()
              }
              closeOnScroll={(e) => e.target === document}
              onClickOutside={() => setIsStartDateOpen(false)}
              onChange={(v) => {
                setSelected(null);
                setStartDate(v);
                setIsStartDateOpen(false);
              }}
              dateFormat={"DD-MM-YYYY"}
              inline
            />
          </div>
        )}

        <button
          onClick={() => {
            setIsStartDateOpen(false);
            setIsEndDateOpen(!isEndDateOpen);
          }}
          className="text-sm p-1 px-5 rounded md:w-[130px] bg-gray-200 text-black xs:w-1/2 h-8 rounded-12 hover:bg-primary-500 hover:text-white transition-all "
        >
          {moment(endDate).isValid()
            ? moment(endDate).format("DD-MM-YYYY")
            : t("utils:endDate")}
        </button>
        {isEndDateOpen && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              left: 137,
              top: 40,
            }}
          >
            <ReactDatePicker
              selected={
                moment(endDate).isValid()
                  ? moment(endDate).toDate()
                  : moment().toDate()
              }
              closeOnScroll={(e) => e.target === document}
              onClickOutside={() => setIsEndDateOpen(false)}
              onChange={(v) => {
                setSelected(null);
                setEndDate(v);
                setIsEndDateOpen(false);
              }}
              dateFormat={"DD-MM-YYYY"}
              inline
            />
          </div>
        )}
      </div>
      <div className="flex flex-row w-full gap-2 md:flex xs:hidden">
        {datePicks.map((date, i) => {
          return (
            <FilterButton
              key={i}
              name={date.name}
              value={date.value}
              index={i}
              className={i === 0 && "ml-auto"}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterTabs;
