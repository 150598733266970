import React, { useEffect, useState } from "react";
import QrReader from "modern-react-qr-reader";
import { GenericButton } from "..";
import instance from "../../lib/client";
import { useTranslation } from "react-i18next";
import toastPush from "../../utils/toastPush";
import ScannerResult from "./components/ScannerResult";
import { IoCameraReverseOutline } from "react-icons/io5";
import { Switch } from "@headlessui/react";
import "./styles.css";

const CodeScanner = ({ isOpen, setIsOpen, eventId }) => {
  const { t } = useTranslation();
  const [delay, setDelay] = useState(100);
  const [isOpenSelect, setIsOpenSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isScannerActive, setIsScannerActive] = useState(true);
  const [facing, setFacing] = useState("environment");
  const [lastScannedTicket, setLastScannedTicket] = useState(null);
  const [currentTicket, setCurrentTicket] = useState({
    type: "",
    ticket: undefined,
    isOpen: false,
  });
  const [result, setResult] = useState("No result");
  const [ticketTypes, setTicketTypes] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    setLastScannedTicket("");
    setIsScannerActive(true);
    handleData();
  }, []);

  const handleData = () => {
    instance
      .get(`/scanner/available-tickets/${eventId}`)
      .then(({ data: { data } }) => {
        setTicketTypes([
          { name: t("utils:all"), key: "all", isActive: true },
          ...data.map((e) => {
            return {
              name: e,
              key: e,
              isActive: true,
            };
          }),
        ]);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleError = (err) => {
    console.error(err);
  };

  const previewStyle = {
    height: 350,
    width: 310,
    marginTop: "auto",
    objectFit: "fill",
    borderRadius: "10px",
  };

  const handleScan = (barcodeData) => {
    if (
      barcodeData &&
      isScannerActive &&
      !currentTicket.isOpen &&
      barcodeData.text !== lastScannedTicket
    ) {
      setIsScannerActive(false);
      instance
        .post("/scanner/scan", {
          qr: barcodeData,
          event: eventId,
          ticketTypes: ticketTypes.filter((e) => e.isActive).map((e) => e.key),
        })
        .then(({ data: { data } }) => {
          if (data.operationStatus) {
            if (
              data.operationStatus === "SUCCESS_TICKET" ||
              data.operationStatus === "ALREADY_SCANNED"
            ) {
              console.log(data);
              setCurrentTicket({
                type: data.operationStatus,
                ticket: data.payload.ticketDetail,
                isOpen: true,
              });

              setLastScannedTicket(barcodeData.text);
            } else {
              setCurrentTicket({
                type: data.operationStatus,
                ticket: undefined,
                isOpen: true,
              });

              setLastScannedTicket(barcodeData.text);
            }
          }
        });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        position: "fixed",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: isOpen ? 9 : -1500,
        opacity: isOpen ? 1 : 0,
        top: 0,
        left: 0,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "all 0.5s ease",
      }}
    >
      <div
        style={{
          height: 500,
          width: 350,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="rounded-md relative"
      >
        <div
          className="w-11/12 p-2 border rounded flex flex-row overflow-x-auto gap-x-4 max-w-11/12 rounded-12 mt-4 cursor-pointer border-gray-400"
          onClick={() => setIsOpenSelect(true)}
        >
          <span className="absolute text-gray-700 top-2.5 bg-white text-xs font-semibold left-5 z-10">
            {t("scanner:selectTheTypeOfTicketToScan")}
          </span>
          {ticketTypes.map((e) => {
            if (e.isActive)
              return <span className="flex whitespace-nowrap">{e.name}</span>;
          })}
        </div>

        {isOpen && (
          <QrReader
            delay={delay}
            style={previewStyle}
            showViewFinder={false}
            onError={handleError}
            onScan={handleScan}
          />
        )}

        <div className="scan">
          <div className={"scanner"} />
        </div>
        <IoCameraReverseOutline
          size={40}
          onClick={() =>
            setFacing(facing === "environment" ? "user" : "environment")
          }
          style={{
            position: "absolute",
            cursor: "pointer",
            bottom: 80,
            backgroundColor: "white",
            borderRadius: 100,
            padding: 5,
          }}
        />
        <GenericButton
          style={{
            width: "90%",
            height: 40,
            marginBottom: 15,
            marginTop: "auto",
          }}
          onClick={() => setIsOpen(false)}
        >
          Tarayıcıyı Kapat
        </GenericButton>
      </div>

      <div
        className="absolute w-[350px] p-4 rounded rounded-12 h-[550px] flex flex-col gap-y-4 max-h-[550px] overflow-y-auto bg-white"
        style={{
          zIndex: isOpenSelect ? 10 : -1500,
          opacity: isOpenSelect ? 1 : 0,
        }}
      >
        {ticketTypes.map((e, i) => {
          return (
            <div
              onClick={() => {
                if (e.key === "all") {
                  let newTicketTypes = [];
                  if (!e.isActive) {
                    newTicketTypes = ticketTypes.map((a) => {
                      a.isActive = true;
                      return a;
                    });
                  } else {
                    newTicketTypes = [...ticketTypes];

                    newTicketTypes[i].isActive = !newTicketTypes[i].isActive;
                  }

                  setTicketTypes(newTicketTypes);
                } else if (!ticketTypes.find((a) => a.key === "all").isActive) {
                  let newTicketTypes = [...ticketTypes];

                  newTicketTypes[i].isActive = !newTicketTypes[i].isActive;

                  setTicketTypes(newTicketTypes);
                }
              }}
              key={i}
              className="flex flex-row justify-between"
            >
              <span>{e.name}</span>
              <Switch
                checked={
                  ticketTypes.find((a) => a.key === "all").isActive ||
                  e.isActive
                }
                onChange={() => {}}
                className={classNames(
                  ticketTypes.find((a) => a.key === "all").isActive ||
                    e.isActive
                    ? "bg-primary-500"
                    : "bg-gray-200",
                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    ticketTypes.find((a) => a.key === "all").isActive ||
                      e.isActive
                      ? "translate-x-5"
                      : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                  )}
                />
              </Switch>
            </div>
          );
        })}

        <GenericButton
          style={{
            width: "100%",
            height: 40,
            marginTop: "auto",
          }}
          onClick={() => {
            if (!ticketTypes.find((a) => a.isActive)) {
              toastPush("en az 1 tane secmelisiniz", "error");
            } else {
              setIsOpenSelect(false);
            }
          }}
        >
          Onayla
        </GenericButton>
      </div>
      {currentTicket.isOpen && (
        <ScannerResult
          type={currentTicket.type}
          onClick={() => {
            setCurrentTicket({ isOpen: false, ticket: undefined, type: "" });
            setIsScannerActive(true);
          }}
          ticket={currentTicket.ticket}
        />
      )}
    </div>
  );
};

export default CodeScanner;
