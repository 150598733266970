import React from "react";
import BugeceDark from "../../assets/images/BugeceDark.svg";
import LoginRight from "../../assets/images/LoginRight.png";
import Appstore from "../../assets/images/appstore.png";
import Googleplay from "../../assets/images/playstore.png";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { PrivateRoute } from "../../components/index";
import {
  Login,
  ChooseAccount,
  ForgotPassword,
  AcceptInvite,
} from "./components/index";

const AuthContainer = () => {
  const { url } = useRouteMatch();

  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 w-1/2">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <img className="h-12 w-auto mx-auto" src={BugeceDark} alt="BUGECE" />
        </div>
        <Switch>
          <Route path={url + "/login"}>
            <Login />
          </Route>
          <Route path={url + "/accept-invite"}>
            <AcceptInvite />
          </Route>
          <Route path={url + "/delete-account"}>
            <ForgotPassword />
          </Route>
          <PrivateRoute path={url + "/choose"} component={ChooseAccount} />

          <Redirect to={url + "/login"} />
        </Switch>
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={LoginRight}
          alt="loginBg"
        />
      </div>

      <div className={"absolute xs:hidden md:flex right-0 bottom-0 "}>
        <a
          href={"https://apps.apple.com/tr/app/bugece-pro/id1578565996"}
          target={"_blank"}
        >
          <img
            src={Appstore}
            style={{
              height: 100,
              position: "absolute",
              zIndex: 0,
              bottom: 40,
              left: 5,
            }}
          />
        </a>

        <a
          href={`https://play.google.com/store/apps/details?id=com.bgpro&gl=TR`}
          target={"_blank"}
        >
          <img
            src={Googleplay}
            style={{
              height: 65,
              zIndex: 1,
            }}
          />
        </a>
      </div>
    </div>
  );
};

export default AuthContainer;
