import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStore from "../../hooks/useStores";

const PrivateRoute = observer(({ component: Component, ...rest }) => {
  const { auth } = useStore();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isLogged === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/auth/login" />
        )
      }
    />
  );
});

export default PrivateRoute;
