import React from "react";

const CouponBlock = ({ coupon, onClick }) => {
  return (
    <div className="flex pt-1 pb-1  w-full cursor-pointer" onClick={onClick}>
      <div className="flex items-center py-2 px-3 border w-full rounded rounded-12">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="w-4/12">
            <div>
              <p className="text-sm font-medium text-black truncate">
                {coupon.name}
              </p>
            </div>
          </div>

          <div className="min-w-0 flex px-4 md:grid md:grid-cols-1 md:gap-4 w-5/12">
            <p className="text-sm font-medium text-primary-500">
              {coupon.formattedText}
            </p>
          </div>
          <p className="flex items-center justify-end w-[90px] text-sm text-gray-500 ">
            {coupon.used}/{coupon.totalStock}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CouponBlock;
