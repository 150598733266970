import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  GenericButton,
  Loading,
  NotFoundMobile,
  CouponForm,
} from "../../../components";
import instance from "../../../lib/client";
import { isHaveAccess } from "../../../utils/roleUtils";
import { amountConverter } from "../../../utils/stringUtils";
import CouponItem from "./CouponItem";
import CouponUsed from "./CouponUsed";

const Coupons = ({ eventId, slug }) => {
  const [coupons, setCoupons] = useState([]);
  const [activeCoupons, setActiveCoupons] = useState([]);
  const { t } = useTranslation();
  const [edit, setEdit] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [couponModal, setCouponModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleData = () => {
    setLoading(true);
    Promise.all([
      instance.get(`report/coupon-sales/${eventId}`),
      instance.get(`coupon/event/${eventId}`),
    ]).then(([sales, coupon]) => {
      setCoupons(sales.data.data.coupons);
      setActiveCoupons(coupon.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    handleData();
  }, [eventId]);

  const handleEdit = (coupon) => {
    setEdit(true);
    setSelectedCoupon(coupon);
    setCouponModal(true);
  };

  const totalTransactionCount = () => {
    var a = 0;
    coupons.forEach((coupon) => (a += coupon.normalAmount));
    return a;
  };

  const totalDiscountAmount = () => {
    var a = 0;
    coupons.forEach((coupon) => (a += coupon.totalPrice));
    return a;
  };

  if (loading) return <Loading />;
  return (
    <div className="relative">
      {activeCoupons.length > 0 ? (
        <div>
          <div className="flex flex-row justify-between mb-2">
            <label className="text-md font-medium text-gray-500 truncate">
              {t("coupon:totalTransactionAmount")}
            </label>
            <label className="text-md font-medium text-gray-500 truncate">
              {amountConverter(totalTransactionCount())}
            </label>
          </div>
          <div class="relative mt-2">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div className="flex flex-row justify-between mb-2 mt-2">
            <label className="text-md font-medium text-gray-500 truncate">
              {t("coupon:totalDiscountAmount")}
            </label>
            <label className="text-md font-medium text-gray-500 truncate">
              {amountConverter(totalDiscountAmount())}
            </label>
          </div>
          <div class="relative mt-2">
            <div class="w-full border-t border-gray-300"></div>
          </div>
          <div className="flex flex-row justify-between mb-2 mt-2">
            <label className="text-md font-medium text-gray-500 truncate">
              {t("coupon:totalDiscount")}
            </label>
            <label className="text-md font-medium text-gray-500 truncate">
              {amountConverter(totalTransactionCount() - totalDiscountAmount())}
            </label>
          </div>
          {isHaveAccess("eventCouponCreate") && (
            <GenericButton
              className={"mt-4"}
              onClick={() => {
                setSelectedCoupon(null);
                setEdit(false);
                setCouponModal(true);
              }}
            >
              {t("coupon:addNewCoupon")}
            </GenericButton>
          )}

          <div className="flex flex-col mt-10">
            <label className="text-lg font-medium text-gray-800 truncate mb-4">
              {t("coupon:activeCoupons")}
            </label>
            {activeCoupons && activeCoupons.length > 0 && (
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-h-[250px] overflow-y-auto">
                  <table className="min-w-full divide-y divide-gray-200 ">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("coupon:name")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("coupon:price")}
                        </th>
                        <th
                          scope="col"
                          className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          {t("coupon:used")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {activeCoupons.map((coupon, i) => (
                        <CouponItem
                          key={i}
                          coupon={coupon}
                          index={i}
                          onClick={() => handleEdit(coupon)}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {coupons && coupons.length > 0 && (
              <>
                <label className="text-lg font-medium text-gray-800 truncate mb-4">
                  {t("coupon:couponsUsageRates")}
                </label>
                <div className="-my-2 overflow-x-auto ">
                  <div className="py-2 align-middle inline-block min-w-full">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg max-h-[250px] overflow-y-auto">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("coupon:name")}
                            </th>
                            <th
                              scope="col"
                              className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("coupon:count")}
                            </th>
                            <th
                              scope="col"
                              className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                              {t("coupon:totalDiscount")}
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {coupons.map((coupon, i) => (
                            <CouponUsed key={i} coupon={coupon} index={i} />
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <NotFoundMobile
          text={t("coupon:noItem")}
          buttonText={t("coupon:add")}
          onClick={() => {
            setSelectedCoupon(null);
            setEdit(false);
            setCouponModal(true);
          }}
        />
      )}

      {!window.location.pathname.includes("coupons") &&
        isHaveAccess("eventCouponList") && (
          <Link to={`/dashboard/coupons/${eventId}?slug=${slug}`}>
            <GenericButton
              className={"mt-4"}
              onClick={() => {
                setSelectedCoupon(null);
                setEdit(false);
                setCouponModal(true);
              }}
            >
              {t("utils:viewAll")}
            </GenericButton>
          </Link>
        )}

      {isHaveAccess("")}
      <CouponForm
        isOpen={couponModal}
        setIsOpen={() => {
          setEdit(false);
          setSelectedCoupon(null);
          setCouponModal(false);
        }}
        edit={edit}
        setEdit={() => {
          setEdit(false);
          setSelectedCoupon(null);
        }}
        handleData={handleData}
        coupon={selectedCoupon}
        eventId={eventId}
      />
    </div>
  );
};

export default Coupons;
