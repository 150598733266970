import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../lib/client";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { FormInput } from "../../../components";
import { Controller } from "react-hook-form";
import QuestionModal from "../../../components/QuestionModal";
import autoAnimate from "@formkit/auto-animate";

function Basics({
  selectedSection,
  setSelectedSection,
  register,
  setValue,
  getValues,
  clearErrors,
  control,
  checkIsValid,
  saveAsDraft,
  errors,
  id,
}) {
  const { t } = useTranslation();
  const [venues, setVenues] = useState([]);
  const [isAnnounced, setIsAnnounced] = useState(false);
  const [selectedLang, setSelectedLang] = useState("tr");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [targetUrl, setTargetUrl] = useState("");

  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  // useEffect(() => {
  //   Promise.all([instance.get("venue")]).then(([venues]) => {
  //     console.log("venues", venues.data.data);
  //     setVenues(venues.data.data);
  //   });
  // }, []);

  const returnSelectData = (datas) => {
    var a = [];

    datas?.forEach((data) => {
      a.push({
        id: data._id,
        label: data.name
          ? data.name
          : data.currency_symbol + " - " + data.currency_name,
        value: data.slug ? data.slug : data.currency_symbol,
        image: data.image,
      });
    });

    return a;
  };

  const handleIsAnnounced = () => {
    setValue(`isVenueAnnounced`, !getValues(`isVenueAnnounced`));
    setIsAnnounced(!isAnnounced);
  };

  const onNextPage = async () => {
    (await checkIsValid()) && setSelectedSection(selectedSection + 1);
  };

  const handleSearchVenue = async (inputValue, resolve) => {
    if (inputValue.length < 3) {
      return [];
    }
    const data = await instance.get(`venue/search?keyword=${inputValue}`);
    resolve(returnSelectData(data?.data.data));
    setVenues(data?.data?.data);
  };

  return (
    <section aria-labelledby="section-1-title">
      <div className="rounded-lg bg-white shadow ">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md ">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3 relative">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.name ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("eventForm:eventName")}
                    </label>
                    <FormInput
                      type="text"
                      name="eventName"
                      id="eventName"
                      {...register("name")}
                      className={
                        errors?.name &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.name?.message}
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-6 sm:border-t sm:border-gray-200 " />
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.start_date ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:start_date")}
                    </label>
                    <FormInput
                      {...register("start_date")}
                      type="date"
                      name="start_date"
                      id="start_date"
                      className={
                        errors?.start_date &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.start_date?.message}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3 ">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.end_date ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:end_date")}
                    </label>
                    <FormInput
                      type="date"
                      {...register("end_date")}
                      className={
                        errors?.end_date &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      name="end_date"
                      id="end_date"
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.end_date?.message}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.start_time ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:start_time")}
                    </label>
                    <FormInput
                      {...register("start_time")}
                      className={
                        errors?.start_time &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      type="time"
                      name="start_time"
                      id="start_date"
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.start_time?.message}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.end_time ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:end_time")}
                    </label>
                    <FormInput
                      type="time"
                      {...register("end_time")}
                      className={
                        errors?.end_time &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      name="end_time"
                      id="end_time"
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.end_time?.message}
                    </div>
                  </div>
                  <div className="col-span-6 sm:col-span-6 sm:border-t sm:border-gray-200 " />
                  <div className="flex h-5 items-center col-span-6 sm:col-span-6">
                    <input
                      checked={getValues(`isVenueAnnounced`)}
                      onChange={() => {
                        handleIsAnnounced();
                      }}
                      id="isAnnounced"
                      name="isAnnounced"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500 mr-2 ml-0.5 accent-gray-700"
                    />
                    <label
                      htmlFor="start_date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Event Venue To Be Announced Later
                    </label>
                  </div>
                  <div className="col-span-6 sm:col-span-6 " ref={parent}>
                    {!isAnnounced && (
                      <div className="col-span-6 sm:col-span-6 z-30">
                        <label
                          className={`block text-sm font-medium  ${
                            errors?.venue_detail
                              ? "text-red-400"
                              : "text-gray-700"
                          }`}
                        >
                          {t("reports:venue")}
                        </label>
                        <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <div
                              className={`h-fit w-fit ${
                                errors?.venue_detail
                                  ? "border border-red-400 rounded-md"
                                  : "border-none"
                              }`}
                            >
                              <AsyncSelect
                                noOptionsMessage={() =>
                                  "No results found, type to search"
                                }
                                placeholder={"Type to search venues"}
                                // options={returnSelectData(venues)}
                                onChange={onChange}
                                value={value}
                                onBlur={onBlur}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                loadOptions={handleSearchVenue}
                              />
                            </div>
                          )}
                          name="venue_detail"
                          defaultValue=""
                        />
                        {(!id || getValues("type") == "draft") && (
                          <div className="text-primary-600 text-xs mt-2 ml-1 flex justify-end items-center">
                            <div
                              onClick={() => {
                                setTargetUrl("/new-venue");
                                setIsOpenModal(true);
                              }}
                              className="cursor-pointer "
                            >
                              {t("eventForm:addNewVenue")}
                            </div>
                          </div>
                        )}
                        <div className="text-red-400 text-xs mt-2 ml-1">
                          {errors?.venue_detail
                            ? errors?.venue_detail?.message
                            : ""}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end gap-4">
                {(getValues("type") == "draft" ||
                  !window.location.pathname.includes("update-event")) && (
                  <button
                    onClick={() => {
                      saveAsDraft();
                    }}
                    className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {t("eventForm:saveAsDraft")}
                  </button>
                )}
                <div
                  onClick={() => {
                    onNextPage();
                  }}
                  className="bg-primary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("eventForm:next")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <QuestionModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        header={t("utils:warning")}
        question={t("eventForm:newVenueArtistWarning")}
        noText={t("utils:cancel")}
        yesText={t("eventForm:saveAsDraft")}
        onClickYes={() => {
          saveAsDraft(targetUrl);
        }}
      />
    </section>
  );
}

export default Basics;
