import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { HiCheckCircle, HiMinusCircle, HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const EventBlock = ({ event }) => {
  const [error, setError] = React.useState(false);
  const { t } = useTranslation();

  return (
    <div key={event._id}>
      <Link
        to={
          event?.type == "draft"
            ? `/dashboard/update-event/${event?._id}`
            : `/dashboard/event/detail/${event?.slug}`
        }
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0 flex flex-col w-12 justify-center">
              <div className="h-6 w-6 rounded-md flex items-center text-primary-500 text-sm text-center">
                {moment.utc(event.start_time).format("MMM").toUpperCase()}
              </div>
              <div className="h-6 w-6 rounded-md flex items-center text-primary-500 font-bold text-center">
                {moment.utc(event.start_time).format("DD")}
              </div>
            </div>
            <div className="flex-shrink-0">
              {event.image && !error ? (
                <img
                  className="h-12 w-12 rounded-md"
                  src={event.image}
                  onError={() => setError(true)}
                  alt="banner"
                />
              ) : (
                <div className="h-12 w-12 rounded-md bg-primary-500 flex items-center justify-center text-white">
                  {event?.name.charAt(0)}
                </div>
              )}
            </div>

            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">
                  {event?.name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate">{event?.venue?.name}</span>
                </p>
              </div>
              <div className="hidden md:flex flex-row">
                <div>
                  <p className="flex items-center text-sm text-gray-500">
                    <HiCheckCircle
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400 "
                      aria-hidden="true"
                    />
                    {moment.utc(event.start_time).format("LLL")}
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <HiMinusCircle
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {moment.utc(event.end_time).format("LLL")}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center ml-auto xs:hidden md:flex">
                  <p
                    className={`text-sm rounded-md font-semibold p-1 text-white ${
                      !event.isActive ||
                      moment().isAfter(
                        moment(
                          event.end_date ? event.end_date : event.end_time
                        ).toDate()
                      )
                        ? "bg-gray-400"
                        : !event.for_sale
                        ? "bg-red-400 "
                        : "bg-primary-500 "
                    }`}
                  >
                    {event?.type == "draft"
                      ? t("events:draft")
                      : moment().isAfter(
                          moment(
                            event.end_date ? event.end_date : event.end_time
                          ).toDate()
                        )
                      ? t("events:pastEvent")
                      : !event?.isActive
                      ? t("events:waiting")
                      : !event?.for_sale
                      ? t("eventTickets:deactive")
                      : t("eventTickets:active")}
                  </p>
                </div>
                <div className="flex flex-row items-center justify-center ml-auto xs:flex md:hidden">
                  {event.isActive ? (
                    <HiMinusCircle
                      className="flex-shrink-0 mr-1.5 h-8 w-8 text-gray-400"
                      aria-hidden="true"
                    />
                  ) : (
                    <HiCheckCircle
                      className="flex-shrink-0 mr-1.5 h-8 w-8 text-green-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <HiChevronRight
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default EventBlock;
