import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../lib/client";
import Select from "react-select";
import { FormInput, FormTextArea } from "../../../components";
import { Controller, useWatch } from "react-hook-form";
import autoAnimate from "@formkit/auto-animate";

function Details({
  selectedSection,
  setSelectedSection,
  register,
  control,
  genres,
  setCities,
  cities,
  labelList,
  countries,
  errors,
  checkIsValid,
  setValue,
  getValues,
}) {
  const { t } = useTranslation();
  const parent = useRef(null);
  const [labels, setLabels] = useState(labelList);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    setLabels(labelList);
  }, [labelList]);

  const addNewLabel = () => {
    labels[labels.length - 1].label &&
      setLabels([...labels, { label: "", labelLink: "" }]);
  };

  const deleteLabel = (index) => {
    setLabels(labels.filter((_, i) => i !== index));
  };

  const country = useWatch({
    control: control,
    name: "country",
  });

  useEffect(async () => {
    if (country?.hasOwnProperty("value")) {
      const { data } = await instance.get(`venue/country/${country?.value}`);

      setCities(
        data.data.map((city) => {
          return {
            label: city.name,
            value: city.city_id,
          };
        })
      );
    }
  }, [country]);

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log(errors);
  };

  useEffect(() => {
    setValue("labels", labels);
    labels.length == 1 && setValue("label", labels[0].label);
    labels.length == 1 && setValue("labelLink", labels[0].labelLink);
  }, [labels]);

  return (
    <section aria-labelledby="section-1-title overflow-auto">
      <h2 className="sr-only" id="section-1-title">
        Section title
      </h2>
      <div className="rounded-lg bg-white overflow-hidden shadow overflow-auto">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.name ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("artistForm:name")}
                    </label>
                    <FormInput
                      className={
                        errors?.name &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      type="text"
                      {...register("name")}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.translations && errors?.translations[0]?.desc
                          ? "text-red-400"
                          : "text-gray-700"
                      }`}
                    >
                      {t("artistForm:desc") + ` (TR)`}
                    </label>

                    <FormTextArea
                      {...register("translations.0.desc")}
                      className={
                        errors?.translations &&
                        errors?.translations[0]?.desc &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      rows={3}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.translations && errors?.translations[1]?.desc
                          ? "text-red-400"
                          : "text-gray-700"
                      }`}
                    >
                      {t("artistForm:desc") + ` (EN)`}
                    </label>

                    <FormTextArea
                      {...register("translations.1.desc")}
                      className={
                        errors?.translations &&
                        errors?.translations[1]?.desc &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      rows={3}
                    />
                  </div>

                  <div className="md:col-span-3 xs:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.country ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:country")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.country
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            onBlur={onBlur}
                            options={countries}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="country"
                      defaultValue=""
                    />
                  </div>
                  <div className="md:col-span-3 xs:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.city ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:city")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.city
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            onBlur={onBlur}
                            options={cities}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="city"
                      defaultValue=""
                    />
                  </div>

                  <div ref={parent} className="col-span-6">
                    {labels?.map((label, index) => (
                      <div className="grid grid-cols-6 gap-4 mt-4">
                        <div className="col-span-6 sm:col-span-3">
                          <label className=" text-sm font-medium text-gray-700 flex  items-center gap-2">
                            {`${t("artistForm:label")} ${index + 1}`}
                            {index > 0 && (
                              <div
                                onClick={() => {
                                  deleteLabel(index);
                                }}
                                className="text-red-400 pb-[1px] border border-red-400 w-4 h-4 flex justify-center items-center text-center rounded-full cursor-pointer"
                              >
                                -
                              </div>
                            )}
                          </label>
                          <FormInput
                            type="text"
                            // {...register("label")}
                            value={label?.label}
                            onChange={(e) => {
                              setLabels((prev) => {
                                const newLabels = [...prev];
                                newLabels[index].label = e.target.value;
                                return newLabels;
                              });
                            }}
                          />
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label className="block text-sm font-medium text-gray-700">
                            {t("artistForm:labelLink")}
                          </label>
                          <FormInput
                            type="text"
                            // {...register("labelLink")}
                            value={label?.labelLink}
                            onChange={(e) => {
                              setLabels((prev) => {
                                const newLabels = [...prev];
                                newLabels[index].labelLink = e.target.value;
                                return newLabels;
                              });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                    <div
                      className=" col-span-6 text-xs text-right mt-2 text-primary-600 cursor-pointer"
                      onClick={() => {
                        addNewLabel();
                      }}
                    >
                      Add New Label
                    </div>
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.genres ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("ticket:addGenres")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.city
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="top"
                            isMulti
                            onBlur={onBlur}
                            options={genres}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="genres"
                      defaultValue=""
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="button"
                  onClick={() => onNextPage()}
                  className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("artistForm:next")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
