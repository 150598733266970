import React from "react";
import { useTranslation } from "react-i18next";
import UsedBlock from "./UsedBlock";

const UsedCoupons = ({ coupons }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col max-h-[700px] overflow-y-auto">
      <div className="flex items-center px-2 py-2 sm:px-6 border w-full rounded rounded-12">
        <div className="min-w-0 w-full flex items-center">
          <div className="w-3/12 text-sm font-medium text-black truncate ">
            {t("coupon:title")}
          </div>

          <p className="text-sm font-medium w-3/12 ">
            {t("eventAddTicket:count")}
          </p>
          <p className="text-sm font-medium w-3/12 md:flex xs:hidden">
            {t("eventAddTicket:price")}
          </p>
          <p className="text-sm font-medium w-4/12">
            {t("ticket:discountAmount")}
          </p>
        </div>
        <p className="flex items-center text-sm text-gray-800 "></p>
      </div>
      {coupons.map((coupon, i) => {
        return <UsedBlock key={i} coupon={coupon} />;
      })}
    </div>
  );
};

export default UsedCoupons;
