import React from "react";

const FormInput = React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      autoComplete="given-name"
      className={`mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${className}`}
      {...rest}
    />
  );
});

export default FormInput;
