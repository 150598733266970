import moment from "moment";
import React, { useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { Link } from "react-router-dom";

const SearchBlock = ({ event, onClick }) => {
  const [error, setError] = useState(false);

  return (
    <Link
      onClick={onClick}
      to={`/dashboard/event/detail/${event.slug}`}
      className="block hover:bg-gray-50 cursor-pointer"
    >
      <div className="flex items-center px-4 py-4 sm:px-6">
        <div className="min-w-0 flex-1 flex items-center">
          <div className="flex-shrink-0">
            {event.image && !error ? (
              <img
                className="h-12 w-12 rounded-md"
                src={event.image}
                onError={() => setError(true)}
                alt="banner"
              />
            ) : (
              <div className="h-12 w-12 rounded-md bg-primary-500 flex items-center justify-center text-white">
                {event.name.charAt(0)}
              </div>
            )}
          </div>

          <div
            className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
            style={{ minWidth: 400 }}
          >
            <div>
              <p className="text-sm font-medium text-indigo-600 truncate">
                {event.name}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                <span className="truncate">
                  {moment.utc(event.start_time).format("LLL")}
                </span>
              </p>
            </div>
            <div className="hidden md:block"></div>
          </div>
        </div>
        <div>
          <HiChevronRight
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      </div>
    </Link>
  );
};

export default SearchBlock;
