import React from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { GenericButton } from "..";

const NotFoundMobile = ({
  text,
  onClick,
  buttonText,
  onClickFirst,
  height = 400,
  textSize = "xl",
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 30,
      }}
    >
      <AiOutlineInfoCircle
        className="text-gray-500 "
        style={{
          width: 60,
          height: 60,
        }}
      />
      <h3
        onClick={onClickFirst}
        className={`text-${textSize} font-medium text-gray-500 truncate`}
      >
        {text}
      </h3>
      <GenericButton className={""} onClick={onClick}>
        {buttonText}
      </GenericButton>
    </div>
  );
};

export default NotFoundMobile;
