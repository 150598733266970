import { toast } from "react-toastify";

const toastPush = (text, type = "success") => {
  switch (type) {
    case "success":
      return toast.success(text, {
        position: "top-left",
      });
    case "error":
      return toast.error(text, {
        position: "top-left",
      });
    default:
      return toast.success(text, {
        position: "top-left",
      });
  }
};

export default toastPush;
