import React from "react";

const CouponUsed = ({ coupon, index, onClick }) => {
  return (
    <tr
      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
      style={{ cursor: "pointer" }}
      onClick={() => onClick()}
    >
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm font-medium text-gray-900">
        {coupon.name}
      </td>
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm text-gray-500">
        {coupon.discount > 1
          ? coupon.discount + " ₺"
          : coupon.discount * 100 + "%"}
      </td>
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm text-gray-500">
        {coupon.used}/{coupon.totalStock}
      </td>
    </tr>
  );
};

export default CouponUsed;
