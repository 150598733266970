import React from "react";
import { HiCheck, HiDownload } from "react-icons/hi";
import useStore from "../../hooks/useStores";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";

export const handleDownloadClick = (pdfUrl, name) => {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `${name}.pdf`; // İndirilen dosyanın adını buraya belirleyebilirsiniz
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      console.error("PDF indirme hatası:", error);
    });
};

const GuestBlock = ({
  guest,
  checkedGuests,
  setCheckedGuests,
  deleteGuest,
  seeMore,
  cannotEdit = false,
  cannotCheck = false,
  isGroup = false,
  isLast = false,
}) => {
  const { modal } = useStore();
  return (
    <div key={guest._id} className={`${isGroup && "-z-10"}`}>
      <div
        className={`block ${
          isGroup ? `pl-3 -z-10  ${!isLast && "border-b"} ` : ""
        }`}
        style={guest.arrived ? { backgroundColor: "rgb(181,248,167)" } : null}
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div
            className={`min-w-0 flex-1 flex items-center ${
              !cannotEdit && "cursor-pointer"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              if (!cannotEdit) {
                console.log(guest);
                modal.triggerGuestTicketModal(guest);
              }
            }}
          >
            <div className="min-w-600 flex-1">
              <div className={`flex flex-row relative items-center `}>
                <p className="text-sm font-medium text-black truncate">
                  {guest.name}
                </p>
                <p className="text-xs font-medium text-gray-500 truncate ml-2">
                  {guest.group_order && `No: ${guest.group_order}`}
                </p>
              </div>
            </div>

            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 hidden md:flex-1">
              <div>
                <p className="text-sm font-medium text-indigo-600"></p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate"></span>
                </p>
              </div>
            </div>
            {isGroup && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownloadClick(
                    guest.cdn_url,
                    guest.name + `Ticket ${guest.group_order}`
                  );
                }}
                className="flex items-center text-sm text-gray-500 z-50 -mr-1 "
              >
                <p
                  className={`bg-white rounded-full h-7 w-7 flex items-center  justify-center`}
                >
                  <HiDownload
                    className="h-5 w-5 text-primary-500"
                    aria-hidden="true"
                  />
                </p>
              </div>
            )}
            {guest.property && (
              <p className="text-sm truncate ml-4 text-white mr-2 bg-primary-500 px-2 py-0.5 rounded-md font-bold">
                {guest.property.name}
              </p>
            )}

            <p className="flex items-center text-sm text-gray-500 ">
              {guest?.arrived || checkedGuests?.includes(guest._id) ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    if (checkedGuests.includes(guest._id)) {
                      setCheckedGuests(
                        checkedGuests.filter((id) => id !== guest._id)
                      );
                    }
                  }}
                  className={`bg-white rounded-full h-7 w-7 flex items-center mr-2 justify-center ${
                    checkedGuests?.includes(guest._id) &&
                    "border border-primary-500 cursor-pointer"
                  } `}
                >
                  <HiCheck
                    className="h-6 w-6 text-primary-500"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                !cannotCheck && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!checkedGuests?.includes(guest._id)) {
                        setCheckedGuests([...checkedGuests, guest._id]);
                      }
                    }}
                    className="bg-white rounded-full h-7 w-7 flex items-center mr-2 justify-center border border-primary-500 cursor-pointer"
                  />
                )
              )}
            </p>
          </div>
          <p onClick={() => deleteGuest('single',guest._id, guest)} className="text-sm z-60 truncate cursor-pointer text-white mr-2 bg-red-500 px-1.5 py-1.5 rounded-full font-bold">
            <AiOutlineClose/>
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuestBlock;
