import React from "react";

const FormTextArea = React.forwardRef(({ className, ...rest }, ref) => {
  return (
    <textarea
      ref={ref}
      className={`mt-1 max-w-lg shadow-sm block w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md ${className}`}
      {...rest}
    />
  );
});

export default FormTextArea;
