import React from "react";
import { FcAndroidOs } from "react-icons/fc";
import { AiFillApple } from "react-icons/ai";
import { GoBrowser } from "react-icons/go";
import moment from "moment";
import { IoMdExit } from "react-icons/io";

const DeviceBlock = ({ device }) => {
  return (
    <div>
      <div className="block">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div>
              {device.device_info.type === "web" ? (
                <GoBrowser className="h-8 w-8 " aria-hidden="true" />
              ) : device.device_info.name.includes("iOS") ? (
                <AiFillApple className="h-8 w-8 " aria-hidden="true" />
              ) : device.device_info.name.includes("android") ? (
                <FcAndroidOs className="h-8 w-8 " aria-hidden="true" />
              ) : null}
            </div>
            <div className="min-w-600 flex-1 ml-4 sm:max-w-200">
              <div>
                <p className="text-sm font-medium text-black truncate">
                  {device.device_info.operatingSystem}
                </p>
                <p className="text-xs font-medium text-black truncate">
                  Giriş tarihi :{moment(device.createdAt).format("LLL")}
                </p>
              </div>
            </div>

            <p className="flex items-center text-sm text-gray-500 ">
              <div className="bg-white rounded-full h-7 w-7 flex items-center mr-2 justify-center">
                <div className="hover:bg-red-500 rounded-full p-1">
                  <IoMdExit
                    className="h-7 w-7 cursor-pointer text-red-500 hover:text-white"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceBlock;
