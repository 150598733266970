import React, { useEffect, useState, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormInput, GenericButton } from "../../../../../components";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import instance from "../../../../../lib/client";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import toastPush from "../../../../../utils/toastPush";
import SingleGuest from "./SingleGuest";
import MultipleGuest from "./MultipleGuest";
import ExcelGuest from "./ExcelGuest";

const GuestForm = ({ guest, handleData, eventId, isOpen, setIsOpen }) => {
  const { register, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: guest,
  });
  const [properties, setProperties] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [guestDetails, setGuestDetails] = useState({});
  const [actionType, setActionType] = useState("singleGuest");
  const { t } = useTranslation();

  useEffect(() => {
    Promise.all([
      instance.get("guest/property/active"),
      instance.get(`scanner/available-tickets/${eventId}`),
    ]).then(([properties, tickets]) => {
      setTicketTypes(tickets.data.data.map((e) => ({ label: e, value: e })));
      setProperties(properties.data.data);
    });
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (guest) {
      handleGuestDetail();
    }
  }, [guest, isOpen, setIsOpen]);

  const handleGuestDetail = async () => {
    const { data } = await instance.get(`/guest/detail/${guest._id}`);
    setValue("name", data?.data?.name);
    setValue(
      "property",
      data.data.property
        ? {
            label: data?.data?.property.name,
            value: data?.data?.property.name,
          }
        : null
    );
    setValue("email", data?.data?.email);
    setValue(
      "ticket_type",
      data.data.ticket_type
        ? {
            label: data?.data?.ticket_type,
            value: data?.data?.ticket_type,
          }
        : null
    );
    setValue("arrived", data?.data?.arrived);
    setGuestDetails(data?.data);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setGuestDetails({});
          reset();
          setIsOpen(false);
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-[350px]">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {guest ? t("guests:updateTitle") : t("guests:addTitle")}
                      </Dialog.Title>

                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setIsOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <HiOutlineX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className=" mt-2 relative flex-1 px-2 sm:px-1.5">
                    <div>
                      <div className="lg:block border-t border-white border-opacity-20 ">
                        <div className="flex justify-around items-center  space-x-4 px-4">
                          <GenericButton
                            className="inline-flex items-center px-1.5 py-1.5 border  shadow-sm text-xs font-medium rounded text-white bg-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => setActionType("singleGuest")}
                          >
                            Single
                          </GenericButton>
                          <GenericButton
                            className="inline-flex items-center px-1.5 py-1.5 border  shadow-sm text-xs font-medium rounded text-white bg-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => setActionType("multipleGuest")}
                          >
                            Multiple
                          </GenericButton>
                          <GenericButton
                            className="inline-flex items-center px-1.5 py-1.5 border  shadow-sm text-xs font-medium rounded text-white bg-white hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            onClick={() => setActionType("excelGuest")}
                          >
                            Excel File
                          </GenericButton>
                        </div>
                      </div>
                    </div>
                    {/* Replace with your content */}
                    <div className=" grid grid-cols-1 row-gap-6 col-gap-4 sm:grid-cols-6"></div>
                    {
                      actionType === 'singleGuest' && 
                      <SingleGuest
                        eventId={eventId}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        handleData={handleData}
                      />
                    }
                    {
                      actionType === 'excelGuest' && (
                        <ExcelGuest
                          eventId={eventId}
                          isOpen={isOpen}
                          setIsOpen={setIsOpen}
                          handleData={handleData}
                        /> 
                      )
                    }
                    {
                      actionType === 'multipleGuest' && (
                        <MultipleGuest
                        eventId={eventId}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        handleData={handleData}
                      />
                      )
                    }
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GuestForm;
