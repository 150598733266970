import React from "react";
import {
  HiCheckCircle,
  HiMinusCircle,
  HiChevronRight,
  HiDotsCircleHorizontal,
} from "react-icons/hi";
import { Link } from "react-router-dom";

const ArtistBlock = ({ artist, deleteArtist }) => {
  const [error, setError] = React.useState(false);

  return (
    <div key={artist._id} className="relative">
      <Link
        to={`/dashboard/update-artist/${artist._id}`}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              {artist.image && !error ? (
                <img
                  className="h-12 w-12 rounded-md"
                  src={artist.image}
                  onError={() => setError(true)}
                  alt="banner"
                />
              ) : (
                <div className="h-12 w-12 rounded-md bg-primary-500 flex items-center justify-center text-white">
                  {artist?.name?.charAt(0)?.toUpperCase()}
                </div>
              )}
            </div>

            <div className="min-w-600 flex-1 px-4 md:grid xs:grid-cols-5 md:grid-cols-1 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">
                  {artist.name}
                </p>
                <p className="mt-2 flex flex-row items-center w-auto text-sm text-gray-500 md:max-w-auto xs:max-w-[120px] xs:overflow-x-auto">
                  {artist?.genres?.length > 0 ? (
                    artist.genres?.map((genre, i) => (
                      <span className="mr-1 bg-gray-200 p-1 rounded-md text-xs">
                        {genre?.toUpperCase()}
                      </span>
                    ))
                  ) : artist.label ? (
                    <span className="mr-1 bg-gray-200 p-1 rounded-md text-xs">
                      {artist?.label?.toUpperCase()}
                    </span>
                  ) : (
                    <span className="mr-1 bg-gray-200 p-1 rounded-md text-xs">
                      {artist?.website?.toUpperCase()}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 hidden md:flex-1">
              <div>
                <p className="text-sm font-medium text-indigo-600"></p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate"></span>
                </p>
              </div>
            </div>
            <div
              className=" py-0.5 px-1 rounded-md border border-red-400 text-red-400 mr-2 z-40 cursor-pointer"
              onClick={(e) => {
                deleteArtist(artist._id, e);
              }}
            >
              Delete
            </div>
            <p className="flex items-center text-sm text-gray-500 ">
              {artist.pending ? (
                <HiDotsCircleHorizontal
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-gray-400"
                  aria-hidden="true"
                />
              ) : artist.isActive ? (
                <HiCheckCircle
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-primary-500"
                  aria-hidden="true"
                />
              ) : (
                <HiMinusCircle
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
                  aria-hidden="true"
                />
              )}
            </p>
          </div>
          <div>
            <HiChevronRight
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ArtistBlock;
