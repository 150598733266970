import React from "react";
import authStore from "../stores/authStore";
import modalStore from "../stores/modalStore";

const storeContext = React.createContext({
  auth: authStore,
  modal: modalStore,
});

export default storeContext;
