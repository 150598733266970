import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../lib/client";
import Select from "react-select";
import { FormInput, FormTextArea, Loading } from "../../../components";
import { Controller, useWatch } from "react-hook-form";
import MapPicker from "react-google-map-picker";
import Geocode from "react-geocode";

Geocode.setApiKey("AIzaSyBEvJmylnfNZXeqMaws_TQ-WFpRoddgav8");
Geocode.setLanguage("en");
Geocode.setRegion("es");
Geocode.setLocationType("ROOFTOP");
const DefaultLocation = { lat: 41.115934609188116, lng: 29.095913864693397 };

function Basics({
  selectedSection,
  setSelectedSection,
  register,
  control,
  countrys,
  setValue,
  getValues,
  cities,
  setCities,
  errors,
  checkIsValid,
}) {
  const { t } = useTranslation();
  const [showMap, setShowMap] = useState(false);
  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
  const [location, setLocation] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [address, setAddress] = useState("");
  const [country2, setCountry2] = useState("");
  const [city, setCity] = useState("");
  const [key, setKey] = useState("AIzaSyBEvJmylnfNZXeqMaws_TQ-WFpRoddgav8");

  const phone = useWatch({
    control: control,
    name: "phone",
  });

  const coordinates = useWatch({
    control: control,
    name: "map",
  });

  const country = useWatch({
    control: control,
    name: "country",
  });

  const map = useWatch({
    control: control,
    name: "map",
  });

  function handleChangeLocation(lat, lng) {
    setDefaultLocation({ lat: lat, lng: lng });
    setLocation({ lat: lat, lng: lng });
    setValue("map", `${lat.toString()},${lng.toString()}`);
  }

  function handleChangeZoom(newZoom) {
    setZoom(newZoom);
  }

  useEffect(() => {
    location && getAddress(location.lat, location.lng);
  }, [location]);

  const getAddress = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setCountry2(country);
        setCity(state);
        setAddress(address);
        address && setValue("address", address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handlePhoneChange = (number) => {
    let val = number;
    val = val.replace(/ /gm, "");

    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
      6,
      8
    )} ${val.substring(8, val.length)}`;

    num = num.trim();

    setValue("phone", num);
  };

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log(errors);
  };

  useEffect(async () => {
    let country = 223;
    const { data } = await instance.get(`venue/country/${country}`);
    console.log(data);
    setCities(
      data.data.map((city) => {
        return {
          label: city.name,
          value: city.city_id,
        };
      })
    );
  }, []);

  useEffect(() => {
    // let search = country2 === "Türkiye" ? "Turkey" : country2;
    // countrys.map((country) => {
    //   if (country.label === search) {
    //     setValue("country", country);
    //   }
    // });

    let citySearch = city === "İstanbul" ? "İstanbul" : city;

    cities.map((item) => {
      if (item.label === citySearch) {
        setValue("city", item);
      }
    });
  }, [address, cities, countrys]);

  useEffect(() => {
    let timer = setTimeout(() => setShowMap(true), 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    handlePhoneChange(getValues("phone"));
  }, [phone]);

  useEffect(() => {
    if (coordinates) {
      const coord = coordinates.split(",");
      setDefaultLocation({
        lat: parseFloat(coord[0]),
        lng: parseFloat(coord[1]),
      });
    }
  }, [coordinates]);

  return (
    <section aria-labelledby="section-1-title overflow-visible">
      <h2 className="sr-only" id="section-1-title">
        Section title
      </h2>
      <div className="rounded-lg bg-white  shadow overflow-visible">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md overflow-visible">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.name ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:name")}
                    </label>
                    <FormInput
                      className={
                        errors?.name &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }
                      type="text"
                      {...register("name")}
                    />
                    <div className="text-red-400 text-xs mt-1 ml-0">
                      {errors?.name?.message}
                    </div>
                  </div>
                  <div className="md:col-span-3 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.country ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:country")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.country
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={{
                              label: "Türkiye",
                              value: 223,
                            }}
                            isDisabled={true}
                            menuPlacement="auto"
                            onBlur={onBlur}
                            options={countrys || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="country"
                      defaultValue=""
                    />
                  </div>
                  <div className="md:col-span-3 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.city ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("utils:city")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.city
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="auto"
                            onBlur={onBlur}
                            options={cities || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="city"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-[100%]">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.address ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:address")}
                    </label>
                    <FormTextArea
                      className={`!max-w-4xl ${
                        errors?.address &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }`}
                      {...register("address")}
                      rows={3}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-[100%] h-[375px] w-full">
                    {showMap ? (
                      <div id="map_canvas">
                        <MapPicker
                          defaultLocation={defaultLocation}
                          zoom={zoom}
                          mapTypeId="roadmap"
                          style={{ height: "375px", width: "100%" }}
                          onChangeLocation={handleChangeLocation}
                          onChangeZoom={handleChangeZoom}
                          apiKey={key}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center items-center h-full my-auto">
                          <Loading />
                        </div>
                      </>
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.map ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:map")}
                    </label>
                    <FormInput
                      type="text"
                      value={map}
                      {...register("map")}
                      placeholder={"41.116730,29.093288"}
                      className={`placeholder-gray-300 ${
                        errors?.map &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                      }`}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.phone ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:phone")}
                    </label>
                    <div className="relative ">
                      <div className="absolute w-4 h-full text-sm text-gray-700 flex justify-center items-center left-3">
                        +90
                      </div>
                      <FormInput
                        type="phone"
                        // {...register("phone", {
                        //   pattern: {
                        //     value: /^[0-9]+$/,
                        //     message: "Please enter a number",
                        //   },
                        // })}
                        // check is typing only number
                        onKeyPress={(e) => {
                          if (e.which < 48 || e.which > 57) {
                            e.preventDefault();
                            console.log("only number");
                          }
                        }}
                        value={phone}
                        onChange={(e) => {
                          console.log(e.target.value);
                          e.target.value.length <= 13 &&
                            handlePhoneChange(e.target.value);
                        }}
                        placeholder={"555 555 55 55"}
                        className={`placeholder-gray-300 !pl-9 ${
                          errors?.phone &&
                          "focus:!ring-red-400 focus:!border-red-400 !border-red-300 "
                        }`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="button"
                  onClick={() => {
                    onNextPage();
                  }}
                  className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("venueForm:next")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Basics;
