/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton, Loading } from "../../../components";
import toastPush from "../../../utils/toastPush";
import { observer } from "mobx-react-lite";
import { HiMinusCircle } from "react-icons/hi";

const RefundReasonDetailModal = observer(({}) => {
  const { modal } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [newRefundReason, setNewRefundReason] = useState("");
  const [refundReasons, setRefundReasons] = useState([]);

  const getRefundReasons = async () => {
    setLoading(true);
    const { data } = await instance.get("/sale/refund-reasons");
    setRefundReasons(data.data);
    setLoading(false);
  };

  const addRefundReason = async () => {
    if (newRefundReason) {
      const { data } = await instance.post("/refund/refund-reason/create", {
        name: newRefundReason,
      });

      toastPush(t("refund:refundReasonAdded"));
      setNewRefundReason("");
      getRefundReasons();
    }
  };

  const deleteRefundReason = async (_id) => {
    const result = await instance.get(`/refund/refund-reason/${_id}`);
    toastPush(t("refund:refundReasonDeleted"));
    getRefundReasons();
  };

  useEffect(() => {
    getRefundReasons();
  }, []);

  return (
    <Transition.Root show={modal.refundReasonDetail} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => modal.triggerRefundReasonDetailModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold">
                {t("refund:refundReasonTitle")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => modal.triggerRefundReasonDetailModal()}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-6">
                <FormInput
                  placeholder={t("refund:refundReason")}
                  value={newRefundReason}
                  label={t("refund:refundReason")}
                  onChange={(v) => setNewRefundReason(v.target.value)}
                  style={{ marginBottom: 10 }}
                />
                <GenericButton
                  background="primary"
                  onClick={() => addRefundReason()}
                  style={{ paddingVertical: 5, marginBottom: 15 }}
                  textBold
                >
                  {t("utils:add")}
                </GenericButton>
                <div className="flex flex-col gap-y-2 mt-4">
                  {loading ? (
                    <Loading />
                  ) : (
                    <div>
                      {refundReasons.map((refundReason, i) => {
                        return (
                          <div className="p-2 mb-1  text-sm bg-primary-200  font-semibold rounded-md flex flex-row relative">
                            {refundReason.name}

                            <HiMinusCircle
                              className="absolute right-1 h-5 w-5 cursor-pointer"
                              onClick={() => {
                                deleteRefundReason(refundReason._id);
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default RefundReasonDetailModal;
