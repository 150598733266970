import React, { useEffect, useState, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormInput, GenericButton } from "../../../../../components";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import instance from "../../../../../lib/client";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import toastPush from "../../../../../utils/toastPush";

const SingleGuest = ({ guest, handleData, eventId, isOpen, setIsOpen }) => {
  const { register, control, handleSubmit, reset, setValue } = useForm({
    defaultValues: guest,
  });
  const [properties, setProperties] = useState([]);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [guestDetails, setGuestDetails] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    Promise.all([
      instance.get("guest/property/active"),
      instance.get(`scanner/available-tickets/${eventId}`),
    ]).then(([properties, tickets]) => {
      setTicketTypes(tickets.data.data.map((e) => ({ label: e, value: e })));
      setProperties(properties.data.data);
    });
  }, [isOpen, setIsOpen]);

  useEffect(() => {
    if (guest) {
      handleGuestDetail();
    }
  }, [guest, isOpen, setIsOpen]);

  const handleGuestDetail = async () => {
    const { data } = await instance.get(`/guest/detail/${guest._id}`);
    setValue("name", data?.data?.name);
    setValue(
      "property",
      data.data.property
        ? {
            label: data?.data?.property.name,
            value: data?.data?.property.name,
          }
        : null
    );
    setValue("email", data?.data?.email);
    setValue(
      "ticket_type",
      data.data.ticket_type
        ? {
            label: data?.data?.ticket_type,
            value: data?.data?.ticket_type,
          }
        : null
    );
    setValue("arrived", data?.data?.arrived);
    setGuestDetails(data?.data);
  };

  const handleGuestRemove = async (guestId) => {
    const data = await instance.post(`/guest/delete/${guestId}`);
    setIsOpen(false);
    reset();
    setGuestDetails({});
    handleData();
    toastPush(t("guests:deleted"));
  };

  const returnSelectData = (datas) => {
    var a = [];
    datas.forEach((data) => {
      a.push({
        id: data._id,
        label: data.name
          ? data.name
          : data.currency_symbol + " - " + data.currency_name,
        value: data.slug ? data.slug : data.currency_symbol,
        image: data.image,
      });
    });

    return a;
  };

  const onSubmit = (data) => {
    data.arrived = false;
    data.ticket_type = data.ticket_type.value;
    data.property = data.property.id;
    data.count = parseInt(data.count);
    if (guest?._id) {
      instance.post(`/guest/update/${guest._id}`, data).then(({ data }) => {
        setIsOpen(false);
        setGuestDetails({});
        handleData();
        toastPush(t("guests:updated"));
        reset();
        window.reload()
      });
    } else {
      instance.post(`/guest/${eventId}`, data).then((response) => {
        setIsOpen(false);
        handleData();
        setGuestDetails({});
        reset();
        toastPush(t("guests:created"));
        window.reload()
      });
    }
    reset();
  };

  return (
    <>
      <div className="mt-6 relative flex-1 px-4 sm:px-6">
        <div className="absolute inset-0 px-4 sm:px-6">
          <form class="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div>
                <FormInput
                  name="name"
                  {...register("name")}
                  placeholder={t("guestForm:name")}
                />
              </div>
              <div class="mt-4">
                <FormInput
                  name="email"
                  {...register("email")}
                  placeholder={t("guestForm:email")}
                />
              </div>
              <div class="mt-4">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder={t("guestForm:guestType")}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      options={returnSelectData(properties)}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                  name="property"
                  defaultValue=""
                />
              </div>
              <div class="mt-4">
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      placeholder={t("guestForm:ticketType")}
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                      options={ticketTypes}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  )}
                  name="ticket_type"
                  defaultValue=""
                />
              </div>
              {!guest && (
                <div class="mt-4">
                  <FormInput
                    name="count"
                    type={"number"}
                    {...register("count")}
                    min={1}
                    placeholder={t("guestForm:count")}
                  />
                </div>
              )}
            </div>

            {guest && (
              <div className="flex flex-col">
                {guest.arrived && (
                  <>
                    <span className="text-sm text-gray-400">
                      {t("guests:arrivedBy")}
                    </span>
                    <label className="font-bold mb-2">
                      {guestDetails?.marked_as_arrived_by?.full_name}
                    </label>

                    <span className="text-sm text-gray-400">
                      {t("guests:arrivedAt")}
                    </span>
                    <label className="font-bold mb-2">
                      {moment(guestDetails?.arrived_at).format(
                        "DD MMM YYYY HH:mm"
                      )}
                    </label>
                  </>
                )}
                <span className="text-sm text-gray-400">
                  {t("guests:createdBy")}
                </span>
                <label className="font-bold mb-2">
                  {guestDetails?.created_by?.full_name
                    ? guestDetails?.created_by?.full_name
                    : guestDetails?.created_by?.username}
                </label>

                <span className="text-sm text-gray-400">
                  {t("guests:createdAt")}
                </span>
                <label className="font-bold mb-2">
                  {moment(guestDetails?.created_at).format("DD MMM YYYY HH:mm")}
                </label>
              </div>
            )}

            <div>
              <GenericButton
                type="submit"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {t("guests:addTitle")}
              </GenericButton>
              {guest && (
                <GenericButton
                  type="button"
                  onClick={() => handleGuestRemove(guest._id)}
                  class="w-full flex justify-center mt-2 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fourth-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("utils:delete")}
                </GenericButton>
              )}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SingleGuest;
