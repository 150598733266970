import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";
import {
  ProgressBar,
  Loading,
  NoItem,
  FormInput,
  EventDetails,
  GenericButton,
  ExcelLoading,
} from "../../../../components";
import instance from "../../../../lib/client";
import { dynamicSort, searchInArray } from "../../../../utils/arrayUtils";
import GuestForm from "./components/GuestForm";
import { GuestBlock } from "./components/index";
import Select from "react-select";
import { toast } from "react-toastify";
import toastPush from "../../../../utils/toastPush";
import { useHistory } from "react-router-dom";
import { HiMinusCircle } from "react-icons/hi";
import DetailSide from "../../../../components/DetailSide/DetailSide";
import { IoMdDownload } from "react-icons/io";
import useStore from "../../../../hooks/useStores";
import downloadExcelFile from "../../../../utils/downloadExcelFile";
import GuestBlockGroup from "./components/GuestBlockGroup";
import QuestionModal from "../../../../components/QuestionModal";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Guests() {
  const [loading, setLoading] = useState(true);
  const [propertyLoading, setPropertyLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [guests, setGuests] = useState([]);
  const [checkedGuests, setCheckedGuests] = useState([]);
  const [tempGuests, setTempGuests] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [event, setEvent] = useState({});
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const query = useQuery();
  const { auth } = useStore();
  const [isDownloading, setIsDownloading] = useState(false);
  const [isGuestTicketList, setIsGuestTicketList] = useState(false);
  const [groupedGuests, setGroupedGuests] = useState([]);
  const [tempGuestData, setTempGuestData] = useState({
    type:'',
    id:'',
    guest:'',
  });

  const startDeleteGuest = async () => {
    const {data} = await instance.get(`guest/delete/${tempGuestData.type}/${tempGuestData.id}`);

    if(!data.data.success) {
      toastPush(t("guests:deleteError"), "error");
    }

    if(tempGuestData.type ==='group') {
      toastPush(t("guests:deletedGroup"));
    }else {
      toastPush(t("guests:deleted"));
    }

    setTempGuestData({
        type:'',
        id:'',
        guest:'',
    })
    setIsOpenModal(false)
    handleData();
  }

  const deleteGuest = async (type,id,guest) => {
    setTempGuestData({
      type,
      id,
      guest,
    });
    setIsOpenModal(true);
  }

  function groupData(data) {
    return data.reduce((groups, item) => {
      if (item.group) {
        const existingGroup = groups.find(
          (group) => group._id === item.group._id
        );
        if (existingGroup) {
          existingGroup.count += 1;
          existingGroup.guests.push(item);
          if (item.arrived) {
            existingGroup.arrivedCount += 1;
          }
        } else {
          groups.unshift({
            alias: item.group.alias,
            zipUrl: item.group.zipUrl,
            _id: item.group._id,
            count: 1,
            guests: [item],
            arrivedCount: item.arrived ? 1 : 0,
            ticket_type: item.ticket_type,
          });
        }
      } else {
        groups.push(item);
      }
      return groups;
    }, []);
  }

  const handleData = () => {
    setLoading(true);
    getProperties();
    if (id && query.get("slug")) {
      instance.get(`event/${query.get("slug")}`).then(({ data }) => {
        setEvent(data.data);
        instance.get(`guest/${id}`).then(({ data }) => {
          setGuests(data.data);
          setTempGuests(data.data);
          setLoading(false);
        });
      });
    } else history.push("/dashboard");
  };

  const getProperties = async () => {
    setPropertyLoading(true);
    const { data } = await instance.get("guest/property/active");

    setProperties(data.data);
    setPropertyLoading(false);
  };

  const sortArray = (sortType) => {
    let sortedArray = guests.guests;

    if (sortType === "asc") sortedArray?.sort(dynamicSort("name", "asc"));
    else if (sortType === "desc")
      sortedArray?.sort((a, b) => b.name.localeCompare(a.name));
    else if (sortType === "scanned") sortedArray.sort(compareDate);

    setGuests({
      ...guests,
      guests: sortedArray,
    });
  };

  const compareDate = (a, b) => {
    var dateA =
      a.arrived_at !== undefined
        ? moment(a.arrived_at).toDate()
        : moment().subtract(10, "year").toDate();
    var dateB =
      b.arrived_at !== undefined
        ? moment(b.arrived_at).toDate()
        : moment().subtract(10, "year").toDate();

    return dateB > dateA ? 1 : -1;
  };

  const handleArrivedEntrants = () => {
    setLoading(true);
    instance
      .post(`/guest/${id}/arrive`, checkedGuests)
      .then(({ data: { data } }) => {
        handleData();
        setCheckedGuests([]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (searchText.length > 0) {
      setGuests({
        ...tempGuests,
        guests: searchInArray(
          searchText.toLowerCase(),
          tempGuests.guests,
          "name"
        ),
      });
    } else {
      setGuests(tempGuests);
    }
  }, [searchText, tempGuests]);

  const deleteProperty = async (id) => {
    const result = await instance.post(`guest/property/delete/${id}`);
    toastPush(t("guests:guestTypeDeleted"));
    getProperties();
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    await downloadExcelFile(
      `/report/excel/guests?eventId=${id}&lang=${auth.language}`,
      event.name + " " + "Guest List",
      auth.accessToken,
      setIsDownloading
    );
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    // group guests by property of group id if only group key exist in guest object, if not let data as it is. Sort all list and first items should be groupped. if a item group is then add a key as group true or false
    const groupedData = guests?.guests && groupData(guests.guests);
    console.log("groupedData", groupedData);
    setGroupedGuests(groupedData);
  }, [guests]);

  useEffect(() => {
    console.log("groupedGuests", groupedGuests);
  }, [groupedGuests]);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      {/* Main 1 column grid */}

      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* one column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title ">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                  <div className="items-center mb-4 md:flex flex-column justify-center lg:flex flex-row">
                    <h3
                      onClick={() => {
                        setIsGuestTicketList(false);
                      }}
                      className="text-xl text-gray-800"
                    >
                      Guest List
                    </h3>

                    <div className="ml-auto flex items-center flex-row gap-2 ">
                      {isDownloading ? (
                        <ExcelLoading />
                      ) : (
                        <IoMdDownload
                          size={36}
                          style={{ marginTop: 5, marginRight: 2 }}
                          color={"#1B1A17"}
                          onClick={exportExcelFile}
                        />
                      )}
                      <Select
                        placeholder={t("utils:sort")}
                        onChange={(data) => sortArray(data.value)}
                        options={[
                          { label: "A-Z", value: "asc" },
                          { label: "Z-A", value: "desc" },
                          { label: t("filter:byScanned"), value: "scanned" },
                        ]}
                        className="basic-multi-select w-[200px] mt-1 text-sm"
                        classNamePrefix="select"
                      />
                      <FormInput
                        placeholder={t("utils:search")}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                    </div>
                  </div>
                  <ProgressBar
                    all={guests.counts.all}
                    desired={guests.counts.arrived}
                    allText={t("guests:allBar")}
                    desiredText={t("guests:scannedBar")}
                  />
                  <div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
                    <div className="divide-y divide-gray-200 max-h-[63vh] overflow-y-auto">
                      {groupedGuests?.length > 0 ? (
                        groupedGuests.map((guest, i) => {
                          if (guest?.alias) {
                            return (
                              <GuestBlockGroup
                                group={guest}
                                checkedGuests={checkedGuests}
                                setCheckedGuests={setCheckedGuests}
                                deleteGuest={deleteGuest}
                                seeMore={(data) => {
                                  setSelectedGuest(data);
                                  setIsOpen(true);
                                }}
                                setSelectedGuest={setSelectedGuest}
                                setIsOpen={setIsOpen}
                              />
                            );
                          } else {
                            return (
                              <GuestBlock
                                key={i}
                                guest={guest}
                                deleteGuest={deleteGuest}
                                checkedGuests={checkedGuests}
                                setCheckedGuests={setCheckedGuests}
                                seeMore={(data) => {
                                  setSelectedGuest(data);
                                  setIsOpen(true);
                                }}
                              />
                            );
                          }
                        })
                      ) : (
                        <NoItem
                          name={t("notFound:guest")}
                          explanation={t("notFound:guestExp")}
                          button={t("utils:newGuest")}
                          route={"/dashboard/new-guest"}
                          onClick={() => setIsOpen(true)}
                        />
                      )}
                    </div>
                    {checkedGuests.length > 0 && (
                      <GenericButton
                        type="button"
                        onClick={() => handleArrivedEntrants()}
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        {t("guests:userArrived")}
                      </GenericButton>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <DetailSide id={id} slug={query.get("slug")} defaultSelectedTab={4} />
        </div>
      </div>
      <QuestionModal
          open={isOpenModal}
          setOpen={setIsOpenModal}
          header={t("utils:warning")}
          question={`${tempGuestData.type ==='group' ? 'Davetli Grubu' : 'Davetli'} Silinecektir Eminmisiniz?!`}
          noText={t("utils:cancel")}
          yesText={t("utils:done")}
          onClickYes={() => {
            startDeleteGuest();
          }}
      />
    </div>
  );
}

export default Guests;
