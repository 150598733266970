import React from "react";
import { useTranslation } from "react-i18next";
import { FormInput, FormSocialInput } from "../../../components";

function Social({
  selectedSection,
  setSelectedSection,
  register,
  setValue,
  errors,
  checkIsValid,
}) {
  const { t } = useTranslation();

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log(errors);
  };

  return (
    <section aria-labelledby="section-1-title overflow-auto">
      <h2 className="sr-only" id="section-1-title">
        Section title
      </h2>
      <div className="rounded-lg bg-white overflow-hidden shadow overflow-auto">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      Facebook
                    </label>
                    <FormSocialInput
                      socialTag={"https://www.facebook.com/"}
                      type="text"
                      {...register("facebook")}
                      onChange={(e) => {
                        let value = e.target.value.replace(
                          "https://www.facebook.com/",
                          ""
                        );
                        setValue("facebook", value);
                      }}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      Instagram
                    </label>
                    <FormSocialInput
                      socialTag={"https://www.instagram.com/"}
                      type="text"
                      {...register("instagram")}
                      onChange={(e) => {
                        let value = e.target.value.replace(
                          "hhttps://www.instagram.com/",
                          ""
                        );
                        setValue("instagram", value);
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      Youtube
                    </label>
                    <FormSocialInput
                      socialTag={"https://www.youtube.com/"}
                      type="text"
                      {...register("youtube")}
                      onChange={(e) => {
                        let value = e.target.value.replace(
                          "https://www.youtube.com/",
                          ""
                        );
                        setValue("youtube", value);
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      Twitter
                    </label>
                    <FormSocialInput
                      socialTag={"https://www.twitter.com/"}
                      type="text"
                      {...register("twitter")}
                      onChange={(e) => {
                        let value = e.target.value.replace(
                          "https://www.twitter.com/",
                          ""
                        );
                        setValue("twitter", value);
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      Spotify
                    </label>
                    <FormSocialInput
                      type="text"
                      socialTag={"https://open.spotify.com/"}
                      {...register("spotify")}
                      onChange={(e) => {
                        let value = e.target.value.replace(
                          "https://open.spotify.com/",
                          ""
                        );
                        setValue("spotify", value);
                      }}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("artistForm:website") + "(Link)"}
                    </label>
                    <FormInput type="text" {...register("website")} />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("artistForm:socialMedia") + "(Link)"}
                    </label>
                    <FormInput type="text" {...register("social_media")} />
                  </div>
                  <div className="col-span-6 sm:col-span-9">
                    <label className="block text-sm font-medium text-gray-700">
                      {t("artistForm:soundCloud") + " " + "(Embed Code)"}
                    </label>
                    <FormInput
                      type="text"
                      {...register("soundcloud")}
                      className={"placeholder-gray-300"}
                      placeholder={`<iframe width="100%" height="300" scrolling="no" frameborder="no" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/211655884&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>`}
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="button"
                  onClick={() => onNextPage()}
                  className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("artistForm:next")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Social;
