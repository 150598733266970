import React, { useEffect } from "react";
import { FormInput } from "../../../components";

const ArtistBlock = ({
  item,
  provided,
  index,
  t,
  register,
  getValues,
  setValue,
}) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="relative rounded-lg border w-full border-gray-200 bg-white px-2 shadow-md  py-5 flex items-center space-x-3 hover:border-gray-300 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
    >
      <div className="flex-shrink-0">
        <img className="h-10 w-10 rounded-full" src={item.image} alt="" />
      </div>
      <div className="flex-1 min-w-0">
        <span className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{item.label}</p>
          <p className="text-sm text-gray-500 truncate">{item.value}</p>
        </span>
      </div>
      <div className="flex-shrink-0 flex flex-row z-1 relative items-center">
        <div className="col-span-4 sm:col-span-3">
          <label
            htmlFor="start_date"
            className="block text-sm font-medium text-gray-700"
          >
            {t("utils:start_time")}
          </label>
          <FormInput
            type="time"
            value={getValues(`djs_detail.${index}.start_time`)}
            onChange={(e) => {
              setValue(`djs_detail.${index}.start_time`, e.target.value);
            }}
          />
        </div>
        <div className="col-span-4 sm:col-span-3 ml-4">
          <label
            htmlFor="end_date"
            className="block text-sm font-medium text-gray-700"
          >
            {t("utils:end_time")}
          </label>
          <FormInput
            type="time"
            value={getValues(`djs_detail.${index}.end_time`)}
            onChange={(e) => {
              setValue(`djs_detail.${index}.end_time`, e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ArtistBlock;
