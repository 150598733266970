import React from "react";
import {
  HiCheckCircle,
  HiMinusCircle,
  HiChevronRight,
  HiDotsCircleHorizontal,
} from "react-icons/hi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

const VenueBlock = ({ venue, deleteVenue }) => {
  const [error, setError] = React.useState(false);

  return (
    <div key={venue._id} className="relative">
      <Link
        to={`/dashboard/update-venue/${venue._id}`}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              {venue.image && !error ? (
                <img
                  className="h-12 w-12 rounded-full"
                  src={venue.image}
                  onError={() => setError(true)}
                  alt="banner"
                />
              ) : (
                <div className="h-12 w-12 rounded-full bg-primary-500 flex items-center justify-center text-white">
                  {venue.name.charAt(0)}
                </div>
              )}
            </div>

            <div className="min-w-600 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">
                  {venue.name}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate">@{venue.slug}</span>
                </p>
              </div>
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 hidden md:flex-1">
              <div>
                <p className="text-sm font-medium text-indigo-600">
                  {venue.address}
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate">{venue.website}</span>
                </p>
              </div>
            </div>
            <div
              className="  py-0.5 px-1 rounded-md border border-red-400 text-red-400 mr-2 z-40 cursor-pointer"
              onClick={(e) => {
                deleteVenue(venue._id, e);
              }}
            >
              Delete
            </div>
            <p className="flex items-center text-sm text-gray-500 ">
              <FaMapMarkerAlt className="flex-shrink-0 mr-1.5 h-6 w-6 mr-10 text-gray-400 hidden md:flex" />

              {venue.pending ? (
                <HiDotsCircleHorizontal
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-gray-400"
                  aria-hidden="true"
                />
              ) : venue.isActive ? (
                <HiCheckCircle
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-primary-500"
                  aria-hidden="true"
                />
              ) : (
                <HiMinusCircle
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
                  aria-hidden="true"
                />
              )}
            </p>
          </div>
          <div>
            <HiChevronRight
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default VenueBlock;
