import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import instance from "../../lib/client";
import ReactApexChart from "react-apexcharts";
import { MdEventAvailable } from "react-icons/md";
import { HiOutlineReceiptRefund } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { FaTicketAlt } from "react-icons/fa";
import {
  DonutChart,
  TabNav,
  Stats,
  Loading,
  GenericButton,
  CodeScanner,
  ExcelLoading,
} from "../../components/index";
import { EventDetails } from "../../components/index";
import moment from "moment";
import { Coupons, Guests, Tickets, VenueReport } from "./components";
import classNames from "../../utils/classNames";
import { AiOutlineQrcode } from "react-icons/ai";
import DetailSide from "../../components/DetailSide/DetailSide";
import ReactECharts from "echarts-for-react";
import {
  barChartData,
  lineChartOptions,
  multiBarChartData,
  pieChartData,
} from "./ChartOptions";
import _ from "lodash";
import { FcNegativeDynamic } from "react-icons/fc";
import numberToMoney from "../../utils/numberToMoney";
import useStore from "../../hooks/useStores";
import { isHaveAccess } from "../../utils/roleUtils";
import { IoMdDownload } from "react-icons/io";
import downloadExcelFile from "../../utils/downloadExcelFile";
import { data } from "autoprefixer";

function EventDetail() {
  const { slug } = useParams();
  const [details, setDetails] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [openScanner, setOpenScanner] = useState(false);
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genders, setGenders] = useState([]);
  const [refunds, setRefunds] = useState([]);
  const { modal, auth } = useStore();
  const { t } = useTranslation();
  const history = useHistory();
  const [isDownloading, setIsDownloading] = useState(false);
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    if (!slug) history.push("/dashboard");
    instance.get(`event/${slug}`).then(({ data }) => {
      setDetails(data?.data);
      Promise.all([
        instance.get(`/report/ticket/${data?.data?._id}`),
        instance.get(`/sale/active-tickets/${data?.data?._id}`),
        instance.get(`/report/gender/${data?.data?._id}`),
        instance.get(`/report/refund-tickets/${data?.data?._id}`),
      ]).then(([tickets, sales, genders, refunds]) => {
        setTickets(tickets?.data?.data);
        setSales(sales?.data?.data);
        setGenders(genders?.data?.data);
        setRefunds(refunds?.data?.data);
        setLoading(false);
        setEventId(data?.data?._id);
      });
    });
  }, [slug, history]);

  const areaData = () => {
    /*const nameObjects = _.groupBy(sales?.tickets, (item) => {
      return item["selected_ticket_type"];
    });

    const dates = Object.keys(
      _.groupBy(sales?.tickets, (item) => {
        return moment(item.transactionDate).format("DD-MM-YYYY");
      })
    );

    let series = Object.entries(nameObjects).map((d) => {
      let sortedData = d[1].sort(
        (a, b) =>
          moment(a.transactionDate, "DD-MM-YYYY") -
          moment(b.transactionDate, "DD-MM-YYYY")
      );

      for (var i = 0; i <= dates.length - 1; i++) {
        if (dates[i] !== sortedData[i].transactionDate) {
          sortedData.splice(i, 0, {
            transactionDate: dates[i],
            price: 0,
          });
        }
      }



      

      return {
        name: d[0],
        type: "line",
        data: sortedData
          .sort(
            (a, b) =>
              moment(a.transactionDate, "DD-MM-YYYY") -
              moment(b.transactionDate, "DD-MM-YYYY")
          )
          .map((k) =>
            k.price ? Math.round(k.transactionAmount / k.price) : 0
          ),
      };
    });*/

    const sortedArray = sales?.tickets.sort((a, b) => {
      var dateA = new Date(a.transactionDate).getTime();
      var dateB = new Date(b.transactionDate).getTime();
      return dateA > dateB ? 1 : -1;
    });

    const dateObjects = _.groupBy(sortedArray, (item) => {
      return moment(item["transactionDate"]).format("DD-MM-YYYY");
    });

    const dates = Object.keys(dateObjects);

    const series = [
      {
        name: "Count",
        type: "line",
        lineStyle: { color: "#ffc800" },
        data: Object.values(dateObjects).map((dat) => {
          let totalPrice = 0;
          dat.forEach((f) => {
            totalPrice += f.price;
          });

          return totalPrice;
        }),
      },
    ];

    let legends = ["Price"];

    return { series, dates, legends };
  };

  const compare = (a, b) => {
    if (a.count > b.count) {
      return -1;
    }
    if (a.count < b.count) {
      return 1;
    }
    return 0;
  };

  const returnTotalPrice = (array) => {
    if (array) {
      let ticketPrice = 0;

      array.forEach((item) => {
        ticketPrice += item.price;
      });

      return numberToMoney(ticketPrice) + "₺";
    } else {
      return 0;
    }
  };

  const stats = [
    {
      id: 1,
      name: t("events:sale"),
      stat: sales?.tickets?.length,
      icon: FaTicketAlt,
      change: "",
      changeType: "",
    },
    {
      id: 2,
      name: t("events:revenue"),
      stat: returnTotalPrice(tickets.report),
      icon: MdEventAvailable,
      change: "",
      changeType: "",
    },
    {
      id: 3,
      name: t("events:refund"),
      stat: numberToMoney(refunds?.counts?.totalAmount) + "₺",
      icon: HiOutlineReceiptRefund,
      change: "",
      changeType: "",
    },
  ];

  const donutData = (data, lng, name = "") => {
    let series = [
      {
        name: name,
        data: [],
        type: "pie",
        radius: ["40%", "70%"],

        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "40",
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
      },
    ];

    data?.forEach((report, i) => {
      /*
      {
          value: report.percentage,
          name: t("utils:" + report.name),
          type: "pie",
          color: report.color,
      */
      if (lng) {
        series[0].data.push({
          value: report.percentage,
          name: t("utils:" + report.name),
          color: report.color,
        });
      } else
        series[0].data.push({
          value: report.percentage,
          name: report.name,
          color: report.color,
        });
    });

    return { series };
  };

  const handleBarData = (array) => {
    const series = [];
    const colors = [];

    const legends = Object.keys(_.groupBy(array, (item) => item["name"]));

    array.forEach((d) => {
      colors.push(d.color);
      series.push({
        data: [d.count],
        name: d.name,
        type: "bar",
        itemStyle: { color: d.color },
      });
    });

    return { series, colors, legends };
  };

  const handleMultiBarData = (array) => {
    const series = [];
    const colors = [];

    const legends = Object.keys(_.groupBy(array, (item) => item["name"]));

    const combinedTickets = _.groupBy(
      sales?.tickets || [],
      (item) => item["selected_ticket_type"]
    );

    // name: 'Forest',
    // type: 'bar',
    // barGap: 0,
    // label: labelOption,
    // emphasis: {
    //   focus: 'series'
    // },
    // data: [320, 332, 301, 334, 390]

    let dates = [];

    let result = Object.entries(combinedTickets).map((d) => {
      const groupedByDate = _.groupBy(d[1], (item) =>
        moment(item["transactionDate"]).format("DD-MM-YYYY")
      );

      dates.push(...Object.keys(groupedByDate));

      return {
        name: d[0],
        type: "bar",
        barGap: 0,
        // label: labelOption,
        emphasis: {
          focus: "series",
        },
        data: groupedByDate, //Object.values(groupedByDate)?.map((k) => k.length),
      };
    });

    dates = [...new Set(dates)];

    result = result.map((d) => {
      const newData = dates.map((j) => {
        return Object.keys(d.data).find((c) => c === j) ? d.data[j].length : 0;
      });

      return {
        ...d,
        data: newData,
      };
    });

    dates = dates.map((d) => moment(d, "DD-MM-YYYY").format("DD ddd"));

    array.forEach((d) => {
      colors.push(d.color);
      series.push({
        data: [d.count],
        name: d.name,
        type: "bar",
        itemStyle: { color: d.color },
      });
    });

    return { series: result, colors, legends, dates };
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    await downloadExcelFile(
      `/report/excel/report?eventId=${eventId}&lang=${auth.language}`,
      slug + " " + t("reports:ticketSales"),
      auth.accessToken,
      setIsDownloading
    );
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="sr-only">Page title</h1>
      <div className="md:grid md:grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8 xs:flex xs:flex-wrap-reverse">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                {loading ? (
                  <Loading />
                ) : (
                  <>
                    <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 pb-5">
                      {stats.map((item, i) => {
                        return (
                          <Stats
                            key={i}
                            item={item}
                            t={t}
                            trigger={
                              item.id === 3 && refunds?.counts?.totalAmount > 0
                                ? () => modal.triggerRefundModal(details._id)
                                : false
                            }
                          />
                        );
                      })}
                    </dl>

                    <div className="relative">
                      <ReactECharts
                        option={lineChartOptions(
                          areaData().series,
                          areaData().dates,
                          areaData().legends,
                          t("reports:ticketSales")
                        )}
                        style={{ height: 400, width: "100%" }}
                      />
                      <div className="absolute top-0 right-0">
                        {isDownloading ? (
                          <ExcelLoading />
                        ) : (
                          <IoMdDownload
                            size={26}
                            style={{ marginTop: 2, marginRight: 10 }}
                            color={"#1B1A17"}
                            className={"cursor-pointer"}
                            onClick={exportExcelFile}
                          />
                        )}
                      </div>
                    </div>
                    <div className="md:hidden xs:flex">
                      <ReactECharts
                        name={t("reports:ticketScanCount")}
                        option={barChartData({
                          series: handleBarData(tickets.report).series,
                          colors: handleBarData(tickets.report).colors,
                          legends: handleBarData(tickets.report).legends,
                          name: t("reports:ticketScanCount"),
                          tickets: tickets.report,
                          isMobile: true,
                        })}
                        style={{ height: 400, width: "100%", marginTop: 40 }}
                      />
                    </div>
                    <div className="md:flex xs:hidden">
                      <ReactECharts
                        name={t("reports:ticketScanCount")}
                        option={barChartData({
                          series: handleBarData(tickets.report).series,
                          colors: handleBarData(tickets.report).colors,
                          legends: handleBarData(tickets.report).legends,
                          name: t("reports:ticketScanCount"),
                          tickets: tickets.report,
                          isMobile: false,
                        })}
                        style={{ height: 400, width: "100%", marginTop: 40 }}
                      />
                    </div>

                    <div className="md:flex xs:hidden">
                      <ReactECharts
                        name={t("reports:ticketCountByType")}
                        option={multiBarChartData({
                          series: handleMultiBarData(tickets.report).series,
                          colors: handleBarData(tickets.report).colors,
                          legend: {
                            data: handleBarData(tickets.report).legends,
                          },
                          dates: handleMultiBarData(tickets.report).dates,
                          name: t("reports:ticketCountByType"),
                          tickets: sales?.tickets,
                          isMobile: false,
                        })}
                        style={{ height: 400, width: "100%", marginTop: 40 }}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        paddingTop: 15,
                        flexWrap: "wrap",
                        alignItems: "center",
                      }}
                    >
                      <ReactECharts
                        option={pieChartData({
                          colors: ["#47c4a0", "#FACC14"],
                          series: [
                            {
                              name: t("reports:ticketScan"),
                              data: [
                                {
                                  name: t("reports:scanned"),
                                  value: tickets.counts?.scanned,
                                },
                                {
                                  name: t("reports:not_scanned"),
                                  value:
                                    tickets.counts?.all -
                                    tickets.counts?.scanned,
                                },
                              ],
                              type: "pie",
                              radius: ["40%", "70%"],
                              avoidLabelOverlap: false,
                              itemStyle: {
                                borderRadius: 10,
                                borderColor: "#fff",
                                borderWidth: 2,
                              },
                              label: {
                                show: false,
                                position: "center",
                              },
                              emphasis: {
                                label: {
                                  show: true,
                                  fontSize: "40",
                                  fontWeight: "bold",
                                },
                              },
                              labelLine: {
                                show: false,
                              },
                            },
                          ],
                        })}
                        style={{
                          width: 250,
                          height: 250,
                        }}
                      />

                      <ReactECharts
                        option={pieChartData({
                          series: donutData(genders, true, t("reports:gender"))
                            .series,
                        })}
                        style={{
                          width: 250,
                          height: 250,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4">
          {details && <DetailSide id={details._id} slug={slug} />}
        </div>
      </div>
      {isHaveAccess("eventScanner") && (
        <GenericButton
          onClick={() => setOpenScanner(true)}
          style={{
            position: "fixed",
            right: 15,
            bottom: 15,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 220,
            fontSize: 18,
            zIndex: 0,
          }}
        >
          <AiOutlineQrcode style={{ fontSize: 30, marginRight: 10 }} />
          {t("events:scanTicket")}
        </GenericButton>
      )}

      {details?._id && isHaveAccess("eventScanner") && (
        <CodeScanner
          isOpen={openScanner}
          setIsOpen={setOpenScanner}
          eventId={details._id}
        />
      )}
    </div>
  );
}

export default EventDetail;
