import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const LastTickets = ({ tickets, t, href }) => {
  return (
    <div>
      <div className="flow-root mt-6">
        <div role="list" className="-my-5 divide-y divide-gray-200">
          {tickets.slice(0, 10).map((ticket, i) => (
            <div key={i} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-primary-500 flex items-center justify-center">
                    <p className="text-md font-medium text-white truncate">
                      {ticket.name.charAt(0).toUpperCase() +
                        ticket.last_name.charAt(0).toUpperCase()}
                    </p>
                  </div>
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {ticket.name + " " + ticket.last_name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {ticket.selected_ticket_type}
                  </p>
                </div>
                <div>
                  <p
                    className="text-sm text-gray-500 truncate"
                    style={{
                      maxWidth: 80,
                      wordBreak: "break-all",
                    }}
                  >
                    {moment(ticket.transactionDate).format("LLL")}
                  </p>
                </div>
                <div>
                  <span className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
                    {ticket.transactionAmount + " ₺"}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-auto">
        <Link
          to={href}
          className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          {t("utils:viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default LastTickets;
