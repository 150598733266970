import React from "react";
import { Link } from "react-router-dom";
import BugeceDark from "../../assets/images/BugeceDark.svg";
import { useTranslation } from "react-i18next";

const NoItem = ({ name, explanation, button, route, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-indigo-600 sm:text-5xl">
            <img src={BugeceDark} alt="no_data_image" />
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-gray-800 tracking-tight sm:text-5xl">
                {name}
              </h1>
              <p className="mt-1 text-base text-gray-500">{explanation}</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              {button && (
                <button
                  onClick={() => onClick()}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {button}
                </button>
              )}

              <Link
                to={"/dashboard"}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {t("utils:backHome")}
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default NoItem;
