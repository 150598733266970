import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { GenericButton, Loading, NoItem } from "../../../../components";
import instance from "../../../../lib/client";
import { VenueBlock } from "./components/index";
import Select from "react-select";
import { isHaveAccess } from "../../../../utils/roleUtils";

function Venue() {
  const [loading, setLoading] = React.useState(true);
  const [venues, setVenues] = React.useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const getVenue = () => {
    console.log("getVenue");
    instance.get("venue/my-list").then(({ data }) => {
      setVenues(data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getVenue();
  }, []);

  const deleteVenue = (id, e) => {
    e.preventDefault();
    if (window.confirm("Do you really want to delete?")) {
      instance.delete(`venue/detail/${id}`).then(({ data }) => {
        console.log(data);
        getVenue();
      });
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      {/* Main 1 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        {/* one column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <h2 className="sr-only" id="section-1-title">
              Section title
            </h2>
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                  {venues.length > 0 && (
                    <div className="mb-4 w-full flex md:flex-row xs:flex-col gap-2 justify-between">
                      {isHaveAccess("venueCreate") && (
                        <Link to={"/dashboard/new-venue"} className={"ml-auto"}>
                          <GenericButton className="shadow md:w-46 xs:w-full ml-auto">
                            {t("utils:newVenue")}
                          </GenericButton>
                        </Link>
                      )}
                    </div>
                  )}

                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <div
                      className="divide-y divide-gray-200 overflow-y-auto"
                      style={{ maxHeight: 800 }}
                    >
                      {venues.length > 0 ? (
                        venues.map((venue, i) => {
                          return (
                            <VenueBlock
                              key={i}
                              venue={venue}
                              deleteVenue={(id, e) => deleteVenue(id, e)}
                            />
                          );
                        })
                      ) : (
                        <NoItem
                          name={t("notFound:venue")}
                          explanation={t("notFound:venueExp")}
                          onClick={() => history.push("/dashboard/new-venue")}
                          button={t("utils:newVenue")}
                          route={"/dashboard/new-venue"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Venue;
