import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  GenericButton,
  LastTickets,
  RightTicketModal,
} from "../../../components";
import instance from "../../../lib/client";
import { TicketForm, TicketTypeList } from "../../Tickets/components";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { amountConverter } from "../../../utils/stringUtils";
import { Link } from "react-router-dom";
import { isHaveAccess } from "../../../utils/roleUtils";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";

const totalAmount = (values) => {
  let totalValue = 0;
  values.forEach((value) => {
    totalValue += value.price;
  });
  return totalValue;
};

const TicketItem = ({ label, values }) => {
  const [active, setActive] = useState(false);

  const groupByTicketType = values.reduce((acc, curr) => {
    if (acc[curr.selected_ticket_type]) {
      acc[curr.selected_ticket_type].push(curr);
    } else {
      acc[curr.selected_ticket_type] = [curr];
    }
    return acc;
  }, {});

  const ticketList = Object.entries(groupByTicketType).map(([key, value]) => {
    return {
      label: key,
      values: value,
    };
  });

  return (
    <>
      <div
        onClick={() => setActive((a) => !a)}
        style={{
          paddingVertical: 20,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
        className="text-sm text-gray-800 flex flex-row items-center py-3 cursor-pointer"
      >
        <label>{label}</label>
        <label className="ml-auto">{values?.length}</label>
        <label className="ml-auto mr-4 font-semibold">
          {amountConverter(totalAmount(values))}
        </label>

        {active ? (
          <FiChevronUp className="mr-2" />
        ) : (
          <FiChevronDown className=" mr-2" />
        )}
      </div>

      {active &&
        ticketList.map((e) => {
          return <TicketItemByType label={e.label} values={e.values} />;
        })}
    </>
  );
};

const TicketItemByType = ({ label, values }) => {
  const [active, setActive] = useState(false);
  console.log(values);
  return (
    <>
      <div
        onClick={() => setActive((a) => !a)}
        style={{
          paddingVertical: 20,
          paddingLeft: 20,
          borderBottomColor: "#ccc",
          borderBottomWidth: 1,
        }}
        className="text-sm text-gray-800 flex flex-row items-center py-3 cursor-pointer"
      >
        <label className="w-28 truncate">{label}</label>
        <label className="ml-auto">{values?.length}</label>
        <label className="ml-auto mr-4 font-semibold">
          {amountConverter(totalAmount(values))}
        </label>

        {active ? (
          <FiChevronUp className="mr-2" />
        ) : (
          <FiChevronDown className=" mr-2" />
        )}
      </div>

      {active &&
        values.map((e) => {
          return (
            <div
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                borderBottomColor: "#ccc",
                borderBottomWidth: 1,
              }}
              className="flex flex-row text-sm py-2 pl-8"
            >
              <label className="w-1/3 pr-1 truncate">{e?.name}</label>
              <label className="w-1/3 ml-1 pr-1 truncate">{e?.last_name}</label>

              <label className="w-1/3 ml-4 text-xs font-bold">
                {amountConverter(e.price)}
              </label>
            </div>
          );
        })}
    </>
  );
};

const Tickets = ({ slug, id }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleTicketTypes();
    instance
      .get(`/sale/active-tickets/${String(id)}`)
      .then(({ data: { data } }) => {
        const newTickets = {};

        data.tickets.sort((a, b) =>
          moment(a.transactionDate).isAfter(b.transactionDate) ? 1 : -1
        );

        data.tickets
          .sort((a, b) => moment(b.transactionDate) - moment(a.transactionDate))
          .forEach((ticket) => {
            newTickets[moment(ticket["transactionDate"]).format("DD-MM-YYYY")] =
              newTickets[
                moment(ticket["transactionDate"]).format("DD-MM-YYYY")
              ] || [];

            newTickets[
              moment(ticket["transactionDate"]).format("DD-MM-YYYY")
            ]?.push(ticket);
          });

        setTickets(newTickets);
        setLoading(false);
      });
  }, []);

  const handleTicketTypes = () => {
    instance
      .get(`/event/${id}/tickets`)
      .then(({ data }) => {
        setTicketTypes(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setTicketList = (list) => {
    instance.post(`/event/sort-tickets/${id}`, list).then((res) => {
      setTicketTypes(res?.data?.data?.price_list);
    });
  };

  const reorderTicketByPriceAsc = (list) => {
    const result = Array.from(list);

    result.sort((a, b) => (a.value > b.value ? 1 : -1));
    setTicketList(result);
  };

  const reorderTicketByPriceDesc = (list) => {
    const result = Array.from(list);

    result.sort((a, b) => (a.value < b.value ? 1 : -1));
    setTicketList(result);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <label className="font-semibold">{t("events:ticketTypes")}</label>
        <div className="flex flex-row gap-1 items-center justify-center">
          <div className="flex flex-row justify-start items-center gap-1 mr-1 text-sm text-secondary-800">
            <button
              type="button"
              onClick={() => reorderTicketByPriceAsc(ticketTypes)}
              className="p-1 hover:bg-gray-200 rounded-lg "
            >
              <FaSortAmountDownAlt
                className="h-4 w-4 text-secondary-500"
                aria-hidden="true"
              />
            </button>
            <button
              type="button"
              onClick={() => reorderTicketByPriceDesc(ticketTypes)}
              className="p-1 hover:bg-gray-200 rounded-lg "
            >
              <FaSortAmountDown
                className="h-4 w-4 text-secondary-500"
                aria-hidden="true"
              />
            </button>
          </div>
          {isHaveAccess("eventTickets") && (
            <label
              onClick={() => setIsOpen(true)}
              className="bg-primary-500 rounded-md flex w-6 h-6 justify-center items-center text-white cursor-pointer"
            >
              +
            </label>
          )}
        </div>
      </div>

      <TicketTypeList
        eventId={id}
        setSelectedTicket={(data) => {
          if (isHaveAccess("eventTickets")) {
            setSelectedTicket(data);
            setIsOpen(true);
          }
        }}
        tickets={ticketTypes}
        setTickets={setTicketTypes}
      />

      {Object.entries(tickets).length > 0 && (
        <>
          <label className="font-semibold mt-2 mb-2">
            {t("ticket:dailyTicketReport")}
          </label>
          <div className="max-h-[30vh] overflow-y-auto">
            {Object.entries(tickets).map((report, i) => {
              return (
                <TicketItem key={i} label={report[0]} values={report[1]} />
              );
            })}
          </div>
        </>
      )}

      <TicketForm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        ticket={selectedTicket}
        handleData={() => handleTicketTypes()}
        eventId={id}
        setSelectedTicket={setSelectedTicket}
      />
      {!window.location.pathname.includes("tickets") && slug && (
        <Link to={`/dashboard/tickets/${id}?slug=${slug}`} className="mt-4">
          <GenericButton onClick={() => {}}>{t("utils:viewAll")}</GenericButton>
        </Link>
      )}
    </div>
  );
};

export default Tickets;
