import React from "react";

const ExcelLoading = ({ ...rest }) => {
  return (
    <div className="my-auto mt-2.5 w-[38px]" {...rest}>
      <div className="w-6 h-6 border-2 border-black border-dotted rounded-full animate-spin"></div>
    </div>
  );
};

export default ExcelLoading;
