import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineSearch } from "react-icons/hi";
import { Loading, SearchBlock } from "..";
import instance from "../../lib/client";

const Search = ({ isMobile }) => {
  const [events, setEvents] = useState([]);
  const [inputFocus, setInputFocus] = useState(false);
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    instance.get("event").then(({ data }) => {
      setEvents(data?.data);
    });
  }, []);

  const filterSearch = () => {
    var newEvents = [];

    if (search)
      events.forEach((event) => {
        if (event.name.toLowerCase().includes(search.toLowerCase()))
          newEvents.push(event);
      });

    return newEvents;
  };

  return (
    <div
      className={
        isMobile ? "min-w-0 w-full lg:hidden " : "max-w-md mx-auto w-[350px]"
      }
    >
      <label htmlFor="mobile-search" className="sr-only">
        {t("utils:search")}
      </label>
      <div className="relative text-white">
        <div
          className="absolute inset-y-0 left-0 pl-3 flex items-center"
          style={{ color: inputFocus ? "black" : "white" }}
        >
          <HiOutlineSearch className="h-5 w-5" aria-hidden="true" />
        </div>
        <input
          id="mobile-search"
          className="block w-full bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
          placeholder={t("utils:search")}
          style={{ color: inputFocus ? "black" : "white" }}
          onFocus={() => setInputFocus(true)}
          value={search}
          onBlur={() => {
            setTimeout(() => {
              setInputFocus(false);
            }, 200);
          }}
          onChange={(e) => setSearch(e.target.value)}
          type="search"
          name="search"
        />

        <div
          style={{
            backgroundColor: "white",
            zIndex: 10000000,
            position: "absolute",
            top: 50,
            width: "100%",
            maxHeight: 400,
            overflowY: "auto",
          }}
          className="shadow rounded-md"
        >
          {!events[0] && inputFocus ? (
            <Loading />
          ) : (
            inputFocus &&
            filterSearch().map((event, i) => (
              <SearchBlock
                key={i}
                event={event}
                onClick={() => setSearch("")}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
