import React, { useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Loading, PrivateRoute } from "./components";
import AuthContainer from "./pages/Auth/auth.navigation";
import Dashboard from "./pages/Dashboard/dashboard.navigation";
import { ToastContainer } from "react-toastify";
import useStore from "./hooks/useStores";
import authService from "./services/authService";
import BugeceDark from "./assets/images/BugeceDark.svg";
import jwt_decode from "jwt-decode";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import i18n from "./services/i18n";
import instance from "./lib/client";
import { Status } from "./pages/Status";

const App = observer(() => {
  const [loading, setLoading] = useState(true);
  const [loadingSecond, setLoadingSecond] = useState(true);
  const { t } = useTranslation();
  const { auth, modal } = useStore();
  const handleUserDetails = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await instance.get("/auth/me");
      auth.setRoles(data.user.roles);

      auth.setPromoter(data.promoter);
    } catch (error) {
      console.log("me", error);
    }
  }, [auth]);

  // React.useEffect(() => {
  //   i18n.changeLanguage(auth.language);
  // }, []);

  React.useEffect(() => {
    if (auth?.accessToken) authService.setAuthorizationToken(auth?.accessToken);

    if (auth?.accessToken) {
      handleUserDetails();
      const currentTime = Date.now() / 1000;
      if (jwt_decode(auth.accessToken).exp < currentTime) {
        auth.signOut();
        window.location.href = "/auth/login";
      }
    }

    setTimeout(() => {
      setLoading(false);
      setTimeout(() => {
        setLoadingSecond(false);
      }, 300);
    }, 800);
  }, [auth.accessToken, auth]);

  return (
    <LoadingOverlay active={modal.loading} spinner text={t("utils:loading")}>
      <Router>
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            transition: "all 0.5s ease",
            backgroundColor: "white",
            opacity: loading ? 1 : 0,
            display: loadingSecond ? "flex" : "none",
            zIndex: loading ? 100000 : -10000,
          }}
        >
          <img
            src={BugeceDark}
            style={{
              marginBottom: 100,
            }}
            alt={"loading_image"}
          />
          <Loading />
        </div>
        <ToastContainer
          position="bottom-right"
          hideProgressBar={true}
          theme={"dark"}
        />
        {!loading && (
          <Switch>
            <Route path="/auth">
              <AuthContainer />
            </Route>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            <Route path={"/status/refund-list/:id"}>
              <Status />
            </Route>
            <Redirect to={"/auth"} />
          </Switch>
        )}
      </Router>
    </LoadingOverlay>
  );
});

export default App;
