import React from "react";
import { Loading } from "..";

const LoadingModal = ({ text, loading, unVisible = false }) => {
  return (
    <div
      style={{
        display: loading ? "flex" : "none",
        position: "fixed",
        width: "100vw",
        height: "100vh",
        zIndex: 100,
        backgroundColor: "rgba(0,0,0,0.5)",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          width: 300,
          borderRadius: 10,
          height: 180,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
        }}
      >
        <Loading />
        {!unVisible && (
          <span className="block text-xl font-xl text-gray-700 sm:mt-px sm:pt-2">
            {text}...
          </span>
        )}
      </div>
    </div>
  );
};

export default LoadingModal;
