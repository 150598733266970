import React, { useEffect, useState, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { FormTextArea, GenericButton } from "../../../../../components";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import instance from "../../../../../lib/client";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import toastPush from "../../../../../utils/toastPush";

const MultipleGuest = ({ eventId, setIsOpen, handleData }) => {
  const [text, setText] = useState("");
  const { t } = useTranslation();

  const onSubmit = async (type) => {
    const { data } = await instance.post(`/guest/add-multiple/${eventId}`, {
      data: text,
      type: type,
    });

    if (data.data.success) {
      setIsOpen(false);
      handleData();
      toastPush(t("guests:created"));
    } else {
    }
  };

  return (
    <div className="mt-4 relative flex-1 px-4 sm:px-6">
      <div className="absolute inset-0 px-4 sm:px-6">
        <div
          style={{
            marginTop: 15,
            paddingHorizontal: 16,
            height: "95%",
          }}
        >
          <FormTextArea
            style={{ height: "250px" }}
            type="textarea"
            placeholder={`Paste guests here, example:
Anna smith
Erik Svensson +1
Peter Jones +4
      `}
            numberOfLines={5}
            value={text}
            onChange={(event) => setText(event.target.value)}
          />

          <GenericButton
            onClick={() => onSubmit("text")}
            class="w-full flex justify-center py-2 px-4 my-[25px] border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {t("guests:addTitle")}
          </GenericButton>
          <div className="text-xs text-gray-400 text-center">
            Çoklu Eklenen Davetiyelerde QR kod gitmemektedir. İsimler Davetli
            Listesine Alınacaktır
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleGuest;
