import React from "react";

const ProgressBar = ({ all, desired, allText, desiredText }) => {
  const calculateBarWidth = (all, desired) => {
    const value = `${Math.floor((desired / all) * 102) || 0}`;
    return value;
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: 7,
        }}
      >
        <label style={{ color: "rgb(82,153,54)" }}>
          {allText} {all}
        </label>
        <label className={"text-gray-500"}>
          {desiredText} {desired}
        </label>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            height: 5,
            borderTopLeftRadius: 15,
            borderBottomLeftRadius: 15,
            width: 100 - calculateBarWidth(all, desired) + "%",
            backgroundColor: "rgb(148,217,133)",
          }}
        />

        <div
          style={{
            height: 5,
            borderTopRightRadius: 15,
            borderBottomRightRadius: 15,
            width: calculateBarWidth(all, desired) + "%",
            backgroundColor: "rgb(181,248,167)",
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
