import React, { useEffect, useState, useRef } from "react";
import { CgDetailsMore } from "react-icons/cg";
import { ImAttachment } from "react-icons/im";
import classNames from "../../utils/classNames";
import { useTranslation } from "react-i18next";
import Attachments from "./components/Attachments";
import Details from "./components/Details";
import { useForm } from "react-hook-form";
import instance from "../../lib/client";
import { useHistory, useParams } from "react-router";
import toastPush from "../../utils/toastPush";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IoClipboardOutline } from "react-icons/io5";
import Social from "./components/Social";
import autoAnimate from "@formkit/auto-animate";

const ArtistSchema = yup.object().shape({
  name: yup.string().required(),
  translations: yup.array().of(
    yup.object().shape({
      desc: yup.string().required(),
      langcode: yup.string().optional(),
    })
  ),
  country: yup.object().required(),
  city: yup.object().required(),
  genres: yup.array().min(1),
  image: yup.string().required(),
});

function NewArtist() {
  const [selectedSection, setSelectedSection] = React.useState(0);
  const history = useHistory();
  const {
    control,
    register,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ArtistSchema),
    soundcloud: "",
    pending: true,
    defaultValues: {
      name: "",
      translations: [
        {
          desc: "",
          langcode: "tr",
        },
        {
          desc: "",
          langcode: "en",
        },
      ],
      country: "",
      city: "",
      genres: [],
      image: "",
      labelsList: [],
    },
  });
  const { t } = useTranslation();
  const parent = useRef(null);
  const facebookUrl = "https://www.facebook.com/";
  const facebookUrl2 = "https://facebook.com/";
  const instagramUrl = "https://www.instagram.com/";
  const instagramUrl2 = "https://instagram.com/";
  const twitterUrl = "https://www.twitter.com/";
  const twitterUrl2 = "https://twitter.com/";
  const youtubeUrl = "https://www.youtube.com/";
  const spotifyUrl = "https://open.spotify.com/";

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const onSubmit = (data) => {
    data.facebook = facebookUrl + data.facebook;
    data.twitter = twitterUrl + data.twitter;
    data.instagram = instagramUrl + data.instagram;
    data.youtube = youtubeUrl + data.youtube;
    data.spotify = spotifyUrl + data.spotify;
    data.image = data.image;
    data.category_genres = data.genres;

    if (!window.location.pathname.includes("update-artist")) {
      instance
        .post("/artist/", data)
        .then((response) => {
          toastPush(t("artistForm:created"));
          history.push("/dashboard/main");
        })
        .catch((err) => {
          toastPush(t("utils:error"), "error");
        });
    } else {
      instance
        .put("/artist/", data)
        .then((response) => {
          toastPush(t("artistForm:updated"));
          history.push("/dashboard/main");
        })
        .catch((err) => {
          toastPush(t("utils:error"), "error");
        });
    }
  };
  const [countries, setCountries] = useState([]);
  const [genres, setGenres] = useState([]);
  const [cities, setCities] = useState([]);
  const [labelList, setLabelList] = useState([{ label: "", labelLink: "" }]);
  const { id } = useParams();

  useEffect(async () => {
    if (window.location.pathname.includes("update-artist")) {
      const { data } = await instance.get(`/artist/detail/${id}`);
      for (const [key, value] of Object.entries(data.data)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setValue(
        "facebook",
        data?.data?.facebook.replace(facebookUrl, "").replace(facebookUrl2, "")
      );
      setValue(
        "twitter",
        data?.data?.twitter.replace(twitterUrl, "").replace(twitterUrl2, "")
      );
      setValue("youtube", data.data.youtube.replace(youtubeUrl, ""));
      setValue(
        "instagram",
        data?.data?.instagram
          .replace(instagramUrl, "")
          .replace(instagramUrl2, "")
      );
      setValue("spotify", data?.data?.spotify.replace(spotifyUrl, ""));
      setLabelList(
        data.data?.labels.length > 0
          ? data.data?.labels
          : [
              {
                label: data?.data?.label ? data?.data?.label : "",
                labelLink: data?.data?.labelLink ? data?.data?.labelLink : "",
              },
            ]
      );
      getDatas(true);
    } else {
      getDatas(false);
    }
  }, [id]);

  const getDatas = (isUpdate) => {
    Promise.all([
      instance.get("category/genre"),
      instance.get("venue/country"),
    ]).then(async ([genre, country]) => {
      setGenres(
        genre.data.data.map((g) => {
          if (getValues("genres").includes(g.label)) {
            setValue("genres", [
              ...getValues("genres").filter((f) => f !== g.label),
              g,
            ]);
          }
          return g;
        })
      );

      setCountries(
        country.data.data.map((c) => {
          if (isUpdate && getValues("country")._id === c._id) {
            setValue("country", {
              label: c.country,
              value: parseInt(c.country_id),
            });
          }
          return { label: c.country, value: parseInt(c.country_id) };
        })
      );
      if (getValues("country")?.country_id || getValues("country")?.value) {
        const { data } = await instance.get(
          `venue/country/${getValues("country")?.value}`
        );
        setCities(
          data.data.map((city) => {
            if (
              getValues("city") &&
              getValues("city").city_id === city.city_id
            ) {
              setValue("city", {
                label: city.name,
                value: city.city_id,
              });
            }

            return {
              label: city.name,
              value: city.city_id,
            };
          })
        );
      }
    });
  };

  const checkIsValid = async () => {
    let isValid = true;
    switch (selectedSection) {
      case 0:
        isValid = await trigger([
          "name",
          "translations",
          "country",
          "city",
          "genres",
        ]);
        break;
      case 1:
        isValid = true;
        break;
      case 2:
        isValid = await trigger(["image"]);
        break;

      default:
        isValid = true;
    }
    return isValid;
  };

  const navigation = [
    { name: t("venueForm:basics"), href: "#", icon: IoClipboardOutline },
    { name: t("venueForm:socialMedia"), href: "#", icon: CgDetailsMore },
    { name: t("artistForm:attachments"), href: "#", icon: ImAttachment },
  ];

  const returnCurrentSection = () => {
    switch (selectedSection) {
      case 0:
        return (
          <Details
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            cities={cities}
            setCities={setCities}
            countries={countries}
            genres={genres}
            errors={errors}
            checkIsValid={checkIsValid}
            labelList={labelList}
          />
        );
      case 1:
        return (
          <Social
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );
      case 2:
        return (
          <Attachments
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            errors={errors}
            checkIsValid={checkIsValid}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="sr-only">Page title</h1>
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                <aside className="lg:py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                  <nav className="space-y-1">
                    {navigation.map((item, i) => (
                      <span
                        key={i}
                        onClick={async () =>
                          selectedSection < i
                            ? (await checkIsValid()) && setSelectedSection(i)
                            : setSelectedSection(i)
                        }
                        className={classNames(
                          selectedSection === i
                            ? "bg-gray-800 text-white hover:text-white hover:bg-gray-900"
                            : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                          "group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer "
                        )}
                        aria-current={
                          selectedSection === i ? "page" : undefined
                        }
                      >
                        <item.icon
                          className={classNames(
                            selectedSection === i
                              ? "text-white group-hover:text-white"
                              : "text-gray-400 group-hover:text-gray-900",
                            "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        <span className="truncate ml-2">{item.name}</span>
                      </span>
                    ))}
                  </nav>
                </aside>
              </div>
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <form ref={parent} onSubmit={handleSubmit(onSubmit)}>
            {returnCurrentSection()}
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewArtist;
