import React from "react";
import { FiAlertTriangle } from "react-icons/fi";
import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import colors from "./colors.json";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useStore from "../../../hooks/useStores";

const AlreadyScanned = ({ dimension, t, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        width: dimension,
        height: dimension,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors["warning-dark"],
      }}
    >
      <FiAlertTriangle
        name="alert"
        size={dimension * 0.36}
        color={colors["warning-darker"]}
      />
      <span
        size={"lg"}
        bold
        className="font-bold"
        style={{
          marginTop: 20,
          color: colors["warning-darker"],
        }}
      >
        {t("scanner:ticketAlreadyScanned")}
      </span>
    </div>
  );
};

const SuccessScan = ({ dimension, t, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        width: dimension,
        height: dimension,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        borderRadius: 8,
        flexDirection: "column",
        display: "flex",
        backgroundColor: colors["success"],
      }}
    >
      <AiOutlineCheckCircle
        name="checkbox-marked-circle"
        size={dimension * 0.36}
        color={colors["success-dark"]}
      />
      <span
        size={"lg"}
        className="font-bold"
        bold
        style={{
          marginTop: 20,
          colors: colors["success-dark"],
        }}
      >
        {t("scanner:successScan")}
      </span>
    </div>
  );
};

const InvalidScanned = ({ dimension, t, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        width: dimension,
        height: dimension,
        alignItems: "center",
        borderRadius: 8,
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer",
        display: "flex",
        backgroundColor: colors.invalid,
      }}
    >
      <AiOutlineCloseCircle
        name="close-circle"
        size={dimension * 0.36}
        color={colors["invalid-darker"]}
      />
      <span
        size={"lg"}
        bold
        className="font-bold"
        style={{
          marginTop: 20,
          color: colors["invalid-darker"],
        }}
      >
        {t("scanner:invalidScanned")}
      </span>
    </div>
  );
};

const ScannerResult = ({ type, ticket, onClick }) => {
  const { modal } = useStore();
  const { t } = useTranslation();

  return (
    <div className="absolute flex flex-col items-center">
      {type === "ALREADY_SCANNED" && (
        <AlreadyScanned dimension={200} t={t} onClick={onClick} />
      )}
      {type === "SUCCESS_TICKET" && (
        <SuccessScan dimension={200} t={t} onClick={onClick} />
      )}
      {type === "INVALID_TICKET" ||
        (type === "INVALID" && (
          <InvalidScanned dimension={200} t={t} onClick={onClick} />
        ))}

      {(type === "ALREADY_SCANNED" || type === "SUCCESS_TICKET") && ticket && (
        <div
          onClick={() => {
            ticket.id = ticket.ticketId;
            modal.triggerTicketModal({
              ticket: ticket,
              event: { _id: ticket.event_id },
            });
          }}
          className="bg-white"
          style={{
            backgroundColor: "white",
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            marginBottom: 50,
          }}
        >
          <div
            style={{
              height: 60,
              width: 350,
              backgroundColor: "white",
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
              bottom: 0,
            }}
          >
            <div
              style={{
                width: 50,
                height: 5,
                backgroundColor: "rgba(0,0,0,1)",
                borderRadius: 20,
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: 10,
              }}
            />

            <div
              style={{ paddingHorizontal: 16, backgroundColor: "white" }}
              contentContainerStyle={{ paddingBottom: 0 }}
            >
              <div
                style={{
                  marginBottom: 10,
                  backgroundColor: "white",
                  marginTop: 10,
                }}
              >
                <span
                  style={{ fontSize: 19, fontWeight: "600", color: "black" }}
                >
                  {t("ticket:customerInfo")}
                </span>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    height: 50,
                    width: 50,
                    backgroundColor: colors.primary,
                    borderRadius: 99,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    marginRight: 15,
                  }}
                >
                  <span
                    style={{
                      color: "black",

                      fontSize: 18,
                      fontWeight: "600",
                    }}
                  >
                    {ticket?.name?.charAt(0)?.toUpperCase() +
                      ticket?.last_name?.charAt(0)?.toUpperCase()}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span style={{ fontWeight: "700", fontSize: 15 }}>
                    {ticket?.name?.toUpperCase() +
                      " " +
                      ticket?.last_name?.toUpperCase()}
                  </span>
                  <span style={{ marginTop: 5 }}>
                    {ticket.selected_ticket_type}
                  </span>
                </div>
              </div>
              <div
                style={{
                  height: 2,
                  width: "100%",
                }}
                className="bg-gray-200 mt-2"
              />
              <div style={{ backgroundColor: "white" }}>
                <span
                  style={{
                    color: "rgba(0,0,0,0.4)",
                    marginRight: 20,
                  }}
                >
                  {ticket.scanned
                    ? t("ticket:scannedAt")
                    : t("ticket:purchaseDate")}
                </span>
                <span style={{ fontWeight: "bold", letterSpacing: 1 }}>
                  {ticket.scanned
                    ? moment(ticket?.scanned_at).format("DD/MM/YYYY - HH:mm")
                    : moment(ticket?.transactionDate).format(
                        "DD/MM/YYYY - HH:mm"
                      )}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScannerResult;
