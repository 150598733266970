import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../services/i18n";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  EnvelopeIcon,
} from "@heroicons/react/20/solid";
import instance from "../../../../lib/client";
import { FiEdit2 } from "react-icons/fi";
import CrewForm from "./components/CrewForm";
import { Loading } from "../../../../components";
import * as PropTypes from "prop-types";
import InviteMemberModal from "./components/InviteMemberModal";

const PermItem = ({ member, permissions, t }) => {
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div
      className={`px-4 pb-4 transition-all ${
        seeMore ? "max-h-auto" : "max-h-[30px]"
      } overflow-hidden max-w-[100%]`}
    >
      {member?.details?.roles?.slice(0, seeMore ? 10000 : 5)?.map((role, i) => {
        return (
          <p
            key={i}
            className="px-2 mx-0.5 my-0.5 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
          >
            {
              permissions.find((perm) => perm.role === role)?.name[
                i18n.language
              ]
            }
          </p>
        );
      })}
      {member.details.roles?.length > 5 && (
        <label
          className="text-xs right-8 pl-4 text-gray-400 cursor-pointer"
          onClick={() => setSeeMore(!seeMore)}
        >
          {seeMore ? t("utils:viewLess") : t("utils:viewMore")}
        </label>
      )}
    </div>
  );
};

function Crew() {
  const [crew, setCrew] = useState([]);
  const [tempCrew, setTempCrew] = useState([]);
  const [loading, setLoading] = useState(true);
  const [permissions, setPermissions] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const { t } = useTranslation();

  const handleCrewList = () => {
    setLoading(true);
    Promise.all([instance.get("/crew"), instance.get("crew/permissions")]).then(
      ([crew, permissions]) => {
        const newCrewData = crew?.data?.data?.map((item) => {
          item.user.name = item.user.full_name
            ? item.user.full_name
            : item.user.name;
          return item;
        });

        setCrew(newCrewData);
        setTempCrew(newCrewData);
        setPermissions(permissions.data.data);
        setLoading(false);
      }
    );
  };

  const getPermissionArray = () => {
    const permArray = [];

    permissions.forEach((perm) => permArray.push(perm.role));

    return permArray;
  };

  useEffect(() => {
    handleCrewList();
  }, []);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <h1 className="sr-only">Page title</h1>
      {/* Main 1 column grid */}
      <div className="grid grid-cols-1 rounded-lg bg-white items-start lg:grid-cols-1">
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {t("crew:tabTitle")}
              </h3>
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              <button
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:!ring-gray-800"
                onClick={() => {
                  setSelectedMember(null);
                  setIsInviteOpen(true);
                }}
              >
                {t("crew:addNewMember")}
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="py-10">
            <Loading />
          </div>
        ) : (
          <ul role="list" className="divide-y divide-gray-200">
            {tempCrew?.length > 0 &&
              crew.map((member, i) => (
                <li key={member.user._id}>
                  <div
                    className="block hover:bg-gray-50"
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedMember({
                        id: member.user._id,
                        name: member.user.full_name,
                        email: "",
                        phone: "",
                        permission: member.details.roles.find(
                          (role) => role === "all:access"
                        )
                          ? getPermissionArray()
                          : member.details.roles,
                      });
                      setIsOpen(true);
                    }}
                  >
                    <div className="flex items-center px-4 py-4 sm:px-6">
                      <div className="flex min-w-0 flex-1 items-center">
                        <div className="flex-shrink-0">
                          {member.user?.photo?.length > 0 ? (
                            <img
                              className="inline-block h-12 w-12 rounded-full"
                              src={member.user?.photo}
                              alt=""
                            />
                          ) : (
                            <span className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-red-500">
                              <span className="text-xl font-medium leading-none text-white">
                                {`${member.user?.name
                                  ?.slice(0, 1)
                                  ?.toLocaleUpperCase(
                                    "tr"
                                  )}${member.user?.last_name
                                  ?.slice(0, 1)
                                  ?.toLocaleUpperCase("tr")}`}
                              </span>
                            </span>
                          )}
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="truncate text-sm font-medium text-indigo-600">
                              {member?.user?.name}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <EnvelopeIcon
                                className={`mr-1.5 h-5 w-5 flex-shrink-0 ${
                                  member.user.emails[0].verified
                                    ? "text-green-500"
                                    : "text-gray-500"
                                }`}
                                aria-hidden="true"
                              />
                              <span className="truncate">
                                {member?.user?.emails[0]?.address}
                              </span>
                            </p>
                          </div>
                          {/*<div className="hidden">*/}
                          {/*  <div>*/}
                          {/*    <p className="text-sm text-gray-900">*/}
                          {/*      Applied on <time dateTime={application.date}>{application.dateFull}</time>*/}
                          {/*    </p>*/}
                          {/*    <p className="mt-2 flex items-center text-sm text-gray-500">*/}
                          {/*      <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />*/}
                          {/*      {application.stage}*/}
                          {/*    </p>*/}
                          {/*  </div>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedMember({
                            id: member.user._id,
                            name: member.user.full_name,
                            email: "",
                            phone: "",
                            permission: member.details.roles.find(
                              (role) => role === "all:access"
                            )
                              ? getPermissionArray()
                              : member.details.roles,
                          });
                          setIsOpen(true);
                        }}
                      >
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </div>
                  <PermItem member={member} permissions={permissions} t={t} />
                </li>
              ))}
          </ul>
        )}
      </div>
      <InviteMemberModal isOpen={isInviteOpen} setIsOpen={setIsInviteOpen} />
      <CrewForm
        permissions={permissions}
        member={selectedMember}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleData={handleCrewList}
      />
    </div>
  );
}

export default Crew;
