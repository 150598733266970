import React, { useState } from "react";
import instance from "../../../../../lib/client";
import { useTranslation } from "react-i18next";
import { Loading, NotFoundMobile } from "../../../../../components/index";
import EventItem from "./EventItem";
import { Link } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedState, setSelectedState] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    instance.get("event").then(({ data }) => {
      setEvents(data.data);
      setLoading(false);
    });
  }, []);

  const eventDateCompare = () => {
    var activeEvents = [];
    var pastEvents = [];

    events.forEach((event) => {
      if (moment(event.start_time) > moment()) activeEvents.push(event);
      else pastEvents.push(event);
    });

    if (selectedState)
      return activeEvents.sort(
        (a, b) => moment(a.start_time) - moment(b.start_time)
      );
    else return pastEvents;
  };

  const FilterButton = ({ label, index }) => {
    return (
      <button
        onClick={() => setSelectedState(index)}
        className={`text-sm p-1 px-5 rounded md:w-20 flex items-center justify-center xs:w-1/2 rounded-12 hover:bg-primary-500 hover:text-white hover:font-bold transition-all ${
          selectedState === index
            ? "bg-primary-500 text-white font-bold"
            : "bg-gray-200 text-black"
        }`}
      >
        {label}
      </button>
    );
  };

  if (loading) return <Loading />;
  return (
    <div className="h-[520px] flex flex-col">
      <div className="flex flex-row justify-between">
        <label
          className={"font-bold ml-2"}
          style={{
            color: "rgb(70,70,70)",
          }}
        >
          {t("events:tabTitle")}
        </label>
        <div className="flex flex-row gap-4">
          <FilterButton label="Aktif" index={true} />
          <FilterButton label="Geçmiş" index={false} />
        </div>
      </div>
      <div className="flow-root mt-6">
        <div className="-my-5 divide-y divide-gray-200">
          {eventDateCompare().length > 0 ? (
            eventDateCompare()
              ?.slice(0, 5)
              .map((event, i) => {
                return <EventItem key={i} event={event} t={t} />;
              })
          ) : (
            <div className="w-full h-[200px] flex items-center justify-center">
              <div>
                <NotFoundMobile
                  height={200}
                  buttonText={t("utils:newEvent")}
                  text={"Cannot found any event!"}
                  textSize="md"
                  onClick={() => {
                    history.push("/dashboard/new-event");
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-auto">
        <Link
          to={"/dashboard/events"}
          className="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
        >
          {t("utils:viewAll")}
        </Link>
      </div>
    </div>
  );
};

export default Events;
