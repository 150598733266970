import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { FormInput, FormTextArea } from "..";
import Select from "react-select";
import instance from "../../lib/client";
import { toast } from "react-toastify";

const defaultValues = {
  title: "",
  translations: [
    {
      rules: "",
      langcode: "",
    },
  ],
};

const RightRuleModal = ({ isOpen, setIsOpen, rule }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(defaultValues);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-left",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-left",
    });
  };

  useEffect(() => {
    if (rule) {
      setItems(rule);
    } else {
      setItems(defaultValues);
    }
  }, [rule]);

  const langOpt = [
    { value: "en", label: "English" },
    { value: "tr", label: "Türkçe" },
  ];

  const addNewRule = async () => {
    instance
      .post("event/rules", items)
      .then((res) => setIsOpen(false), notifySuccess("Rule added successfully"))
      .catch((err) => notifyError("Rule could not be added"));
  };

  const updateRule = async () => {
    const payload = {
      id: items._id,
      title: items.title,
      translations: items.translations,
    };
    instance
      .put("event/rules", payload)
      .then((res) => {
        setIsOpen(false);
        notifySuccess("Rule updated successfully");
      })
      .catch((err) => notifyError("Rule could not be updated"));
  };

  const deleteRule = async () => {
    instance
      .delete(`event/rules/${items._id}`)
      .then((res) => {
        setIsOpen(false);
        notifySuccess("Rule deleted successfully");
      })
      .catch((err) => notifyError("Rule could not be deleted"));
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <div className="absolute inset-0 overflow-hidden ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-[350px]">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-primary-500 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {rule ? "Update Rule" : "Add New Rule"}
                        </Dialog.Title>

                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-white hover:text-white "
                            onClick={() => {
                              setIsOpen(false);
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <HiOutlineX
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="px-6 py-6">
                      <div className="flex flex-col gap-4 justify-center items-center">
                        <div className="w-full">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Rule Title
                          </label>
                          <div className="mt-1">
                            <FormInput
                              type="text"
                              name="title"
                              id="title"
                              value={items.title}
                              onChange={(e) =>
                                setItems({
                                  ...items,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Language
                          </label>
                          <Select
                            className="text-sm"
                            options={langOpt}
                            placeholder={"Select Language"}
                            value={langOpt.find(
                              (lang) =>
                                items.translations[0].langcode == lang.value
                            )}
                            onChange={(e) =>
                              setItems({
                                ...items,
                                translations: [
                                  {
                                    ...items.translations[0],
                                    langcode: e.value,
                                  },
                                ],
                              })
                            }
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="project-name"
                            className="block text-sm font-medium text-gray-900"
                          >
                            Rule Text
                          </label>
                          <div className="mt-1">
                            <FormTextArea
                              rows={10}
                              type="text"
                              name="name"
                              id="name"
                              value={items.translations[0].rules}
                              onChange={(e) =>
                                setItems({
                                  ...items,
                                  translations: [
                                    {
                                      ...items.translations[0],
                                      rules: e.target.value,
                                    },
                                  ],
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    {rule && (
                      <button
                        type="button"
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={() => {
                          deleteRule();
                        }}
                      >
                        {t("utils:delete")}
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={() => {
                        rule ? updateRule() : addNewRule();
                      }}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      {rule ? "Update" : t("utils:save")}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RightRuleModal;
