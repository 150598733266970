/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton } from "../../../components";
import toastPush from "../../../utils/toastPush";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = [
  { name: "TR", value: "tr" },
  { name: "EN", value: "en" },
];

function PromoterInfoModal({ open, setOpen }) {
  const { auth } = useStore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [promoter, setPromoter] = useState({});
  const imageRef = useRef();
  const [currentTab, setCurrentTab] = useState("en");

  const handlePromoter = async () => {
    setLoading(true);
    const { data } = await instance.get(
      `/promoter/detail/${auth.promoter._id}`
    );

    console.log(data.data);
    setPromoter(data.data);

    setLoading(false);
  };

  useEffect(() => {
    handlePromoter();
  }, [open, setOpen]);

  const updatePromoter = async () => {
    const { data } = await instance.put(
      `/promoter/detail/${auth.promoter._id}`,
      promoter
    );

    toastPush(t("utils:promoterUpdated"));

    handlePromoter();
    setOpen(false);
  };

  const setExplanation = (lang, value) => {
    const translation = promoter.translations.map((transl) => {
      if (lang === transl.langcode) {
        return {
          ...transl,
          desc: value,
        };
      } else return transl;
    });
    setPromoter({ ...promoter, translations: translation });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full  sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold">
                {t("settings:promoterInfo")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-6 gap-y-4">
                <div className="flex flex-row gap-4">
                  <label className="flex w-3/12 cursor-pointer">
                    <input
                      ref={imageRef}
                      type="file"
                      name="image"
                      className="hidden"
                      id="file-upload"
                      onChange={(a) => {
                        console.log(a);
                      }}
                    />
                    <div className="flex items-center justify-center min-h-14 min-w-14 h-14 w-14 z-10 rounded-full absolute bg-[rgba(89,95,108,0)] opacity-0 hover:opacity-100 transition-all hover:bg-[rgba(89,95,108,0.5)] cursor-pointer">
                      <label className="text-white cursor-pointer font-semibold text-xs">
                        Change
                      </label>
                    </div>
                    <img
                      htmlFor="file-upload"
                      className="flex min-h-14 min-w-14 h-14 w-14 rounded-full relative"
                      src={promoter?.image}
                      alt=""
                    />
                  </label>

                  <FormInput
                    placeholder={t("promoter:name")}
                    value={promoter.name}
                    onChange={(v) =>
                      setPromoter({ ...promoter, name: v.target.value })
                    }
                  />
                </div>

                <textarea
                  rows={4}
                  name="comment"
                  placeholder={t("utils:desc") + " TR"}
                  id="comment"
                  className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={
                    promoter?.translations?.find((a) => a.langcode === "tr")
                      ?.desc
                  }
                  onChange={(v) => setExplanation("tr", v.target.value)}
                />
                <textarea
                  rows={4}
                  name="comment"
                  id="comment"
                  placeholder={t("utils:desc") + " EN"}
                  className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  value={
                    promoter?.translations?.find((a) => a.langcode === "en")
                      ?.desc
                  }
                  onChange={(v) => setExplanation("en", v.target.value)}
                />
                <FormInput
                  placeholder={"Soundcloud"}
                  value={promoter?.soundcloud}
                  onChange={(v) =>
                    setPromoter({ ...promoter, soundcloud: v.target.value })
                  }
                />
                <FormInput
                  placeholder={t("promoter:socialMedia")}
                  value={promoter?.social_media}
                  onChange={(v) =>
                    setPromoter({ ...promoter, social_media: v.target.value })
                  }
                />
                <FormInput
                  placeholder={t("promoter:website")}
                  value={promoter?.website}
                  onChange={(v) =>
                    setPromoter({ ...promoter, website: v.target.value })
                  }
                />

                <GenericButton
                  background="primary"
                  onClick={() => updatePromoter()}
                  style={{ paddingVertical: 20, marginTop: 20 }}
                  textBold
                >
                  {t("utils:update")}
                </GenericButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PromoterInfoModal;
