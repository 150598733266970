import React from "react";
import { HiOutlineArrowDown, HiOutlineArrowUp } from "react-icons/hi";
import classNames from "../../utils/classNames";

function Stats({ item, t, trigger }) {
  return (
    <div
      key={item.id}
      className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden"
    >
      <dt>
        <div className="absolute bg-primary-500 rounded-md p-3">
          <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <p className="ml-16 text-sm font-medium text-gray-500 truncate">
          {item.name}
        </p>
      </dt>
      <dd className={`ml-16 flex items-baseline ${trigger && "sm:pb-7 pb-6"}`}>
        <p className="text-lg font-semibold text-gray-900">{item.stat}</p>
        {trigger && (
          <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <span
                onClick={() => trigger()}
                className="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer"
              >
                {t("utils:viewAll")}
                <span className="sr-only"> {item.name} stats</span>
              </span>
            </div>
          </div>
        )}
      </dd>
    </div>
  );
}

export default Stats;
