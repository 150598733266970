/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useState, useCallback } from "react";
import { Dialog, Transition } from "@headlessui/react";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import numberToMoney from "../../../utils/numberToMoney";
import { IoMdDownload } from "react-icons/io";
import downloadExcelFile from "../../../utils/downloadExcelFile";
import { ExcelLoading } from "../../../components";

const RefundDetailModal = observer(() => {
  const { modal } = useStore();
  const [totalRefund, setTotalRefund] = useState(null);
  const [totalTicketCount, setTotalTicketCount] = useState(null);
  const [reportList, setReportList] = useState([]);
  const [reports, setReports] = useState(null);
  const [loading, setLoading] = useState(true);
  const { auth } = useStore();
  const [isDownloading, setIsDownloading] = useState(false);

  const handleTicketReport = () => {
    setLoading(true);

    if (modal?.currentEvent)
      instance
        .get(`/report/refund-tickets/${modal.currentEvent._id}`)
        .then(({ data: { data, ...rest } }) => {
          const newReportList = {};
          setTotalRefund(data.counts.totalAmount);
          setTotalTicketCount(data.counts.all);
          data.tickets.forEach((e) => {
            newReportList[e.selected_ticket_type] =
              newReportList[e.selected_ticket_type] || [];

            newReportList[e.selected_ticket_type].push(e);
          });

          setReportList(newReportList);
          setReports(data);
          setLoading(false);
        });
  };

  const { t } = useTranslation();

  const collider = {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.3)",
    opacity: 0.4,
    marginVertical: 10,
    marginBottom: 10,
  };

  const lightText = {
    color: "rgba(0,0,0,0.5)",
    fontSize: 15,
  };

  const boldText = {
    fontSize: 17,
    fontWeight: "700",
    marginTop: 7,
  };

  useEffect(() => {
    if (modal.currentEvent) {
      handleTicketReport();
    }
  }, [modal.currentEvent]);

  const onClose = () => {
    modal.triggerRefundModal();
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    await downloadExcelFile(
      `/report/excel/refund?eventId=${modal.currentEvent._id}&lang=${auth.language}`,
      reports.event.name + " " + t("reports:refundReport"),
      auth.accessToken,
      setIsDownloading
    );
  };

  const RefundItem = ({ label, values }) => {
    const [active, setActive] = useState(false);
    return (
      <>
        <div
          onClick={() => setActive((a) => !a)}
          style={{
            paddingVertical: 20,
            borderBottomColor: "#ccc",
            borderBottomWidth: 1,
          }}
          className="text-sm text-gray-800 flex flex-row items-center py-3 cursor-pointer"
        >
          <label className={"min-w-[140px]"}>{label}</label>
          <label className="ml-auto min-w-[30px]">{values?.length}</label>
          <label className="ml-auto mr-4 font-semibold min-w-[50px]">
            {numberToMoney(
              values.map((e) => e.price).reduce((a, b) => a + b, 0)
            ) + "₺"}
          </label>

          {active ? (
            <FiChevronUp className="mr-2" />
          ) : (
            <FiChevronDown className=" mr-2" />
          )}
        </div>

        {active &&
          values.map((e) => {
            return (
              <div
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomColor: "#ccc",
                  borderBottomWidth: 1,
                }}
                className="flex flex-row text-sm py-2 pl-8"
              >
                <label>{e.name + " " + e.last_name}</label>
                <label className="ml-4 text-xs font-bold">
                  {numberToMoney(e.price) + "₺"}
                </label>
                <label className="flex-1" />
                <label className="flex-1" />
                <label className="flex-1" />
              </div>
            );
          })}
      </>
    );
  };

  return (
    <Transition.Root show={modal.refund} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block text-left align-bottom bg-white rounded-lg px-4 pt-5 pb-4 label-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="flex flex-row justify-between items-center pt-4">
                <div className="font-semibold text-lg">
                  {t("reports:refundReport")}
                </div>
                <div>
                  {isDownloading ? (
                    <ExcelLoading />
                  ) : (
                    <IoMdDownload
                      size={30}
                      style={{ marginTop: 5 }}
                      color={"#1B1A17"}
                      onClick={exportExcelFile}
                    />
                  )}
                </div>
              </div>
              <div className={"flex flex-col pt-[50px]"}>
                <div className="flex flex-row justify-between mb-2">
                  <label className="text-md font-medium text-gray-500 truncate">
                    {t("refund:refundPrice")}
                  </label>
                  <label className="text-md font-medium text-gray-500 truncate">
                    {numberToMoney(totalRefund || 0) + "₺"}
                  </label>
                </div>
                <div class="relative mt-2">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
                <div className="flex flex-row justify-between mb-2 mt-2">
                  <label className="text-md font-medium text-gray-500 truncate">
                    {t("refund:refundCount")}
                  </label>
                  <label className="text-md font-medium text-gray-500 truncate">
                    {totalTicketCount || 0}
                  </label>
                </div>
              </div>
              {Object.entries(reportList).length > 0 && (
                <>
                  <div className="flex flex-row justify-between mb-2 mt-[20px]">
                    <label className="text-md font-medium text-black truncate">
                      {t("refund:refundedTickets")}
                    </label>
                  </div>
                  <div>
                    {Object.entries(reportList).map((report) => {
                      return (
                        <RefundItem label={report[0]} values={report[1]} />
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default RefundDetailModal;
