/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../../../lib/client";
import useStore from "../../../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton } from "../../../../../components";
import autoAnimate from "@formkit/auto-animate";
import toastPush from "../../../../../utils/toastPush";
import { toast } from "react-toastify";

const InviteMemberModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [mail, setMail] = useState("");
  const [permmisions, setPermmissions] = useState([]);
  const [isSelectMail, setIsSelectMail] = useState(false);
  const [selectedPerm, setSelectedPerm] = useState();
  const parent = useRef(null);
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-left",
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-left",
    });

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const getPermmisions = async () => {
    try {
      const res = await instance.get("crew/permission-groups");
      setPermmissions(res?.data?.data);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setMail("");
    setSelectedPerm(null);
    setIsSelectMail(false);
  }, [isOpen]);

  const handleSubmit = async () => {
    console.log(mail, selectedPerm);
    setLoading(true);
    instance
      .post("crew/invite", {
        email: mail,
        group: selectedPerm,
      })
      .then((res) => {
        console.log(res);
        if (res) {
          notifySuccess(`İstek ${mail} adresine gönderildi`);
          setTimeout(() => {
            setIsOpen(false);
            setLoading(false);
          }, 300);
        } else {
          setLoading(false);
          notifyError("Bir hata oluştu");
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        notifyError("Bir hata oluştu");
      });
  };

  useEffect(() => {
    getPermmisions();
  }, []);

  useEffect(() => {
    console.log(permmisions);
    console.log(selectedPerm);
  }, [permmisions, selectedPerm]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold">
                Add Crew Member
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-6">
                <div className="relative flex items-baseline">
                  <FormInput
                    placeholder={t("utils:email")}
                    value={mail}
                    disabled={isSelectMail}
                    onChange={(v) => setMail(v.target.value)}
                    style={{ marginBottom: 10 }}
                    className={"focus:!ring-gray-800 focus:!border-gray-800"}
                  />
                  {isSelectMail ? (
                    <GenericButton
                      className={
                        "absolute right-0 top-1 border-l-none rounded-l-none !bg-red-600 !border !border-red-400 focus:ring-red-400"
                      }
                      background="primary"
                      onClick={() => {
                        setIsSelectMail(false);
                        setSelectedPerm(null);
                      }}
                      style={{ paddingVertical: 5, width: 96 }}
                      textBold
                    >
                      Cancel
                    </GenericButton>
                  ) : (
                    <GenericButton
                      className={
                        "absolute right-0 top-1 border-l-none rounded-l-none !border !bg-gray-800 !border-gray-800 focus:!ring-gray-800 "
                      }
                      background="primary"
                      onClick={() => mail && setIsSelectMail(true)}
                      style={{ paddingVertical: 5, width: 96 }}
                      textBold
                    >
                      Select
                    </GenericButton>
                  )}
                </div>
                <fieldset ref={parent}>
                  {isSelectMail && (
                    <>
                      <div className="space-y-2 mt-4">
                        {permmisions?.map((p) => (
                          <div
                            key={p.key}
                            onClick={() => setSelectedPerm(p.key)}
                            className="relative flex items-center border border-gray-300 hover:border-primary-500 hover:scale-[101%] rounded-md w-full px-4 py-2 hover:shadow-md cursor-pointer transition ease-in-out delay-50 "
                          >
                            <div className="flex h-6 items-center">
                              <input
                                id={p.key}
                                aria-describedby={`${p.key}-description`}
                                name="plan"
                                type="radio"
                                value={p.key}
                                checked={p.key === selectedPerm}
                                className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-600"
                              />
                            </div>
                            <div className="ml-3 text-sm leading-6">
                              <label
                                htmlFor={p.key}
                                className="font-medium text-gray-900"
                              >
                                {p?.name?.tr}
                              </label>
                              <p
                                id={`${p.key}-description`}
                                className="text-gray-500"
                              >
                                {p.description?.tr}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                      <GenericButton
                        className={
                          " !border !bg-transparent !border-gray-800 focus:!ring-gray-800 flex justify-center items-center !text-gray-800 "
                        }
                        background="primary"
                        onClick={() => handleSubmit()}
                        style={{ paddingVertical: 5, marginTop: 16 }}
                        textBold
                      >
                        {loading ? (
                          <div class=" flex justify-center items-center  transform">
                            <div class="border-t-transparent border-solid animate-spin  rounded-full border-gray-800 border h-6 w-6"></div>
                          </div>
                        ) : (
                          `Send Invite to ${mail}`
                        )}
                      </GenericButton>
                    </>
                  )}
                </fieldset>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteMemberModal;
