import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { GenericButton, Loading, NoItem } from "../../../../components";
import instance from "../../../../lib/client";
import { ArtistBlock } from "./components/index";
import { useHistory } from "react-router-dom";
import Select from "react-select";

const musicOptions = [
  {
    label: "Techno",
    value: "Techno",
  },
  {
    label: "House",
    value: "House",
  },
  {
    label: "Electronica",
    value: "Electronica",
  },
];

function Artists() {
  const [loading, setLoading] = React.useState(true);
  const history = useHistory();
  const [artists, setArtists] = React.useState([]);
  const { t } = useTranslation();

  const getArtist = () => {
    console.log("getArtist");
    instance.get("artist/my-list").then(({ data }) => {
      setArtists(data.data);

      setLoading(false);
    });
  };

  useEffect(() => {
    getArtist();
  }, []);

  const deleteArtist = (id, e) => {
    e.preventDefault();
    if (window.confirm("Do you really want to delete?")) {
      instance.delete(`artist/${id}`).then(({ data }) => {
        console.log(data);
        getArtist();
      });
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                  {artists.length > 0 && (
                    <div className="mb-4 w-full flex md:flex-row xs:flex-col gap-2 justify-between">
                      <Link to={"/dashboard/new-artist"} className={"ml-auto"}>
                        <GenericButton className="shadow md:w-60 xs:w-full">
                          {t("utils:newArtist")}
                        </GenericButton>
                      </Link>
                    </div>
                  )}

                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <div
                      className="divide-y divide-gray-200 overflow-y-auto"
                      style={{ maxHeight: 800 }}
                    >
                      {artists.length > 0 ? (
                        artists.map((artist, i) => {
                          return (
                            <ArtistBlock
                              key={i}
                              artist={artist}
                              deleteArtist={(id, e) => deleteArtist(id, e)}
                            />
                          );
                        })
                      ) : (
                        <NoItem
                          name={t("notFound:artist")}
                          explanation={t("notFound:artistExp")}
                          button={t("utils:newArtist")}
                          onClick={() => history.push("/dashboard/new-artist")}
                          route={"/dashboard/new-artist"}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Artists;
