import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { benufoUrl } from "../../lib/client";
import { useTranslation } from "react-i18next";
import i18n from "../../services/i18n";

const Status = () => {
  const [request, setRequest] = useState({
    status: "",
    event: "",
    slug: "",
  });
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("works");
    if (!id) {
      history.push("/auth/login");
    } else {
      handleRequest();
    }
  }, [id]);

  const handleRequest = async () => {
    const { data } = await axios.get(benufoUrl + "refund/check-status/" + id);

    if (data.success) {
      setRequest(data.data);
    } else {
      history.push("/auth/login");
    }
  };

  return (
    <div className="w-screen h-screen flex items-center bg-gray-100 justify-center">
      <div className="max-w-[420px] shadow-md px-4 py-4 border rounded-lg bg-white">
        <div>
          <div className="mx-auto flex items-center justify-center h-18 w-18 rounded-full">
            {request.status ? (
              <AiOutlineCheckCircle
                className="h-16 w-16 text-primary-500"
                aria-hidden="true"
              />
            ) : (
              <AiOutlineCloseCircle
                className="h-16 w-16 text-red-400"
                aria-hidden="true"
              />
            )}
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {request.status
                ? i18n.language === "tr"
                  ? `${request.event} ${t("status:refundListConfirmed")}`
                  : `${t("status:refundListConfirmed")} ${request.event}`
                : i18n.language === "tr"
                ? `${request.event} ${t("status:refundListDeclined")}`
                : `${t("status:refundListDeclined")} ${request.event}`}
            </h3>
          </div>
        </div>
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
          <a
            type="button"
            href={"https://bugece.co/events/" + request.slug}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-500 text-base font-medium text-white hover:bg-primary-600 transition-all focus:outline-none focus:ring-2 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
          >
            {t("status:eventPage")}
          </a>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
            onClick={() => history.push("/auth/login")}
          >
            {t("status:login")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Status;
