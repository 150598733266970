import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DonutChart,
  GenericButton,
  GuestBlock,
  LastTickets,
  Loading,
  NotFoundMobile,
  RightGuestModal,
} from "../../../components";
import instance from "../../../lib/client";
import GuestForm from "../../Dashboard/components/Guests/components/GuestForm";
import { HiMinusCircle } from "react-icons/hi";
import toastPush from "../../../utils/toastPush";
import useStore from "../../../hooks/useStores";
import { observer } from "mobx-react-lite";
import { isHaveAccess } from "../../../utils/roleUtils";

const Guests = observer(({ href, eventId }) => {
  const { t } = useTranslation();
  const { modal } = useStore();
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    handleData();
  }, [eventId]);

  const handleData = () => {
    Promise.all([instance.get(`guest/${eventId}`), getProperties()]).then(
      ([guests, properties]) => {
        setGuests(guests.data.data);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    getProperties();
  }, [modal.property, modal.triggerPropertyModal]);

  const getProperties = async () => {
    const { data } = await instance.get("guest/property/active");

    setProperties(data.data);
  };

  const deleteProperty = async (id) => {
    const result = await instance.post(`guest/property/delete/${id}`);
    toastPush(t("guests:guestTypeDeleted"));
    getProperties();
  };

  if (loading) return <Loading />;
  return (
    <div>
      {guests?.counts?.all > 0 || guests.counts.arrived > 0 ? (
        <div className="w-full flex flex-col items-center justify-center mb-4">
          {isHaveAccess("eventGuests") && (
            <GenericButton onClick={() => setIsOpen(true)} className="mb-4">
              {t("guestForm:addNew")}
            </GenericButton>
          )}

          <DonutChart
            name={t("reports:guest")}
            series={[
              guests.counts.arrived,
              guests.counts.all - guests.counts.arrived,
            ]}
            labels={["Arrived", "Not Arrived"]}
          />
        </div>
      ) : null}
      <div className="flex flex-row justify-between items-center">
        <label className="font-semibold">{t("guests:types")}</label>
        {isHaveAccess("guestTypeCreate") && (
          <label
            onClick={() => modal.triggerPropertyModal()}
            className="bg-primary-500 rounded-md flex w-6 h-6 justify-center items-center text-white cursor-pointer"
          >
            +
          </label>
        )}
      </div>
      <div className="flex flex-col gap-2 mb-4 mt-3 max-h-[40.5vh] overflow-y-auto">
        {isHaveAccess("guestTypeList") &&
          properties.slice(0, 20).map((property, i) => {
            return (
              <div className="p-2 text-sm bg-primary-200  font-semibold rounded-md flex flex-row relative">
                {property.name}

                <HiMinusCircle
                  className="absolute right-1 h-5 w-5 cursor-pointer"
                  onClick={() => {
                    deleteProperty(property._id);
                  }}
                />
              </div>
            );
          })}
      </div>
      <label className="font-semibold text-md mt-4 mb-4">
        {t("events:guests")}
      </label>
      <div className="divide-y divide-gray-200 mt-4">
        {guests.guests.length > 0 ? (
          guests.guests.slice(0, 10).map((guest, i) => {
            return (
              <GuestBlock
                key={i}
                guest={guest}
                cannotCheck={true}
                cannotEdit={true}
              />
            );
          })
        ) : (
          <NotFoundMobile
            text={"Hiç misafir bulunamadı!"}
            buttonText={"Yeni misafir ekle"}
            onClickFirst={() => setIsOpen(true)}
            onClick={() => setIsOpen(true)}
          />
        )}
      </div>

      {!window.location.pathname.includes("guests") &&
        isHaveAccess("eventGuests") &&
        guests.guests.length > 0 && (
          <LastTickets tickets={[]} t={t} href={href} />
        )}

      <GuestForm
        eventId={eventId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleData={handleData}
      />
    </div>
  );
});

export default Guests;
