import moment from "moment";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../../../lib/client";

const LastTickets = () => {
  const { t } = useTranslation();
  const [lastTickets, setLastTickets] = useState([]);

  useEffect(() => {
    instance.get("dashboard/last-tickets").then(({ data }) => {
      setLastTickets(data.data);
    });
  }, []);

  return (
    <div>
      <span className="font-semibold text-xl">{t("home:lastTickets")}</span>
      <div className="flow-root mt-6 max-h-[1024px] min-h-[1024px] overflow-y-auto">
        <div role="list" className="-my-5 divide-y divide-primary-500">
          {lastTickets.map((ticket, i) => (
            <div key={i} className="py-4">
              <div className="flex items-center space-x-4">
                <div className="flex-1 min-w-0 flex flex-col">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {ticket.name + " " + ticket.last_name}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {ticket?.event?.name}
                  </p>
                </div>
                <div>
                  <p
                    className="text-sm text-gray-500 truncate"
                    style={{
                      maxWidth: 80,
                      wordBreak: "break-all",
                    }}
                  >
                    {moment(ticket.created_at).format("DD MMM")}
                  </p>
                  <p
                    className="text-sm text-gray-500 truncate"
                    style={{
                      maxWidth: 80,
                      wordBreak: "break-all",
                    }}
                  >
                    {moment(ticket.created_at).format("HH:mm")}
                  </p>
                </div>
                <div>
                  <span className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-white bg-primary-500  text-sm leading-5 rounded-full text-white font-bold">
                    {ticket.total_price + " ₺"}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LastTickets;
