import React, { useEffect } from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormInput, GenericButton } from "../";
import { HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Switch } from "@headlessui/react";
import instance from "../../lib/client";
import toastPush from "../../utils/toastPush";
import QuestionModal from "../QuestionModal";
import { analytics } from "../../utils/analytics";
import useStore from "../../hooks/useStores";

const defaultValues = {
  name: "",
  countMax: "",
  type: 0,
  start_date: moment(),
  end_date: moment(),
  discount: "",
  isActive: false,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const CouponForm = ({ isOpen, setIsOpen, handleData, coupon, eventId }) => {
  const { auth } = useStore();
  const [couponNew, setCouponNew] = useState(defaultValues);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();

  const onSubmit = (data) => {
    if (coupon?._id) {
      updateCoupon();
    } else {
      createCoupon();
    }
  };

  const deleteCoupon = () => {
    instance.post("/coupon/delete", coupon).then(({ data }) => {
      handleData();
      onClose();
      toastPush(t("coupon:deleted"));
    });
  };

  const stopStartSale = () => {
    instance.post("/coupon/deactivate", coupon).then(({ data }) => {
      handleData();
      onClose();
      toastPush(t("coupon:couponDeactivated"));
    });
  };

  const handleUpdateCoupon = () => {
    setIsOpenModal(false);
    const newValues = {
      event: eventId,
      countMax: parseInt(couponNew.countMax),
      name: couponNew.name,
      start_date: couponNew.start_date.toDate(),
      end_date: couponNew.end_date.toDate(),
      discount:
        parseInt(couponNew.type) === 0
          ? parseFloat(couponNew.discount) / 100
          : parseFloat(couponNew.discount),
    };

    instance
      .put(`/coupon/${coupon._id}`, newValues)
      .then(({ data }) => {
        handleData();
        onClose();

        toastPush(t("coupon:updated"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createCouponAnalytics = (values) => {
    analytics.track("Coupon Code Created", {
      coupon_name: values?.model?.name,
      groupId: auth.promoter._id,
      id: values?.model?._id,
      name: values?.model?.event,
    });
  };

  const createCoupon = () => {
    const newValues = {
      event: eventId,
      countMax: parseInt(couponNew.countMax),
      name: couponNew.name,
      start_date: couponNew.start_date.toDate(),
      end_date: couponNew.end_date.toDate(),
      isActive: couponNew.isActive,
      discount:
        parseInt(couponNew.type) === 0
          ? parseFloat(couponNew.discount) / 100
          : parseFloat(couponNew.discount),
    };

    instance
      .post("/coupon", newValues)
      .then((response) => {
        createCouponAnalytics(response.data.data);
        handleData();
        onClose();
        toastPush(t("coupon:created"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCoupon = () => {
    if (
      parseFloat(couponNew.discount) !==
      parseFloat(couponNew.type === 0 ? coupon.discount * 100 : coupon.discount)
    ) {
      setIsOpenModal(true);
    } else {
      handleUpdateCoupon();
    }
  };

  useEffect(() => {
    if (coupon) {
      setCouponNew({
        name: coupon ? coupon.name : "",
        countMax: coupon ? coupon.countMax : 0,
        // 0: percentage, 1: amount
        type: coupon ? (coupon.discount > 1 ? 1 : 0) : 0,
        start_date: coupon?.start_date ? moment(coupon.start_date) : moment(),
        end_date: coupon?.end_date ? moment(coupon.end_date) : moment(),
        discount: coupon
          ? coupon.discount < 1
            ? coupon.discount * 100
            : coupon.discount
          : 0,
      });
    } else setCouponNew(defaultValues);
  }, [coupon]);

  const onClose = () => {
    setIsOpen(false);
    setCouponNew(defaultValues);
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={() => (isOpenModal ? {} : onClose())}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-[350px]">
                  <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {coupon?._id
                            ? t("coupon:updateCoupon")
                            : t("coupon:addNewCoupon")}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => onClose(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <HiOutlineX
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {/* Replace with your content */}
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div class="space-y-4">
                          <div className="flex-1 flex flex-col justify-between">
                            <div className="space-y-3 pb-5 flex flex-col w-full">
                              <div>
                                <div className="mt-1">
                                  <FormInput
                                    type="text"
                                    id="name"
                                    value={couponNew?.name}
                                    onChange={(e) =>
                                      setCouponNew({
                                        ...couponNew,
                                        name: e.target.value,
                                      })
                                    }
                                    placeholder={t("coupon:name")}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="w-full transition-all flex flex-row text-xs bg-gray-200 mb-4 rounded rounded-12">
                                  <div
                                    className={`w-full p-2 text-center  ${
                                      couponNew.type === 0
                                        ? "bg-primary-500 font-bold rounded-16 rounded"
                                        : "cursor-pointer"
                                    }`}
                                    onClick={() =>
                                      setCouponNew({ ...couponNew, type: 0 })
                                    }
                                  >
                                    {t("coupon:percentage")}
                                  </div>
                                  <div
                                    onClick={() =>
                                      setCouponNew({ ...couponNew, type: 1 })
                                    }
                                    className={`w-full p-2 text-center ${
                                      couponNew.type === 1
                                        ? "bg-primary-500 font-bold rounded-16 rounded"
                                        : "cursor-pointer"
                                    }`}
                                  >
                                    {t("coupon:amount")}
                                  </div>
                                </div>
                                <div className="mt-1 ">
                                  <FormInput
                                    type="number"
                                    name="count"
                                    id="count"
                                    min={0}
                                    value={couponNew?.discount}
                                    onChange={(e) =>
                                      setCouponNew({
                                        ...couponNew,
                                        discount: e.target.value,
                                      })
                                    }
                                    placeholder={t("coupon:discount")}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="mt-1">
                                  <FormInput
                                    type="number"
                                    name="countMax"
                                    id="countMax"
                                    min={0}
                                    value={couponNew?.countMax}
                                    onChange={(v) => {
                                      setCouponNew({
                                        ...couponNew,
                                        countMax: String(v.target.value),
                                      });
                                    }}
                                    placeholder={t("coupon:countMax")}
                                  />
                                </div>
                              </div>

                              {!coupon?._id && (
                                <label className="text-md font-semibold flex flex-row justify-between w-full">
                                  {t("utils:startNow")}

                                  <Switch
                                    value={couponNew.isActive}
                                    onChange={(value) =>
                                      setCouponNew({
                                        ...couponNew,
                                        isActive: !couponNew.isActive,
                                      })
                                    }
                                    className={classNames(
                                      couponNew.isActive
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        couponNew.isActive
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </label>
                              )}

                              <GenericButton
                                onClick={() => onSubmit()}
                                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                {coupon?._id ? t("utils:save") : t("utils:add")}
                              </GenericButton>
                              {coupon?._id && (
                                <>
                                  <GenericButton
                                    onClick={() => stopStartSale()}
                                    className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                      !coupon.isActive
                                        ? "bg-primary-500"
                                        : "bg-fourth-500"
                                    } hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                                  >
                                    {!coupon.isActive
                                      ? t("utils:activate")
                                      : t("utils:deactivate")}
                                  </GenericButton>
                                  <GenericButton
                                    onClick={() => deleteCoupon()}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fourth-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                  >
                                    {t("utils:delete")}
                                  </GenericButton>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /End replace */}
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <QuestionModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        header={t("utils:warning")}
        question={t("coupon:warningText")}
        noText={t("utils:cancel")}
        yesText={t("utils:continue")}
        onClickYes={() => handleUpdateCoupon()}
      />
    </>
  );
};

export default CouponForm;
