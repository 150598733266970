/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton } from "../../../components";
import toastPush from "../../../utils/toastPush";
import { observer } from "mobx-react-lite";
import { HiMinusCircle } from "react-icons/hi";

const GuestPropertyModal = observer(({}) => {
  const { modal } = useStore();
  const { t } = useTranslation();
  const [propertyName, setPropertyName] = useState("");
  const [properties, setProperties] = useState([]);

  const getProperties = async () => {
    const { data } = await instance.get("guest/property/active");
    setProperties(data.data);
  };

  const addGuestPropert = async () => {
    if (propertyName) {
      const { data } = await instance.post("/guest/property", {
        name: propertyName,
      });

      toastPush(t("guests:guestPropertyAdded"));
      setPropertyName("");
      modal.triggerPropertyModal();
    }
  };

  const deleteProperty = async (id) => {
    const result = await instance.post(`guest/property/delete/${id}`);
    toastPush(t("guests:guestTypeDeleted"));
    getProperties();
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <Transition.Root show={modal.property} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => modal.triggerPropertyModal()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold">
                {t("guests:addTitle")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => modal.triggerPropertyModal()}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-6">
                <FormInput
                  placeholder={t("guests:propertyName")}
                  value={propertyName}
                  label={t("guests:propertyName")}
                  onChange={(v) => setPropertyName(v.target.value)}
                  style={{ marginBottom: 15 }}
                />
                <GenericButton
                  background="primary"
                  onClick={() => addGuestPropert()}
                  style={{ paddingVertical: 20, marginTop: 20 }}
                  textBold
                >
                  {t("utils:add")}
                </GenericButton>
                <div className="flex flex-col gap-y-2 mt-4">
                  {properties.map((property, i) => {
                    return (
                      <div className="p-2 text-sm bg-primary-200  font-semibold rounded-md flex flex-row relative">
                        {property.name}

                        <HiMinusCircle
                          className="absolute right-1 h-5 w-5 cursor-pointer"
                          onClick={() => {
                            deleteProperty(property._id);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default GuestPropertyModal;
