import React, { useState } from "react";

const CouponUsed = ({ coupon, index }) => {
  return (
    <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}>
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm font-medium text-gray-900">
        {coupon.name}
      </td>
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm text-gray-500">
        {coupon.totalCount}
      </td>
      <td className="px-2 py-2 whitespace-nowrap w-1/3 text-sm text-gray-500">
        {coupon.discountAmount} ₺
      </td>
    </tr>
  );
};

export default CouponUsed;
