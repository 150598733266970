import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ text, ...rest }) => {
  return (
    <div
      className={"w-full flex items-center justify-center flex-col"}
      {...rest}
    >
      <ReactLoading type={"spin"} color={"#ffc800"} height={70} width={70} />
      {text && (
        <span className="text-md font-medium text-secondary-800 mt-8">
          {text}
        </span>
      )}
    </div>
  );
};

export default Loading;
