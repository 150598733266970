import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Menu, Popover, Transition } from "@headlessui/react";
import classNames from "../../utils/classNames";
import {
  useHistory,
  Route,
  Switch,
  useRouteMatch,
  NavLink,
  Redirect,
  Link,
} from "react-router-dom";
import { HiOutlineBell, HiOutlineMenu, HiX } from "react-icons/hi";
import { BiDevices } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { GrLanguage } from "react-icons/gr";
import { RiExchangeBoxLine } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { FaMoneyBillAlt, FaUserEdit } from "react-icons/fa";
import { IoMdExit, IoMdPersonAdd, IoMdReturnLeft } from "react-icons/io";
import { TiGroup } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import BugeceLight from "../../assets/images/BugeceLight.svg";
import BugeceDark from "../../assets/images/BugeceDark.svg";
import useStore from "../../hooks/useStores";
import Home from "./components/Home/Home";
import Events from "./components/Events/Events";
import Venues from "./components/Venues/Venues";
import Artists from "./components/Artists/Artists";
import Crew from "./components/Crew/Crew";
import Reports from "./components/Reports";
import instance from "../../lib/client";
import EventDetail from "../EventDetail/EventDetail";
import NewEvent from "../NewEvent/NewEvent";
import NewVenue from "../NewVenue/NewVenue";
import NewArtist from "../NewArtist/NewArtist";
import Guests from "./components/Guests/Guests";
import Tickets from "../Tickets/Tickets";
import ActiveDevices from "../ActiveDevices/ActiveDevices";
import Coupons from "./components/Coupons/Coupons";
import { Search } from "../../components";
import BillingInfoModal from "./components/BillingInfoModal";
import PersonalInfoModal from "./components/PersonalInfoModal";
import GuestPropertyModal from "./components/GuestPropertyModal";
import TicketDetailModal from "./components/TicketDetailModal";
import RefundDetailModal from "./components/RefundDetailReport";
import i18n from "../../services/i18n";
import PromoterInfoModal from "./components/PromoterInfoModal";
import { isHaveAccess } from "../../utils/roleUtils";
import TicketRefundModal from "./components/TicketRefundModal";
import RefundReasonDetailModal from "./components/RefundReasonDetailModal";
import { RiRefund2Fill } from "react-icons/ri";
import TicketPos from "./components/TicketPos/TicketPos";
import GuestTicketModal from "./components/GuestTicketModal";
import GeneralEventReport from "../GeneralEventReport/GeneralEventReport";

function Dashboard() {
  const [errorImage, setErrorImage] = useState(false);
  const [openBillingInfo, setOpenBillingInfo] = useState(false);
  const [openPromoterInfo, setOpenPromoterInfo] = useState(false);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const { auth, modal } = useStore();

  const { url } = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [routes] = useState([
    {
      path: url + "/home",
      name: t("home:tabTitle"),
      isVisible: true,
      tag: "",
      render: () => <Home />,
    },
    {
      path: url + "/events",
      name: t("events:tabTitle"),
      isVisible: true,
      tag: "eventList",
      render: () => <Events />,
    },
    {
      path: url + "/tickets/:id",
      name: t("eventForm:tickets"),
      isVisible: false,
      tag: "",
      render: () => <Tickets />,
    },
    {
      path: url + "/guests/:id",
      name: t("utils:guests"),
      isVisible: false,
      tag: "",
      render: () => <Guests />,
    },
    {
      path: url + "/activedevices",
      name: t("utils:activeDevices"),
      isVisible: false,
      tag: "",
      render: () => <ActiveDevices />,
    },

    {
      path: url + "/crew",
      name: t("crew:tabTitle"),
      isVisible: true,
      tag: "crewManagement",
      render: () => <Crew />,
    },
    {
      path: url + "/venues",
      name: t("venues:tabTitle"),
      tag: "venueList",
      isVisible: true,
      render: () => <Venues />,
    },
    {
      path: url + "/artists",
      name: t("artists:tabTitle"),
      isVisible: true,
      tag: "artistList",
      render: () => <Artists />,
    },
    {
      path: url + `/update-artist/:id`,
      name: t("artists:tabTitle"),
      tag: "artistUpdate",
      isVisible: false,
      render: () => <NewArtist />,
    },
    {
      path: url + `/update-venue/:id`,
      name: t("venues:tabTitle"),
      isVisible: false,
      tag: "venueUpdate",
      render: () => <NewVenue />,
    },
    {
      path: url + "/new-venue",
      name: "newVenue",
      isVisible: false,
      tag: "venueCreate",
      render: () => <NewVenue />,
    },
    {
      path: url + "/new-artist",
      name: "newArtist",
      isVisible: false,
      tag: "artistCreate",
      render: () => <NewArtist />,
    },
    {
      path: url + "/new-event",
      name: "newEvent",
      isVisible: false,
      tag: "eventCreate",

      render: () => <NewEvent />,
    },
    {
      path: url + "/update-event/:id",
      name: "newEvent",
      isVisible: false,
      tag: "eventCreate",
      render: () => <NewEvent />,
    },
    {
      path: url + "/coupons/:id",
      name: "event",
      tag: "eventCouponList",
      isVisible: false,
      render: () => <Coupons />,
    },

    {
      path: url + "/event/detail/:slug",
      name: "EventDetail",
      isVisible: false,
      tag: "",
      render: () => <EventDetail />,
    },
    {
      path: url + "/ticket-pos/:id",
      name: "TicketPos",
      isVisible: false,
      tag: "",
      render: () => <TicketPos />,
    },
    {
      path: url + "/general-event-report",
      name: t('generalEventReport:tabTitle'),
      isVisible: true,
      isRight: true,
      tag: "all:access",
      render: () => <GeneralEventReport/>
    }
  ]);

  const userNavigation = [
    {
      icon: GrLanguage,
      name: "EN/TR",
      href: () => {
        i18n.changeLanguage(i18n.language === "en" ? "tr" : "en");
        auth.setLanguage(i18n.language === "en" ? "tr" : "en");
      },
      isFunc: true,
      tag: "",
    },
    {
      icon: CgProfile,
      name: t("settings:personalInformation"),
      href: setOpenUserInfo,
      isFunc: true,
      tag: "",
    },

    {
      icon: FaMoneyBillAlt,
      name: t("settings:billingInformation"),
      href: setOpenBillingInfo,
      tag: "all:access",
      isFunc: true,
    },
    {
      icon: FaUserEdit,
      name: t("settings:promoterInfo"),
      href: setOpenPromoterInfo,
      tag: "all:access",
      isFunc: true,
    },
    {
      icon: IoMdPersonAdd,
      name: t("settings:guestsProperty"),
      href: () => modal.triggerPropertyModal(),
      tag: "guestTypeCreate",
      isFunc: true,
    },
    {
      icon: RiRefund2Fill,
      name: t("settings:refundReasonSetting"),
      href: () => modal.triggerRefundReasonDetailModal(),
      tag: "refundReasonCreate",
      isFunc: true,
    },
    {
      icon: TiGroup,
      name: t("settings:editCrew"),
      href: "/dashboard/crew",
      tag: "crewManagement",
      isFunc: false,
    },
    {
      icon: BiDevices,
      name: t("utils:activeDevices"),
      href: "/dashboard/activedevices",
      isFunc: false,
      tag: "",
    },
    {
      icon: RiExchangeBoxLine,
      name: t("utils:changeAccount"),
      href: "/auth/choose",
      isFunc: false,
      tag: "",
    },

    { icon: IoMdExit, name: t("utils:signOut"), href: "#", isFunc: false },
  ];

  const addNew = [
    { name: t("utils:newEvent"), href: url + "/new-event", tag: "eventCreate" },
    { name: t("utils:newVenue"), href: url + "/new-venue", tag: "venueCreate" },
    {
      name: t("utils:newArtist"),
      href: url + "/new-artist",
      tag: "artistCreate",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100">
      <Popover as="header" className="pb-24 bg-gray-800 md:pt-0 xs:pt-3">
        {({ open, close }) => (
          <>
            <div className="md:hidden xs:flex flex-row">
              <Link to={url + "/home"}>
                <img
                  className={"md:hidden xs:flex h-8 mr-auto ml-4"}
                  src={BugeceLight}
                  alt="BugeceLogo"
                />
              </Link>

              <Menu
                as="div"
                className="absolute right-[70px] flex items-center justify-center flex-shrink-0"
              >
                <Menu.Button className="w-10 h-10 text-indigo-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                  <HiOutlineBell className="h-6 w-6 ml-2" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right top-10 z-40 p-2 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <h4 className="text-sm font-semibold border-b pb-1">
                      {t("notifications:title")}
                    </h4>
                    <span className="text-sm pt-1">
                      {t("notifications:explanation")}
                    </span>
                  </Menu.Items>
                </Transition>
              </Menu>
              <div className="flex-shrink-0 absolute right-4 lg:hidden">
                {/* Mobile menu button */}
                <Popover.Button className="bg-transparent  rounded-md inline-flex items-center justify-center text-indigo-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <HiX className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <img
                      className="h-10 w-10 rounded-full border bg-white"
                      src={auth.promoter.image}
                      alt=""
                    />
                  )}
                </Popover.Button>
              </div>
            </div>

            <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative py-5 flex items-center justify-center lg:justify-between">
                {/* Logo */}
                <div className="absolute left-0 flex-shrink-0 lg:static hidden lg:flex">
                  <span>
                    <span className="sr-only">Workflow</span>
                    <Link to={url + "/home"}>
                      <img
                        className="h-8 w-auto"
                        src={BugeceLight}
                        alt="BugeceLogo"
                      />
                    </Link>
                  </span>
                </div>
                <div className="hidden lg:flex">
                  <Search />
                </div>
                {/* Right section on desktop */}
                <div className="hidden lg:mr-4 lg:flex lg:items-center lg:pr-0.5 ">
                  <Menu as="div" className="mr-4 relative flex-shrink-0">
                    <div>
                      <Menu.Button className="bg-transparent rounded-full flex text-sm  ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                        <span className="sr-only">Open user menu</span>
                        <AiOutlinePlus
                          className={"h-6 w-6 rounded-full text-primary-500"}
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {addNew.map((item) => {
                          if (isHaveAccess(item.tag))
                            return (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <span
                                    onClick={() => {
                                      history.push(item.href);
                                    }}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                                    )}
                                  >
                                    {item.name}
                                  </span>
                                )}
                              </Menu.Item>
                            );
                        })}
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex-shrink-0 p-1 text-indigo-200 rounded-full hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                        <HiOutlineBell className="h-6 w-6" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right z-40 p-2 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <h4 className="text-sm font-semibold border-b pb-1">
                          {t("notifications:title")}
                        </h4>
                        <span className="text-sm pt-1">
                          {t("notifications:explanation")}
                        </span>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-4 relative flex-shrink-0">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
                        <span className="sr-only">Open user menu</span>
                        {errorImage ? (
                          <div className="h-8 w-8 rounded-full bg-primary-500 text-white flex items-center justify-center">
                            {auth.promoter.name.charAt(0)}
                          </div>
                        ) : (
                          <img
                            className="h-8 w-8 rounded-full"
                            src={auth.promoter.image}
                            onError={() => setErrorImage(true)}
                            alt=""
                          />
                        )}
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right z-40 absolute -right-2 mt-2 w-56 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map(
                          (item) =>
                            isHaveAccess(item.tag) && (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <span
                                    onClick={
                                      t("utils:signOut") === item.name
                                        ? () => {
                                            close();
                                            instance.get("auth/logout");
                                            auth.signOut();

                                            history.push("/auth/login");
                                          }
                                        : () => {
                                            if (item.isFunc) {
                                              close();
                                              item.href(true);
                                            } else {
                                              close();
                                              history.push(item.href);
                                            }
                                          }
                                    }
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "px-4 py-2 text-sm text-gray-700 cursor-pointer flex flex-row items-center"
                                    )}
                                  >
                                    <item.icon className="w-5 h-5 mr-2" />
                                    {item.name}
                                  </span>
                                )}
                              </Menu.Item>
                            )
                        )}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="w-full flex  md:hidden xs:flex">
                  <Search isMobile={true} />
                </div>

                {/* Menu button */}
              </div>
              <div className="hidden lg:block border-t border-white border-opacity-20 py-5">
                <div className="grid grid-cols-3 gap-8 items-center">
                  <div className="col-span-2">
                    <nav className="flex space-x-4">
                      {routes.map((item) => {
                        return (
                          item.isVisible &&
                          isHaveAccess(item.tag) && (
                            <NavLink
                              key={item.name}
                              onClick={() => close()}
                              className={classNames(
                                "text-white text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10 cursor-pointer mt-2"
                              )}
                              to={item.path}
                              activeStyle={{
                                backgroundColor: "white",
                                color: "#1F2937"
                              }}
                            >
                              {console.log(item)}
                              {item.name}
                            </NavLink>
                          )
                        );
                      })}
                    </nav>
                  </div>
                </div>
              </div>
            </div>

            <Transition.Root as={Fragment}>
              <div className="lg:hidden">
                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <Transition.Child
                  as={Fragment}
                  enter="duration-150 ease-out"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="duration-150 ease-in"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Popover.Panel
                    focus
                    className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                  >
                    <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                      <div className="pt-3 pb-2">
                        <div className="flex items-center justify-between px-4">
                          <div>
                            <img
                              className="h-8 w-auto "
                              src={BugeceDark}
                              alt="Workflow"
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                              <span className="sr-only">Close menu</span>
                              <HiX className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {routes.map((item, i) => {
                            if (isHaveAccess(item.tag))
                              return (
                                item.isVisible && (
                                  <NavLink
                                    to={item.path}
                                    key={i}
                                    onClick={() => close()}
                                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 cursor-pointer"
                                    activeStyle={{
                                      color: "white",
                                      backgroundColor: "#1F2937",
                                    }}
                                  >
                                    {item.name}
                                  </NavLink>
                                )
                              );
                          })}
                        </div>
                      </div>
                      <div className="pt-4 pb-2">
                        <div className="flex items-center px-5">
                          <div className="flex-shrink-0">
                            <img
                              className="h-10 w-10 rounded-full border border-primary-500"
                              src={auth.promoter.image}
                              alt=""
                            />
                          </div>
                          <div className="ml-3 min-w-0 flex-1">
                            <div className="text-base font-medium text-gray-800 truncate">
                              {auth.promoter.name}
                            </div>
                            <div className="text-sm font-medium text-gray-500 truncate">
                              {auth.promoter.slug}
                            </div>
                          </div>
                        </div>
                        <div className="mt-3 px-2 space-y-1">
                          {userNavigation.map(
                            (item) =>
                              isHaveAccess(item.tag) && (
                                <span
                                  onClick={
                                    t("utils:signOut") === item.name
                                      ? () => {
                                          auth.signOut();
                                          history.push("/auth/login");
                                        }
                                      : () => {
                                          if (item.isFunc) {
                                            item.href(true);
                                          } else {
                                            history.push(item.href);
                                          }
                                        }
                                  }
                                  key={item.name}
                                  className="rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800 flex flex-row items-center"
                                >
                                  <item.icon className={"mr-4 w-6 h-6"} />
                                  {item.name}
                                </span>
                              )
                          )}
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition.Child>
              </div>
            </Transition.Root>
          </>
        )}
      </Popover>
      <main className="-mt-24 pb-8">
        <Switch>
          {routes.map((route, i) => {
            return (
              isHaveAccess(route.tag) && (
                <Route key={i} path={route.path} component={route.render} />
              )
            );
          })}
          <Redirect to={url + "/home"} />
        </Switch>
      </main>
      <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="border-t border-gray-200 py-8 text-sm text-gray-500 text-center sm:text-left">
            <span className="block sm:inline">&copy; 2022 Bugece.co.</span>{" "}
            <span className="block sm:inline">All rights reserved.</span>
          </div>
        </div>
      </footer>
      <TicketDetailModal />
      <GuestTicketModal />
      <TicketRefundModal />
      <RefundDetailModal />
      <GuestPropertyModal />
      <RefundReasonDetailModal />
      <PromoterInfoModal
        open={openPromoterInfo}
        setOpen={setOpenPromoterInfo}
      />
      <PersonalInfoModal open={openUserInfo} setOpen={setOpenUserInfo} />
      <BillingInfoModal open={openBillingInfo} setOpen={setOpenBillingInfo} />
    </div>
  );
}

export default Dashboard;
