const encodeQueryString = (params) => {
  const keys = Object.keys(params);
  return keys.length
    ? "?" +
        keys
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          )
          .join("&")
    : "";
};

const searchInArray = (searchQuery, array, objectKey = null) => {
  return array.filter((d) => {
    let data = objectKey ? d[objectKey] : d; //Incase If It's Array Of Objects.
    let dataWords = typeof data === "string" && data?.toLocaleLowerCase();
    let searchWords =
      typeof searchQuery === "string" &&
      searchQuery
        ?.split(" ")
        ?.map((b) => b && b?.toLocaleLowerCase())
        ?.filter((b) => b);

    let matchingWords = searchWords?.filter((word) =>
      dataWords?.includes(word)
    );

    return matchingWords.length;
  });
};

const dynamicSort = (property) => {
  return function (a, b) {
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

    return result;
  };
};

export { encodeQueryString, searchInArray, dynamicSort };
