import { action, makeAutoObservable } from "mobx";
import { makePersistable, isHydrated, hydrateStore } from "mobx-persist-store";

class ModalStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: [],
      storage: localStorage,
    });
  }

  refund = false;
  currentEvent = null;
  loading = false;
  property = false;
  refundReasonDetail = false;
  ticket = false;
  ticketDetail = null;
  ticketRefund = false;
  isModalInModal = false;
  guestModal = false;
  guestData = null;

  @action
  triggerTicketModal(data) {
    if (data) {
      this.ticketDetail = data.ticket;
      this.currentEvent = data.event;
      this.ticket = true;
    } else {
      this.ticket = false;
      setTimeout(() => {
        this.ticketDetail = null;
        this.currentEvent = null;
      }, 500);
    }
  }

  @action
  triggerGuestTicketModal(data) {
    console.log(data);
    this.guestModal = !this.guestModal;
    this.guestData = data;
  }

  @action
  triggerTicketRefundModal() {
    this.ticketRefund = !this.ticketRefund;
    this.isModalInModal = !this.isModalInModal;
  }

  @action
  triggerLoading() {
    this.loading = !this.loading;
  }

  @action
  triggerRefundModal(id) {
    if (this.refund) {
      this.currentEvent = null;
      this.refund = !this.refund;
    } else {
      this.currentEvent = { _id: id };
      this.refund = !this.refund;
    }
  }

  @action
  triggerPropertyModal() {
    this.property = !this.property;
  }

  @action
  triggerRefundReasonDetailModal() {
    this.refundReasonDetail = !this.refundReasonDetail;
  }

  @action
  triggerisRefunded() {
    this.isRefunded = !this.isRefunded;
  }

  get isHydrated() {
    return isHydrated(this);
  }

  async hydrateStore() {
    await hydrateStore(this);
  }
}

const modalStore = new ModalStore();

export default modalStore;
