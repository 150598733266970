import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import instance from "../../../lib/client";
import { FormTextArea, RightRuleModal } from "../../../components";
import { IoAddOutline, IoCloseCircleOutline, IoPencil } from "react-icons/io5";
import QuestionModal from "../../../components/QuestionModal";
import { useForm, useWatch } from "react-hook-form";
function Details({
  selectedSection,
  setSelectedSection,
  register,
  setValue,
  getValues,
  clearErrors,
  control,
  checkIsValid,
  saveAsDraft,
  errors,
  id,
}) {
  const { t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState("tr");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [targetUrl, setTargetUrl] = useState("");
  const [rules, setRules] = useState([]);
  const [trRules, setTrRules] = useState([]);
  const [enRules, setEnRules] = useState([]);
  const [rulesModal, setRulesModal] = useState(false);
  const [selectedRule, setSelectedRule] = useState();
  const [appliedRules, setAppliedRules] = useState({
    en: "",
    tr: "",
  });

  const tabs = [
    { name: "TR", value: "tr" },
    { name: "EN", value: "en" },
  ];

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const translations = useWatch({
    control: control,
    name: "translations",
  });

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(
          window.location.pathname.includes("update-event")
            ? getValues("type") == "draft"
              ? selectedSection + 1
              : selectedSection + 2
            : selectedSection + 1
        )
      : console.log("trans", errors);
  };

  const getRules = async () => {
    instance
      .get("event/rules")
      .then((response) => setRules(response.data.data));
  };

  const checkRuleChange = (id, lang) => {
    const updatedRule = rules.find((rule) => rule._id == id);
    if (appliedRules[lang] == id && id && lang) {
      setValue(
        `translations.${lang == "tr" ? 0 : 1}.rules`,
        updatedRule?.translations[0].rules
      );
    }
    return false;
  };

  const matchRules = (id, lang) => {
    console.log("id", id);
    console.log("lang", lang);
    setAppliedRules({
      en: translations[1].rulesId ? translations[1].rulesId : "",
      tr: translations[0].rulesId ? translations[0].rulesId : "",
    });
  };

  useEffect(() => {
    getRules();
  }, [rulesModal]);

  useEffect(() => {
    console.log("appliedRules", appliedRules);
  }, [appliedRules]);

  useEffect(() => {
    translations.map((translation) => {
      matchRules(translation.rulesId, translation.langcode);
    });
    setRulesList();
    rules.length > 1 &&
      checkRuleChange(
        appliedRules[selectedLang == "tr" ? "tr" : "en"],
        selectedLang
      );
  }, [rules]);

  const selectTrRule = (rule) => {
    setAppliedRules({ ...appliedRules, tr: rule._id });
    setValue(`translations.${0}.rules`, rule.translations[0].rules);
    setValue(`translations.${0}.rulesId`, rule._id);
  };

  const selectEnRule = (rule) => {
    setAppliedRules({ ...appliedRules, en: rule._id });
    setValue(`translations.${1}.rules`, rule.translations[0].rules);
    setValue(`translations.${1}.rulesId`, rule._id);
  };

  const setRulesList = () => {
    setTrRules(rules.filter((rule) => rule.translations[0].langcode == "tr"));
    setEnRules(rules.filter((rule) => rule.translations[0].langcode == "en"));
  };

  const editRule = (rule) => {
    setRulesModal(true);
    setSelectedRule(rule);
  };

  return (
    <section aria-labelledby="section-1-title overflow-auto">
      <div className="rounded-lg bg-white shadow overflow-auto">
        <div className="p-6">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6">
                    <div className="sm:hidden">
                      <label htmlFor="tabs" className="sr-only">
                        Select a tab
                      </label>

                      <select
                        id="tabs"
                        name="tabs"
                        className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                        defaultValue={
                          tabs.find((tab) => tab.value === selectedLang).name
                        }
                      >
                        {tabs.map((tab) => (
                          <option key={tab.name}>{tab.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="hidden sm:block">
                      <nav
                        className="flex space-x-4 items-center"
                        aria-label="Tabs"
                      >
                        {tabs.map((tab) => (
                          <div
                            onClick={() => setSelectedLang(tab.value)}
                            key={tab.name}
                            className={classNames(
                              tab.value === selectedLang
                                ? "bg-primary-400 text-white"
                                : "text-gray-500 hover:text-gray-700",
                              "px-3 py-2 font-medium text-sm rounded-md cursor-pointer"
                            )}
                            aria-current={
                              tabs.find((tab) => tab.value === selectedLang)
                                ? "page"
                                : undefined
                            }
                          >
                            {tab.name}
                          </div>
                        ))}
                        {errors?.translations && (
                          <div className="text-red-400 text-sm">
                            Bütün Alanlar Doldurulmalıdır
                          </div>
                        )}
                      </nav>
                    </div>
                  </div>
                  {selectedLang == "tr" ? (
                    <>
                      <div className="col-span-6 sm:col-span-[100%] ">
                        <label
                          className={`block text-sm font-medium  ${
                            errors?.translations &&
                            errors?.translations[0]?.desc
                              ? "text-red-400"
                              : "text-gray-700"
                          }`}
                        >
                          {t("eventForm:eventDesc") + ` (TR)`}
                        </label>

                        <FormTextArea
                          value={getValues(`translations.${0}.desc`)}
                          {...register("translations.0.desc")}
                          className={`!max-w-4xl ${
                            errors?.translations &&
                            errors?.translations[0]?.desc &&
                            "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                          }`}
                          rows={5}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          className={`block text-sm font-medium  ${
                            errors?.translations &&
                            errors?.translations[0]?.rules
                              ? "text-red-400"
                              : "text-gray-700"
                          }`}
                        >
                          {t("eventForm:eventRules") + ` (TR)`}
                        </label>

                        <FormTextArea
                          value={getValues(`translations.${0}.rules`)}
                          {...register("translations.0.rules")}
                          className={
                            errors?.translations &&
                            errors?.translations[0]?.rules &&
                            "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                          }
                          rows={6}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex flex-col gap-1 text-sm font-medium text-gray-700">
                          <div className="flex justify-between items-center text-sm gap-1">
                            Kayıtlı Kurallar{" "}
                            <div
                              className="w-fit h-fit cursor-pointer flex gap-0.5 items-center"
                              onClick={() => {
                                setSelectedRule(null);
                                setRulesModal(true);
                              }}
                            >
                              <IoAddOutline
                                className="h-5 w-5 text-primary-600"
                                aria-hidden="true"
                              />
                              <div className="text-primary-600 text-xs">
                                Add New Rule
                              </div>
                            </div>
                          </div>
                          <div className=" flex flex-col gap-2 h-[138px] overflow-y-auto">
                            {trRules.map((rule, i) => (
                              <div className="flex justify-between items-center py-2 px-2 border rounded-lg border-gray-300 text-sm font-medium">
                                <div>{rule.title}</div>
                                <div className="flex justify-between gap-1 w-fit items-center">
                                  {appliedRules.tr == rule._id && (
                                    <div
                                      className="w-fit h-fit cursor-pointer"
                                      onClick={() => {
                                        setValue(`translations.${0}.rules`, "");
                                        setAppliedRules({
                                          ...appliedRules,
                                          tr: "",
                                        });
                                      }}
                                    >
                                      <IoCloseCircleOutline
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="w-fit h-fit cursor-pointer"
                                    onClick={() => {
                                      editRule(rule);
                                    }}
                                  >
                                    <IoPencil
                                      className="h-4 w-4 text-gray-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div
                                    className="w-fit h-fit cursor-pointer"
                                    onClick={() => selectTrRule(rule)}
                                  >
                                    <IoAddOutline
                                      className="h-6 w-6 text-primary-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-span-6 sm:col-span-[100%] ">
                        <label
                          className={`block text-sm font-medium  ${
                            errors?.translations &&
                            errors?.translations[1]?.desc
                              ? "text-red-400"
                              : "text-gray-700"
                          }`}
                        >
                          {t("eventForm:eventDesc") + ` (EN)`}
                        </label>

                        <FormTextArea
                          value={getValues(`translations.${1}.desc`)}
                          {...register("translations.1.desc")}
                          className={`!max-w-4xl ${
                            errors?.translations &&
                            errors?.translations[1]?.desc &&
                            "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                          }`}
                          rows={5}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          className={`block text-sm font-medium  ${
                            errors?.translations &&
                            errors?.translations[1]?.rules
                              ? "text-red-400"
                              : "text-gray-700"
                          }`}
                        >
                          {t("eventForm:eventRules") + ` (EN)`}
                        </label>

                        <FormTextArea
                          value={getValues(`translations.${1}.rules`)}
                          {...register("translations.1.rules")}
                          className={
                            errors?.translations &&
                            errors?.translations[1]?.rules &&
                            "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                          }
                          rows={6}
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <div className="flex flex-col gap-1 text-sm font-medium text-gray-700">
                          <div className="flex justify-between items-center text-sm gap-1">
                            Kayıtlı Kurallar{" "}
                            <div
                              className="w-fit h-fit cursor-pointer flex gap-0.5 items-center"
                              onClick={() => {
                                setSelectedRule(null);
                                setRulesModal(true);
                              }}
                            >
                              <IoAddOutline
                                className="h-5 w-5 text-primary-600"
                                aria-hidden="true"
                              />
                              <div className="text-primary-600 text-xs">
                                Add New Rule
                              </div>
                            </div>
                          </div>
                          <div className=" flex flex-col gap-2 h-[138px] overflow-y-auto">
                            {enRules.map((rule, i) => (
                              <div className="flex justify-between items-center py-2 px-2 border rounded-lg border-gray-300 text-sm font-medium">
                                <div>{rule.title}</div>
                                <div className="flex justify-between gap-1 w-fit items-center">
                                  {appliedRules.en == rule._id && (
                                    <div
                                      className="w-fit h-fit cursor-pointer"
                                      onClick={() => {
                                        setValue(`translations.${1}.rules`, "");
                                        setAppliedRules({
                                          ...appliedRules,
                                          en: "",
                                        });
                                      }}
                                    >
                                      <IoCloseCircleOutline
                                        className="h-6 w-6 text-red-600"
                                        aria-hidden="true"
                                      />
                                    </div>
                                  )}
                                  <div
                                    className="w-fit h-fit cursor-pointer"
                                    onClick={() => {
                                      editRule(rule);
                                    }}
                                  >
                                    <IoPencil
                                      className="h-4 w-4 text-gray-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div
                                    className="w-fit h-fit cursor-pointer"
                                    onClick={() => selectEnRule(rule)}
                                  >
                                    <IoAddOutline
                                      className="h-6 w-6 text-primary-600"
                                      aria-hidden="true"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-span-6 sm:col-span-6 sm:border-t sm:border-gray-200 " />
                </div>
                <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end gap-4">
                  {(getValues("type") == "draft" ||
                    !window.location.pathname.includes("update-event")) && (
                    <button
                      onClick={() => {
                        saveAsDraft();
                      }}
                      className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      {t("eventForm:saveAsDraft")}
                    </button>
                  )}
                  <div
                    onClick={() => {
                      onNextPage();
                    }}
                    className="bg-primary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  >
                    {t("eventForm:next")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RightRuleModal
        isOpen={rulesModal}
        setIsOpen={setRulesModal}
        rule={selectedRule}
      />
      <QuestionModal
        open={isOpenModal}
        setOpen={setIsOpenModal}
        header={t("utils:warning")}
        question={t("eventForm:newVenueArtistWarning")}
        noText={t("utils:cancel")}
        yesText={t("eventForm:saveAsDraft")}
        onClickYes={() => {
          saveAsDraft(targetUrl);
        }}
      />
    </section>
  );
}

export default Details;
