import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormInput, GenericButton } from "../../../components";
import { HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import moment from "moment";
import instance from "../../../lib/client";
import { Switch } from "@headlessui/react";
import Select from "react-select";

const defaultValues = {
  name: "",
  value: "",
  quantity: "",
  total_stock: "",
  count_max: 1,
  ticketType: null,
  ticketTypeName: "",
  start_time: moment().toDate(),
  end_time: moment().toDate(),
  startNow: true,
  newFark: 0,
  isActive: false,
  promotions: [],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const defaultTicketTypes = [
  {
    label: "Super Early Bird",
    value: "Super Early Bird",
  },
  {
    label: "Early Bird",
    value: '"Early Bird"',
  },
  {
    label: "1st Phase",
    value: "1st Phase",
  },
  {
    label: "2nd Phase",
    value: "2nd Phase",
  },
  {
    label: "3rd Phase",
    value: "3rd Phase",
  },
  {
    label: "Super Early Bird Backstage",
    value: "Super Early Bird Backstage",
  },
  {
    label: "Early Bird Backstage",
    value: "Early Bird Backstage",
  },
  {
    label: "Backstage",
    value: "Backstage",
  },
];

const TicketForm = ({
  isOpen,
  setIsOpen,
  handleData,
  ticket,
  eventId,
  setSelectedTicket,
}) => {
  const [ticketNew, setTicketNew] = useState(defaultValues);
  const [promotionList, setPromotionList] = useState([]);
  const { t } = useTranslation();

  const onSubmit = (data) => {
    if (ticket?.realId) {
      updateTicket();
    } else {
      createTicket();
    }
  };

  const deleteTicket = () => {
    instance.post(`event/delete-ticket/${eventId}`, ticket).then((res) => {
      setTicketNew(defaultValues);
      handleData();
      setIsOpen(false);
      setSelectedTicket(null);
    });
  };

  const stopStartSale = () => {
    ticket.isActive = !ticket.isActive;

    instance
      .post(`event/update-ticket-status/${eventId}`, ticket)
      .then((res) => {
        setTicketNew(defaultValues);
        handleData();
        setIsOpen(false);
      });
  };

  const createTicket = () => {
    if (
      !ticketNew.quantity ||
      !ticketNew.count_max ||
      !ticketNew.value ||
      (!ticketNew.name && ticketNew.ticketTypeName === null)
    ) {
    } else {
      ticketNew.name = ticketNew.ticketTypeName?.label
        ? ticketNew.ticketTypeName?.label
        : ticketNew.name;

      ticketNew.startNow = ticketNew.isActive;

      instance
        .post(`event/add-ticket/${eventId}`, ticketNew)
        .then((res) => {
          setTicketNew(defaultValues);
          handleData();
          setIsOpen(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const updateTicket = () => {
    ticketNew.name = ticketNew.ticketTypeName?.label
      ? ticketNew.ticketTypeName?.label
      : ticketNew.name;
    instance
      .post(`event/update-ticket/${eventId}`, ticketNew)
      .then((res) => {
        setTicketNew(defaultValues);
        handleData();
        setIsOpen(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (ticket) {
      setTicketNew({
        name: ticket.name,
        value: ticket.value,
        quantity: ticket.quantity,
        total_stock: String(ticket.total_stock ? ticket.total_stock : 0),
        count_max: ticket.count_max ? String(ticket.count_max) : "9",
        start_time: ticket.start_time,
        end_time: ticket.end_time,
        newFark: 0,
        isActive: ticket.isActive,
        startNow: moment(ticket.start_time) < moment() ? true : false,
        realId: ticket.realId,
        promotions: ticket?.promotions,
      });
    }
  }, [ticket, isOpen]);

  const onClose = () => {
    setSelectedTicket(null);
    setTicketNew(defaultValues);
    setIsOpen(false);
  };

  const handlePromotionList = async () => {
    const response = await instance.get("promotion");
    setPromotionList(response.data.data);
  };

  useEffect(() => {
    handlePromotionList();
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={onClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-[350px]">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        {ticket?.realId
                          ? t("eventTickets:updateTicket")
                          : t("eventTickets:addNewTicket")}
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => onClose(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <HiOutlineX className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <div className="absolute inset-0 px-4 sm:px-6">
                      <div class="space-y-4">
                        <div className="flex-1 flex flex-col justify-between">
                          <div className="space-y-3 pb-5 flex flex-col w-full">
                            <div>
                              <>
                                <label
                                  className={`ml-0.5 block text-sm font-medium  ${"text-gray-700"}`}
                                >
                                  {t("ticket:ticketType")}
                                </label>
                                <Select
                                  className="text-sm"
                                  options={defaultTicketTypes}
                                  placeholder={t("ticket:ticketType")}
                                  value={
                                    ticketNew.ticketTypeName
                                      ? ticketNew.ticketTypeName
                                      : defaultTicketTypes.map(
                                          (item) =>
                                            item.label === ticketNew.name &&
                                            item
                                        )
                                  }
                                  onChange={(data) =>
                                    setTicketNew({
                                      ...ticketNew,
                                      ticketTypeName: data,
                                      name: "",
                                    })
                                  }
                                />
                                <span className="text-sm text-gray-400 flex w-full items-center justify-center py-1">
                                  {t("utils:or")}
                                </span>
                              </>

                              <div className="mt-1">
                                <label
                                  className={`ml-0.5 block text-sm font-medium  ${"text-gray-700"}`}
                                >
                                  {t("ticket:addTicketType")}
                                </label>
                                <FormInput
                                  type="text"
                                  id="name"
                                  value={ticketNew?.name}
                                  onChange={(e) =>
                                    setTicketNew({
                                      ...ticketNew,
                                      name: e.target.value,
                                      ticketTypeName: "",
                                    })
                                  }
                                  placeholder={t("ticket:addTicketType")}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="mt-1">
                                <label
                                  className={`ml-0.5 block text-sm font-medium  ${"text-gray-700"}`}
                                >
                                  {t("eventAddTicket:price")}
                                </label>
                                <FormInput
                                  type="number"
                                  name="count"
                                  id="count"
                                  min={0}
                                  value={ticketNew?.value}
                                  onChange={(e) =>
                                    setTicketNew({
                                      ...ticketNew,
                                      value: e.target.value,
                                    })
                                  }
                                  placeholder={t("eventAddTicket:price")}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="mt-1">
                                <label
                                  className={`ml-0.5 block text-sm font-medium  ${"text-gray-700"}`}
                                >
                                  {ticket?.realId
                                    ? t("eventAddTicket:quantity")
                                    : t("ticket:totalStock")}
                                </label>
                                <FormInput
                                  type="number"
                                  name="quantity"
                                  id="quantity"
                                  min={0}
                                  value={ticketNew?.quantity}
                                  onChange={(v) => {
                                    if (!ticket?.realId) {
                                      setTicketNew({
                                        ...ticketNew,
                                        quantity: String(v.target.value),
                                        total_stock: String(v.target.value),
                                      });
                                    } else {
                                      let newFark = 0;
                                      let newTotalStock = 0;
                                      if (
                                        Number(v.target.value) >
                                        Number(ticket.quantity)
                                      ) {
                                        newFark =
                                          Number(v.target.value) -
                                          Number(ticket.quantity);
                                        newTotalStock =
                                          Number(v.target.value) -
                                          Number(ticket.quantity) +
                                          Number(ticket.total_stock);
                                      } else if (
                                        Number(v.target.value) <
                                        Number(ticket.quantity)
                                      ) {
                                        newFark =
                                          Number(v.target.value) -
                                          Number(ticket.quantity);
                                        newTotalStock =
                                          Number(v.target.value) -
                                          Number(ticket.quantity) +
                                          Number(ticket.total_stock);
                                      } else {
                                        newTotalStock = ticket.total_stock;
                                      }

                                      setTicketNew({
                                        ...ticketNew,
                                        total_stock: newTotalStock,
                                        newFark: newFark,
                                        quantity: v.target.value,
                                      });
                                    }
                                  }}
                                  placeholder={
                                    ticket?.realId
                                      ? t("eventAddTicket:quantity")
                                      : t("ticket:totalStock")
                                  }
                                />
                              </div>
                            </div>
                            {ticket?.realId && (
                              <div>
                                <div className="mt-1">
                                  <label
                                    className={`ml-0.5 block text-sm font-medium  ${"text-gray-700"}`}
                                  >
                                    {t("ticket:totalStock")}
                                  </label>
                                  <FormInput
                                    placeholder={t("ticket:totalStock")}
                                    min={0}
                                    value={
                                      ticketNew.total_stock
                                        ? ticketNew.total_stock
                                        : 0
                                    }
                                    disabled={true}
                                    label={
                                      ticketNew.total_stock
                                        ? t("ticket:totalStock")
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            )}

                            <div>
                              <label className="text-gray-500 text-md font-bold flex w-full justify-center">
                                {`${t("eventAddTicket:maxSale")}`}
                                {" : "}
                                {ticketNew.count_max}
                              </label>
                              <FormInput
                                type="range"
                                value={ticketNew.count_max}
                                min={1}
                                max={9}
                                onChange={(e) =>
                                  setTicketNew({
                                    ...ticketNew,
                                    count_max:
                                      parseInt(e.target.value) > 9
                                        ? 9
                                        : e.target.value,
                                  })
                                }
                                placeholder={t("eventAddTicket:maxSale")}
                                className={
                                  "text-input form-control bg-white shadow-none"
                                }
                              />
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {t("eventTickets:selectPromotion")}
                              </label>
                              <div className="mt-1">
                                <Select
                                  className="text-sm"
                                  options={promotionList.map((item) => ({
                                    label: item.name,
                                    value: item.key,
                                  }))}
                                  placeholder={t(
                                    "eventTickets:selectPromotion"
                                  )}
                                  value={
                                    ticketNew?.promotions?.length > 0
                                      ? {
                                          label: promotionList.find(
                                            (item) =>
                                              item.key ===
                                              ticketNew?.promotions[0]
                                          )?.name,
                                          value: ticketNew?.promotions[0],
                                        }
                                      : null
                                  }
                                  onChange={(e) => {
                                    setTicketNew({
                                      ...ticketNew,
                                      promotions: [e.value],
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            {!ticketNew?.realId && (
                              <label className="text-md font-semibold flex flex-row justify-between w-full">
                                {t("utils:startNow")}

                                <Switch
                                  checked={ticketNew.isActive}
                                  onChange={(value) =>
                                    setTicketNew({
                                      ...ticketNew,
                                      isActive: !ticketNew.isActive,
                                    })
                                  }
                                  className={classNames(
                                    ticketNew.isActive
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  )}
                                >
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      ticketNew.isActive
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                    )}
                                  />
                                </Switch>
                              </label>
                            )}

                            <GenericButton
                              onClick={() => onSubmit()}
                              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                            >
                              {ticket?.realId
                                ? t("utils:save")
                                : t("utils:add")}
                            </GenericButton>
                            {ticket?.realId && (
                              <>
                                <GenericButton
                                  onClick={() => stopStartSale()}
                                  className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                                    ticket.isActive
                                      ? "bg-primary-500"
                                      : "bg-fourth-500"
                                  } hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`}
                                >
                                  {ticket.isActive
                                    ? t("eventAddTicket:stopSale")
                                    : t("eventAddTicket:startSale")}
                                </GenericButton>
                                <GenericButton
                                  onClick={() => deleteTicket()}
                                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-fourth-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                >
                                  {t("utils:delete")}
                                </GenericButton>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default TicketForm;

/* 
TIMES
 <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {t("utils:start_date")}
                              </label>
                              <div className="mt-1">
                                <FormInput
                                  type="date"
                                  name="start_date"
                                  id="start_date"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {t("utils:start_time")}
                              </label>
                              <div className="mt-1">
                                <FormInput
                                  type="time"
                                  name="start_time"
                                  id="start_time"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {t("utils:end_date")}
                              </label>
                              <div className="mt-1">
                                <FormInput
                                  type="date"
                                  name="end_date"
                                  id="end_date"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="project-name"
                                className="block text-sm font-medium text-gray-900"
                              >
                                {t("utils:end_time")}
                              </label>
                              <div className="mt-1">
                                <FormInput
                                  type="time"
                                  name="end_time"
                                  id="end_time"
                                />
                              </div>
                            </div>
*/
