import React, { useEffect, useState } from "react";
import { GenericButton } from "../../components";
import instance from "../../lib/client";
import DeviceBlock from "./components/DeviceBlock";
import toastPush from "../../utils/toastPush";
import { useTranslation } from "react-i18next";

function ActiveDevices() {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);

  const logoutAll = () => {
    instance.get("auth/logout-all").then(() => {
      toastPush(t("notifyMessages:logoutedAll"));
      handleSessions();
    });
  };

  const handleSessions = () => {
    instance.get("auth/devices").then(({ data }) => {
      setDevices(data.data);
    });
  };

  useEffect(() => {
    handleSessions();
  }, []);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                <div className="flex flex-row justify-end">
                  <GenericButton onClick={logoutAll}>
                    Tüm Cihazlardan Çık
                  </GenericButton>
                </div>
                <div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
                  <div className="divide-y divide-gray-200">
                    {devices.map((device, i) => (
                      <DeviceBlock key={i} device={device} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ActiveDevices;
