import React from "react";
import { HiCheck, HiMinusCircle } from "react-icons/hi";
import useStore from "../../../hooks/useStores";

const TicketBlock = ({ ticket, event }) => {
  const { modal } = useStore();
  return (
    <div
      key={ticket._id}
      onClick={() => {
        modal.triggerTicketModal({
          ticket: ticket,
          event: event,
        });
      }}
    >
      <div
        className="block"
        style={
          ticket.isRefundList
            ? { backgroundColor: "rgb(239, 97, 79)" }
            : ticket.scanned
            ? { backgroundColor: "rgb(181,248,167)" }
            : null
        }
      >
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-600 flex-1 cursor-pointer">
              <div>
                <p
                  className="text-sm font-medium text-black truncate"
                  style={ticket.isRefundList ? { color: "white" } : null}
                >
                  {ticket.name} {ticket.last_name}
                </p>
              </div>
            </div>

            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4 hidden md:flex-1">
              <div>
                <p className="text-sm font-medium text-indigo-600"></p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <span className="truncate"></span>
                </p>
              </div>
            </div>
            <p className="text-sm truncate ml-4 text-white mr-4 bg-primary-500 px-2 py-0.5 rounded-md font-bold">
              {ticket.selected_ticket_type}
            </p>
            <p className="flex items-center text-sm text-gray-500 ">
              {ticket?.scanned ? (
                <div
                  className="bg-white rounded-full h-7 w-7 flex items-center mr-2 justify-center"
                  style={{ opacity: ticket.isRefundList ? 0 : 1 }}
                >
                  <HiCheck
                    className="h-6 w-6 text-primary-500"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <HiMinusCircle
                  className="flex-shrink-0 mr-1.5 h-8 w-8 text-red-400"
                  aria-hidden="true"
                  style={{ opacity: ticket.isRefundList ? 0 : 1 }}
                />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBlock;
