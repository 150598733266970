import React, { useEffect } from "react";
import { LoginInput, GenericButton } from "../../../../components/index";
import instance from "../../../../lib/client";
import authService from "../../../../services/authService";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "../../../../components/Loading/Loading";
import useStore from "../../../../hooks/useStores";
import { toast } from "react-toastify";
import i18n from "../../../../services/i18n";

import Appstore from "../../../../assets/images/appstore.png";
import Googleplay from "../../../../assets/images/playstore.png";

const Login = () => {
  const { t } = useTranslation();
  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-left",
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-left",
    });
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);
  const { auth } = useStore();
  const history = useHistory();

  useEffect(() => {
    if (auth.isLogged) {
      history.push("/auth/choose");
    }
  }, [history, auth.isLogged]);

  const onSubmit = (data) => {
    setLoading(true);
    instance
      .post("auth/login", data)
      .then(({ data: { data } }) => {
        authService.setAuthorizationToken(data.access_token);
        auth.setUser(data);
        auth.setInitialAccessToken(data.access_token);
        auth.setAccessToken(data.access_token);
        setLoading(false);
        history.push(`/auth/choose`, { accounts: data.accounts });
      })
      .catch((err) => {
        notifyError("Kullanıcı adı veya şifre yanlış!");
        setLoading(false);
      });
  };
  if (loading)
    return (
      <div
        style={{
          minHeight: 542,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loading text="Yönlendiriliyorsunuz" />
      </div>
    );
  else
    return (
      <>
        <div
          className={
            "absolute md:hidden xs:flex right-0 bottom-0  md:w-auto xs:w-full "
          }
        >
          <a
            href={"https://apps.apple.com/tr/app/bugece-pro/id1578565996"}
            target={"_blank"}
          >
            <img
              src={Appstore}
              className={
                "md:bottom-[40px] xs:bottom-[-20px] md:r-0 md:b-0 absolute w-[190px]"
              }
            />
          </a>

          <a
            href={`https://play.google.com/store/apps/details?id=com.bgpro&gl=TR`}
            target={"_blank"}
          >
            <img
              src={Googleplay}
              className="xs:right-0 xs:bottom-0 md:flex xs:absolute"
              style={{
                height: 65,
                zIndex: 1,
              }}
            />
          </a>
        </div>
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mt-8">
            <div className="mt-6">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                <LoginInput
                  label={t("auth:email")}
                  name="email"
                  autoComplete="email"
                  {...register("email", { required: true })}
                />
                <LoginInput
                  label={t("auth:password")}
                  name="password"
                  autoComplete="current-password"
                  type="password"
                  containerClassName="space-y-1"
                  {...register("password", { required: true })}
                />
                <div className="flex items-center justify-between">
                  <div className="text-sm ml-auto">
                    <a
                      href={`https://www.bugece.co/${i18n.language}/forgot-password`}
                      target="_blank"
                      className="font-medium text-secondary-600 hover:text-secondary-500"
                    >
                      {t("auth:forgotPassword")}
                    </a>
                  </div>
                </div>
                <div>
                  <GenericButton type="submit">
                    {t("auth:signIn")}
                  </GenericButton>
                </div>
                <div className={"flex justify-center "}>
                  <a
                    href={`https://bugece.co/${i18n.language}/signup`}
                    target="_blank"
                    className="font-medium text-sm text-secondary-600 hover:text-secondary-500 "
                  >
                    {t("auth:signUp")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
};

export default Login;
