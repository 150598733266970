import axios from "axios";
import authService from "../services/authService";

const benufoUrl = process.env.REACT_APP_BENUFO_URL + "/";
const baracUrl = process.env.REACT_APP_BARAC_URL + "/";
// const api = "http://localhost:3000/v1";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
  undefined,
  authService.setUnauthorizedInterceptor
);

export { benufoUrl, baracUrl };

export default instance;
