import React from "react";

const FormSocialInput = React.forwardRef(
  ({ className, socialTag, ...rest }, ref) => {
    return (
      <div className="flex items-center">
        <div
          className="
        border border-gray-300 rounded-l-md shadow-sm py-2 px-3 min-w-[225px] mt-1 bg-gray-50 
        "
        >
          <label className="block text-sm font-medium text-gray-700">
            {socialTag}
          </label>
        </div>
        <input
          ref={ref}
          autoComplete="given-name"
          className={`mt-1 block w-full border border-l-0 rounded-l-none border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${className}`}
          {...rest}
        />
      </div>
    );
  }
);

export default FormSocialInput;
