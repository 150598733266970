/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance, { baracUrl } from "../../lib/client";
import useStore from "../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton } from "../../components";
import autoAnimate from "@formkit/auto-animate";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useWatch } from "react-hook-form";
import Select from "react-select";
import { Link } from "react-router-dom";
import moment from "moment";
import i18n from "../../services/i18n";
import axios from "axios";

const SignupSchema = yup.object().shape({
  name: yup.string().required(),
  last_name: yup.string().required(),
  birth_day: yup.object().required(),
  birth_month: yup.object().required(),
  birth_year: yup.object().required(),
  gender: yup.string().required(),
  password: yup.string().required(),
  password2: yup.string().required(),
  country_code: yup.object().required(),
  phone_number: yup.string().required(),
  accept_terms: yup
    .boolean()
    .oneOf([true], "Must Accept Terms and Conditions")
    .required(),
});

const MemberSignupModal = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dayList, setDayList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const parent = useRef(null);

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-left",
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-left",
    });

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      name: "",
      last_name: "",
      birth_day: "",
      birth_month: "",
      birth_year: "",
      gender: "",
      password: "",
      password2: "",
      country_code: "",
      phone_number: "",
      accept_terms: false,
    },
  });

  const values = useWatch({
    control: control,
    name: [
      "birth_day",
      "birth_month",
      "birth_year",
      "gender",
      "country_code",
      "phone_number",
      "accept_terms",
    ],
  });

  const phone = useWatch({
    control: control,
    name: "phone_number",
  });

  const getCountryCode = async () => {
    const { data } = await axios.get(baracUrl + "country/country-codes");
    console.log(data);
    setCountryCodes(data?.data);
  };

  const handlePhoneChange = (number) => {
    let val = number;
    val = val.replace(/ /gm, "");

    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
      6,
      8
    )} ${val.substring(8, val.length)}`;

    num = num.trim();

    setValue("phone_number", num);
  };

  useEffect(() => {
    handlePhoneChange(getValues("phone_number"));
  }, [phone]);

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      last_name: data.last_name,
      gender: data.gender,
      password: data.password,
      password2: data.password2,
      birth_day: String(data.birth_day.value),
      birth_month: String(data.birth_month.value),
      birth_year: String(data.birth_year.value),
      phone_country_code: data.country_code.value.replace("+", ""),
      phone_number: data.phone_number.replace(/ /gm, ""),
    };
    console.log(payload);
  };

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const genderList = [
    {
      value: "Male",
      lang: t("signupModal:male"),
    },
    {
      value: "Female",
      lang: t("signupModal:female"),
    },
  ];

  useEffect(() => {
    console.log(errors);
    console.log(values);
  }, [errors, values]);

  useEffect(() => {
    getCountryCode();
    let days = 31;
    let months = 12;
    let years = moment().year() - 18;

    // set days object for dropdown
    let dayList = [];
    for (let i = 1; i <= days; i++) {
      dayList.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    setDayList(dayList);

    // set months object for dropdown
    let monthList = [];
    for (let i = 1; i <= months; i++) {
      monthList.push({
        label: String(
          moment()
            .locale(t("signupModal:activeLang"))
            .startOf("year")
            .add(i - 1, "month")
            .format("MMMM")
        ),
        value: i.toString(),
      });
    }
    setMonthList(monthList);

    // set years object for dropdown
    let yearList = [];
    for (let i = years; i >= 1950; i--) {
      yearList.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    setYearList(yearList);
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => setIsOpen(false)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-visible shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold ">
                {t("signupModal:title")}
              </div>
              <div className="hidden sm:block absolute top-5 left-[25px] pt-4 pr-4 text-xs text-gray-600 ">
                {t("signupModal:subtitle")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-5 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.name ? "text-red-400" : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:name")}
                      </label>
                      <FormInput
                        className={
                          errors?.name &&
                          "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                        }
                        type="text"
                        {...register("name")}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.last_name ? "text-red-400" : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:surname")}
                      </label>
                      <FormInput
                        className={
                          errors?.last_name &&
                          "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                        }
                        type="text"
                        {...register("last_name")}
                      />
                    </div>
                    <div
                      className={`md:col-span-6 xs:col-span-6 -mb-5 ${
                        errors.birth_day ||
                        errors.birth_month ||
                        errors.birth_year
                          ? "text-red-400"
                          : "text-gray-700"
                      }`}
                    >
                      {t("signupModal:birthdate")}
                    </div>
                    <div className="md:col-span-2 xs:col-span-2">
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <div
                            className={`h-fit w-fit ${
                              errors?.birth_day
                                ? "border border-red-400 rounded-md"
                                : "border-none"
                            }`}
                          >
                            <Select
                              placeholder={t("signupModal:day")}
                              defaultValue={[]}
                              value={value}
                              onBlur={onBlur}
                              options={dayList}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        )}
                        name="birth_day"
                        defaultValue=""
                      />
                    </div>
                    <div className="md:col-span-2 xs:col-span-2">
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <div
                            className={`h-fit w-fit ${
                              errors?.birth_month
                                ? "border border-red-400 rounded-md"
                                : "border-none"
                            }`}
                          >
                            <Select
                              placeholder={t("signupModal:month")}
                              defaultValue={[]}
                              value={value}
                              onBlur={onBlur}
                              options={monthList}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        )}
                        name="birth_month"
                        defaultValue=""
                      />
                    </div>
                    <div className="md:col-span-2 xs:col-span-2">
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <div
                            className={`h-fit w-fit ${
                              errors?.birth_year
                                ? "border border-red-400 rounded-md"
                                : "border-none"
                            }`}
                          >
                            <Select
                              placeholder={t("signupModal:year")}
                              defaultValue={[]}
                              value={value}
                              onBlur={onBlur}
                              options={yearList}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        )}
                        name="birth_year"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-span-6">
                      <label
                        className={`text-sm font-medium  ${
                          errors?.gender ? "text-red-400" : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:gender")}
                      </label>

                      <fieldset className="mt-1">
                        <div className="space-y-2 sm:flex sm:items-center sm:space-x-5 sm:space-y-0">
                          {genderList.map((gender) => (
                            <div
                              key={gender.value}
                              className="flex items-center"
                            >
                              <input
                                id={gender.value}
                                name="notification-method"
                                type="radio"
                                onClick={() => {
                                  setValue("gender", gender.value);
                                }}
                                checked={getValues("gender") == gender.value}
                                value={gender.value}
                                className="h-4 w-4 border-gray-300 text-gray-800 focus:ring-gray-800"
                              />
                              <label
                                htmlFor={gender.value}
                                className={`ml-2 block text-sm font-medium leading-6 ${
                                  errors.gender
                                    ? "text-red-400"
                                    : "text-gray-700"
                                }`}
                              >
                                {gender.lang}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.password ? "text-red-400" : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:password")}
                      </label>
                      <FormInput
                        className={
                          errors?.password &&
                          "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                        }
                        type="text"
                        {...register("password")}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.password2 ? "text-red-400" : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:passwordAgain")}
                      </label>
                      <FormInput
                        className={
                          errors?.password2 &&
                          "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                        }
                        type="text"
                        {...register("password2")}
                      />
                    </div>
                    <div className="md:col-span-2 xs:col-span-2">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.country_code
                            ? "text-red-400"
                            : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:countryCode")}
                      </label>

                      <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <div
                            className={`h-fit w-fit mt-1 ${
                              errors?.country_code
                                ? "border border-red-400 rounded-md"
                                : "border-none"
                            }`}
                          >
                            <Select
                              defaultValue={[]}
                              value={value}
                              onBlur={onBlur}
                              options={countryCodes}
                              onChange={onChange}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              inputRef={ref}
                            />
                          </div>
                        )}
                        name="country_code"
                        defaultValue=""
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        className={`block text-sm font-medium  ${
                          errors?.phone_number
                            ? "text-red-400"
                            : "text-gray-700"
                        }`}
                      >
                        {t("signupModal:phoneNumber")}
                      </label>
                      <div className="relative ">
                        <FormInput
                          type="phone"
                          onKeyPress={(e) => {
                            if (e.which < 48 || e.which > 57) {
                              e.preventDefault();
                              console.log("only number");
                            }
                          }}
                          value={phone}
                          onChange={(e) => {
                            handlePhoneChange(e.target.value);
                          }}
                          placeholder={"555 555 55 55"}
                          className={`placeholder-gray-300  ${
                            errors?.phone_number &&
                            "focus:!ring-red-400 focus:!border-red-400 !border-red-300 "
                          }`}
                        />
                      </div>
                    </div>
                    <div
                      className={`relative flex items-start col-span-6 w-full !text-gray-700" ${
                        errors?.accept_terms && "!text-red-400"
                      }`}
                    >
                      <div className="flex h-6 items-center">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          defaultChecked={values.accept_terms}
                          onChange={(e) => {
                            setValue("accept_terms", e.target.checked);
                          }}
                          className={`h-4 w-4 rounded focus:ring-gray-800 text-gray-800 ${
                            errors?.accept_terms
                              ? "border-red-400"
                              : "border-gray-300"
                          }`}
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <a
                          href="https://bugece.co/terms"
                          target={"_blank"}
                          className=" font-bold underline"
                        >
                          Kullanım Koşullarını
                        </a>{" "}
                        ve{" "}
                        <a
                          href="https://bugece.co/privacy"
                          target={"_blank"}
                          className=" font-bold underline"
                        >
                          Gizlilik Politikasını
                        </a>{" "}
                        okudum ve kabul ediyorum.
                      </div>
                    </div>
                    <div className="col-span-6">
                      <GenericButton
                        className={
                          " !border !bg-transparent !border-gray-800 focus:!ring-gray-800 flex justify-center items-center !text-gray-800 w-full"
                        }
                        background="primary"
                        type="submit"
                        style={{ paddingVertical: 5, marginTop: -4 }}
                        textBold
                      >
                        {loading ? (
                          <div class=" flex justify-center items-center  transform">
                            <div class="border-t-transparent border-solid animate-spin  rounded-full border-gray-800 border h-6 w-6"></div>
                          </div>
                        ) : (
                          t("signupModal:register")
                        )}
                      </GenericButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MemberSignupModal;
