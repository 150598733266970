import React, { useState, useCallback } from "react";
import { GenericButton, Loading } from "../../../../../components";
import instance from "../../../../../lib/client";
import { useTranslation } from "react-i18next";
import moment from "moment";
import toastPush from "../../../../../utils/toastPush";
import ExcelGuestItem from "./ExcelGuestItem";
import { useDropzone } from "react-dropzone";
import { AiOutlineFileExcel } from "react-icons/ai";

const ExcelGuest = ({ setIsOpen, eventId }) => {
  const { t } = useTranslation();
  const [guests, setGuests] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setLoading(true);
    const formData = new FormData();

    formData.append("file", acceptedFiles[0]);

    instance({
      method: "post",
      url: "file/upload",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then(async (res) => {
      // URL res.data.data.url

      const { data } = await instance.post(`/guest/add-multiple/${eventId}`, {
        data: res.data.data.url,
        type: "file",
      });

      if (data?.success || data?.data?.success) {
        setGuests(data.data.guests);
        setLoading(false);
      }
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onApproved = async () => {
    const { data } = await instance.post(`/guest/add-multiple-confirm`, {
      guests: guests,
    });
    if (data.data.success) {
      setIsOpen(false);
      toastPush(t("guests:created"));
    }
  };

  return (
    <div className="mt-4 relative flex-1 px-4 sm:px-6">
      <div className="absolute inset-0 px-4 sm:px-6">
        <div
          style={{
            marginTop: 15,
            paddingHorizontal: 16,
            height: "95%",
          }}
        >
          {guests
            ? guests.map((item) => (
                <ExcelGuestItem
                  item={item}
                  guests={guests}
                  setGuests={setGuests}
                />
              ))
            : null}
          {guests.length < 1 ? (
            <>
              {loading ? (
                <Loading style={{ marginTop: 48 }} />
              ) : (
                <div className="h-36 flex items-center border-dashed border-2 border-primary-500 p-4 rounded-lg ">
                  <div
                    {...getRootProps()}
                    className={
                      "w-full flex items-center justify-between text-gray-600"
                    }
                  >
                    <AiOutlineFileExcel
                      size={36}
                      color={"gray"}
                      className={"mr-2"}
                    />
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div className="w-52">{t("guests:excelGuestDrop")}</div>
                    ) : (
                      <div className="w-52">{t("guests:excelGuestDrag")}</div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <GenericButton
              onClick={onApproved}
              type="file"
              class="w-full flex justify-center py-2 px-4 my-[25px] border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {t("guests:addTitle")}
            </GenericButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcelGuest;
