import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { EventDetails, Loading, TabNav } from "..";
import useStore from "../../hooks/useStores";
import instance from "../../lib/client";
import {
  Coupons,
  Guests,
  Tickets,
  VenueReport,
} from "../../pages/EventDetail/components";
import i18n from "../../services/i18n";
import classNames from "../../utils/classNames";

const DetailSide = ({ id, slug, defaultSelectedTab = 0 }) => {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const [details, setDetails] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [openScanner, setOpenScanner] = useState(false);
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [genders, setGenders] = useState([]);
  const { t } = useTranslation();
  const { modal, roles } = useStore();
  const history = useHistory();

  const rightTabs = [
    { name: t("sideTab:event"), href: "#", current: true, visible: true },
    { name: t("sideTab:overview"), href: "#", current: true, visible: true },
    {
      name: t("sideTab:tickets"),
      href: `/dashboard/tickets/${id}`,
      current: false,
      tag: "eventTickets",
      visible: true,
    },
    {
      name: t("sideTab:coupons"),
      href: "#",
      current: true,
      visible: true,
      tag: "eventCouponList",
    },
    {
      name: t("sideTab:guests"),
      href: "#",
      current: true,
      visible: true,
      tag: "eventGuests",
    },
    {
      name: t("sideTab:guests"),
      href: `/dashboard/guests/${id}?slug=${slug}`,
      current: false,
      visible: false,
    },
  ];

  useEffect(() => {
    if (!slug) history.push("/dashboard");
    instance.get(`event/${slug}`).then(({ data }) => {
      setDetails(data?.data);
      Promise.all([
        instance.get(`/report/ticket/${id}`),
        instance.get(`/sale/active-tickets/${id}`),
        instance.get(`/report/gender/${id}`),
      ]).then(([tickets, sales, genders]) => {
        setTickets(tickets?.data?.data);
        setSales(sales?.data?.data);
        setGenders(genders?.data?.data);
        setLoading(false);
      });
    });
  }, [slug, history]);

  const returnCurrentTab = () => {
    switch (selectedTab) {
      case 0:
        return (
          <EventDetails
            details={details}
            buttonsVisible={true}
            setDetails={setDetails}
          />
        );
      case 1:
        return <EventDetails
          details={details}
          buttonsVisible={true}
          setDetails={setDetails}
        />;
      case 2:
        return (
          <Tickets
            tickets={sales?.tickets}
            id={details._id}
            slug={details.slug}
          />
        );
      case 3:
        return <Coupons eventId={id} slug={details.slug} />;
      case 4:
        return (
          <Guests
            eventId={details._id}
            href={`/dashboard/guests/${id}?slug=${slug}`}
          />
        );
      default:
        return <div></div>;
    }
  };

  if (loading)
    return (
      <div className="p-16 bg-white rounded-md">
        <Loading />
      </div>
    );
  else
    return (
      <section aria-labelledby="section-2-title">
        <h2 className="sr-only" id="section-2-title">
          Son Biletler
        </h2>

        <div className="rounded-lg bg-white overflow-hidden shadow">
          <div
            className={classNames(
              selectedTab === 0
                ? "text-gray-900"
                : "text-gray-500 hover:text-gray-700",
              selectedTab === 0 ? "rounded-l-lg" : "",
              selectedTab === rightTabs.length - 1 ? "rounded-r-lg" : "",
              "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer"
            )}
            onClick={() => {
              history.push("/dashboard/event/detail/" + slug);
              setSelectedTab(0);
            }}
            aria-current={selectedTab === 0 ? "page" : undefined}
          >
            <span>
              {details.name
                ? i18n.language === "tr"
                  ? details.name + " Detayları"
                  : details.name + " Details"
                : t("eventForm:eventDetails")}
            </span>
            <span
              aria-hidden="true"
              className={classNames(
                selectedTab === 0 ? "bg-primary-500" : "bg-transparent",
                "absolute inset-x-0 bottom-0 h-0.5"
              )}
            />
          </div>
          <TabNav
            tabs={rightTabs.splice(1)}
            onSelect={setSelectedTab}
            selected={selectedTab}
          />
          <div className="p-6">
            {loading ? <Loading /> : returnCurrentTab()}
          </div>
        </div>
      </section>
    );
};

export default DetailSide;
