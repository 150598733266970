import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { AiFillCheckCircle } from "react-icons/ai";
import { GenericButton } from "../../../../components";
import { useTranslation } from "react-i18next";
import instance from "../../../../lib/client";
import authService from "../../../../services/authService";
import Loading from "../../../../components/Loading/Loading";
import useStore from "../../../../hooks/useStores";

const ChooseAccount = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(0);
  const [accounts, setAccounts] = React.useState([]);
  const { auth } = useStore();

  React.useEffect(() => {
    setLoading(true);
    if (auth.user && auth.isLogged) {
      instance
        .get("/user/accounts", {
          headers: {
            defaults: {
              headers: {
                Authorization: auth.initialAccessToken,
                common: {
                  Authorization: auth.initialAccessToken,
                },
              },
            },
          },
        })
        .then(({ data }) => {
          setAccounts(data.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
      setAccounts(auth.user.accounts);
    } else if (!location.state) history.push("/auth/login");
    else setAccounts(location.state.accounts);
  }, [
    auth.user,
    location.state,
    history,
    auth.isLogged,
    auth.initialAccessToken,
  ]);

  const continueState = () => {
    setLoading(true);
    instance
      .post("auth/claim-token", {
        promoter: accounts[selectedAccount]._id,
      })
      .then(({ data }) => {
        authService.setAuthorizationToken(data.data.access_token);
        auth.setAccessToken(data.data.access_token);
        auth.setRoles(data.data.roles);
        auth.setPromoter(accounts[selectedAccount]);
        setLoading(false);
        history.push("/dashboard");
      });
  };

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96 mt-8">
      {loading ? (
        <div
          style={{
            minHeight: 542,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading text={"Yönlendiriliyorsunuz..."} />
        </div>
      ) : (
        <div>
          <div
            style={{
              maxHeight: 550,
              overflowY: "auto",
            }}
          >
            {accounts.map((person, i) => {
              return (
                <div
                  key={i}
                  className={`relative rounded-lg border ${
                    selectedAccount === i
                      ? "border-primary-500"
                      : "border-gray-300"
                  } bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 mb-4 cursor-pointer`}
                  onClick={() => setSelectedAccount(i)}
                >
                  <div className="flex-shrink-0">
                    {person.image ? (
                      <img
                        className="h-10 w-10 rounded-full"
                        src={person.image}
                        alt=""
                      />
                    ) : (
                      <div className="h-10 w-10 rounded-full bg-primary-500" />
                    )}
                  </div>
                  <div className="flex-1 min-w-0">
                    <span className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">
                        {person.name}
                      </p>
                    </span>
                  </div>
                  <div>
                    {selectedAccount === i && (
                      <AiFillCheckCircle
                        fill={"#ffc800"}
                        style={{ height: 30, width: 30 }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <GenericButton onClick={() => continueState()} className={"py-4"}>
            {t("auth:continue")}
          </GenericButton>
        </div>
      )}
    </div>
  );
};

export default ChooseAccount;
