import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Switch, Transition } from "@headlessui/react";
import { HiOutlineX } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { FormInput } from "..";
import moment from "moment";
import Select from "react-select";
import instance from "../../lib/client";

const defaultValues = {
  name: "",
  value: "",
  quantity: "",
  total_stock: "",
  count_max: 1,
  ticketType: null,
  ticketTypeName: "",
  start_time: moment().toDate(),
  end_time: moment().toDate(),
  newFark: 0,
  isActive: false,
  promotions: [],
};

const defaultTicketTypes = [
  {
    label: "Super Early Bird",
    value: "Super Early Bird",
  },
  {
    label: "Early Bird",
    value: '"Early Bird"',
  },
  {
    label: "1st Phase",
    value: "1st Phase",
  },
  {
    label: "2nd Phase",
    value: "2nd Phase",
  },
  {
    label: "3rd Phase",
    value: "3rd Phase",
  },
  {
    label: "Super Early Bird Backstage",
    value: "Super Early Bird Backstage",
  },
  {
    label: "Early Bird Backstage",
    value: "Early Bird Backstage",
  },
  {
    label: "Backstage",
    value: "Backstage",
  },
];

const RightTicketModal = ({
  isOpen,
  setIsOpen,
  setValue,
  getValues,
  conf,
  setConf,
  confTicket,
  setConfTicket,
  isInForm,
}) => {
  const { t } = useTranslation();
  const [items, setItems] = useState(defaultValues);
  const [promotionList, setPromotionList] = useState([]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  useEffect(() => {
    if (conf) {
      var a = getValues("price_list");
      setItems(a[confTicket]);
    } else {
      setItems(defaultValues);
    }
  }, [confTicket, setConfTicket, conf, getValues]);

  const onSubmit = (data) => {
    var a;
    if (!conf) {
      items.name = items.ticketTypeName?.label
        ? items.ticketTypeName?.label
        : items.name;

      setIsOpen(false);
      a = getValues("price_list");
      a.push(data);
      setValue("price_list", a);
      setItems(defaultValues);
      setConfTicket(null);
      setConf(false);
    } else if (isInForm) {
    } else {
      items.name = items.ticketTypeName?.label
        ? items.ticketTypeName?.label
        : items.name;
      setIsOpen(false);
      a = getValues("price_list");
      a[confTicket] = data;
      setValue("price_list", a);
      setConfTicket(null);
      setConf(false);
      setItems(defaultValues);
    }
  };

  const handlePromotionList = async () => {
    const response = await instance.get("promotion");
    setPromotionList(response.data.data);
  };

  useEffect(() => {
    handlePromotionList();
  }, []);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setIsOpen(false);

          if (!isInForm) {
            setConf(false);
            setConfTicket(null);
            setItems(defaultValues);
          }
        }}
      >
        <div className="absolute inset-0 overflow-hidden ">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-[350px]">
                <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                  <div className="flex-1 h-0 overflow-y-auto">
                    <div className="py-6 px-4 bg-primary-500 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-white">
                          {t("eventTickets:addNewTicket")}
                        </Dialog.Title>

                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md text-white hover:text-white "
                            onClick={() => {
                              setIsOpen(false);

                              if (!isInForm) {
                                setConf(false);
                                setConfTicket(null);
                                setItems(defaultValues);
                              }
                            }}
                          >
                            <span className="sr-only">Close panel</span>
                            <HiOutlineX
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex-1 flex flex-col justify-between">
                      <div className="px-4 divide-y divide-gray-200 sm:px-6">
                        <div className="space-y-2 pt-6 pb-5">
                          <Select
                            className="text-sm"
                            options={defaultTicketTypes}
                            placeholder={t("ticket:ticketType")}
                            value={items.ticketTypeName}
                            onChange={(e) =>
                              setItems({
                                ...items,
                                ticketTypeName: e,
                                name: "",
                              })
                            }
                          />
                          <span className="text-sm text-gray-400 flex w-full items-center justify-center py-1">
                            {t("utils:or")}
                          </span>
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {t("ticket:addTicketType")}
                            </label>
                            <div className="mt-1">
                              <FormInput
                                type="text"
                                name="name"
                                id="name"
                                value={items.name}
                                onChange={(e) =>
                                  setItems({
                                    ...items,
                                    name: e.target.value,
                                    ticketTypeName: "",
                                  })
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {t("eventAddTicket:price")}
                            </label>
                            <div className="mt-1">
                              <FormInput
                                type="number"
                                name="value"
                                min={0}
                                value={items.value}
                                onChange={(e) =>
                                  setItems({
                                    ...items,
                                    value: parseFloat(e.target.value),
                                  })
                                }
                                id="value"
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {t("ticket:totalStock")}
                            </label>
                            <div className="mt-1">
                              <FormInput
                                type="number"
                                name="count"
                                id="count"
                                min={0}
                                value={items.quantity}
                                onChange={(e) => {
                                  let value = parseInt(e.target.value);
                                  setItems({
                                    ...items,
                                    total_stock: e.target.value,
                                    quantity: e.target.value,
                                    count_max:
                                      value >= 1
                                        ? value >= items.count_max
                                          ? items.count_max
                                          : 1
                                        : 0,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {t("eventAddTicket:maxSale")}
                            </label>
                            <div>
                              <label className="text-gray-500 text-md font-bold flex w-full justify-center">
                                {items.count_max}
                              </label>
                              <FormInput
                                type="range"
                                value={items.count_max}
                                disabled={items.total_stock < 1}
                                min={1}
                                max={
                                  items.total_stock < 10 ? items.total_stock : 9
                                }
                                onChange={(e) =>
                                  setItems({
                                    ...items,
                                    count_max:
                                      parseInt(e.target.value) > 9
                                        ? 9
                                        : e.target.value,
                                  })
                                }
                                placeholder={t("eventAddTicket:maxSale")}
                                className={
                                  "text-input form-control bg-white shadow-none"
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="project-name"
                              className="block text-sm font-medium text-gray-900"
                            >
                              {t("eventTickets:selectPromotion")}
                            </label>
                            <div className="mt-1">
                              <Select
                                className="text-sm"
                                options={promotionList.map((item) => ({
                                  label: item.name,
                                  value: item.key,
                                }))}
                                placeholder={t("eventTickets:selectPromotion")}
                                value={
                                  items?.promotions?.length > 0
                                    ? {
                                        label: promotionList.find(
                                          (item) =>
                                            item.key === items.promotions[0]
                                        )?.name,
                                        value: items.promotions[0],
                                      }
                                    : null
                                }
                                onChange={(e) => {
                                  setItems({
                                    ...items,
                                    promotions: [e.value],
                                  });
                                }}
                              />
                            </div>
                          </div>
                          {/* <div>
                            <label className="text-md font-semibold flex flex-row justify-between w-full pt-4">
                              1+1 Hediye Promosyon
                              <Switch
                                checked={
                                  items?.promotions.length > 0 ? true : false
                                }
                                onChange={(value) => {
                                  console.log(value);
                                  setItems({
                                    ...items,
                                    promotions: value ? ["extraTicket"] : [],
                                  });
                                }}
                                className={classNames(
                                  items?.promotions.length > 0
                                    ? "bg-indigo-600"
                                    : "bg-gray-200",
                                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    items?.promotions.length > 0
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                  )}
                                />
                              </Switch>
                            </label>
                          </div> */}

                          <label className="text-md font-semibold flex flex-row justify-between w-full pt-4">
                            {t("utils:startNow")}

                            <Switch
                              checked={items.isActive}
                              onChange={(value) => {
                                setItems({
                                  ...items,
                                  isActive: !items.isActive,
                                });
                              }}
                              className={classNames(
                                items.isActive
                                  ? "bg-indigo-600"
                                  : "bg-gray-200",
                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              )}
                            >
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  items.isActive
                                    ? "translate-x-5"
                                    : "translate-x-0",
                                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                )}
                              />
                            </Switch>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setItems(defaultValues);
                        setIsOpen(false);
                      }}
                    >
                      {t("utils:cancel")}
                    </button>
                    <button
                      type="button"
                      onClick={() => onSubmit(items)}
                      className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                      {t("utils:save")}
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RightTicketModal;
