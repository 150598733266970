import moment from "moment";
import numberToMoney from "../../utils/numberToMoney";

const lineChartOptions = (series, categories, legends, text = "") => {
  return {
    title: {
      text: text,
    },
    tooltip: {
      trigger: "axis",
      formatter: (v) => {
        return `${v[0].axisValueLabel}<br/>${v[0].seriesName} : ${
          numberToMoney(v[0].value) + " ₺"
        } `;
      },
      x: {
        show: true,
        formatter: function (value) {
          return moment(value).format("lll");
        },
        z: {
          show: true,
          formatter: function (value) {
            return moment(value).format("lll");
          },
        },
      },
      axisPointer: {
        type: "cross",
        animation: false,

        label: {
          backgroundColor: "#505765",
        },
      },
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      data: legends,
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },

    xAxis: {
      type: "category",
      boundaryGap: false,
      data: categories,
      axisLabel: {
        formatter: function (value) {
          return moment(value).format("DD MMM");
        },
      },
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: { onZero: false },
        data: categories,
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: series,
  };
};

const homeChartOptions = (
  series,
  categories,
  text = "Stacked Area Chart",
  formatter
) => {
  return {
    title: {
      text: text,
    },
    tooltip: {
      trigger: "axis",
      formatter: (v) => {
        return `${moment(v[0].name).format("lll")} <br/>${
          v[0].seriesName
        } : ${numberToMoney(v[0].value)} ₺`;
      },

      axisPointer: {
        type: "cross",
        animation: true,
        label: {
          backgroundColor: "#505765",
          formatter: formatter,
        },
      },
      x: {
        show: true,
        formatter: function (value) {
          return moment(value).format("lll");
        },
        z: {
          show: true,
          formatter: function (value) {
            return moment(value).format("lll");
          },
        },
      },
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 100,
        formatter: function (value) {
          return moment(value).format("lll");
        },
        color: "red",
      },
      {
        start: 0,
        end: 100,
        formatter: function (value) {
          return moment(value).format("lll");
        },
        color: "red",
      },
    ],
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: categories,
      axisLabel: {
        formatter: function (value) {
          return moment(value).format("DD MMM");
        },
      },
    },
    yAxis: {
      type: "value",
    },
    series: series,
  };
};

const multiBarChartData = ({
  series,
  colors,
  name,
  legends,
  tickets,
  dates,
  isMobile,
}) => {
  return {
    title: {
      text: name,
    },
    tooltip: {
      position: isMobile ? ["50%,50%"] : null,
      formatter: function (v) {
        return `${v.seriesName} <br/> Count: ${v.value}`;

        // <br/> Price: ${
        //   numberToMoney(tickets.find((g) => g.name === v.seriesName)?.price) +
        //   " ₺"
        // }
      },
    },
    color: colors,
    dataZoom: [
      {
        show: true,
        start: 0,
        end: 10,
      },
      {
        type: "inside",
        start: 94,
        end: 100,
      },
    ],
    legend: {
      data: legends,
      top: 24,
      type: "scroll",
    },
    colorBy: "series",
    name: "Ticket",
    xAxis: { type: "category", show: true, name: "", data: dates },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: series,
  };
};

const barChartData = ({ series, colors, name, legends, tickets, isMobile }) => {
  return {
    title: {
      text: name,
    },
    tooltip: {
      position: isMobile ? ["50%,50%"] : null,
      formatter: function (v) {
        console.log(v);
        return `${v.seriesName} <br/> Count: ${v.value} <br/> Price: ${
          numberToMoney(tickets.find((g) => g.name === v.seriesName)?.price) +
          " ₺"
        } `;
      },
    },
    color: colors,

    legend: {
      data: legends,
      top: 24,
      type: "scroll",
    },
    colorBy: "series",
    name: "Ticket",
    xAxis: { type: "category", show: false, name: "Tickets" },
    yAxis: {},
    // Declare several bar series, each will be mapped
    // to a column of dataset.source by default.
    series: series,
  };
};

const pieChartData = ({ series }) => {
  return {
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        console.log(params);
        return `
               <b>${params.seriesName}</b></br>
                ${params.data.name} : ${
          params.seriesName === "Gender Report"
            ? params.value + "%"
            : params.value
        } <br />`;
      },
    },
    center: ["25%", "25%"],
    labelLine: {
      lineStyle: {
        width: 4,
      },
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: series,
  };
};

/*
[
          {
            name: "Email",
            type: "line",
            stack: "Total",
            source: "TEST",
            areaStyle: {},
      
            emphasis: {
              focus: "series",
            },
            data: [120, 132, 101, 134, 90, 230, 210],
          },
        ],
*/

export {
  lineChartOptions,
  homeChartOptions,
  pieChartData,
  barChartData,
  multiBarChartData,
};
