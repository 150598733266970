import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Loading } from "../../components/index";
import instance from "../../lib/client";
import { HiMinusCircle } from "react-icons/hi";
import { useWatch } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import toastPush from "../../utils/toastPush";
import { useTranslation } from "react-i18next";

const UploadImage = ({
  setValue,
  getValues,
  name,
  control,
  width = 100000000,
  height = 100000000,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const image = useWatch({
    control: control,
    name: name,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      getValues(`${name}`),
      result.source.index,
      result.destination.index
    );

    setValue(`${name}`, items);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoading(true);
      var formData = new FormData();
      formData.append("file", acceptedFiles[0]);
      if (
        !(acceptedFiles[0].width > width) &&
        !(acceptedFiles[0].height > height)
      ) {
        instance({
          method: "post",
          url: "file/upload",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then((res) => {
          console.log(typeof getValues(`${name}`), name);
          if (typeof getValues(`${name}`) === "object" && Object.keys(getValues(`${name}`) || {}).length > 0) {
            var array = getValues(`${name}`) || [];
            array?.push(res.data.data.url);
            setValue(`${name}`, array);
          } else {
            setValue(`${name}`, res.data.data.url);
          }

          setLoading(false);
        });
      } else {
        setLoading(false);
      }
    },
    [name, setValue, getValues]
  );

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 ${grid}px 0 0`,

    // change background colour if dragging

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const grid = 8;

  const getListStyle = (isDraggingOver) => ({
    display: "flex",
    padding: grid,
    position: "relative",
    overflow: "auto",
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    accept: "image/jpeg, image/png, image/jpg",
    getFilesFromEvent: async (event) => {
      const files = event.target.files || event.dataTransfer.files;
      const promises = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const promise = new Promise((resolve, reject) => {
          const image = new Image();
          let url = "";
          image.onload = function () {
            file.width = image.width;
            file.height = image.height;
            resolve(file);
          };
          url = URL.createObjectURL(file);
          image.src = url;
        });
        promises.push(promise);
      }
      return await Promise.all(promises);
    },
    validator: (file) => {
      // You can access width/height properties
      if (file.width > width || file.height > height) {
        toastPush(t("utils:imageSizeError"), "error");
      }
      return null;
    },
  });

  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2" {...getRootProps()}>
      <div className="max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md overflow-x-auto">
        {loading ? (
          <Loading />
        ) : image &&
          typeof image === "object" &&
          Object?.keys(image).length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  {image.map((item, index) => (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="shadow"
                        >
                          <HiMinusCircle
                            onClick={() => {
                              var array = getValues(`${name}`);
                              array.splice(index, 1);
                              setValue(`${name}`, array);
                            }}
                            className="text-red-400"
                            style={{
                              position: "relative",
                              borderRadius: "100%",
                              zIndex: 10,
                              width: 35,
                              height: 35,
                              marginLeft: "auto",
                              cursor: "pointer",
                            }}
                          />
                          <img
                            src={item}
                            style={{
                              maxHeight: 180,
                              marginLeft: 10,
                            }}
                            alt="banner_image"
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : typeof image !== "object" && image ? (
          <div
            style={{
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <HiMinusCircle
              onClick={() => {
                setValue(`${name}`, "");
              }}
              className="text-red-400"
              style={{
                position: "absolute",
                borderRadius: "100%",
                zIndex: 1000,
                width: 35,
                height: 35,
                right: -20,
                top: -20,
                cursor: "pointer",
              }}
            />
            <img
              src={image}
              style={{
                maxHeight: 180,
              }}
              alt="banner_image"
            />
          </div>
        ) : (
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <div
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>{!isDragActive && "Upload a file"}</span>
                <input
                  {...getInputProps()}
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  className="sr-only"
                />
              </div>
              <p className="pl-1">{!isDragActive && "or drag and drop"}</p>
            </div>
            <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImage;
