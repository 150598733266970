import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router";
import {
  EventDetails,
  FormInput,
  GenericButton,
  Loading,
  NoItem,
  ProgressBar,
  ExcelLoading,
} from "../../components";
import instance from "../../lib/client";
import { TicketForm, TicketBlock, TicketTypeList } from "./components/index.js";
import Select from "react-select";
import { dynamicSort, searchInArray } from "../../utils/arrayUtils";

import moment from "moment";
import { useHistory } from "react-router-dom";
import DetailSide from "../../components/DetailSide/DetailSide";
import useStore from "../../hooks/useStores";
import { observer } from "mobx-react-lite";
import { IoMdDownload } from "react-icons/io";
import downloadExcelFile from "../../utils/downloadExcelFile";

const Tickets = observer(() => {
  const { modal } = useStore();
  const { id } = useParams();
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const [tempTickets, setTempTickets] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [ticketTypes, setTicketTypes] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { auth } = useStore();
  const [isDownloading, setIsDownloading] = useState(false);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const handleData = () => {
    if (tickets.length > 0 && tempTickets.length > 0) {
      setLoading(true);
    }

    Promise.all([
      instance.get(`event/${query.get("slug")}`),
      instance.get(`sale/active-tickets/${id}`),
    ]).then(([event, tickets]) => {
      setEvent(event?.data?.data);
      setTickets(tickets?.data?.data);
      setTempTickets(tickets?.data?.data);
      setLoading(false);
    });
  };

  const handleTickets = () => {
    instance
      .get(`/event/${id}/tickets`)
      .then(({ data }) => {
        setTicketTypes(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (query.get("slug")) {
      handleTickets();
      handleData();
    } else history.push("/dashboard");
  }, [id]);

  useEffect(() => {
    if (searchText.length > 0 && searchText) {
      setTickets({
        ...tickets,
        tickets: searchInArray(searchText, tempTickets.tickets, "name"),
      });
    } else {
      setTickets({ ...tickets, tickets: tempTickets.tickets });
    }
  }, [searchText, tempTickets]);

  const sortArray = (sortType) => {
    var sortedArray = tempTickets.tickets;
    if (sortType === "asc") sortedArray?.sort(dynamicSort("name", "asc"));
    else if (sortType === "desc")
      sortedArray?.sort((a, b) => b.name.localeCompare(a.name));
    else if (sortType === "scanned") sortedArray.sort(compareDate);

    setTickets({ ...tempTickets, tickets: sortedArray });
  };

  const compareDate = (a, b) => {
    var dateA =
      a.scanned_at !== undefined
        ? moment(a.scanned_at).toDate()
        : moment().subtract(10, "year").toDate();
    var dateB =
      b.scanned_at !== undefined
        ? moment(b.scanned_at).toDate()
        : moment().subtract(10, "year").toDate();

    return dateB > dateA ? 1 : -1;
  };

  const exportExcelFile = async () => {
    setIsDownloading(true);
    await downloadExcelFile(
      `/report/excel/ticket?eventId=${id}&lang=${auth.language}`,
      event.name + " " + "Ticket List",
      auth.accessToken,
      setIsDownloading
    );
  };

  useEffect(() => {
    handleData();
  }, [modal.ticketDetail, modal]);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* one column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title ">
            <div className="rounded-lg bg-white overflow-hidden shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                  <div className="items-center mb-4 md:flex flex-column justify-center lg:flex flex-row">
                    <h3 className="text-xl text-gray-800">Ticket List</h3>
                    <div className="ml-auto flex flex-row gap-2 ">
                      {isDownloading ? (
                        <ExcelLoading />
                      ) : (
                        <IoMdDownload
                          size={36}
                          style={{ marginTop: 5, marginRight: 2 }}
                          color={"#1B1A17"}
                          onClick={exportExcelFile}
                        />
                      )}
                      <Select
                        placeholder={t("utils:sort")}
                        onChange={(data) => sortArray(data.value)}
                        options={[
                          { label: "A-Z", value: "asc" },
                          { label: "Z-A", value: "desc" },
                          { label: t("filter:byScanned"), value: "scanned" },
                        ]}
                        className="basic-multi-select w-[200px] mt-1 text-sm"
                        classNamePrefix="select"
                      />
                      <FormInput
                        placeholder={t("utils:search")}
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                      />
                    </div>
                  </div>

                  <ProgressBar
                    all={tickets.counts.all}
                    desired={tickets.counts.scanned}
                    allText={"All"}
                    desiredText={"Scanned"}
                  />
                  <div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
                    <div
                      className="divide-y divide-gray-200 overflow-y-auto"
                      style={{ maxHeight: "65vh" }}
                    >
                      {tickets.tickets.length > 0 ? (
                        tickets.tickets.map((ticket, i) => {
                          return (
                            <TicketBlock
                              key={i}
                              ticket={ticket}
                              event={event}
                            />
                          );
                        })
                      ) : (
                        <NoItem
                          name={t("ticket:noSaleTicket")}
                          explanation={""}
                          button={""}
                          route={""}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4">
          <DetailSide id={id} slug={query.get("slug")} defaultSelectedTab={2} />
        </div>
      </div>
    </div>
  );
});

export default Tickets;
