import React from 'react';

const LoginInput = React.forwardRef(({label, name, className, containerStyle = {}, containerClassName, ...rest}, ref) => {
  return (
    <div className={containerClassName} style={containerStyle}>
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          id={`${name}-login-input`}
          name={name}
          ref={ref}
          className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${className}`}
          {...rest}
        />
      </div>
    </div>
  )
})
export default LoginInput