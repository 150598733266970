/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import { FormInput, GenericButton } from "../../../components";
import toastPush from "../../../utils/toastPush";

function BillingInfoModal({ open, setOpen }) {
  const { auth } = useStore();
  const { t } = useTranslation();

  const [promoter, setPromoter] = useState({});

  const handlePromoter = async () => {
    if (auth?.promoter?._id) {
      const { data } = await instance.get(`/promoter/${auth?.promoter._id}`);
      setPromoter(data.data ? data.data : {});
    }
  };

  useEffect(() => {
    handlePromoter();
  }, [open, setOpen]);

  const updatePromoter = async () => {
    const { data } = await instance.put("/promoter", promoter);
    toastPush(t("utils:promoterUpdated"));

    handlePromoter();
    setOpen(false);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold">
                {t("settings:billingInformation")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-6">
                <FormInput
                  placeholder={t("promoter:name")}
                  value={promoter.name}
                  label={t("promoter:name")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, name: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:city")}
                  value={promoter.city}
                  label={t("promoter:city")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, city: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:address")}
                  value={promoter.address}
                  label={t("promoter:address")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, address: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:email")}
                  value={promoter.email}
                  label={t("promoter:email")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, email: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:phone")}
                  value={promoter.phone}
                  label={t("promoter:phone")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, phone: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:taxNo")}
                  value={promoter.taxNo}
                  label={t("promoter:taxNo")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, taxNo: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <FormInput
                  placeholder={t("promoter:taxOffice")}
                  value={promoter.taxOffice}
                  label={t("promoter:taxOffice")}
                  onChange={(v) =>
                    setPromoter({ ...promoter, taxOffice: v.target.value })
                  }
                  style={{ marginBottom: 15 }}
                />
                <GenericButton
                  background="primary"
                  onClick={() => updatePromoter()}
                  style={{ paddingVertical: 20, marginTop: 20 }}
                  textBold
                >
                  {promoter.hasOwnProperty("_id")
                    ? t("utils:update")
                    : t("utils:save")}
                </GenericButton>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default BillingInfoModal;
