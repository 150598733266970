import React, { useEffect, useState, useRef } from "react";
import {
  IoTicketOutline,
  IoClipboardOutline,
  IoLayersOutline,
} from "react-icons/io5";
import { CgDetailsMore } from "react-icons/cg";
import { ImAttachment } from "react-icons/im";
import classNames from "../../utils/classNames";
import { useTranslation } from "react-i18next";
import {
  Tickets,
  Attachments,
  Details,
  Basics,
  Contents,
} from "./components/index";
import { useForm } from "react-hook-form";
import instance from "../../lib/client";
import { useHistory } from "react-router";
import { LoadingModal } from "../../components/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "../../hooks/useStores";
import { useParams, Prompt } from "react-router-dom";
import moment from "moment";
import toastPush from "../../utils/toastPush";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import autoAnimate from "@formkit/auto-animate";
import { analytics } from "../../utils/analytics";

const EventSchema = yup.object().shape({
  name: yup.string().required("Etkinlik İsmi Boş Bırakılamaz"),
  translations: yup.array().of(
    yup.object().shape({
      desc: yup.string().required("Gerekli Alan Açıklama"),
      rules: yup.string().required("Gerekli Alan Kurallar"),
      langcode: yup.string().optional(),
    })
  ),
  start_date: yup
    .date()
    .required("Geçerli Bir Başlangıç Tarihi Seçiniz")
    .min(moment().subtract(1, "days"), "Date must be later than today.")
    .typeError("Geçerli Bir Başlangıç Tarihi Giriniz"),
  start_time: yup
    .string()
    .required("Geçerli Bir Başlangıç Saati Seçiniz")
    .test("is-greater", "Start time should be greater", function (value) {
      const { start_date } = this.parent;
      if (moment(start_date).isSame(moment(), "day")) {
        return moment(value, "HH:mm").isSameOrAfter(
          moment(new Date(), "HH:mm")
        );
      } else {
        return true;
      }
    }),

  end_date: yup
    .date("Geçerli Bir Bitiş Tarihi Seçiniz")
    .required("Geçerli Bir Bitiş Tarihi Seçiniz")
    .when("start_date", (start_date, schema) =>
      moment(new Date(start_date)).isValid()
        ? schema
            .required()
            .min(start_date, "Bitiş Tarihi Başlangıç Tarinden Önce Olamaz")
        : schema.required("Geçerli Bir Bitiş Tarihi Seçiniz")
    )
    .typeError("Geçerli Bir Bitiş Tarihi Seçiniz"),
  end_time: yup
    .string()
    .required("Geçerli Bir Bitiş Saati Seçiniz")
    .test("is-greater", "End time should be greater", function (value) {
      const { start_date, end_date, start_time } = this.parent;
      if (moment(start_date).isSame(moment(end_date), "day")) {
        return moment(value, "HH:mm").isSameOrAfter(
          moment(start_time, "HH:mm")
        );
      } else {
        return true;
      }
    }),
  isVenueAnnounced: yup.boolean().optional(),

  venue_detail: yup.object().when("isVenueAnnounced", {
    is: false,
    then: yup.object().shape({
      id: yup.string().required("Gerekli Alan id"),
      value: yup.string().required("Gerekli Alan value"),
      label: yup.string().required("Gerekli Alan label"),
    }),
    otherwise: yup.object().shape({
      id: yup.string().optional(),
      value: yup.string().optional(),
      label: yup.string().optional(),
    }),
  }),

  djs_detail: yup.array().min(1),
  genres: yup.array().min(1),
  category: yup.array().min(1),
  podcast: yup.string().optional(),
  price_list: yup.array().min(1),
  banner_image: yup.string().required(),
  image: yup.string().required(),
});

function NewEvent() {
  const { auth } = useStore();
  const {
    control,
    register,
    getValues,
    watch,
    setValue,
    handleSubmit,
    trigger,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: {
      type: "event",
      name: "",
      translations: [
        {
          rulesId: "",
          desc: "",
          rules: "",
          langcode: "tr",
        },
        {
          rulesId: "",
          desc: "",
          rules: "",
          langcode: "en",
        },
      ],
      promoters_detail: [
        {
          image: auth.promoter.image,
          label: auth.promoter.name,
          value: auth.promoter.slug,
          id: auth.promoter._id,
          isFixed: true,
        },
      ],
      currencyUnit: [],
      start_date: "",
      start_time: "",
      end_time: "",
      end_date: "",
      podcast: "",
      isVenueAnnounced: false,
      venue_detail: {
        id: "",
        value: "",
        label: "",
      },
      genres: [],
      category: [],
      banner_time: "",
      seat_map: [],
      djs_detail: [],
      price_list: [],
      banner_image: "",
      image: "",
    },
  });
  const notifySuccess = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);
  const [loadingInitial, setLoadingInitial] = useState(true);
  const [selectedSection, setSelectedSection] = useState(0);
  const [isSaved, setIsSaved] = useState(true);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const details = watch();
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(async () => {
    const genres = await instance.get("category/genre");
    const genreList = genres.data.data;
    const categories = await instance.get("category/event");
    const categoryList = categories.data.data;

    if (window.location.pathname.includes("update-event")) {
      setLoadingInitial(true);
      const { data } = await instance.get(`event/details/${id}`);
      const event = data.data;

      for (const [key, value] of Object.entries(event)) {
        setValue(key, value, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }
      setValue("start_date", moment.utc(event.start_time).format("YYYY-MM-DD"));
      setValue("end_date", moment.utc(event.end_time).format("YYYY-MM-DD"));
      setValue("start_time", moment.utc(event.start_time).format("HH:mm"));
      setValue("end_time", moment.utc(event.end_time).format("HH:mm"));
      setValue("currencyUnit", {
        label:
          event?.country?.currency_symbol +
          " - " +
          event?.country?.currency_name,
        value: event?.country?.currency_name,
      });

      setValue("podcast", event?.soundcloud);

      setValue(
        "genres",
        // match event genres with genreList
        genres.length > 0
          ? event?.genres?.map((genre) => {
              return genreList.find((item) => item.value === genre);
            })
          : event?.music_categories?.map((genre) => {
              return genreList.find((item) => item.value === genre);
            })
      );
      setValue(
        "category",
        // match event categories with categoryList
        event?.categories?.map((category) => {
          return categoryList.find((item) => item.value === category);
        })
      );
      setValue("venue_detail", {
        id: event.venue._id,
        label: event.venue.name,
        value: event.venue._id,
      });
      setValue(
        "djs_detail",
        event.djs.map((dj) => {
          const lineupStart = event.line_ups.find(
            (lineup) => lineup.dj === dj._id
          )?.start_time;
          const lineupEnd = event.line_ups.find(
            (lineup) => lineup.dj === dj._id
          )?.end_time;
          return {
            id: dj?._id,
            label: dj?.name,
            value: dj?.slug,
            start_time: lineupStart
              ? moment(lineupStart, "HH:mm").format("HH:mm")
              : "",
            end_time: lineupEnd
              ? moment(lineupEnd, "HH:mm").format("HH:mm")
              : "",
            isAnnounced: event?.line_ups?.find(
              (lineup) => lineup?.dj == dj?._id
            )?.isAnnounced,
            image: dj?.image,
          };
        })
      );

      setValue("seat_map", event?.seatMaps);

      setLoadingInitial(false);
      await checkIsValid();
    } else setLoadingInitial(false);
  }, []);

  const createEventAnalytics = (values, id) => {
    analytics.track("Event Created", {
      id: id,
      name: values.name,
      date: values.start_date,
      genre: values.genres.map((genre) => (genre.label ? genre.label : genre)),
      venue: values.venue_detail.label,
      promoters: [auth.promoter.name],
      time: moment(values.start_time, "HH:mm").format("HH:mm"),
      artists: values.djs_detail.map((dj) => dj.label),
      tickets: values.price_list,
      groupId: auth.promoter._id,
    });
  };

  const onSubmit = (data) => {
    let payload = {
      ...data,
      type: "event",
      start_date: moment(data.start_date).format("DD-MM-YYYY"),
      end_date: moment(data.end_date).format("DD-MM-YYYY"),
      music_categories: data.genres.map((genre) =>
        genre.value ? genre.value : genre
      ),
      categories: data.category.map((cat) => (cat.value ? cat.value : cat)),
      djs_detail: data.djs_detail.map((dj) => {
        return {
          id: dj.id,
          start_time: moment(dj.start_time, "HH:mm").format("HH:mm"),
          end_time: moment(dj.end_time, "HH:mm").format("HH:mm"),
          isAnnounced: dj.isAnnounced,
          label: dj.label,
          value: dj.value,
        };
      }),
    };

    setLoading(true);
    if (window.location.pathname.includes("update-event")) {
      instance
        .put(`event/${id}`, payload)
        .then(({ data }) => {
          setIsSaved(false);
          setLoading(false);
          toastPush(t("notifyMessages:successEventUpdate"));
          setTimeout(() => {
            history.push("/dashboard");
          }, 100);
          setLoading(false);
        })
        .catch((err) => {
          toastPush("Error", "error");
          setLoading(false);
        });
    } else {
      instance
        .post("event", payload)
        .then(({ data }) => {
          createEventAnalytics(payload, data.data._id);
          setIsSaved(false);
          setLoading(false);
          toastPush(t("notifyMessages:successEvent"));
          setTimeout(() => {
            history.push("/dashboard");
          }, 100);
          setLoading(false);
        })
        .catch((err) => {
          toastPush(t("utils:error"), "error");
          setLoading(false);
        });
    }
  };

  const saveAsDraft = async (url) => {
    const data = getValues();
    let isValid = false;
    isValid = await trigger([
      "name",
      "start_date",
      "start_time",
      "end_date",
      "end_time",
    ]);
    setLoading(true);
    let payload = {
      ...data,
      type: "draft",
      start_date: moment(data.start_date).format("DD-MM-YYYY"),
      end_date: moment(data.end_date).format("DD-MM-YYYY"),
      music_categories: data.genres.map((genre) =>
        genre.value ? genre.value : genre
      ),
      categories: data.category.map((cat) => (cat.value ? cat.value : cat)),
    };
    id
      ? instance
          .put(`event/${id}`, payload)
          .then(({ data }) => {
            setIsSaved(false);
            setLoading(false);
            toastPush(t("notifyMessages:successEventDraft"));
            setTimeout(() => {
              history.push(`/dashboard${url ? url : ""}`);
            }, 100);
            setLoading(false);
          })
          .catch((err) => {
            toastPush("Error", "error");
            setLoading(false);
          })
      : instance
          .post("event", payload)
          .then(({ data }) => {
            setIsSaved(false);
            setLoading(false);
            toastPush(t("notifyMessages:successEventDraft"));
            setTimeout(() => {
              history.push(`/dashboard${url ? url : ""}`);
            }, 100);
            setLoading(false);
          })
          .catch((err) => {
            toastPush(t("utils:error"), "error");
            setLoading(false);
          });
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  const onError = (errors) => {
    console.log(errors);
  };
  const checkIsValid = async () => {
    let isValid = true;
    switch (selectedSection) {
      case 0:
        isValid = await trigger([
          "name",
          "start_date",
          "start_time",
          "end_date",
          "end_time",
          "venue_detail",
        ]);
        break;
      case 1:
        isValid = await trigger([
          "djs_detail",
          "genres",
          "category",
          "podcast",
        ]);
        break;
      case 2:
        isValid = await trigger(["translations"]);
        break;
      case 3:
        isValid = await trigger(["price_list"]);
        break;
      case 4:
        isValid = await trigger(["attachments"]);
        break;
      default:
        isValid = true;
    }

    return isValid;
  };

  const navigation = [
    {
      name: t("eventForm:eventBasics"),
      href: "#",
      icon: IoClipboardOutline,
      visible: true,
    },
    {
      name: t("eventForm:eventContents"),
      href: "#",
      icon: IoLayersOutline,
      visible: true,
    },
    {
      name: t("eventForm:eventDetails"),
      href: "#",
      icon: CgDetailsMore,
      visible: true,
    },
    {
      name: t("eventForm:tickets"),
      href: "#",
      icon: IoTicketOutline,
      visible: !window.location.pathname.includes("update-event")
        ? true
        : getValues("type") == "draft"
        ? true
        : false,
    },
    {
      name: t("eventForm:attachments"),
      href: "#",
      icon: ImAttachment,
      visible: true,
    },
  ];

  const returnCurrentSection = () => {
    switch (selectedSection) {
      case 0:
        return (
          <Basics
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            clearErrors={clearErrors}
            checkIsValid={checkIsValid}
            saveAsDraft={saveAsDraft}
            errors={errors}
            id={id}
          />
        );
      case 1:
        return (
          <Contents
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            clearErrors={clearErrors}
            checkIsValid={checkIsValid}
            saveAsDraft={saveAsDraft}
            errors={errors}
            id={id}
          />
        );
      case 2:
        return (
          <Details
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            clearErrors={clearErrors}
            checkIsValid={checkIsValid}
            saveAsDraft={saveAsDraft}
            errors={errors}
            id={id}
          />
        );
      case 3:
        return (
          <Tickets
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            checkIsValid={checkIsValid}
            saveAsDraft={saveAsDraft}
            errors={errors}
          />
        );
      case 4:
        return (
          <Attachments
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            register={register}
            control={control}
            getValues={getValues}
            setValue={setValue}
            checkIsValid={checkIsValid}
            saveAsDraft={saveAsDraft}
            errors={errors}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <Prompt
        when={isSaved}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 className="sr-only" id="section-2-title">
              Son Biletler
            </h2>

            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                <aside className="px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                  <nav className="space-y-1">
                    {navigation.map((item, i) => {
                      if (item.visible)
                        return (
                          <span
                            key={i}
                            onClick={async () =>
                              selectedSection < i
                                ? (await checkIsValid()) &&
                                  setSelectedSection(i)
                                : setSelectedSection(i)
                            }
                            className={classNames(
                              selectedSection === i
                                ? "bg-gray-800 text-white hover:text-white hover:bg-gray-900"
                                : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                              "group rounded-md px-3 py-2 flex items-center text-sm font-medium cursor-pointer "
                            )}
                            aria-current={
                              selectedSection === i ? "page" : undefined
                            }
                          >
                            <item.icon
                              className={classNames(
                                selectedSection === i
                                  ? "text-white group-hover:text-white"
                                  : "text-gray-400 group-hover:text-gray-900",
                                "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            <span className="truncate ml-2">{item.name}</span>
                          </span>
                        );
                    })}
                  </nav>
                </aside>
              </div>
            </div>
            {/* <div className="rounded-lg bg-white overflow-hidden shadow mt-4">
              <div className="p-6">
                <EventAppOverview details={details} />
              </div>
            </div> */}
          </section>
        </div>
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <form onSubmit={handleSubmit(onSubmit, onError)}>
            <div ref={parent}> {returnCurrentSection()}</div>
          </form>
        </div>
      </div>
      <LoadingModal
        unVisible={
          window.location.pathname.includes("update-event") && loadingInitial
        }
        text={
          window.location.pathname.includes("update-event")
            ? t("notifyMessages:updatingEvent")
            : t("notifyMessages:creatingEvent")
        }
        loading={loadingInitial || loading}
      />
    </div>
  );
}

export default NewEvent;
