/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import toastPush from "../../../utils/toastPush";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Loading } from "../../../components";

const TicketRefundModal = observer(() => {
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedReason, setSelectedReason] = useState("");
  const { modal } = useStore();
  const { t } = useTranslation();

  const handleReasons = async () => {
    setLoading(true);
    const { data } = await instance.get("/sale/refund-reasons");

    setReasons(data.data);
    setLoading(false);
  };

  useEffect(() => {
    handleReasons();
  }, []);

  const sendRefund = async () => {
    if (!selectedReason) {
      toastPush(t("utils:refundReasonError"), "error", 1500);
    } else {
      instance
        .post(`sale/refund-ticket/${modal.ticketDetail.id}`, {
          reason: selectedReason,
        })
        .then((status) => {
          toastPush(t("refund:refundSuccess"), "success", 1500);
          onClose();
        })
        .catch((err) => {
          toastPush(t("utils:refundReasonError"), "error", 1500);
        });
    }
  };

  const onClose = () => {
    setSelectedReason("");
    modal.triggerTicketRefundModal();
    modal.triggerTicketModal();
  };

  return (
    <Transition.Root show={modal.ticketRefund} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block text-left align-bottom md:w-3/12 xs:w-11/12 bg-white rounded-lg px-4 pt-5 pb-4 label-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold text-lg">
                {t("ticket:returnTicket")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md label-gray-400 hover:label-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="flex flex-col mt-10 max-h-[95vh] overflow-y-auto">
                <span className="text-sm font-semibold pb-1 ml-1">
                  {t("ticket:refundReasons")}
                </span>

                {loading ? (
                  <Loading />
                ) : (
                  <div className="flex flex-col ">
                    {reasons.map((reason, i) => (
                      <div
                        onClick={() => {
                          setSelectedReason(
                            selectedReason === reason.name ? "" : reason.name
                          );
                        }}
                        key={i}
                        className={`
                        p-2 mb-1 text-sm bg-gray-100"  font-semibold rounded-md flex flex-row relative cursor-pointer ${
                          selectedReason === reason.name
                            ? "bg-primary-200"
                            : "bg-gray-100"
                        }
                      `}
                        style={{
                          borderBottom: "1px solid white",
                        }}
                      >
                        {reason.name}
                      </div>
                    ))}
                  </div>
                )}

                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: 30,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => sendRefund()}
                    className="bg-primary-500"
                    style={{
                      height: 55,
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "100%",
                      borderRadius: 8,
                    }}
                  >
                    <label style={{ fontWeight: "700", fontSize: 17 }}>
                      {t("ticket:returnTicket")}
                    </label>
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default TicketRefundModal;
