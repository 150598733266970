import React from "react";

function Reports() {
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <h1 className="sr-only">Page title</h1>
      {/* Main 1 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        {/* one column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <h2 className="sr-only" id="section-1-title">
              Section title
            </h2>
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">{/* Your content */}</div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Reports;
