import { useEffect, useState, useRef, useMemo } from "react";
import { FormInput, GenericButton, Loading } from "../../../../components";
import instance, { baracUrl } from "../../../../lib/client";
import { useHistory, useLocation } from "react-router-dom";
import authService from "../../../../services/authService";
import { Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useStore from "../../../../hooks/useStores";
import autoAnimate from "@formkit/auto-animate";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import Select from "react-select";
import moment from "moment";
import * as yup from "yup";
import axios from "axios";

const SignupSchema = yup.object().shape({
  name: yup.string().required(),
  last_name: yup.string().required(),
  birth_day: yup.object().required(),
  birth_month: yup.object().required(),
  birth_year: yup.object().required(),
  gender: yup.string().required(),
  password: yup.string().required(),
  password2: yup.string().required(),
  country_code: yup.object().required(),
  phone_number: yup.string().required(),
  accept_terms: yup
    .boolean()
    .oneOf([true], "Must Accept Terms and Conditions")
    .required(),
});

const AcceptInvite = () => {
  const [isSignloading, setIsSignloading] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [dayList, setDayList] = useState([]);
  const [token, setToken] = useState(null);
  const [mail, setMail] = useState("");
  const { search } = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const parent = useRef(null);
  const { auth } = useStore();

  const notifySuccess = (message) =>
    toast.success(message, {
      position: "top-left",
    });
  const notifyError = (message) =>
    toast.error(message, {
      position: "top-left",
    });

  const genderList = [
    {
      value: "Male",
      lang: t("signupModal:male"),
    },
    {
      value: "Female",
      lang: t("signupModal:female"),
    },
  ];

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const {
    control,
    register,
    getValues,
    setValue,
    trigger,
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(SignupSchema),
    defaultValues: {
      name: "",
      last_name: "",
      birth_day: "",
      birth_month: "",
      birth_year: "",
      gender: "",
      password: "",
      password2: "",
      country_code: "",
      phone_number: "",
      accept_terms: false,
    },
  });

  const values = useWatch({
    control: control,
    name: [
      "birth_day",
      "birth_month",
      "birth_year",
      "gender",
      "country_code",
      "phone_number",
      "accept_terms",
    ],
  });

  const phone = useWatch({
    control: control,
    name: "phone_number",
  });

  const checkInvite = async () => {
    setLoading(true);
    instance
      .post("user/check-invite", {
        verificationToken: token,
      })
      .then(({ data }) => {
        setToken(data.data?.token);
        setMail(data.data?.email);
        setLoading(false);
      })
      .catch((error) => {
        history.push("/auth/login");
        console.log(error);
        setLoading(false);
      });
  };

  const getCountryCode = async () => {
    const { data } = await axios.get(baracUrl + "country/country-codes");
    console.log(data);
    setCountryCodes(data?.data);
  };

  const handlePhoneChange = (number) => {
    let val = number;
    val = val.replace(/ /gm, "");

    let num = `${val.substring(0, 3)} ${val.substring(3, 6)} ${val.substring(
      6,
      8
    )} ${val.substring(8, val.length)}`;

    num = num.trim();

    setValue("phone_number", num);
  };

  const onSubmit = async (data) => {
    setIsSignloading(true);
    const payload = {
      verificationToken: token,
      name: data.name,
      last_name: data.last_name,
      gender: data.gender,
      password: data.password,
      password2: data.password2,
      birth_day: String(data.birth_day.value),
      birth_month: String(data.birth_month.value),
      birth_year: String(data.birth_year.value),
      phone_country_code: data.country_code.value.replace("+", ""),
      phone_number: data.phone_number.replace(/ /gm, ""),
    };
    console.log(payload);
    try {
      const { data } = await instance.post("auth/invite-register", payload);

      authService.setAuthorizationToken(data.data.access_token);
      auth.setUser(data.data);
      auth.setInitialAccessToken(data.data.access_token);
      auth.setAccessToken(data.data.access_token);
      setIsSignloading(false);
      history.push(`/auth/choose`, { accounts: data.data.accounts });
    } catch (error) {
      setIsSignloading(false);
      notifyError(error.response.data.message);
    }
  };

  useEffect(() => {
    getCountryCode();
    let days = 31;
    let months = 12;
    let years = moment().year() - 18;

    // set days object for dropdown
    let dayList = [];
    for (let i = 1; i <= days; i++) {
      dayList.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    setDayList(dayList);

    // set months object for dropdown
    let monthList = [];
    for (let i = 1; i <= months; i++) {
      monthList.push({
        label: String(
          moment()
            .locale(t("signupModal:activeLang"))
            .startOf("year")
            .add(i - 1, "month")
            .format("MMMM")
        ),
        value: i.toString(),
      });
    }
    setMonthList(monthList);

    // set years object for dropdown
    let yearList = [];
    for (let i = years; i >= 1950; i--) {
      yearList.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
    setYearList(yearList);
  }, []);

  useEffect(() => {
    token && checkInvite();
  }, [token]);

  useEffect(() => {
    values[3]?.length > 0 && clearErrors(["gender"]);
    values[6] && clearErrors("accept_terms");
    console.log(values);
  }, [errors, values]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    console.log("Location changed", searchParams.get("token"));
    setToken(searchParams.get("token"));
  }, [searchParams]);

  useEffect(() => {
    handlePhoneChange(getValues("phone_number"));
    phone.length > 0 && clearErrors("phone_number");
  }, [phone]);

  return (
    <div className="block mt-0 md:mt-6 align-bottom md:px-4 pt-5 pb-4 text-left overflow-visible  transform transition-all mx-auto  sm:max-w-xl sm:w-full sm:p-6">
      {!loading ? (
        <>
          <div className="block absolute top-0 md:left-6 pt-4 pr-4 font-semibold ">
            {t("signupModal:title")}
          </div>
          <div className="block absolute top-5 md:left-[25px] pt-4 pr-4 text-xs text-gray-600 mb-6 md:mb-0 ">
            {t("signupModal:subtitle")}
          </div>
          <div className="flex flex-col mt-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.name ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:name")}
                  </label>
                  <FormInput
                    className={
                      errors?.name &&
                      "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                    }
                    type="text"
                    {...register("name")}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.last_name ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:surname")}
                  </label>
                  <FormInput
                    className={
                      errors?.last_name &&
                      "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                    }
                    type="text"
                    {...register("last_name")}
                  />
                </div>
                <div className="col-span-6 sm:col-span-6">
                  <label
                    className={`block text-sm font-medium  
                       text-gray-700
                    `}
                  >
                    {t("signupModal:mail")}
                  </label>
                  <FormInput
                    className={`!bg-gray-100`}
                    disabled={true}
                    type="text"
                    value={mail}
                  />
                </div>
                <div
                  className={`md:col-span-6 xs:col-span-6 -mb-5 ${
                    errors.birth_day || errors.birth_month || errors.birth_year
                      ? "text-red-400"
                      : "text-gray-700"
                  }`}
                >
                  {t("signupModal:birthdate")}
                </div>
                <div className="md:col-span-2 xs:col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`h-fit w-fit ${
                          errors?.birth_day
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          placeholder={t("signupModal:day")}
                          defaultValue={[]}
                          value={value}
                          onBlur={onBlur}
                          options={dayList}
                          onChange={onChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    name="birth_day"
                    defaultValue=""
                  />
                </div>
                <div className="md:col-span-2 xs:col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`h-fit w-fit ${
                          errors?.birth_month
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          placeholder={t("signupModal:month")}
                          defaultValue={[]}
                          value={value}
                          onBlur={onBlur}
                          options={monthList}
                          onChange={onChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    name="birth_month"
                    defaultValue=""
                  />
                </div>
                <div className="md:col-span-2 xs:col-span-2">
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <div
                        className={`h-fit w-fit ${
                          errors?.birth_year
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          placeholder={t("signupModal:year")}
                          defaultValue={[]}
                          value={value}
                          onBlur={onBlur}
                          options={yearList}
                          onChange={onChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    )}
                    name="birth_year"
                    defaultValue=""
                  />
                </div>
                <div className="col-span-6">
                  <label
                    className={`text-sm font-medium  ${
                      errors?.gender ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:gender")}
                  </label>

                  <fieldset className="mt-1">
                    <div className="space-y-2 sm:flex sm:items-center sm:space-x-5 sm:space-y-0">
                      {genderList.map((gender) => (
                        <div key={gender.value} className="flex items-center">
                          <input
                            id={gender.value}
                            name="notification-method"
                            type="radio"
                            onClick={() => {
                              setValue("gender", gender.value);
                            }}
                            checked={getValues("gender") == gender.value}
                            value={gender.value}
                            className="h-4 w-4 border-gray-300 text-gray-800 focus:ring-gray-800"
                          />
                          <label
                            htmlFor={gender.value}
                            className={`ml-2 block text-sm font-medium leading-6 ${
                              errors.gender ? "text-red-400" : "text-gray-700"
                            }`}
                          >
                            {gender.lang}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.password ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:password")}
                  </label>
                  <FormInput
                    className={
                      errors?.password &&
                      "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                    }
                    type="password"
                    {...register("password")}
                  />
                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.password2 ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:passwordAgain")}
                  </label>
                  <FormInput
                    className={
                      errors?.password2 &&
                      "focus:!ring-red-400 focus:!border-red-400 !border-red-300"
                    }
                    type="password"
                    {...register("password2")}
                  />
                </div>
                <div className="md:col-span-2 xs:col-span-2">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.country_code ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:countryCode")}
                  </label>

                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <div
                        className={`h-fit w-fit mt-1 ${
                          errors?.country_code
                            ? "border border-red-400 rounded-md"
                            : "border-none"
                        }`}
                      >
                        <Select
                          defaultValue={[]}
                          value={value}
                          onBlur={onBlur}
                          options={countryCodes}
                          onChange={onChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          inputRef={ref}
                        />
                      </div>
                    )}
                    name="country_code"
                    defaultValue=""
                  />
                </div>
                <div className="col-span-4 sm:col-span-4">
                  <label
                    className={`block text-sm font-medium  ${
                      errors?.phone_number ? "text-red-400" : "text-gray-700"
                    }`}
                  >
                    {t("signupModal:phoneNumber")}
                  </label>
                  <div className="relative ">
                    <FormInput
                      type="phone"
                      onKeyPress={(e) => {
                        if (e.which < 48 || e.which > 57) {
                          e.preventDefault();
                          console.log("only number");
                        }
                      }}
                      value={phone}
                      onChange={(e) => {
                        handlePhoneChange(e.target.value);
                      }}
                      placeholder={"555 555 55 55"}
                      className={`placeholder-gray-300  ${
                        errors?.phone_number &&
                        "focus:!ring-red-400 focus:!border-red-400 !border-red-300 "
                      }`}
                    />
                  </div>
                </div>
                <div
                  className={`relative flex items-start col-span-6 w-full !text-gray-700" ${
                    errors?.accept_terms && "!text-red-400"
                  }`}
                >
                  <div className="flex h-6 items-center">
                    <input
                      id="comments"
                      aria-describedby="comments-description"
                      name="comments"
                      type="checkbox"
                      defaultChecked={values.accept_terms}
                      onChange={(e) => {
                        setValue("accept_terms", e.target.checked);
                      }}
                      className={`h-4 w-4 rounded focus:ring-gray-800 text-gray-800 ${
                        errors?.accept_terms
                          ? "border-red-400"
                          : "border-gray-300"
                      }`}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <a
                      href="https://bugece.co/terms"
                      target={"_blank"}
                      className=" font-bold underline"
                    >
                      Kullanım Koşullarını
                    </a>{" "}
                    ve{" "}
                    <a
                      href="https://bugece.co/privacy"
                      target={"_blank"}
                      className=" font-bold underline"
                    >
                      Gizlilik Politikasını
                    </a>{" "}
                    okudum ve kabul ediyorum.
                  </div>
                </div>
                <div className="col-span-6">
                  <GenericButton
                    className={
                      " !border !bg-transparent !border-gray-800 focus:!ring-gray-800 flex justify-center items-center !text-gray-800 w-full"
                    }
                    background="primary"
                    type="submit"
                    style={{ paddingVertical: 5, marginTop: -4 }}
                    textBold
                  >
                    {isSignloading ? (
                      <div class=" flex justify-center items-center  transform">
                        <div class="border-t-transparent border-solid animate-spin  rounded-full border-gray-800 border h-6 w-6"></div>
                      </div>
                    ) : (
                      t("signupModal:confirm")
                    )}
                  </GenericButton>
                </div>
              </div>
            </form>
          </div>
        </>
      ) : (
        <div className="my-36">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default AcceptInvite;
