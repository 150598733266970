import React, { useEffect, useState } from "react";
import instance from "../../../../lib/client";
import { HiOutlineMailOpen } from "react-icons/hi";
import { MdEventAvailable } from "react-icons/md";
import { BiTransfer } from "react-icons/bi";
import { Events } from "./components/index";
import { useTranslation } from "react-i18next";
import { Stats, FilterTabs, Loading } from "../../../../components/index";
import numberToMoney from "../../../../utils/numberToMoney";
import { homeChartOptions } from "../../../EventDetail/ChartOptions";
import ReactECharts from "echarts-for-react";
import moment from "moment";
import * as echarts from "echarts";
import LastTickets from "./components/LastTickets";
import { isHaveAccess } from "../../../../utils/roleUtils";

function Home() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(moment().startOf("year"));
  const [endDate, setEndDate] = useState(moment().endOf("day"));
  const [dashboardData, setDashboardData] = useState({});

  const handleNewData = () => {
    Promise.all([
      instance.get(
        `dashboard?startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(endDate).format("YYYY-MM-DD")}`
      ),
    ]).then(([dashboard, lastTickets]) => {
      setDashboardData(dashboard.data.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    handleNewData();
  }, [startDate, endDate, setStartDate, setEndDate]);

  const returnDates = () => {
    const series = dashboardData?.transactions?.data
      .sort((a, b) => b.date - a.date)
      .map((d) => {
        return d.count;
      })
      .reverse();

    const dates = dashboardData?.transactions?.data
      .sort((a, b) => b.date - a.date)
      .map((d) => {
        return moment.utc(d.date).format("lll");
      })
      .reverse();

    return {
      series: [
        {
          name: "Count",
          symbol: "none",
          smooth: false,
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(255, 200, 0, 1)",
              },
              {
                offset: 1,
                color: "rgba(255, 200, 0, 0.4)",
              },
            ]),
          },
          lineStyle: { color: "#ffc800" },

          type: "line",
          data: series,
        },
      ],
      dates: dates,
    };
  };

  const returnEventCount = (isActive) => {
    const activeEvents = [];
    const pastEvents = [];
    dashboardData?.events?.forEach((event) => {
      if (moment(event.end_time) > moment()) activeEvents.push(event);
      else pastEvents.push(event);
    });

    if (isActive) return activeEvents.length;
    else return pastEvents.length;
  };

  const stats = [
    {
      id: 1,
      name: t("events:activeEventCount"),
      stat: returnEventCount(true),
      icon: MdEventAvailable,
      change: "",
      changeType: "",
    },
    {
      id: 2,
      name: t("reports:revenue"),
      stat: numberToMoney(dashboardData?.transactions?.count) + "₺",
      icon: BiTransfer,
      change: "",
      changeType: "",
    },
    {
      id: 3,
      name: t("home:ticketSales"),
      stat: dashboardData?.saleCount,
      icon: HiOutlineMailOpen,
      change: "",
      changeType: "",
    },
  ];

  const formatter = (v) => {
    switch (v.axisDimension) {
      case "y":
        return numberToMoney(v.value) + " ₺";
        break;
      case "x":
        return moment(v.value).format("lll");
        break;

      default:
        break;
    }
  };

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      <h1 className="sr-only">Page title</h1>
      {/* Main 3 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
        {/* Left column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
          <section aria-labelledby="section-1-title ">
            <h2 className="sr-only" id="section-1-title">
              Section title
            </h2>
            <div className="rounded-lg bg-white overflow-hidden shadow min-h-[1124px] md:max-h-[1124px] xs:max-h-auto">
              <div className="p-6">
                {isHaveAccess("eventReports") && (
                  <FilterTabs
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                  />
                )}

                {!loading ? (
                  <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 ">
                    {isHaveAccess("eventReports") &&
                      stats.map((item, i) => {
                        return <Stats key={i} item={item} t={t} />;
                      })}
                  </dl>
                ) : (
                  <div
                    className="ml-auto mr-auto w-full flex justify-center"
                    style={{ height: 132 }}
                  >
                    <Loading />
                  </div>
                )}

                <div className="mt-10">
                  {loading ? (
                    <div
                      className="ml-auto mr-auto w-full flex justify-center"
                      style={{ height: 600 }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    isHaveAccess("eventReports") && (
                      <ReactECharts
                        option={homeChartOptions(
                          returnDates().series,
                          returnDates().dates,
                          t("reports:transactions"),
                          formatter
                        )}
                        style={{ height: 350, width: "100%" }}
                      />
                    )
                  )}
                </div>
                <div>{isHaveAccess("eventList") && <Events />}</div>
              </div>
            </div>
          </section>
        </div>

        {/* Right column */}
        <div className="grid grid-cols-1 gap-4">
          <section aria-labelledby="section-2-title">
            <h2 className="sr-only" id="section-2-title">
              {t("home:lastTickets")}
            </h2>
            <div className="rounded-lg bg-white overflow-hidden shadow">
              <div className="p-6">
                {isHaveAccess("eventTickets") && <LastTickets />}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
