/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MdClose } from "react-icons/md";
import instance from "../../../lib/client";
import useStore from "../../../hooks/useStores";
import { useTranslation } from "react-i18next";
import toastPush from "../../../utils/toastPush";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { Loading } from "../../../components";

const GuestTicketModal = observer(() => {
  const { modal } = useStore();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const collider = {
    width: "100%",
    height: 1,
    backgroundColor: "rgba(0,0,0,0.3)",
    opacity: 0.4,
    marginVertical: 10,
    marginBottom: 10,
  };

  const lightText = {
    color: "rgba(0,0,0,0.5)",
    fontSize: 15,
  };

  const boldText = {
    fontSize: 17,
    fontWeight: "700",
    marginTop: 7,
  };

  const onClose = () => {
    modal.triggerGuestTicketModal();
  };

  useEffect(() => {
    console.log(modal.guestData);
    if (modal?.guestData) {
      setLoading(false);
      console.log(modal.guestData.name);
    }
  }, [modal?.guestData]);

  const deleteGuest = (id) => {
    setLoading(true);
    instance
      .post(`/delete/${id}`)
      .then(({ data: { data } }) => {
        toastPush("success", "Guest deleted successfully");
        modal.triggerGuestTicketModal();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Transition.Root show={modal.guestModal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => onClose()}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block text-left align-bottom md:w-2/6 xs:w-11/12 bg-white rounded-lg px-4 pt-5 pb-4 label-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div className="hidden sm:block absolute top-0 left-6 pt-4 pr-4 font-semibold text-lg">
                {t("ticket:customerInfo")}
              </div>
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-white rounded-md label-gray-400 hover:label-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={() => onClose()}
                >
                  <span className="sr-only">Close</span>
                  <MdClose className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              <div className="flex flex-col mt-10 max-h-[95vh] overflow-y-auto">
                <div
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  className="flex mb-4"
                >
                  <div
                    className="bg-primary-500 flex mr-4"
                    style={{
                      height: 50,
                      width: 50,
                      borderRadius: 99,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label
                      style={{
                        color: "white",
                        fontSize: 18,
                        fontWeight: "600",
                      }}
                    >
                      {modal.guestData?.name?.charAt(0).toUpperCase()}
                    </label>
                  </div>
                  <div>
                    <label style={{ fontWeight: "700", fontSize: 18 }}>
                      {modal.guestData?.name?.toUpperCase()}
                    </label>
                  </div>
                </div>
                <div style={collider} />
                {loading ? (
                  <div className="py-10">
                    <Loading />
                  </div>
                ) : (
                  <>
                    {" "}
                    <div style={{ marginBottom: 20 }} className="flex flex-col">
                      <label style={lightText}>{"Guest List Added By"}</label>
                      <label style={{ ...boldText, letterSpacing: 1 }}>
                        {modal.guestData?.created_by?.full_name}
                      </label>
                    </div>
                    <div style={{ marginBottom: 20 }} className="flex flex-col">
                      <label style={lightText}>{"Guest List Added Date"}</label>
                      <label style={{ ...boldText, letterSpacing: 1 }}>
                        {moment(modal.guestData?.created_at).format(
                          "DD/MM/YYYY - HH:mm"
                        )}
                      </label>
                    </div>
                    <div style={{ marginBottom: 20 }} className="flex flex-col">
                      <label style={lightText}>{"Guest E-Mail"}</label>
                      <label style={{ ...boldText, letterSpacing: 1 }}>
                        {modal.guestData?.email}
                      </label>
                    </div>
                    <div style={{ marginBottom: 20 }} className="flex flex-col">
                      <label style={lightText}>{t("ticket:ticketType")}</label>
                      <label style={boldText}>
                        {modal.guestData?.ticket_type}
                      </label>
                    </div>
                    <div style={{ marginBottom: 20 }} className="flex flex-col">
                      <label style={lightText}>
                        {t("ticket:ticketStatus")}
                      </label>
                      <label style={boldText}>
                        {modal.guestData?.arrived ? "Taratıldı" : "Taratılmadı"}
                      </label>
                    </div>
                    {modal.guestData?.arrived && (
                      <>
                        <div
                          style={{ marginBottom: 20 }}
                          className="flex flex-col"
                        >
                          <label style={lightText}>
                            {t("ticket:scannedBy")}
                          </label>

                          <label style={boldText}>
                            {modal.guestData?.marked_as_arrived_by?.full_name
                              ? modal.guestData?.marked_as_arrived_by?.full_name
                              : modal.guestData?.marked_as_arrived_by?._id}
                          </label>
                        </div>
                        <div
                          style={{ marginBottom: 20 }}
                          className="flex flex-col"
                        >
                          <label style={lightText}>
                            {t("ticket:scannedAt")}
                          </label>
                          <label style={{ ...boldText, letterSpacing: 1 }}>
                            {moment(modal.guestData?.arrived_at).format(
                              "DD/MM/YYYY - HH:mm"
                            )}
                          </label>
                        </div>
                      </>
                    )}
                    {modal.guestData?.eventName && (
                      <div
                        style={{ marginBottom: 20 }}
                        className="flex flex-col"
                      >
                        <label style={lightText}>{t("ticket:eventName")}</label>
                        <label style={boldText}>
                          {modal.guestData?.eventName}
                        </label>
                      </div>
                    )}
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        marginTop: 30,
                      }}
                    >
                      <button
                        onClick={() => {
                          deleteGuest(modal.guestData._id);
                        }}
                        className="bg-primary-500"
                        style={{
                          height: 55,
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "100%",
                          borderRadius: 8,
                        }}
                      >
                        <label style={{ fontWeight: "700", fontSize: 17 }}>
                          Delete Guest
                        </label>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default GuestTicketModal;
