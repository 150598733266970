import React from "react";
import moment from "moment";
import { AiOutlineCalendar } from "react-icons/ai";
import { IoLocationOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import instance from "../../lib/client";
import toastPush from "../../utils/toastPush";
import useStore from "../../hooks/useStores";
import { isHaveAccess } from "../../utils/roleUtils";

const EventDetails = ({ details, buttonsVisible, hideImage, setDetails }) => {
  const [error, setError] = React.useState(false);
  const { modal } = useStore();
  const { t } = useTranslation();

  const changeEventStatus = async () => {
    modal.triggerLoading();
    const { data } = await instance.post(`/event/status/${details._id}`);
    setDetails({ ...details, for_sale: data.data.for_sale });
    toastPush(
      data.data.for_sale ? t("events:activated") : t("events:deactivated")
    );
    modal.triggerLoading();
  };

  return (
    <div className="flex flex-col justify-center">
      {details.banner_image && !error && !hideImage && (
        <img
          alt="venueImage"
          className={"rounded-md shadow"}
          onError={() => setError(true)}
          src={details?.banner_image}
        />
      )}
      <span
        className={`text-md text-gray-500 truncate ${
          !hideImage && "mt-4"
        } font-2xl font-medium`}
      >
        {details.name}
      </span>
      <div className="flex flex-row items-center mt-4">
        <AiOutlineCalendar />

        <span
          className={"ml-2 text-xs text-gray-500 truncate font-xs font-medium"}
        >
          {!details.start_date
            ? moment.utc(details.start_time).format("LLL")
            : moment.utc(details.start_date).format("LLL")}
        </span>
      </div>
      <div className="flex flex-row items-center mt-2">
        <AiOutlineCalendar />
        <span
          className={"ml-2 text-xs text-gray-500 truncate font-xs font-medium"}
        >
          {!details.end_date
            ? moment.utc(details.end_time).format("LLL")
            : moment.utc(details.end_date).format("LLL")}
        </span>
      </div>

      <div className="flex flex-row items-center mt-4 mb-4">
        <IoLocationOutline />
        <span
          className={"ml-2 text-xs text-gray-500 truncate font-xs font-medium"}
        >
          {details?.venue_detail
            ? details?.venue_detail?.label
            : details?.venue?.name}
        </span>
      </div>

      {buttonsVisible ? (
        <div className="flex flex-col">
          <a
            href={`https://bugece.co/tr/events/${details.slug}`}
            target="_blank"
            className="p-4 border w-full font-semibold mb-4 text-white bg-primary-400 rounded-md flex items-center justify-center cursor-pointer"
            style={{ width: "100%" }}
          >
            {t("utils:goEvent")}
          </a>

          {isHaveAccess("eventUpdate") && (
            <Link
              to={`/dashboard/update-event/${details._id}`}
              className="p-4 border w-full font-semibold mb-4 text-white bg-secondary-400 rounded-md flex items-center justify-center cursor-pointer"
              style={{ width: "100%" }}
            >
              {t("utils:editEvent")}
            </Link>
          )}

          <div
            onClick={() =>
              details.isActive &&
              !moment().isAfter(
                moment(
                  details.end_date ? details.end_date : details.end_time
                ).toDate()
              ) &&
              changeEventStatus()
            }
            className={`p-4 border w-full font-semibold mb-4 text-white ${
              !details.isActive ||
              moment().isAfter(
                moment(
                  details.end_date ? details.end_date : details.end_time
                ).toDate()
              )
                ? "bg-gray-400"
                : details.for_sale
                ? "bg-red-400 cursor-pointer"
                : "bg-primary-500 cursor-pointer"
            } rounded-md flex items-center justify-center`}
            style={{ width: "100%" }}
          >
            {moment().isAfter(
              moment(
                details.end_date ? details.end_date : details.end_time
              ).toDate()
            )
              ? t("events:pastEvent")
              : !details?.isActive
              ? t("events:waiting")
              : details?.for_sale
              ? t("utils:deactivate")
              : t("utils:activate")}
          </div>
          <div className="flex flex-row mb-4  justify-between text-center">
            {isHaveAccess("eventTickets") && (
              <Link
                to={`/dashboard/tickets/${details._id}?slug=${details.slug}`}
                className="p-4 border border-gray-500 rounded-md flex items-center justify-center cursor-pointer"
                style={{ width: "45%" }}
              >
                {t("events:ticketList")}
              </Link>
            )}
            {isHaveAccess("eventGuests") && (
              <Link
                className="p-4 border border-gray-500 rounded-md text-center flex items-center justify-center cursor-pointer"
                style={{ width: "45%" }}
                to={`/dashboard/guests/${details._id}?slug=${details.slug}`}
              >
                {t("events:guests")}
              </Link>
            )}
          </div>
          {isHaveAccess("eventCouponList") && (
            <div className="flex flex-row mb-4 justify-between text-center">
              <Link
                to={`/dashboard/coupons/${details._id}?slug=${details.slug}`}
                className="p-4 w-full border border-gray-500 rounded-md flex items-center justify-center cursor-pointer"
              >
                {t("events:coupons")}
              </Link>
            </div>
          )}
          {isHaveAccess("eventTicketSeller") && (
            <div className="flex flex-row mb-4 justify-between text-center">
              <Link
                to={`/dashboard/ticket-pos/${details._id}?slug=${details.slug}`}
                className="p-4 w-full border border-gray-500 rounded-md flex items-center justify-center cursor-pointer"
              >
                {'Ticket POS'}
              </Link>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default EventDetails;
