import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EventItem = ({ event, t }) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  return (
    <Link to={`/dashboard/event/detail/${event.slug}`}>
      <div className="py-4">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            {error ? (
              <div className="h-12 w-12 rounded-full bg-primary-500 text-white flex items-center justify-center">
                {event?.venue?.name?.charAt(0)}
              </div>
            ) : (
              <img
                className="h-12 w-12 rounded-full"
                src={event.image}
                onError={(err) => setError(true)}
                alt=""
              />
            )}
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 truncate">
              {event?.name}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {moment(event.start_time).format("DD-MM-YYYY")} -{" "}
              {event?.venue?.name}
            </p>
          </div>
          <div
            className="min-w-0"
            onClick={() => {
              history.push(`/dashboard/event/detail/${event.slug}`);
            }}
          >
            <p
              className={`text-sm rounded-md font-semibold p-1 text-white ${
                !event.isActive ||
                moment().isAfter(
                  moment(
                    event.end_date ? event.end_date : event.end_time
                  ).toDate()
                )
                  ? "bg-gray-400"
                  : !event.for_sale
                  ? "bg-red-400 "
                  : "bg-primary-500 "
              }`}
            >
              {event?.type == "draft"
                ? t("events:draft")
                : moment().isAfter(
                    moment(
                      event.end_date ? event.end_date : event.end_time
                    ).toDate()
                  )
                ? t("events:pastEvent")
                : !event?.isActive
                ? t("events:waiting")
                : !event?.for_sale
                ? t("eventTickets:deactive")
                : t("eventTickets:active")}
            </p>
          </div>
          <div className="md:flex xs:hidden">
            <Link
              to={`/dashboard/event/detail/${event.slug}`}
              className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 cursor-pointer"
            >
              {t("utils:detail")}
            </Link>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default EventItem;
