import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import Select from "react-select";
import instance from "../../../../lib/client";
import moment from "moment/moment";
import {useParams} from "react-router-dom";
import { FiMinus, FiPlus } from "react-icons/fi";
import EmailModal from "./EmailModal";
import useStore from "../../../../hooks/useStores";
import {toast} from "react-toastify";
import {ArrowDownOnSquareStackIcon, ChevronDownIcon, PaperAirplaneIcon} from "@heroicons/react/24/solid";



const TicketQuantitySelect = ({ticket, onChange, value}) => {
  return (
    <div className="flex flex-row items-center p-2 shadow border rounded-md max-w-2xl w-full mb-2 justify-between">
      <div className="flex flex-col w-1/4">
        <div className="text-sm text-gray-500 font-bold">{ticket.name}</div>
        <div className="text-xs text-gray-500">{ticket.value}TL</div>
      </div>
      <div className="flex flex-row items-center ml-4">
        <button
          className="focus:outline-none"
          onClick={() => onChange(value - 1)}
        >
          <FiMinus />
        </button>
        <div className="text-sm text-gray-500 mx-2">{value}</div>
        <button
          className="focus:outline-none"
          onClick={() => onChange(value + 1)}
        >
          <FiPlus />
        </button>
      </div>
    </div>
  );
}

const UserEmailModal = ({onClose, onSubmit}) => {
  const [email, setEmail] = useState("");
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white rounded-lg shadow-2xl p-4 max-w-[600px] w-full">
        <div className="flex flex-col items-start space-y-4">
          <div className="flex items-center w-full">
            <div className="text-gray-900 font-medium text-lg">E-posta Adresi</div>
            <svg onClick={() => onClose()} xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-auto cursor-pointer rounded-full hover:bg-gray-100 p-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div className="w-full">
            <div className="text-gray-700 font-medium text-sm">E-posta adresinizi giriniz.</div>
            <div className="flex items-center space-x-2 mt-2">
              <input
                type="text"
                className="border border-gray-200 p-2 w-full rounded outline-none focus:border-indigo-500"
                placeholder="E-posta adresi"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                type="button"
                onClick={() => onSubmit(email)}
                className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 focus:bg-indigo-600 focus:outline-none"
              >
                Gönder
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const downloadFile = (url, filename) => {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${filename}`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });

}

const TicketCard = ({tx}) => {
  // Create accordion card
  const [open, setOpen] = useState(false);
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-row items-center w-full border-b border-gray-200 py-2" onClick={() => setOpen(e => !e)}>
        <div className="w-2/3 text-gray-500 text-sm font-medium overflow-hidden w-full h-10">
          {moment(tx.created_at).format("HH:mm")} {tx.email} - {tx.ticket_count} Adet - {tx.total_price}TL</div>
        <div style={{width: '10%'}} className="w-[10%] overflow-hidden w-full flex items-center justify-center">
          <ChevronDownIcon className={'h-4 h-4'}/>
        </div>
      </div>
      {open && <div className="flex flex-col items-center w-full">
        <div className="flex flex-col items-start w-full border-b border-gray-200 py-2 text-sm">
          <span>İşlem Detayları</span>
          <hr className={"w-full py-1 mt-1"}/>
          <span>Tarih: {moment(tx.created_at).format('DD.MM.YYYY HH:mm')}</span>
          <span>{tx.name} {tx.last_name} - {tx.email}</span>
        </div>
        {tx.tickets.map((ticket, index) => (
          <div className="flex flex-row items-center w-full border-b border-gray-200 py-2 text-sm">
            {/*badge*/}

            <div className="w-2/3 text-gray-500 font-medium">{ticket.selected_ticket_type} - {ticket.price}TL <span style={{fontSize: 10}} className={`w-auto p-1 ${ticket.scanned ? 'bg-yellow-500' : 'bg-red-700'} text-white rounded-full`}>{ticket.scanned? 'Taratılmış':'Taratılmamış'}</span></div>
            <button className={"w-auto text-gray-500 font-medium px-2 py-1 flex items-center justify-center hover:bg-gray-100"} onClick={() => {
              window.open(tx.ticket_pdfs[index], '_blank');
            }}>
              <ArrowDownOnSquareStackIcon className={'h-4 h-4 text-red-600'}/>
              <span className={'text-sm'}>Pdf İndir</span>
            </button>
            <button className={"w-auto text-gray-500 font-medium px-2 py-1 flex items-center justify-center hover:bg-gray-100"} onClick={() => {
              fetch(process.env.REACT_APP_TRANSACTION_APPROVER_URL + '/sale/sendSuccessEmail?id='+ tx.paymentId, {
                method: 'GET',
              }).then(() => {})
              toast.success('E-posta gönderildi!');
            }}>
              <PaperAirplaneIcon className={'h-4 h-4 text-green-600'}/>
              <span className={'text-sm'}>Email Gönder</span>
            </button>
          </div>
        ))}
      </div>}
    </div>

  )
}

const TicketPosSales = React.forwardRef(({eventId}, ref) => {
  const [sales, setSales] = useState([]);
  const fetchSales = useCallback(async () => {
    const {data} = await instance.get(`/sale/offline-pos-sales/${eventId}`);
    setSales(data.data);
  }, [eventId])
  {/*create data table for sales*/}
  const totalSales = useMemo(() => {
    return sales.reduce((acc, tx) => {
      return acc + tx.total_price;
    }, 0);
  }, [sales])
  useEffect(() => {
    fetchSales();
  }, [fetchSales])

  useImperativeHandle(ref, () => ({
    fetchSales
  }));

  const totalTicketCount = useMemo(() => {
    return sales.reduce((acc, tx) => {
      return acc + tx.ticket_count;
    }, 0);
  }, [sales])

  const scannedTicketCount = useMemo(() => {
    return sales.reduce((acc, tx) => {
      return acc + tx.tickets.filter(ticket => ticket.scanned).length;
    }, 0);
  }, [sales])

  const unscannedTicketCount = useMemo(() => {
    return sales.reduce((acc, tx) => {
      return acc + tx.tickets.filter(ticket => !ticket.scanned).length;
    }, 0);
  }, [sales])

  return (
    <>
      <div className={"mt-4"}>
        <div className={"flex justify-between"}>
          <span className={"font-medium text-gray-900"}>Toplam ({totalTicketCount} Bilet)</span>
          <span className={"font-medium text-gray-900"}>{new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', }).format(totalSales) }</span>
        </div>
        {/* Total Entrance by tx.tickets.scanned */}
        <div className={"flex justify-between text-sm"}>
          <span className={"font-medium text-green-700"}>Taratılmış ({scannedTicketCount} Bilet)</span>
        </div>
        <div className={"flex justify-between text-sm"}>
          <span className={"font-medium text-red-500"}>Taratılmamış ({unscannedTicketCount} Bilet)</span>
        </div>
      </div>
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-row items-center w-full border-b border-gray-200 py-2">
        <div className="w-1/2 text-gray-500 font-medium">Biletler</div>
      </div>
      {sales.map((tx) => (
        <TicketCard tx={tx} />
      ))}
    </div>
    </>
  )
})

const TicketPos = () => {
  const [events, setEvents] = useState([]);
  const {id} = useParams();
  const { modal } = useStore();
  const [selectedEvent, setSelectedEvent] = useState(id);
  const [tickets, setTickets] = useState([]);
  const [ticketQuantityMap, setTicketQuantityMap] = useState({});//{ticketId: quantity}
  const [emailModalOpen, setEmailModalOpen] = useState(false);


  const fetchEvents = useCallback(async () => {
    const {data} = await instance.get("/event");
    setEvents(
      data.data.sort(
        (a, b) => moment(b.start_time) - moment(a.start_time)
      )
    );
  },[])
  useEffect(() => {
    fetchEvents();
  }, [fetchEvents, modal]);
  const eventLists = useMemo(() => {
    return events.map((event) => ({
      value: event._id,
      label: event.name,
    }));
  }, [events])

  const defaultEvent = useMemo(() => {
    const event = events.find((event) => event._id === selectedEvent);
    console.log(event)
    if(!event) return null;
    return {
      value: event?._id,
      label: event?.name,
    }
  }, [eventLists, selectedEvent])

  const defaultEventModel = useMemo(() => {
    const event = events.find((event) => event._id === selectedEvent);
    console.log(event)
    if(!event) return null;
    return event
  }, [eventLists, selectedEvent]);

  const fetchEventTickets = useCallback(async () => {
    modal.triggerLoading();
    const {data} = await instance.get(`/event/${selectedEvent}/tickets`);
    setTickets(data.data);
    modal.triggerLoading();
  }, [modal, selectedEvent])

  const ticketsList = useMemo(() => {
    console.log(tickets);
    return tickets.filter(e => e.quantity > 0);
  }, [tickets])

  useEffect(() => {
    if(selectedEvent) fetchEventTickets();
  }, [selectedEvent])

  const totalTicket = useMemo(() => {
    return Object.keys(ticketQuantityMap).reduce((acc, ticketId) => {
      return acc + ticketQuantityMap[ticketId];
    }, 0)
  }, [ticketQuantityMap])

  const totalPrice = useMemo(() => {
    return Object.keys(ticketQuantityMap).reduce((acc, ticketId) => {
      const ticket = tickets.find(e => e.realId === ticketId);
      return acc + ticketQuantityMap[ticketId] * ticket.value;
    }, 0)
  }, [ticketQuantityMap, tickets])

  const handlePurchase = useCallback(async () => {
    setEmailModalOpen(true);
  }, [totalPrice])

  // response only name value quantity from quantityMap
  const inCartTickets = useMemo(() => {
    return Object.keys(ticketQuantityMap).map((ticketId) => {
      const ticket = tickets.find(e => e.realId === ticketId);
      if(ticketQuantityMap[ticketId] === 0) return null;
      return {
        name: ticket.name,
        value: ticket.value,
        total: ticket.value * ticketQuantityMap[ticketId],
        quantity: ticketQuantityMap[ticketId]
      }
    }).filter(e => e !== null);
  }, [ticketQuantityMap, tickets])
  const ticketPosRef = useRef(null);
  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 overflow-y-scroll">
      <h1 className="sr-only">Page title</h1>
      <div className="md:grid md:grid-cols-1 items-start xs:flex xs:flex-wrap-reverse">
        <div className="grid grid-cols-1 gap-4 lg:col-span-2" style={{height: '100vh'}}>
          <section aria-labelledby="section-1-title">
            <div className="rounded-lg bg-white shadow overflow-y-scroll">
              {tickets.length > 0 && (<div className="p-6">
                {/* Event select */}
                <div className="flex flex-col">
                  <label
                    htmlFor="event"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Etkinlik
                  </label>
                  <div className="mt-1">
                    {eventLists.length > 0 && <Select
                      id="event"
                      name="event"
                      isDisabled={true}
                      defaultValue={defaultEvent}
                      onChange={(e) => {
                        setSelectedEvent(e.value)
                        setTickets([]);
                      }}
                      options={eventLists}
                    />}
                  </div>
                </div>
                {/* Event info and image card */}
                {defaultEventModel && <div className="mt-4">
                    {/* Event image and event name between row */}
                    <div className="flex flex-col md:flex-row xs:flex-col items-center p-2 shadow-md">
                      <div className="flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full border-2 border-gray-300"
                          src={defaultEventModel?.image}
                          alt=""
                        />
                      </div>
                      <div className="md:ml-4 xs:ml-0 mt-4 md:mt-0 xs:mt-4">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          {defaultEventModel?.name}
                        </h3>
                        <h4 className="text-md leading-6 font-medium text-gray-500">
                          {defaultEventModel?.venue?.name}
                        </h4>
                      </div>
                    </div>
                </div>}

                {/*
                  Select tickets
                */}
                {defaultEventModel && (<div className="mt-4">
                  <div className="flex flex-col">
                    <label
                      htmlFor="event"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Aktif Biletler
                    </label>
                    {/* 2 row list */}
                    <div className="grid grid-cols-2 gap-4">
                    {ticketsList.map((ticket) => <TicketQuantitySelect
                      key={ticket.realId}
                      ticket={ticket}
                      value={ticketQuantityMap[ticket.realId] || 0}
                      onChange={(value) => {
                        if(value > ticket.quantity) return;
                        if(value < 0) return;
                        if(value === 0) {
                          setTicketQuantityMap((prev) => ({
                            ...prev,
                            [ticket.realId]: value,
                          }))
                          return;
                        }
                        setTicketQuantityMap((prev) => ({
                          ...prev,
                          [ticket.realId]: value,
                        }))
                      }}
                    />)}
                    </div>
                    </div>
                </div>)}
                {/* Order total and make purchase button */}
                <div className="mt-4">
                  <div className="flex justify-between text-lg">
                    <span className="font-medium text-gray-900">Toplam</span>
                    <span className="font-medium text-gray-900">
                      {totalPrice} TL
                    </span>
                  </div>
                  <p className="mt-0.5 text-gray-500 text-sm font-semibold">
                    {totalTicket} bilet
                  </p>
                  {/* render inCartTickets with name price quantity */}
                  {inCartTickets.map((ticket) => <div className="flex justify-between text-lg">
                    <span className="font-medium text-sm text-gray-900">{ticket.name} x {ticket.quantity}</span>
                    <span className="font-medium text-sm text-gray-900">
                      {ticket.total} TL
                    </span>
                  </div>)}
                  {/* Purchase button */}
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={handlePurchase}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Satın Al
                    </button>
                  </div>
                </div>
                <div className={'mt-4'}>
                  <TicketPosSales
                    eventId={selectedEvent}
                    ref={ticketPosRef}
                    />
                </div>
              </div>)}
            </div>
          </section>
          <EmailModal
            open={emailModalOpen}
            setOpen={setEmailModalOpen}
            onSubmit={async (email) => {
              if(!email) return;
              const makeSale = await instance.post('/sale/offline-pos-payment', {
                email,
                tickets: ticketQuantityMap,
                event: selectedEvent,
              })
              console.log(makeSale);
              if(makeSale.data.success) {
                toast.success('Satın alındı');
                fetchEventTickets();
                setSelectedEvent(selectedEvent);
                ticketPosRef.current?.fetchSales();
                setTicketQuantityMap({});
                return;
              }
              setEmailModalOpen(false);
            }}
            onCancel={() => {
              setEmailModalOpen(false);
              // fetchEventTickets();
              ticketPosRef.current?.fetchSales();
              setTicketQuantityMap({});
              toast.error('İptal edildi');

            }}
            totalPrice={totalPrice}
            totalTickets={totalTicket}
          />
        </div>
      </div>
    </div>
    )
}
export default TicketPos;
