import React from "react";
import toastPush from "../../../../utils/toastPush";

const ForgotPassword = () => {
    const [email, setEmail] = React.useState("");
    const [reason, setReason] = React.useState("");

    const onSubmit =()=>{
        if(email && reason){
            toastPush("Talebiniz alınmıştır. En kısa sürede dönüş yapılacaktır.","success")
        }else {
            toastPush("Lütfen tüm alanları doldurunuz.","error")
        }

    }

  return (
      <div style={{width: "100%", height: "100vh"}} className={'items-center flex-col mt-12 flex'}>
          <div className={'w-[260px]'}>
              <label
                  htmlFor={'email'}
                  className="block text-sm font-medium text-gray-700"
              >
                  E-Posta
              </label>
              <div className="mt-1">
                  <input
                        onChange={(e) => setEmail(e.target.value)}
                      id={`email-reason-input`}
                      name={'email'}
                      className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}
                  />
              </div>
          </div>

          <div className={'w-[260px] mt-4'}>
              <label
                  htmlFor={'email'}
                  className="block text-sm font-medium text-gray-700"
              >
                  Hesap Silme Nedeni?
              </label>
              <div className="mt-2">
        <textarea
            rows={4}
            onChange={(e) => setReason(e.target.value)}
            name="comment"
            id="comment"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            defaultValue={''}
        />
              </div>
          </div>
          <div className={'mt-4'}>
              <button
                  onClick={() => onSubmit()}
                  type="button"
                  className="rounded-md bg-white w-[260px] px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                  Talebi Oluştur
              </button>
          </div>
      </div>
  );
};

export default ForgotPassword;
