import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { Controller, useWatch } from "react-hook-form";
import DayBlock from "./DayBlock";

function Details({
  selectedSection,
  setSelectedSection,
  register,
  control,
  days,
  genres,
  extraFields,
  categories,
  artists,
  getValues,
  setValue,
  errors,
  checkIsValid,
}) {
  const { t } = useTranslation();

  const opening_dates = useWatch({
    control: control,
    name: "opening_dates",
  });

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log(errors);
  };

  return (
    <section aria-labelledby="section-1-title overflow-y-visible h-full">
      <h2 className="sr-only" id="section-1-title">
        Section title
      </h2>
      <div className="rounded-lg bg-white  shadow overflow-y-visible">
        <div className="p-6 overflow-y-visible">
          <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 overflow-y-visible ">
            <div className="shadow sm:rounded-md overflow-y-visible h-full">
              <div className="bg-white py-6 px-4 space-y-6 sm:p-6 overflow-y-visible h-full z-40">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.resident_artists
                          ? "text-red-400"
                          : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:residentArtists")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.resident_artists
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="auto"
                            isMulti
                            onBlur={onBlur}
                            options={artists || [{}]}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="resident_artists"
                      defaultValue=""
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.category ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:categories")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.category
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="auto"
                            isMulti
                            onBlur={onBlur}
                            options={categories || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="category"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.genres ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:genres")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit ${
                            errors?.genre
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="auto"
                            isMulti
                            onBlur={onBlur}
                            options={genres || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="genre"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.extra_fields ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:extraFields")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit z-40 ${
                            errors?.extra_fields
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="auto"
                            isMulti
                            onBlur={onBlur}
                            options={extraFields || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="extra_fields"
                      defaultValue=""
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-6">
                    <label className="block mb-1 text-sm font-medium text-gray-700">
                      {t("venueForm:priceRange")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          defaultValue={[]}
                          value={value}
                          menuPlacement="auto"
                          onBlur={onBlur}
                          options={[
                            {
                              label: "₺",
                              value: "₺",
                            },
                            {
                              label: "₺₺",
                              value: "₺₺",
                            },
                            {
                              label: "₺₺₺",
                              value: "₺₺₺",
                            },
                          ]}
                          onChange={onChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                      name="priceRange"
                      defaultValue=""
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6">
                    <label
                      className={`block text-sm font-medium  ${
                        errors?.opening_dates ? "text-red-400" : "text-gray-700"
                      }`}
                    >
                      {t("venueForm:days")}
                    </label>

                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <div
                          className={`h-fit w-fit z-10 ${
                            errors?.opening_dates
                              ? "border border-red-400 rounded-md"
                              : "border-none"
                          }`}
                        >
                          <Select
                            defaultValue={[]}
                            value={value}
                            menuPlacement="top"
                            isMulti
                            onBlur={onBlur}
                            options={days || []}
                            onChange={onChange}
                            className="basic-multi-select"
                            classNamePrefix="select"
                          />
                        </div>
                      )}
                      name="opening_dates"
                      defaultValue=""
                    />
                  </div>
                  <div
                    className={"col-span-6 sm:col-span-6 flex flex-col gap-y-4"}
                  >
                    {opening_dates
                      .sort((a, b) => a.value - b.value)
                      .map((o, i) => {
                        return (
                          <DayBlock
                            errors={errors}
                            key={i}
                            item={o}
                            index={i}
                            register={register}
                            t={t}
                            getValues={getValues}
                            setValue={setValue}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="button"
                  onClick={() => {
                    onNextPage();
                  }}
                  className="bg-primary-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {t("venueForm:next")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Details;
