import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiPlusSm } from "react-icons/hi";
import { FaSortAmountDown, FaSortAmountDownAlt } from "react-icons/fa";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RightTicketModal } from "../../../components";
import { useWatch } from "react-hook-form";
import TicketBlock from "./TicketBlock";
import DraftTicket from "./DraftTicket";
import instance from "../../../lib/client";

function Tickets({
  selectedSection,
  setSelectedSection,
  getValues,
  setValue,
  register,
  control,
  checkIsValid,
  saveAsDraft,
  errors,
}) {
  const [ticketModal, setTicketModal] = useState(false);
  const { t } = useTranslation();
  const [confTicket, setConfTicket] = useState(null);
  const [conf, setConf] = useState(false);
  const [draftTicketError, setDraftTicketError] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const price_list = useWatch({
    control: control,
    name: "price_list",
  });

  useEffect(() => {
    console.log("price_list", price_list);
    if (price_list.length === 0) {
      setTicketModal(true);
    }
  }, [price_list]);

  const configureTicket = (i) => {
    setConfTicket(i);
    setConf(true);
    setTicketModal(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      getValues("price_list"),
      result.source.index,
      result.destination.index
    );

    setValue("price_list", items);
  };

  const removeTicket = (index) => {
    var array = getValues(`price_list`);
    array.splice(index, 1);
    setValue(`price_list`, array);
  };

  const onNextPage = async () => {
    (await checkIsValid())
      ? setSelectedSection(selectedSection + 1)
      : console.log("trans", errors);
  };

  /*second Tickets
    Add Ticket
        ticket name - ticket stock - price - schedule start -schedule end - can delete - settings - active after -state begavior
    ticket buying countmax
    add Promoter
    add Genres
    add lineup
        Artist Select - soundclod1 Url - youtube1 Url - startTime1 - endTime1
    */

  // Draft Ticket ADD
  const checkDraftTicket = (id) => {
    setDraftTicketError(false);
    instance
      .get(`/event/${id}/tickets`)
      .then(({ data }) => {
        setValue("price_list", data.data);
        if (data.data.length < 1) {
          setDraftTicketError(true);
        } else {
          onNextPage();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkNextPage = () => {
    const type = getValues("type");
    const id = getValues("_id");
    if (type == "draft" && id) {
      checkDraftTicket(id);
    } else onNextPage();
  };

  const handlePromotionList = async () => {
    const response = await instance.get("promotion");
    setPromotionList(response.data.data);
  };

  useEffect(() => {
    handlePromotionList();
  }, []);

  const sortPriceByAsc = () => {
    const sorted = getValues("price_list").sort((a, b) => a.value - b.value);
    setValue("price_list", sorted);
  };

  const sortPriceByDesc = () => {
    const sorted = getValues("price_list").sort((a, b) => b.value - a.value);
    setValue("price_list", sorted);
  };

  return (
    <section aria-labelledby="section-1-title">
      <div className="rounded-lg bg-white overflow-hidden shadow">
        <div className="mt-2 flex flex-row justify-start items-center gap-1 py-2 px-6 text-sm text-secondary-800">
          <div>Sort Tickets By Price</div>
          <button
            type="button"
            onClick={() => sortPriceByDesc()}
            className="p-1 hover:bg-gray-200 rounded-lg "
          >
            <FaSortAmountDown
              className="h-4 w-4 text-secondary-500"
              aria-hidden="true"
            />
          </button>
          <button
            type="button"
            onClick={() => sortPriceByAsc()}
            className="p-1 hover:bg-gray-200 rounded-lg "
          >
            <FaSortAmountDownAlt
              className="h-4 w-4 text-secondary-500"
              aria-hidden="true"
            />
          </button>
        </div>
        {getValues("_id") ? (
          <DraftTicket id={getValues("_id")} />
        ) : (
          <div className="px-6 py-1">
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      <div className="grid grid-cols-1 gap-4 sm:grid-cols-1">
                        {price_list.map((item, index) => (
                          <Draggable
                            key={item.name}
                            draggableId={item.name}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TicketBlock
                                promotion={promotionList.find(
                                  (promotion) =>
                                    promotion?.key === item?.promotions[0]
                                )}
                                key={index}
                                item={item}
                                provided={provided}
                                index={index}
                                t={t}
                                getValues={getValues}
                                configureTicket={configureTicket}
                                removeTicket={removeTicket}
                              />
                            )}
                          </Draggable>
                        ))}
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>

            <button
              type="button"
              onClick={() => setTicketModal(true)}
              className="mt-4 inline-flex items-center h-8 p-2 border border-transparent rounded-full shadow-sm text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              <HiPlusSm className="h-8 w-8" aria-hidden="true" />
              <span className="text-white sm:text-sm">
                {t("eventForm:addTicket")}
              </span>
            </button>
          </div>
        )}
        <div className="flex justify-end items-center px-4 py-3 bg-gray-50 text-right sm:px-6 gap-4">
          <div
            className={`block text-sm font-medium    ${
              draftTicketError || errors?.price_list
                ? "text-red-400"
                : "text-gray-700 hidden"
            }`}
          >
            {(draftTicketError || errors?.price_list) &&
              " En Az Bir Adet Bilet Tipi Eklemelisiniz"}
          </div>
          {(getValues("type") == "draft" ||
            !window.location.pathname.includes("update-event")) && (
            <button
              onClick={() => {
                saveAsDraft();
              }}
              className="bg-primary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              {t("eventForm:saveAsDraft")}
            </button>
          )}
          <button
            type="button"
            onClick={() => checkNextPage()}
            className="bg-primary-500 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          >
            {t("eventForm:next")}
          </button>
        </div>
      </div>

      <RightTicketModal
        isOpen={ticketModal}
        setIsOpen={setTicketModal}
        register={register}
        control={control}
        setValue={setValue}
        getValues={getValues}
        conf={conf}
        setConf={setConf}
        confTicket={confTicket}
        setConfTicket={setConfTicket}
      />
    </section>
  );
}

export default Tickets;
