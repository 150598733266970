const tr = {
  auth: {
    signInText: "Giriş Yap",
    email: "E-Posta Adresi",
    password: "Şifre",
    signIn: "Giriş Yap",
    forgotPassword: "Şifremi unuttum?",
    signUp: "Kayıt Ol",
    continue: "Devam Et",
  },
  generalEventReport:{
    tabTitle: "Genel Etkinlik Raporu",
    eventDate: "Etkinlik Tarihi",
    eventName: "Etkinlik Adı",
    venue: "Mekan",
    soldTicketCount: "Satılan Bilet Adeti",
    refundTicketCount: "İade Bilet Adeti",
    revenue: "Tutar",
    refundAmount: "İade Tutarı",
    couponAmount: "Kupon Tutarı",
    ticketName: "Bilet Adı",
    count: "Adet",
    price: "Fiyat",
    total: "Toplam Fiyat",
    refreshAt: "Yenileme Tarihi : {{date}}",
  },
  home: {
    tabTitle: "Anasayfa",
    title: "Anasayfa",
    upcomingEvents: "Yaklaşan Etkinlikler",
    activeEvents: "Tüm Aktif Etkinlikler",
    noSearchResult: "Herhangi bir sonuç bulunamadı",
    ticketSales: "Bilet Satışları",
    lastTickets: "Son Biletler",
  },
  sideTab: {
    event: "Etkinlik",
    overview: "Görünüş",
    tickets: "Biletler",
    coupons: "Kuponlar",
    guests: "Davetliler",
  },
  events: {
    tabTitle: "Etkinliklerim",
    title: "Etkinlikler",
    activeEventCount: "Aktif Event Sayısı",
    activated: "Etkinlik Aktif Edildi",
    deactivated: "Etkinlik Deaktif Edildi",
    scan: "Bilet Tarat",
    ticketList: "Bilet Listesi",
    ticketTypes: "Bilet Tipleri",
    active: "Aktif Etkinlikler",
    deactiveEvents: "Deaktif Etkinlikler",
    waitingEvents: "Onay Bekleyen Etkinlikler",
    past: "Geçmiş Etkinlikler",
    draft: "Taslak",
    coupons: "Kuponlar",
    stopSale: "Satışı Durdur",
    guests: "Davetliler",
    pastEvent: "Geçmiş Etkinlik",
    reports: "Raporlar",
    waiting: "Onay bekleniyor",
    sale: "Satış",
    revenue: "Ciro",
    refund: "İade",
    scanTicket: "Bilet Tarat",
  },
  sales: {
    scannedBar: "Taratılan Bilet",
    allBar: "Satılan Bilet",
  },
  scanner: {
    selectTicketType: "Bilet türüne göre tarat",
    activeTicketType: "Aktif Bilet Türleri",
    scannerOptions: "Tarayıcı Aksiyonları",
    selectTheTypeOfTicketToScan: "TARATILACAK BİLET TÜRÜNÜ SEÇİN",
    ticketAlreadyScanned: "Bilet Zaten Taratılmış",
    invalidScanned: "Geçersiz Taratma",
    successScan: "Taratma Başarılı",
  },
  ticket: {
    customerInfo: "Müşteri Bilgileri",
    purchaseDate: "Satın Alma Tarihi",
    ticketType: "Bilet Tipi",
    ticketPrice: "Bilet Fiyatı",
    addTicketType: "Yeni bilet tipi ekle...",
    addGenres: "Müzik Türü Ekle",
    totalStock: "Toplam Stok",
    dailyTicketReport: "Günlük Bilet Raporu",
    ticketStatus: "Bilet Durumu",
    scannedBy: "Taratan Kişi",
    scannedAt: "Taratılan Tarih",
    eventName: "Etkinlik Adı",
    resendTicket: "Bileti Tekrar Gönder",
    returnTicket: "İade Et",
    usedCouponType: "Kullanılan Kupon Kodu",
    discountAmount: "İndirim Miktarı",
    discountedTicketPrice: "İndirimli Bilet Fiyatı",
    noSaleTicket: "Henüz satış yapılmadı.",
    refundReasons: "İade Nedenleri",
    removeRefundList: "İade Listesinden Çıkart",
    removedFromRefundList: "İade Listesinden Çıkarıldı",
    whoAddedToList: "İade Listesine Ekleyen",
    addedAtRefundList: "İade Listesine Eklenme Tarihi",
    whyAddToRefundList: "İade Listesine Eklenme Nedeni",
  },
  coupon: {
    name: "Adı",
    used: "Kullanılan",
    discount: "İndirim",
    count: "Adet",
    discountAmount: "İndirim Miktarı",
    activeCoupons: "Aktif Kuponlar",
    couponsUsageRates: "Kupon Kullanım Oranları",
    noItem: "Henüz bir kupon\noluşturulmadı.",
    add: "Yeni Oluştur",
    totalTransactionAmount: "Toplam İşlem Tutarı",
    totalDiscountAmount: "Toplam İndirim Tutarı",
    totalDiscount: "Toplam İndirim",
    percentage: "Yüzdelik İndirim",
    addNewCoupon: "Yeni Kupon Ekle",
    updateCoupon: "Kuponu Güncelle",
    deactivated: "Kupon Deaktif Edildi",
    warningText:
      "Şuanki aktif kuponunuz deaktif olacaktır ve aynı isimle yeni kupon oluşturulacaktır!",
    amount: "Toplam İndirim",
    deleted: "Kupon Silindi",
    countMax: "Kullanım Adedi",
    updated: "Kupon Güncellendi",
    created: "Kupon Oluşturuldu",
    startNow: "Şimdi Başlat",
    title: "Kupon",
  },
  eventAddTicket: {
    name: "Ad",
    price: "Fiyat",
    count: "Miktar",
    stock: "Stok",
    quantity: "Kalan Miktar",
    maxSale: "Max Satış",
    stopSale: "Satışı Durdur",
    startSale: "Satışı Başlat",
  },
  guests: {
    deleteCancelled: "Davetli Silme İptal Edildi",
    deletedGroup: "Davetliler Silindi",
    deleteError: "Davetli Silinirken Bir Hata Oluştu",
    listTitle: "Davetli Listesi",
    addTitle: "Yeni Davetli Ekle",
    updateTitle: "Davetli Güncelle",
    guestPropertyAdded: "Davetli tipi eklendi!",
    scannedBar: "Giriş Yapan",
    allBar: "Davet Edilen",
    userArrived: "Davetli Ulaştı",
    types: "Davetli Tipleri",
    entrantType: "Davetli Tipi",
    entrantCount: "Davetli Sayısı",
    propertyName: "Tip Adı",
    guestTypeDeleted: "Davetli tipi silindi!",
    updated: "Davetli Güncellendi",
    deleted: "Davetli Silindi",
    created: "Davetli Eklendi",
    addProperty: "Yeni Davetli Tipi Ekle",
    editProperty: "Davetli Tipi Düzenle",
    detailTitle: "Davetli Görüntüle",
    arrivedAt: "Ulaştığı Tarih",
    arrivedBy: "Onaylayan",
    createdBy: "Ekleyen",
    createdAt: "Eklendiği Tarih",
    singleGuest: "Tekli",
    multipleGuest: "Çoklu",
    excelGuestDrag:
      "Excel dosyasını alana sürükleyip bırakın veya bir dosya seçmek için tıklayın",
    excelGuestDrop: "Excel dosyasını alana bırakın...",
    multipleInfo:
      " Çoklu Eklenen Davetiyelerde QR kod gitmemektedir. İsimler Davetli Listesine Alınacaktır",
  },
  tasks: {
    tabTitle: "Görevler",
    title: "Görevler",
  },
  messages: {
    tabTitle: "Mesajlar",
    title: "Mesajlar",
  },
  venues: {
    tabTitle: "Mekanlarım",
  },
  artists: {
    tabTitle: "Artistlerim",
  },
  crew: {
    tabTitle: "Ekibim",
    title: "Ekibi Düzenle",
    addNewPermissionType: "Yeni Yetki Ekle",
    addNewMember: "Yeni Kişi Ekle",
    crewList: "Ekip Listesi",
    name_surname: "İsim Soyisim",
    email: "E-Posta Adresi",
    phoneNo: "Telefon No",
    permissions: "Yetkiler",
    permList: "Yetki Listesi",
    addToCrew: "Ekibe Ekle",
    updateMember: "Kişiyi Güncelle",
    inviteToTeam: "Ekibe davet et",
  },
  finance: {
    tabTitle: "Finans",
  },

  reports: {
    tabTitle: "Raporlar",
    date: "Tarih",
    venue: "Mekan",
    promoter: "Organizator",
    contract: "Sözleşme Kodu",
    ticketSales: "Bilet Satış Raporu",
    gender: "Kadın / Erkek Oranı",
    ticketScan: "Bilet Taratılma Oranı",
    ticketScanCount: "Bilet Tiplerine Göre Rapor",
    ticketCountByType: "Bilet Tiplerine Göre Günlük Adet",
    guest: "Davetli Giriş Oranı",
    revenue: "Gelir",
    saleByTicketType: "Bilet Tipine Göre Satış",
    // Report Tabs
    ticketTab: "Bilet Raporu",
    detailTab: "Detaylı Rapor",
    venueTab: "Mekan Raporu",
    scanned: "Taratılan",
    not_scanned: "Taratılmayan",
    not_entrant: "Not Entrant",
    transactions: "İşlemler",
    entrance_count: "Giriş Sayısı",
    date_by: "Tarihe Göre",
    refundReport: "İade Raporu",
    date_range: "Tarih Aralığı",
    hourly: "Saatlik",
    daily: "Günlük",
    weekly: "Haftalık",
    monthly: "Aylık",
  },
  refund: {
    refundReasonTitle: "İade Nedenleri",
    refundedTickets: "İade Edilen Biletler",
    refundPrice: "İade Tutarı",
    refundCount: "İade Adedi",
    refundReason: "İade Nedeni",
    refundReasonAdded: "İade Nedeni Eklendi",
    refundReasonDeleted: "İade Nedeni Silindi",
    refundSuccess: "İade Başarıyla Gerçekleştir",
    name: "Adı",
    price: "Fiyatı",
    count: "Adedi",
  },
  attachments: {
    artistImage: "Artist Fotoğrafı",
    bannerImageRectangle: "Banner fotoğrafı (Dikdörtgen)",
    bannerImageSquare: "Banner fotoğrafı (Kare)",
    menu: "Menü",
    indoorImages: "İç Mekan Resimleri",
    seatMap: "Seat Map",
    video: "Video",
    seatMap: "Kroki",
    podcast: "Podcast (Embed Code)",
  },
  settings: {
    tabTitle: "Ayarlar",
    title: "Ayarlar",
    guestsProperty: "Davetli Tip Ayarları",
    refundReasonSetting: "İade Nedeni Ayarları",
    chooseAccount: "Hesap Seç",
    changeAccount: "Hesap Değiştir",
    personalInformation: "Kişisel Bilgiler",
    promoterInfo: "Promoter Bilgileri",
    billingInformation: "Fatura Bilgileri",
    editCrew: "Ekibi Düzenle",
    sessions: "Aktif Oturumlar",
    privacy: "Gizlilik Sözleşmesi",
    session: "Aktif Oturum",
    logout: "Çıkış Yap",
  },
  artistForm: {
    details: "Artist Detayları",
    attachments: "Eklentiler",
    desc: "Açıklama",
    rules: "Kurallar",
    name: "Adı",
    resident: "Resident",
    label: "Etiket",
    labelLink: "Etiket Linki",
    website: "Website",
    socialMedia: "Sosyal Medya",
    soundCloud: "Soundcloud",
    genres: "Müzik türleri",
    next: "Devam Et",
    finish: "Bitir",
    update: "Artist Güncellendi!",
    created: "Artist Oluşturuldu!",
  },
  guestForm: {
    name: "İsim Soyisim",
    email: "E-Posta",
    guestType: "Davetli Tipi",
    ticketType: "Bilet Tipi",
    count: "Adet",
    addNew: "Yeni misafir ekle",
  },
  eventTickets: {
    tickets: "Biletler",
    active: "Aktif",
    deactive: "Deaktif",
    addNewTicket: "Yeni Bilet Ekle",
    selectPromotion: "Promosyon Durumu Seçiniz",
    updateTicket: "Bileti Güncelle",
    notAnyTicket: "Henüz bir bilet\noluşturulmadı.",
    createNew: "Yeni Oluştur",
    sold: "Satılan",
    remainingStock: "Kalan Stok",
    totalStock: "Toplam Stok",
  },
  eventForm: {
    eventBasics: "Temel Bilgiler",
    eventDetails: "Etkinlik Detayları",
    eventContents: "Etkinlik İçerik",
    eventCategory: "Katagori",
    tickets: "Biletler",
    seo: "Seo",
    attachments: "Eklentiler",
    coupons: "Kuponlar",
    currencyUnit: "Para birimi",
    eventName: "Adı",
    eventDesc: "Açıklaması",
    eventRules: "Kuralları",
    eventDates: "Tarihi",
    eventGroup: "Etkinlik Grubu",
    addEventToGroup: "Etkinliği gruba ekle",
    addTicket: "Bilet ekle",
    addDj: "Dj ekle",
    next: "Devam Et",
    finish: "Bitir",
    nameError: "Etkinlik İsmi Boş Bırakılamaz",
    saveAsDraft: "Taslak Olarak kaydet",
    addNewVenue: "Yeni Mekan Ekle",
    addNewArtist: "Yeni Sanatçı Ekle",
    newVenueArtistWarning:
      "Yeni Artist/Mekan ekleme sayfasına yönlendirileceksiniz. Yeni eklenen mekan ve sanatçılar BUGECE ekibi tarafından onay sürecinden geçecektir. Yapmış oldduğunuz değişiklikleri taslak olarak kaydedip daha sonra etkinlik kaydını tamamlayabilirsiniz",
  },

  days: {
    monday: "Pazartesi",
    tuesday: "Salı",
    wednesday: "Çarsamba",
    thursday: "Perşembe",
    friday: "Cuma",
    saturday: "Cumartesi",
    sunday: "Pazar",
  },
  venueForm: {
    basics: "Temel Bilgiler",
    description: "Açıklamalar",
    socialMedia: "Sosyal Medya",
    details: "Mekan Detayları",
    tickets: "Biletler",
    seo: "Seo",
    attachments: "Eklentiler",
    residentArtists: "Resident Artistler",
    phone: "Telefon",
    coupons: "Kuponlar",
    name: "Adı",
    priceRange: "Fiyat Aralığı",
    desc: "Açıklaması",
    categories: "Kategoriler",
    extraFields: "Ekstra Alanlar",
    genres: "Müzik Türü",
    days: "Açık Olduğu Günler",
    rules: "Kuralları",
    address: "Adresi",
    map: "Harita",
    website: "Website",
    socialMedia: "Sosyal Medya",
    next: "Devam Et",
    finish: "Bitir",
    created: "Mekan oluşturuldu.",
    updated: "Mekan güncellendi.",
  },
  notifyMessages: {
    successEvent: "Etkinlik oluşturuldu.",
    successEventUpdate: "Etkinlik güncellendi.",
    successEventDraft: "Etkinlik Taslak Olarak Kaydedildi",
    successVenue: "Mekan oluşturuldu.",
    successArtist: "Artist oluşturuldu.",
    updatingEvent: "Etkinlik güncelleniyor...",
    creatingEvent: "Etkinlik oluşturuluyor...",
    logoutedAll: "Tüm cihazlardan çıkış yapıldı..",
  },
  notFound: {
    venue: "Hiç mekan bulunamadı.",
    venueExp: "Aşağıdaki butonlar yardımıyla mekanlarınızı oluşturabilirsiniz.",
    artist: "Hiç artist bulunamadı.",
    artistExp:
      "Aşağıdaki butonlar yardımıyla artistlerinizi oluşturabilirsiniz.",
    guest: "Hiç misafir bulunamadı!",
    guestExp:
      "Aşağıdaki butonlar yardımıyla misafirlerinizi oluşturabilirsiniz.",
  },
  filter: {
    sortBy: "Sırala",
    filter: "Filtrele",
    permission: "Yetki",
    byScanned: "Taratılmaya göre",
    day: "Gün",
    week: "Hafta",
    month: "Ay",
    year: "Yıl",
  },
  notifications: {
    title: "Bildirimler",
    explanation:
      "Yepyeni bir BUGECE deneyimi için yenilendik 💛 Önerileriniz ve fikirleriniz bizim için önemli, bize buradan ulaşabilirsin 💛",
  },
  user: {
    full_name: "Ad Soyad",
    username: "Kullanıcı Adı",
    email: "E-Posta",
    phone: "Telefon",
  },
  promoter: {
    name: "Ad",
    city: "Şehir",
    address: "Adres",
    email: "E-Posta",
    phone: "Telefon",
    taxNo: "Vergi No",
    taxOffice: "Vergi Dairesi",
    website: "Website",
    socialMedia: "Sosyal Medya",
  },
  status: {
    refundListDeclined: "etkinliğine ait iade listesi onaylanmadı.",
    refundListConfirmed: "etkinliğine ait iade listesi onaylandı.",
    login: "Giriş Yap",
    eventPage: "Etkinlik Sayfası",
  },
  signupModal: {
    title: "Hesabınızı oluşturun",
    subtitle:
      "BUGECE Pro'nun özelliklerinden faydalanmak için hesabınızı oluşuturun.",
    name: "Adınız",
    surname: "Soyadınız",
    birthdate: "Doğum Tarihiniz",
    mail: "E-Posta",
    day: "Gün",
    month: "Ay",
    year: "Yıl",
    gender: "Cinsiyetiniz",
    female: "Kadın",
    male: "Erkek",
    password: "Şifreniz",
    passwordAgain: "Şifrenizi tekrar giriniz",
    countryCode: "Ülke Kodu",
    phoneNumber: "Telefon Numaranız",
    register: "Hesap Oluştur",
    term: "Kullanım Şartlarını",
    privacy: "Gizlilik Politikasını",
    and: "ve",
    accept: "okudum ve kabul ediyorum",
    activeLang: "tr",
    confirm: "Onayla",
  },

  utils: {
    editEvent: "Etkinliği Düzenle",
    unauthorized: "Tekrardan giris yapiniz",
    search: "Arama yap...",
    sort: "Sırala",
    active: "Aktif",
    name: "İsim Soyisim",
    email: "E-posta Adresi",
    profile: "Profilim",
    signOut: "Çıkış Yap",
    select: "Seçiniz",
    or: "ya da",
    add: "Ekle",
    all: "Hepsi",
    update: "Güncelle",
    edit: "Düzenle",
    detail: "Detaylar",
    inbox: "Gelen Kutusu",
    tasks: "Görevler",
    promoterUpdated: "Promoter güncellendi",
    userUpdated: "Kullanıcı güncellendi",
    subscription: "Abonelikler",
    promotions: "Promosyonlar",
    promoters: "Destekçiler",
    guests: "Misafirler",
    delete: "Sil",
    done: "Tamam",
    fbPixelCode: "Facebook pixel kodu",
    all: "Hepsi",
    viewAll: "Hepsini gör",
    save: "Kaydet",
    cancel: "İptal",
    start_date: "Başlangıç Tarihi",
    end_date: "Bitiş Tarihi",
    start_time: "Başlangıç saati",
    end_time: "Bitiş saati",
    from: "Başlangıç",
    to: "Bitiş",
    isBugeceUser: "Davetlinin BUGECE hesabı var mı?",
    male: "Erkek",
    female: "Kadın",
    other: "Diğer",
    activate: "Aktif Et",
    deactivate: "Deaktif Et",
    device: "Cihaz",
    warning: "Uyarı",
    continue: "Devam Et",
    operatingSystem: "İşletim Sistemi",
    created: "Yaratılma Tarihi",
    expiration: "Geçerlilik Sonu",
    logoutAll: "Tüm Cihazlardan Çıkış Yap",
    areYouSure: "Emin misiniz?",
    logoutAllText: "Tüm cihazlardan çıkış yapmak istediğinize emin misiniz?",
    yes: "Evet",
    download: "İndir",
    preparing: "Hazırlanıyor",
    no: "Hayır",
    apply: "Uygula",
    activeDevices: "Aktif cihazlar",
    viewMore: "Daha fazla göster...",
    viewLess: "Daha az göster",
    changeAccount: "Hesap değiştir",
    newEvent: "Yeni Etkinlik",
    newVenue: "Yeni Mekan",
    newArtist: "Yeni Artist",
    newPromoter: "Yeni Destekçi",
    newGuest: "Yeni Misafir",
    newPromotion: "Yeni Promosyon",
    newCrew: "Yeni Ekip",
    startNow: "Şimdi Başlat",
    backHome: "Anasayfa'ya Dön",
    startDate: "Başlangıç Tarihi",
    endDate: "Bitiş Tarihi",
    goEvent: "Etkinlik Sayfasına Git",
    imageSizeError: "Görselin boyutları hatalı!",
    loading: "Yükleniyor...",
    error: "Bir sorun oluştu!",
    sortByDateFirst: "Tarihe göre (önce yeni tarihli etkinlik)",
    sortByDateSecond: "Tarihe göre (önce eski tarihli etkinlik)",
    country: "Ülke",
    city: "Şehir",
    open: "Açılış",
    close: "Kapanış",
    bannerImageDescription: "(Maksimum 1920x710 boyutlarında olmalıdır.)",
    thumbnailImageDescription: "(Maksimum 1080x1080 boyutlarında olmalıdır.)",
    desc: "Açıklama",
    refundReasonError: "Bir iade nedeni seçiniz!",
  },
};

export default tr;
