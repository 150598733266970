import React, { useEffect } from "react";
import numberToMoney from "../../../utils/numberToMoney";
import { FiSettings } from "react-icons/fi";
import { HiMinusCircle } from "react-icons/hi";
import { amountConverter } from "../../../utils/stringUtils";
import { MdDragIndicator } from "react-icons/md";
import { RiDragMoveLine } from "react-icons/ri";

const TicketBlock = ({
  promotion,
  item,
  provided,
  index,
  t,
  removeTicket,
  getValues,
  configureTicket,
}) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className="relative rounded-lg border border-gray-300 bg-white px-6 py-1 shadow-sm flex items-center space-x-3 hover:border-gray-400 justify-between flex-wrap"
    >
      <div className="flex-1 min-w-0 pl-2">
        <span className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />

          <div className="flex flex-row items-center justify-start gap-4 mt-2">
            <p className="text-sm text-gray-500 font-semibold">
              {t("coupon:name")}:
              <label className="font-bold text-black">{" " + item.name}</label>
            </p>
          </div>
          <div className="flex flex-row items-center justify-start gap-2">
            <p className="text-xs w-30  truncate mb-2 text-gray-500 font-semibold">
              {t("ticket:ticketPrice")}:{" "}
              <label className="font-bold text-black">
                {" " + amountConverter(item.value) + " "}{" "}
                {getValues("currencyUnit").value
                  ? getValues("currencyUnit").value
                  : ""}
              </label>
            </p>{" "}
            <p className="text-xs w-30 text-gray-500 truncate mb-2 font-semibold">
              Promotion:{" "}
              <label className="text-black font-bold">
                {item?.promotions?.length > 0
                  ? promotion?.name
                  : "Promosyon Yok"}
              </label>
            </p>
            <p className="text-xs w-30 text-gray-500 truncate mb-2 font-semibold">
              {t("ticket:totalStock")}:{" "}
              <label className="text-black font-bold">
                {" "}
                {item.total_stock}
              </label>
            </p>
            <p className="text-xs w-20 text-gray-500 truncate mb-2 font-semibold">
              {t("eventAddTicket:maxSale")}:{" "}
              <label className="text-black font-bold"> {item.count_max}</label>
            </p>
          </div>
        </span>
      </div>
      <div className="flex-shrink-0 flex flex-row z-1flew-wrap">
        <div className="flex min-w-0 flex-col justify-between ml-4 items-center">
          <button
            type="button"
            onClick={() => removeTicket(index)}
            className="absolute z-1 text-red-600 -top-3 -right-3"
          >
            <HiMinusCircle className="w-6 h-6" />
          </button>
          <button
            type="button"
            className="absolute z-1 text-secondary-600 top-1/2 left-1 transform  -translate-y-1/2  cursor-move  transition duration-500 ease-in-out"
          >
            <MdDragIndicator className="w-6 h-6" />
          </button>
          <button
            type="button"
            onClick={() => configureTicket(index)}
            className="absolute z-1 top-1/2  transform  -translate-y-1/2 right-3 bg-primary-500 p-2 text-white rounded-[8px]"
          >
            <FiSettings className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default TicketBlock;
