import React from "react";
import { useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";

const ExcelGuestItem = ({ item, guests, setGuests }) => {
  const onDelete = () => {
    setGuests(guests.filter((g) => g._id !== item._id));
  };

  useEffect(() => {
    console.log(guests);
  }, [guests]);

  return (
    <>
      <div className="relative justify-between mt-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
        <div className="flex">
          <p className="text-sm font-medium text-gray-900">{item.name}</p>
        </div>
        <ImCancelCircle
          className="h-6 w-6 text-primary-500"
          aria-hidden="true"
          onClick={() => {
            onDelete();
          }}
        />
      </div>
    </>
  );
};

export default ExcelGuestItem;
