import React, { useEffect, useState } from "react";
import { FormInput } from "../../../components";

const DayBlock = ({
  t,
  item,
  register,
  index,
  getValues,
  setValue,
  errors,
}) => {
  return (
    <div className="relative w-full rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
      <div className="flex-1 min-w-0">
        <span className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{item.label}</p>
          {/*<p className="text-sm text-gray-500 truncate">{item.value}</p> */}
        </span>
      </div>
      <div className="flex-shrink-0 flex flex-row z-1 relative">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="start_date"
            className={`block text-sm font-medium ${
              errors?.opening_dates?.[index]?.open
                ? "text-red-600"
                : "text-gray-700"
            }`}
          >
            {t("utils:open")}
          </label>
          <FormInput
            className={`${
              errors?.opening_dates?.[index]?.open ? "!border-red-600" : ""
            }`}
            type="time"
            value={getValues(`opening_dates.${index}.open`)}
            onChange={(e) => {
              setValue(`opening_dates.${index}.open`, e.target.value);
            }}
          />
        </div>
        <div className="col-span-6 sm:col-span-3 ml-4">
          <label
            htmlFor="end_date"
            className={`block text-sm font-medium ${
              errors?.opening_dates?.[index]?.close
                ? "text-red-600"
                : "text-gray-700"
            }`}
          >
            {t("utils:close")}
          </label>
          <FormInput
            className={`${
              errors?.opening_dates?.[index]?.close ? "!border-red-600" : ""
            }`}
            type="time"
            value={getValues(`opening_dates.${index}.close`)}
            onChange={(e) => {
              setValue(`opening_dates.${index}.close`, e.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DayBlock;
