import { action, makeAutoObservable } from "mobx";
import { makePersistable, isHydrated, hydrateStore } from "mobx-persist-store";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AuthStore",
      properties: ["accessToken", "isLogged", "promoter", "user", "language"],
      storage: localStorage,
    });
  }

  initialAccessToken = "";
  accessToken = "";
  language = "tr";
  user = {};
  promoter = {};
  roles = [];
  isLogged = false;

  @action signOut = () => {
    this.accessToken = "";
    this.setPromoter({});
    this.setUser({});
    this.setLogged(false);
  };

  @action
  setLogged = (status) => {
    this.isLogged = status;
  };

  @action
  setInitialAccessToken = (token) => {
    this.initialAccessToken = token;
  };

  @action
  setLanguage = (lang) => {
    this.language = lang;
  };

  @action
  setAccessToken = (token) => {
    this.accessToken = token;
    this.setLogged(true);
  };

  @action
  setUser = (payload) => {
    this.user = payload;
  };

  @action
  setPromoter = (payload) => {
    this.promoter = payload;
  };

  @action
  setRoles = (payload) => {
    this.roles = payload;
  };

  get isHydrated() {
    return isHydrated(this);
  }

  async hydrateStore() {
    await hydrateStore(this);
  }
}

const authStore = new AuthStore();

export default authStore;
