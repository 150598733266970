import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FilterTabs, GenericButton, Loading } from "../../../../components";
import instance, {benufoUrl} from "../../../../lib/client";
import EventBlock from "./components/EventBlock";
import Select from "react-select";
import {AiFillFileExcel} from "react-icons/ai";
import ReactLoading from 'react-loading';
import downloadExcelFile from "../../../../utils/downloadExcelFile";
import useStore from "../../../../hooks/useStores";
import {isHaveAccess} from "../../../../utils/roleUtils";

function Events() {
  const [loading, setLoading] = React.useState(true);
  const [events, setEvents] = React.useState([]);
  const [tempEvents, setTempEvents] = useState([]);
  const { t } = useTranslation();
  const {auth} = useStore()
  const [refresh, setRefresh] = useState(false);
  const [excelLoading,setExcelLoading] = useState(false);
  const [isActiveSort, setIsActiveSort] = useState(false);

  useEffect(() => {
    instance.get("event").then((data) => {
      setTempEvents(
        data.data.data.sort(
          (a, b) => moment(b.start_time) - moment(a.start_time)
        )
      );
      setEvents(
        data.data.data.sort(
          (a, b) => moment(b.start_time) - moment(a.start_time)
        )
      );
      setLoading(false);
    });
  }, []);

  const onSortChange = (value) => {
    let sortedEvents = [];

    if (!isActiveSort) {
      if (value.value === "desc") {
        sortedEvents = events.sort(
          (a, b) => moment(a.start_time) - moment(b.start_time)
        );
      } else {
        sortedEvents = events.sort(
          (a, b) => moment(b.start_time) - moment(a.start_time)
        );
      }
      setEvents(sortedEvents);
      setRefresh(!refresh);
      return;
    } else {
      if (value.value === "desc") {
        sortedEvents = events.sort(
          (a, b) => moment(b.start_time) - moment(a.start_time)
        );
      } else {
        sortedEvents = events.sort(
          (a, b) => moment(a.start_time) - moment(b.start_time)
        );
      }
      setEvents(sortedEvents);
      setRefresh(!refresh);
    }
  };

  const onFilterChange = (value) => {
    setIsActiveSort(false);
    switch (value.value) {
      case "all":
        setEvents(tempEvents);
        break;
      case "active":
        setIsActiveSort(true);
        setEvents(
          tempEvents.filter(
            (event) =>
              event.isActive &&
              event.for_sale &&
              !moment().isAfter(
                moment(
                  event.end_date ? event.end_date : event.end_time
                ).toDate()
              )
          )
        );
        break;
      case "deactive":
        setEvents(
          tempEvents.filter(
            (event) =>
              event.isActive &&
              !event.for_sale &&
              !moment().isAfter(
                moment(
                  event.end_date ? event.end_date : event.end_time
                ).toDate()
              )
          )
        );
        break;
      case "past":
        setEvents(
          tempEvents.filter((event) =>
            moment().isAfter(
              moment(event.end_date ? event.end_date : event.end_time).toDate()
            )
          )
        );
        break;
      case "waiting":
        setEvents(
          tempEvents.filter(
            (event) =>
              !event.isActive &&
              !moment().isAfter(
                moment(
                  event.end_date ? event.end_date : event.end_time
                ).toDate()
              )
          )
        );
        break;
      case "draft":
        setEvents(tempEvents.filter((event) => event?.type == "draft"));
        break;
    }

    setRefresh(!refresh);
  };

  const downloadEventsWithExcel = async () => {
      setExcelLoading(true);


      await downloadExcelFile(benufoUrl+'pro/promoter-excel-report','events.xlsx',auth.accessToken, setExcelLoading)
  }

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
      {/* Main 1 column grid */}
      <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 lg:gap-8">
        {/* one column */}
        <div className="grid grid-cols-1 gap-4 lg:col-span-1 ">
          <section aria-labelledby="section-1-title ">
            <h2 className="sr-only" id="section-1-title">
              Section title
            </h2>
            <div className="rounded-lg bg-white overflow-y-visible shadow">
              {loading ? (
                <div className="p-6">
                  <Loading />
                </div>
              ) : (
                <div className="p-6">
                  <div className="mb-4 w-full flex md:flex-row xs:flex-col items-center">
                    <Select
                      className="md:min-w-[200px] xs:min-w-full md:mb-0 xs:mb-2"
                      placeholder={t("utils:sort")}
                      onChange={onSortChange}
                      options={[
                        {
                          label: t("utils:sortByDateFirst"),
                          value: "asc",
                        },
                        {
                          label: t("utils:sortByDateSecond"),
                          value: "desc",
                        },
                      ]}
                    />
                    <Select
                      className="md:min-w-[200px] xs:min-w-full md:ml-4 xs:ml-0 md:mb-0 xs:mb-2 z-50"
                      placeholder={t("filter:filter")}
                      onChange={onFilterChange}
                      options={[
                        {
                          label: t("utils:all"),
                          value: "all",
                        },
                        {
                          label: t("events:past"),
                          value: "past",
                        },
                        {
                          label: t("events:active"),
                          value: "active",
                        },
                        {
                          label: t("events:deactiveEvents"),
                          value: "deactive",
                        },
                        {
                          label: t("events:waitingEvents"),
                          value: "waiting",
                        },
                        {
                          label: t("events:draft"),
                          value: "draft",
                        },
                      ]}
                    />
                    <div className={'flex flex-row items-center justify-end w-full gap-x-4'}>
                      {isHaveAccess('all:access') && <GenericButton className="shadow h-8 flex w-14 items-center justify-center py-1 px-4 h-full" onClick={() => {
                        if(!excelLoading) downloadEventsWithExcel()
                      }}>
                        {excelLoading ? <ReactLoading width={32} height={22} color={'white'}/> : <AiFillFileExcel/>}
                      </GenericButton>}

                    <Link
                      to={"/dashboard/new-event"}
                      className="flex items-center h-8 md:w-40 xs:w-full"
                    >
                      <GenericButton className="shadow py-1 px-4 h-full">
                        {t("utils:newEvent")}
                      </GenericButton>
                    </Link>
                    </div>
                  </div>
                  <div className="bg-white shadow overflow-hidden sm:rounded-md">
                    <div
                      className="divide-y divide-gray-200 overflow-y-auto"
                      style={{ maxHeight: 800 }}
                    >
                      {events.map((event, i) => {
                        return <EventBlock key={i} event={event} />;
                      })}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Events;
