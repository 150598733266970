import authStore from "../stores/authStore";
const instance = require("../lib/client");

class AuthService {
  setAuthorizationToken(token) {
    instance.default.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
  setUnauthorizedInterceptor(value) {
    if (value?.response?.status === 401) {
      authStore.signOut();
      window.location.pathname != "/auth/login" &&
        (window.location.href = "/auth/login");
    } else if (value?.response?.status === 403) {
      authStore.signOut();
      window.location.href = "/auth/login";
    } else {
      return Promise.reject(value);
    }
  }
}
const authService = new AuthService();
export default authService;
